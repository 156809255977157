import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

const StyledDiscountedAmountBadge = styled.div`
  & label {
    display: block;
    margin-bottom: 3px;
    line-height: 1.43;
    font-weight: 600;
    color: ${props => props.theme.neutral5};
  }
  & .amount-badge-currency {
    font-size: 16px;
    margin-right: 5px;
    color: ${props => props.theme.neutral4};
    text-transform: uppercase;
  }

  & .amount-badge-amount {
    font-weight: 600;
    font-size: 16px;
    color: ${props => props.theme.neutralBase};
  }

  & .discounted-amount-badge-amount {
    font-size: 14px;
    color: ${props => props.theme.neutral5};
    text-decoration: line-through;
  }

  & .amount-free {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.color || props.theme.greenBase};
  }
`;

const DiscountedAmountBadge = ({
  amount: { currency, amount, discountedAmount },
  label,
  color
}) => {
  const parsedAmount = parseFloat(amount);
  return (
    <Translate>
      {({ translate }) => (
        <StyledDiscountedAmountBadge
          color={color}
          className="discounted-amount-badge"
        >
          <div className="row">
            <div className="col-6 col-sm-12 col-md-12">
              {label && <label>{label}</label>}

              {!!discountedAmount && discountedAmount !== amount && (
                <span className="discounted-amount-badge-amount">
                  {currency}{" "}
                  {currency.toLowerCase() !== "мкд" &&
                  currency.toLowerCase() !== "mkd"
                    ? parseFloat(discountedAmount).toFixed(2)
                    : parseFloat(discountedAmount).toFixed(0)}
                </span>
              )}
            </div>
            <div className="col-6 col-sm-12 col-md-12">
              {parsedAmount > 0 && (
                <span className="amount-badge-currency">{currency}</span>
              )}
              {parsedAmount > 0 && (
                <span className="amount-badge-amount">
                  {currency.toLowerCase() !== "мкд" &&
                  currency.toLowerCase() !== "mkd"
                    ? parseFloat(amount).toFixed(2)
                    : parseFloat(amount).toFixed(0)}
                </span>
              )}
              {parsedAmount === 0 && (
                <span className="amount-free">{translate("general.free")}</span>
              )}
              {parsedAmount == -1 && (
                <span className="not-available">
                  {translate("general.not-available")}
                </span>
              )}
            </div>
          </div>
        </StyledDiscountedAmountBadge>
      )}
    </Translate>
  );
};

DiscountedAmountBadge.defaultProps = {
  amount: {
    currency: "MKD",
    amount: 0,
    discountedAmount: 0
  },
  label: null
};

export default DiscountedAmountBadge;
