import React from "react";
import styled from "styled-components";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Tabs from "../../../../components/Tabs";
import Tab from "../../../../components/Tabs/Tab";
import TabContent from "../../../../components/Tabs/TabContent";

import MacOsAddNewAccountImage from "../Images/MacOsAddNewAccountImage";
import MacOsImapServerSettingsImage from "../Images/MacOsImapServerSettingsImage";
import MacOsPopServerSettingsImage from "../Images/MacOsPopServerSettingsImage";
import AndroidPopAddNewAccountImage from "../Images/AndroidPopAddNewAccountImage";

const StyledImage = styled.img`
  width: ${props => {
    if (props.isMobile) return "380px";
    if (props.isTablet) return "380px";
    return "600px";
  }};
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-macos.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

class MacOsImapStepsPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container text-center">
                  <Tabs
                    defaultTab={
                      this.props.currentStep > 0
                        ? `step${this.props.currentStep}`
                        : "step1"
                    }
                    renderTabs={(active, onSelect) => [
                      <Tab
                        key="step-1"
                        label="step1"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(1);
                        }}
                      >
                        {translate("email-tutorials-step-1")}
                      </Tab>,
                      <Tab
                        key="step-2"
                        label="step2"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(2);
                        }}
                      >
                        {translate("email-tutorials-step-2")}
                      </Tab>,
                      <Tab
                        key="step-3"
                        label="step3"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(3);
                        }}
                      >
                        {translate("email-tutorials-step-3")}
                      </Tab>,
                      <Tab
                        key="step-4"
                        label="step4"
                        active={active}
                        onSelect={onSelect}
                      >
                        {translate("email-tutorials-step-4")}
                      </Tab>
                    ]}
                    renderTabContent={active => (
                      <React.Fragment>
                        <TabContent key="step-1" label="step1" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.macos-steps.open-your-mail-application-from-mac"
                            )}
                          </p>
                          {activeLanguage && (
                            <StyledImage
                              src={
                                this.props.images[
                                  `1-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                              className="img-fluid"
                              isMobile={isMobileOnly}
                              isTablet={isTablet}
                              isBrowser={isBrowser}
                            />
                          )}
                        </TabContent>

                        <TabContent key="step-2" label="step2" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.macos-steps.please-enter-info"
                            )}
                          </p>
                          {this.props.email && (
                            <MacOsAddNewAccountImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `2-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </TabContent>

                        <TabContent key="step-3" label="step3" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.macos-steps.please-follow-instruction"
                            )}
                          </p>
                          {this.props.email && (
                            <MacOsImapServerSettingsImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `3-IMAP-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </TabContent>

                        <TabContent key="step-4" label="step4" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.macos-steps.if-you-need-to-change-ports"
                            )}
                            :
                          </p>
                          <StyledImage
                            src={
                              this.props.images[
                                `4-${activeLanguage.code.toLowerCase()}.png`
                              ]
                            }
                            className="img-fluid"
                            isMobile={isMobileOnly}
                            isTablet={isTablet}
                            isBrowser={isBrowser}
                          />
                          <a
                            className="d-block"
                            href="https://blog.mkhost.com/so-promena-na-soodvetna-porta-do-pobezbedna-email-komunikacija/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Со промена на соодветна порта до безбедна емаил
                            комуникација – МКхост Блог
                          </a>
                        </TabContent>
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default MacOsImapStepsPage;
