import React, {createRef} from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as ebookDisplayService from "../../services/ebookDisplayService";


import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";

import WebsiteLocationSection from "../../components/Sections/WebsiteLocationSection";
import WebsiteSpaceSection from "../../components/Sections/WebsiteSpaceSection";
import WebsiteBuildSection from "../../components/Sections/WebsiteBuildSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import ProjectQuoteFormSection from "../../components/Sections/ProjectQuoteFormSection";

import SubscribeFreeEbookModal from "../../components/SubscribeFreeEbookModal";

import {
  ROUTE_WEB_DESIGN_PREMIUM,
  ROUTE_HOSTING_WEB_HOSTING,
  ROUTE_WEBSITE_DOMAIN
} from "../../routes";

import seoData from "../../seo/seoData";

const StyledFirstWebsiteWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 130px;

  & .domain-search-container {
    background-color: ${props => props.theme.neutralBase};
  }

  & .domain-section-search-wrapper {
    background-color: ${props => props.theme.blue1};
  }
`;

const StyledQuoteSection = styled.div`
  padding: ${props => (props.isMobile ? "15px 0" : "75px 0")};
  padding-bottom: ${props => (props.isMobile ? "60px" : "150px")};
`;

class OtherOtherFirstWebsitePage extends React.Component {
  constructor(props) {
    super(props);
    this.quoteRef = createRef();
    this.state = {
      selectedExtension: null,
      domain: null,
      showSubscribeFreeEbookModal: false,
      ebookSeen: ebookDisplayService.shouldEbookShow()
    };
  }

  onHideSubscribeFreeEbookModal = () => {
    this.setState({
      showSubscribeFreeEbookModal: false
    });
  };

  scrollToQuoteSection = () => {
    if (this.quoteRef.current) {
      this.quoteRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  onScrollShowFreeEbookModal = e => {
    if (
      window.scrollY > 200 &&
      !this.state.showSubscribeFreeEbookModal &&
      !this.state.ebookSeen
    ) {
      this.setState(
        {
          showSubscribeFreeEbookModal: true,
          ebookSeen: true
        },
        () => {
          ebookDisplayService.markEbookAsSeen();
        }
      );
    }
  };

  // componentDidMount() {
  //   window.addEventListener("scroll", this.onScrollShowFreeEbookModal);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.onScrollShowFreeEbookModal);
  // }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_first_website.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_first_website
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_first_website
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].other_first_website.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_first_website
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_home-new.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].other_first_website.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_first_website
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_home-new.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("a-home-for-your-website")}
              backgroundColor="neutralBase"
              color="white"
              paddingBottom="0"
              subtitle={translate(
                "building-a-website-has-never-been-easier.-learn-about-the-3-key-components-of-every-website,-and-get-started-on-your-first-website"
              )}
              image="/assets/illustrations/illustration_home_main.png"
            />

            <StyledFirstWebsiteWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <WebsiteLocationSection />
                <DomainsSearchSection
                  type="register"
                  hideQuickPrices={true}
                  searchPlaceholder={translate(
                    "enter-a-domain-name-you-are-looking-for"
                  )}
                />
                <WebsiteSpaceSection />
                <WebsiteBuildSection onScrollToQuote={this.scrollToQuoteSection}/>

                <StyledQuoteSection ref={this.quoteRef} className="row" isMobile={isMobileOnly}>
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate(
                                "are-you-ready-for-your-first-website"
                              )}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                "send-us-your-idea-and-together-we'll-try-to-realize-it-and-build-it"
                              )}
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-8">
                            <ProjectQuoteFormSection />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledQuoteSection>
              </div>
            </StyledFirstWebsiteWrapper>
            {this.state.showSubscribeFreeEbookModal && (
              <SubscribeFreeEbookModal
                onCloseModal={this.onHideSubscribeFreeEbookModal}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherOtherFirstWebsitePage;
