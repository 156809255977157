const BASE_API_ENDPOINT = process.env.REACT_APP_MKHOST_ENDPOINT
  ? process.env.REACT_APP_MKHOST_ENDPOINT
  : "http://api.dev.mk-host.mk/v1";

export const ROOT_DOMAIN_NO_HTTPS = process.env.REACT_APP_MKHOST_ROOT_DOMAIN
  ? process.env.REACT_APP_MKHOST_ROOT_DOMAIN
  : "mk-host.mk";

export const CLIENTS_APP_DOMAIN = process.env.REACT_APP_MKHOST_CLIENTS_DOMAIN
  ? process.env.REACT_APP_MKHOST_CLIENTS_DOMAIN
  : "http://clients.dev.mk-host.mk";

export const WEBSITE_DOMAIN = process.env.REACT_APP_MKHOST_WEBSITE_DOMAIN;

export const TEMPLATES_DOMAIN = process.env.REACT_APP_MKHOST_TEMPLATES_DOMAIN;

export const AUTH_ME_API_ENDPOINT = `${BASE_API_ENDPOINT}/me`;

export const DONATION_API_ENDPOINT = `${BASE_API_ENDPOINT}/donation-percentage`;



export const DOMAINS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/domain`;
export const DOMAIN_AVAILABILITY_ENDPOINT = `${DOMAINS_API_ENDPOINT}/check`;
export const DOMAINS_LISTING_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/top-level-domain/listing`;
export const DOMAINS_LISTING_BY_GROUP_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/top-level-domain/by-group`;
export const DOMAINS_WHOIS_API_ENDPOINT = `${DOMAINS_API_ENDPOINT}/who-is`;
export const DOMAINS_SUGGESTIONS_ENDPOINT = `${DOMAINS_API_ENDPOINT}/tld/suggestions`;
export const DOMAIN_PRICES_ENDPOINT = `${DOMAINS_API_ENDPOINT}/top-level-domain/by-group/generate-pdf`;

export const HOSTING_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/hosting`;
export const HOSTING_LISTING_API_ENDPOINT = `${HOSTING_API_ENDPOINT}/listing`;
export const HOSTING_LISTING_BY_GROUP_API_ENDPOINT = `${HOSTING_API_ENDPOINT}/listing/by-group`;

export const SERVER_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/cloud-server`;
export const SERVER_LISTING_API_ENDPOINT = `${SERVER_API_ENDPOINT}/listing`;

export const CART_API_ENDPOINT = `${BASE_API_ENDPOINT}/cart`;
export const CART_INFO_API_ENDPOINT = `${BASE_API_ENDPOINT}/cart-info`;
export const CART_ADD_ITEM_API_ENDPOINT = `${CART_API_ENDPOINT}/add-item`;
export const CART_REMOVE_ITEM_API_ENDPOINT = `${CART_API_ENDPOINT}/remove-item`;
export const CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT = `${CART_API_ENDPOINT}/attach-service-to-order-item`;
export const CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT = `${CART_API_ENDPOINT}/detach-service-from-order-item`;
export const CART_ATTACH_ASSISTANT_TO_ORDER_ITEM = `${CART_API_ENDPOINT}/attach-domain-assistant-to-order-item`;
export const CART_CLEAR_API_ENDPOINT = `${CART_API_ENDPOINT}/empty`;
export const CART_CHECKOUT_API_ENDPOINT = `${CART_API_ENDPOINT}/checkout`;
export const CART_APPLY_PROMOTION_CODE_API_ENDPOINT = `${CART_API_ENDPOINT}/apply-promotion-code`;

export const HELPDESK_API_ENDPOINT = `${BASE_API_ENDPOINT}/helpdesk`;
export const HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/categories`;
export const HELPDESK_KNOWLEDGE_BASE_CATEGORY_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/category/`;
export const HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/most-popular`;
export const HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT = `${HELPDESK_API_ENDPOINT}/knowledge-base/articles/`;

export const HELPDESK_TICKETS_API_ENDPOINT = `${BASE_API_ENDPOINT}/ticket`;
export const HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT = `${BASE_API_ENDPOINT}/support-departments/listing`;
export const HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT = `${HELPDESK_TICKETS_API_ENDPOINT}/create/visitor`;

export const PROJECT_QUOTE_API_ENDPOINT = `${BASE_API_ENDPOINT}/customer-project-quote/create`;

export const ENTERPRENEURS_QUOTE_API_ENDPOINT = `${BASE_API_ENDPOINT}/entrepreneur-quote/create`;
export const WEB_SOLUTION_FORM_ENDPOINT = `${BASE_API_ENDPOINT}/other/landing-page-contact`;

export const EMAIL_TUTORIAL_CHECK_DOMAIN_API_ENDPOINT = `${BASE_API_ENDPOINT}/lookup/email/`;

export const SSL_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/service/listing`;
export const SSL_APPLICABLE_HOSTINGS_API_ENDPOINT = `${BASE_API_ENDPOINT}/customer/service/%(id)s/applicable-product/listing`;

export const DEDICATED_SERVERS_API_ENDPOINT = `${BASE_API_ENDPOINT}/shop/catalog/dedicated-server/listing`;

export const MAILCHIMP_SUBSCRIBE_ENDPOINT = `${BASE_API_ENDPOINT}/thirdparty/subscribe/ebook`;
export const SCHEDULE_HOSTING_CLOUD_CONSULTATION_ENDPOINT = `${BASE_API_ENDPOINT}/other/hosting-cloud-consultation/schedule`;
export const SIGNUP_WEBINAR_FIRST_WEBSITE_ENDPOINT = `${BASE_API_ENDPOINT}/other/webinar-first-website/signup`;
