import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import SectionDescription from "../../SectionDescription";

import SectionTitle from "../../SectionTitle";
const StyledSectionDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;
const StyledHowSSLCertificatesWorkSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "20px 0")};
  margin: ${props => !props.isMobile && "15px 0"};
  background-image: url("/assets/backgrounds/bg-5.png");
  background-position: center center;
  background-size: ${props => (props.isMobile ? "cover" : "70%")};
  background-repeat: no-repeat;
`;

const StyledImage = styled.img`
  padding-top: 60px;
`;

const StyledTitleContainer = styled.div`
  text-align: center; /* Centers the title horizontally */
  margin-bottom: 30px; /* Adds space below the title */
`;

const AWSSertified = () => (
  <Translate>
    {({ translate }) => (
      <StyledHowSSLCertificatesWorkSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12">
          <StyledTitleContainer>
            <SectionTitle>{translate("certified_support_team_title")}</SectionTitle>
            <StyledSectionDescription>
              {translate(
                "certified_support_team_description")}
            </StyledSectionDescription>
          </StyledTitleContainer>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/AWS-Certified-Cloud-Practitioner_badge.634f8a21af2e0e956ed8905a72366146ba22b74c.png"
                  alt="Client 1"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/AWS-Certified-Developer-Associate_badge.5c083fa855fe82c1cf2d0c8b883c265ec72a17c0.png"
                  alt="Client 2"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/AWS-Certified-Solutions-Architect-Professional_badge.69d82ff1b2861e1089539ebba906c70b011b928a.png"
                  alt="Client 3"
                />
              </div>
            </div>
          </div>
        </div>
      </StyledHowSSLCertificatesWorkSection>
    )}
  </Translate>
);

export default AWSSertified;
