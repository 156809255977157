import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { MobileOnlyView, isTablet, isBrowser } from "react-device-detect";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import FileUpload from "../../../components/FileUpload";
//import Form from "../../../../components/Forms/Form";
import SelectBox from "../../../components/SelectBox";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";

import NoticeDanger from "../../../components/Notices/NoticeDanger";
import Checkbox from "../../Checkbox";

const StyledOpenNewTicketForm = styled.div`
  .form-group {
    width: 100%;
    margin-bottom: 25px;
    .btn.dropdown-toggle {
      width: 100%;
    }
  }
  .related-product-col {
    @media (min-width: 768px) and (max-width: 1199px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media (min-width: 1441px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .dropdown-col {
    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  input {
    min-width: auto;
  }
`;

const relatedProductOptions = ["First", "Second", "Third"];

const priorityOptions = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" }
];

const acceptFileExtensions = [".jpg", ".gif", ".jpeg", ".png", ".txt", ".pdf"];

const OpenNewTicketForm = props => {
  const defaultOption = {
    value: "Medium",
    label: props.translate("help-desk.ticket.priorities.Medium")
  };

  const dep = props.selectedDepartment ? props.selectedDepartment : 3; // 3 = support

  const department = props.departments
    ? props.departments.find(department => department.value === dep)
    : false; //

  return (
    <StyledOpenNewTicketForm>
      <Form
        onSubmit={props.onSubmit}
        validate={props.validate}
        initialValues={{
          priority: defaultOption,
          department
        }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, invalid, submitting }) => (
          <form onSubmit={handleSubmit} id="open-ticket-form">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6">
                <Field
                  component={InputField}
                  name="name"
                  label={props.translate("name")}
                  placeholder={props.translate("enter-first-and-last-name")}
                />
              </div>
              <div className="col-6 col-sm-6 col-md-6">
                <Field
                  component={InputField}
                  name="email"
                  label={props.translate("email")}
                  placeholder={props.translate("enter-email")}
                />
              </div>

              <div className="col-12 col-sm-12 col-md-12">
                <Field
                  name="subject"
                  component={InputField}
                  label={props.translate("help-desk.ticket.ticket-subject")}
                  placeholder={props.translate(
                    "help-desk.ticket.enter-ticket-subject"
                  )}
                />
              </div>
            </div>
            <MobileOnlyView renderWithFragment>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <Field
                    component={SelectBox}
                    name="department"
                    label={props.translate(
                      "help-desk.ticket.ticket-department"
                    )}
                    placeholder={props.translate(
                      "help-desk.ticket.choose-department"
                    )}
                    isSearchable={false}
                    async={true}
                    loadOptions={props.fetchDepartments}
                    defaultOptions
                  />
                </div>
                {/*<div className="col-5">
              <Field
                component={SelectBox}
                name="related"
                label="Related products"
                placeholder="Choose related product"
              />
            </div>*/}
                <div className="col-12 col-sm-12 col-md-12">
                  <Field
                    component={SelectBox}
                    name="priority"
                    label={props.translate("help-desk.ticket.priority")}
                    placeholder={props.translate(
                      "help-desk.ticket.choose-priority"
                    )}
                    defaultValue={defaultOption}
                    options={priorityOptions.map(option => {
                      return {
                        ...option,
                        label: props.translate(
                          "help-desk.ticket.priorities." + option.label
                        )
                      };
                    })}
                  />
                </div>
              </div>
            </MobileOnlyView>

            {(isTablet || isBrowser) && (
              <div className="row">
                {/*<div className="col-5">
              <Field
                component={SelectBox}
                name="related"
                label="Related products"
                placeholder="Choose related product"
              />
            </div>*/}

                <div className="col-6 col-sm-6 col-md-7">
                  <Field
                    component={SelectBox}
                    name="department"
                    label={props.translate(
                      "help-desk.ticket.ticket-department"
                    )}
                    placeholder={props.translate(
                      "help-desk.ticket.choose-department"
                    )}
                    isSearchable={false}
                    async={true}
                    loadOptions={props.fetchDepartments}
                    defaultOptions
                  />
                </div>
                <div className="col-6 col-sm-6 col-md-5">
                  <Field
                    component={SelectBox}
                    name="priority"
                    label={props.translate("help-desk.ticket.priority")}
                    placeholder={props.translate(
                      "help-desk.ticket.choose-priority"
                    )}
                    defaultValue={defaultOption}
                    options={priorityOptions.map(option => {
                      return {
                        ...option,
                        label: props.translate(
                          "help-desk.ticket.priorities." + option.label
                        )
                      };
                    })}
                  />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Field
                  component={TextArea}
                  name="message"
                  label={props.translate("help-desk.ticket.message")}
                  placeholder={props.translate(
                    "help-desk.ticket.enter-message"
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Field
                  component={FileUpload}
                  acceptFileExtensions={acceptFileExtensions}
                  name="attachments"
                  label={props.translate("help-desk.ticket.attachments")}
                  helpText={acceptFileExtensions.join(", ")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Field
                    component={Checkbox}
                    value={true}
                    label={props.translate("sections.free-ebook.agree-privacy-policy")}
                    name="pp"
                />
                <br/>

                <Field
                    component={Checkbox}
                    value={true}
                    label={props.translate("sections.free-ebook.agree-ticket-section")}
                    name="pp2"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 text-right">
                <SecondaryButton onClick={() => props.onCancel()}>
                  {props.translate("help-desk.ticket.cancel")}
                </SecondaryButton>{" "}
                <PrimaryButton
                  disabled={submitting || invalid}
                  submitting={submitting}
                  type="submit"
                >
                  {props.translate("help-desk.ticket.open-ticket")}
                </PrimaryButton>
              </div>
            </div>
          </form>
        )}
      />
    </StyledOpenNewTicketForm>
  );
};

export default withLocalize(OpenNewTicketForm);
