import React from "react";
import styled from "styled-components";
const StyledTabContent = styled.div`
  padding-top: 30px;
`;

const TabContent = ({ active, label, children }) => (
  //<StyledTabContent className={`tab-pane ${active === label ? "active" : ""}`}>
  <StyledTabContent className={`tab-pane ${active === label ? "active" : ""}`}>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">{children}</div>
    </div>
  </StyledTabContent>
);

export default TabContent;
