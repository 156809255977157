import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import ContainerModal from "../../Containers/ContainerModal";
import Perk from "../../Perk";
import SecondaryButton from "../../Buttons/SecondaryButton";

import { ROUTE_ADDONS_SUPPORT } from "../../../routes";

const StyledPremiumSupportOnly = styled.div`
  padding: ${props => (props.isMobile ? "30px 0 70px 0" : "75px 0")};
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 30px;
`;

const StyledContainerModal = styled(ContainerModal)`
  padding: ${props => (props.isMobile ? "40px 20px" : "50px 30px")};
  padding-bottom: 40px;
`;

const StyledImage = styled.img`
  margin-top: 100px;
  margin-left: -280px;
  width: 88%;
`;

const StyledBackImage = styled.div`
  background-image: url("/assets/backgrounds/bg-6-1.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 70%;
`;

const PremiumSupportOnly = () => (
  <Translate>
    {({ translate }) => (
      <StyledPremiumSupportOnly className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <StyledContainerModal isMobile={isMobileOnly}>
                    <Perk
                      title={translate("premium-support")}
                      subtitle={translate("macedonian-&-english")}
                      description={() => (
                        <span>
                          {translate(
                            "our-professional-and-max-business-plans-come-with-free-premium-support-for"
                          )}{" "}
                          <strong>
                            {translate("priority-in-problem-solving")}
                          </strong>{" "}
                          {translate(
                            "and-additional-benefits-including-the-following"
                          )}
                        </span>
                      )}
                      hideIcon={true}
                      items={[
                        translate("help-with-setting-up-email-accounts"),
                        translate("cxs-security-scans"),
                        translate("installing-platforms-and-themes"),
                        translate("resolving-ip-blocking-issues"),
                        translate(
                          "communication-with-other-problem-solving-providers"
                        )
                      ]}
                    />
                    <Link to={ROUTE_ADDONS_SUPPORT}>
                      <StyledSecondaryButton size="l">
                        {translate("learn-more")}
                      </StyledSecondaryButton>
                    </Link>
                  </StyledContainerModal>
                </div>
                {!isMobileOnly && (
                  <StyledBackImage className="col-12 col-sm-12 col-md-7 text-center">
                    <StyledImage
                      className="img-fluid"
                      src="/assets/illustrations/illustration_support.png"
                    />
                  </StyledBackImage>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledPremiumSupportOnly>
    )}
  </Translate>
);

export default PremiumSupportOnly;
