import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import ContainerModal from "../Containers/ContainerModal";
import Icon from "../Icon";

const MODAL_SIZE_SMALL = "400px";
const MODAL_SIZE_DEFAULT = "600px";
const MODAL_SIZE_LARGE = "800px";
const MODAL_SIZE_EXTRA_LARGE = "1000px";
const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999;
  overflow-y: auto;
`;

const StyledModal = styled.div.attrs({
  modalsize: props => {
    if (!props.size) return MODAL_SIZE_DEFAULT;
    switch (props.size.toLowerCase()) {
      case "s":
      case "small":
        return MODAL_SIZE_SMALL;

      case "m":
      case "medium":
      case "d":
      case "default":
        return MODAL_SIZE_DEFAULT;

      case "l":
      case "large":
        return MODAL_SIZE_LARGE;

      case "xl":
      case "extralarge":
      case "extra-large":
        return MODAL_SIZE_EXTRA_LARGE;

      default:
        return MODAL_SIZE_DEFAULT;
    }
  }
})`

  display: block;
  margin-top: 100px;
  position: relative;
  min-height: 70%;

  & > .modal-dialog {
    font-family: "Open Sans", sans-serif;
    width: ${props => (props.isMobile ? "95%" : props.modalsize)};
    max-width: ${props => (props.isMobile ? "95%" : props.modalsize)};
  }

  & > .modal-dialog > .modal-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${props => props.theme.neutral6};
  }

  & > .modal-dialog > .modal-content .modal-header {
    padding: 30px;
    padding-bottom: 15px;
    border-bottom: ${props =>
      props.size === "s" ? 0 : `1px solid ${props => props.theme.neutral3}`};
  }

  & > .modal-dialog > .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${props => props.theme.neutralBase};
  }

  & > .modal-dialog > .modal-content .modal-header .modal-subTitle {
    font-size: 16px;
    line-height: 1.5l
    color: ${props => props.theme.neutral6};
    margin-bottom: 0;
  }

  & > .modal-dialog > .modal-content .modal-header .close {
    margin: -3rem -1.5rem -1rem auto;
    outline: 0;
  }

  & > .modal-dialog > .modal-content > .modal-body {
    padding: ${props => (props.size === "s" ? "0 30px" : "30px 30px")};
  }

  & > .modal-dialog > .modal-content > .modal-footer {
    padding: 30px;
    border-top: ${props =>
      props.size === "s" ? 0 : `1px solid ${props => props.theme.neutral3}`};
  }

  & > .modal-dialog > .modal-content > .modal-footer .btn:last-child {
    margin-right: 0;
  }
  & > .modal-dialog > .modal-content > .modal-footer .ghost-button {
    margin-right: auto !important;
  }
`;

const modalRoot = document.getElementById("modal-root");

class Modal extends React.Component {
  renderModal = () => {
    return (
      <StyledModalWrapper>
        <StyledModal
          className="modal"
          tabindex="-1"
          role="dialog"
          size={isMobileOnly ? "95%" : this.props.size}
          isMobile={isMobileOnly}
        >
          <div className="modal-dialog" role="document">
            <ContainerModal className="modal-content" id="modal-container">
              <div className="modal-header d-block">
                <div className="d-flex">
                  <h5 className="modal-title">{this.props.title}</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={e => this.props.onCloseModal()}
                  >
                    <span aria-hidden="true">
                      <Icon icon="close" />
                    </span>
                  </button>
                </div>

                {this.props.subTitle && (
                  <p className="modal-subTitle">{this.props.subTitle}</p>
                )}
              </div>
              <div className="modal-body">{this.props.body()}</div>
              {typeof this.props.footer === "function" && (
                <div className="modal-footer">{this.props.footer()}</div>
              )}
            </ContainerModal>
          </div>
        </StyledModal>
      </StyledModalWrapper>
    );
  };

  render() {
    const Modal = this.renderModal();
    return ReactDOM.createPortal(Modal, modalRoot);
  }
}

Modal.defaultProps = {
  body: () => <p>No content...</p>
};

export default Modal;
