import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";

import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import DomainsWhoIsSearchSection from "../../components/Sections/DomainsWhoIsSearchSection";
import WhoIsServiceInfoSection from "../../components/Sections/WhoIsServiceInfoSection";

import check_success from "../../assets/images/check_success.svg";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.neutral6};
`;

const StyledTransferPrices = styled.span`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.neutral5};
  text-align: center;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -82px;
`;

class DomainsWhoIs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExtension: null,
      domain: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      let domainName = this.props.match.params.name.split(".");
      const domain = domainName[0];
      domainName.shift();
      const extension = domainName.join(".");
      this.setState({
        selectedExtension: extension,
        domain: this.props.match.params.name
      });
    }
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].domains_whois.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_whois.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_whois.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_whois.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_whois.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-whois.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_whois.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_whois.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-whois.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("pages.domains.whois.whois-for-mk-domains")}
              subtitle={() => (
                <SectionDescription>
                  {translate(
                    "the-whois-service-allows-you-to-check-the-owner-information-of-a-specific-.mk-domain"
                  )}
                  .
                </SectionDescription>
              )}
              image="/assets/illustrations/domains/illustration_domains-whois.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              paddingBottom="20px"
              backgroundPositionY={isMobileOnly ? "-30px" : "145px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer height="164px" />
              <DomainsWhoIsSearchSection
                domain={this.state.domain}
                selectedExtension={this.state.selectedExtension}
              />
              <WhoIsServiceInfoSection />
            </Hero>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default DomainsWhoIs;
