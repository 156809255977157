import React from "react";
import { ROUTE_EMAIL_TUTORIALS_EMAIL } from "../../../../routes";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import Modal from "../../../../components/Modal";

import SecondaryButton from "../../../../components/Buttons/SecondaryButton";

import { Link } from "react-router-dom";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

import {
  ROUTE_EMAIL_TUTORIALS,
  ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_IMAP,
  ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_POP
} from "../../../../routes";

const StyledDomainPricesSection = styled.div``;

const StyledParentImage = styled.div`
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 30px;
`;

const StyledImage = styled.img`
  width: 120px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-thunderbird.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

class ThunderbirdPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDomainModal: null
    };
  }

  onShowModal = product => {
    this.setState({
      showDomainModal: true
    });
  };

  onCloseModal = () => {
    this.setState({
      showDomainModal: false
    });
  };
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div>
            <StyledHeader className="row py-5 ">
              <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-2 col-8">
                <h1>
                  <strong>
                    {translate("pages.email-tutorial.email-setup")}
                  </strong>
                </h1>
              </div>
            </StyledHeader>

            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <h2>
                  <strong>
                    {translate("pages.email-tutorial.thunderbird")}
                  </strong>
                </h2>
                <p>{translate("pages.email-tutorial.choose-protocol")}</p>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-md-4 offset-md-2 my-2">
                <Link to={ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_POP}>
                  <StyledParentImage className="card">
                    <div className="card-body text-center">
                      <StyledImage
                        className="img-fluid"
                        src="/assets/images/email-setup/pop.png"
                      />
                    </div>
                    <div className="card-footer text-center">POP</div>
                  </StyledParentImage>
                </Link>
              </div>

              <div className="col-md-4 my-2">
                <Link to={ROUTE_EMAIL_TUTORIALS_THUNDERBIRD_IMAP}>
                  <StyledParentImage className="card">
                    <div className="card-body text-center">
                      <StyledImage
                        className="img-fluid"
                        src="/assets/images/email-setup/imap.png"
                      />
                    </div>
                    <div className="card-footer text-center">IMAP</div>
                  </StyledParentImage>
                </Link>
              </div>

              <div className="col-md-8 offset-2">
                <Link to={ROUTE_EMAIL_TUTORIALS}>
                  <PrimaryButton size="m">
                    {translate("pages.email-tutorial.go-back")}
                  </PrimaryButton>
                </Link>

                <PrimaryButton
                  size="m"
                  className="float-right"
                  onClick={this.onShowModal}
                >
                  {translate("pages.email-tutorial.imap-or-pop")}
                </PrimaryButton>
              </div>
            </div>

            {this.state.showDomainModal && (
              <Modal
                title={translate("pages.email-tutorial.imap-or-pop")}
                onCloseModal={this.onCloseModal}
                body={() => (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <p>
                        {translate(
                          "pages.email-tutorial.before-you-set-up-email"
                        )}.
                      </p>
                      <h2>IMAP</h2>
                      <p>
                        {translate("pages.email-tutorial.if-you-choose-imap")}.
                      </p>
                      <h2>POP</h2>
                      <p>
                        {translate("pages.email-tutorial.if-you-choose-pop")}.
                      </p>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12">
                      <SecondaryButton
                        className="mt-4 float-right"
                        onClick={this.onCloseModal}
                      >
                        {translate("pages.email-tutorial.close")}
                      </SecondaryButton>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

export default ThunderbirdPage;
