import React from "react";
import styled from "styled-components";
import Tooltip from "rc-tooltip";

const StyledToolip = styled.div`
  display: ${props => props.inline && "inline-block"};
`;

const CustomTooltip = ({ children, content, inline, ...rest }) => (
  <StyledToolip
    className={rest.className}
    inline={!!inline}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <Tooltip
      placement="top"
      trigger={["hover"]}
      overlay={content}
      overlayClassName="custom-rc-tooltip"
      overlayStyle={{
        zIndex: "11000"
      }}
    >
      {children}
    </Tooltip>
  </StyledToolip>
);
export default CustomTooltip;
