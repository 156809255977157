import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import CircledIcon from "../../CircledIcon";

const StyledWhyChooseWordpressHosting = styled.div`
  padding: 75px 0;
  padding-top: ${props => props.isMobile && "30px"};
`;

const Feature = styled.div`
  position: relative;
  margin-bottom: ${props => (props.isMobile ? "20px" : "30px")};
  padding-left: ${props => props.isMobile && "100px"};
  & .circled-icon {
    margin-bottom: 20px;
    position: ${props => props.isMobile && "absolute"};
    left: ${props => props.isMobile && "15px"};
    top: ${props => props.isMobile && "0"};
  }
`;

const StyledCircledIcon = styled.div`
  & .circled-icon {
    transform: rotate(-90deg);
  }
`;

const StyledTop = styled.div`
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const StyledWrapper = styled.div`
  display: ${props => props.isMobile && "inline-block"};
`;
const WhyChooseWordpressHosting = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhyChooseWordpressHosting className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <StyledTop
                  className="col-12 col-sm-12 col-md-5"
                  isMobile={isMobileOnly}
                >
                  <SectionTitle>
                    {translate("why-choose-wordpress-hosting")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "wordpress-is-the-most-popular-content-management-system-cms-in-the-world"
                    )}
                  </SectionDescription>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <BulletPoint>
                        {translate(
                          "fast-and-easy-implement-web-solutions"
                        )}
                      </BulletPoint>
                      <BulletPoint>
                        {translate(
                          "easy-manage-and-update-content"
                        )}
                      </BulletPoint>
                      <BulletPoint>
                        {translate("big-opportunities-in-your-bussiness")}
                      </BulletPoint>
                      <BulletPoint>
                        {translate(
                          "fast-integrity-with-plugins-and-tools"
                        )}
                      </BulletPoint>
                      <BulletPoint>
                        {translate(
                            "excilent-for-web-portals-blog-and-ecommerce"
                        )}
                      </BulletPoint>
                    </div>
                  </div>
                </StyledTop>
                <div className="col-12 col-sm-12 col-md-6 offset-md-1">
                  <div className="row">
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <StyledCircledIcon isMobile={isMobileOnly}>
                        <CircledIcon
                          icon="logout"
                          color="blueBase"
                          backgroundColor="blue1"
                        />
                      </StyledCircledIcon>
                      <StyledWrapper>
                        <SmallTitle>
                          {translate("automatic-updates")}
                        </SmallTitle>
                        <SmallDescription>
                          {translate("always-have-latest-wordpress-version")}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <CircledIcon
                        icon="arrow_right"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <StyledWrapper>
                        <SmallTitle>{translate("big-functionalities")}</SmallTitle>
                        <SmallDescription>
                          {translate("big-spectar-themes-and-plugins")}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <CircledIcon
                        icon="files"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <StyledWrapper>
                        <SmallTitle>{translate("daily-backups")}</SmallTitle>
                        <SmallDescription>
                          {translate(
                            "we-keep-daily-copies-of-your-wordpress-website"
                          )}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <CircledIcon
                        icon="help_desk"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <StyledWrapper>
                        <SmallTitle>{translate("wordpress")}</SmallTitle>
                        <SmallDescription>
                          {translate(
                            "we-can-answer-all-your-wordpress-questions"
                          )}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <CircledIcon
                        icon="websites"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <StyledWrapper>
                        <SmallTitle>
                          {translate("optimized-wp")}
                        </SmallTitle>
                        <SmallDescription>
                          {translate(
                            "wp-is-optimized-and-it-represent-the-best-of-your-web-site"
                          )}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                    <Feature
                      className="col-12 col-sm-12 col-md-4"
                      isMobile={isMobileOnly}
                    >
                      <CircledIcon
                        icon="privacy"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <StyledWrapper>
                        <SmallTitle>
                          {translate("improved-security")}
                        </SmallTitle>
                        <SmallDescription>
                          {translate(
                            "your-website-will-be-safe-even-if-there-are-vulnerable-accounts"
                          )}
                        </SmallDescription>
                      </StyledWrapper>
                    </Feature>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWhyChooseWordpressHosting>
    )}
  </Translate>
);
export default WhyChooseWordpressHosting;
