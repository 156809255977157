import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

const StyledBlogPostCard = styled.a`
  display: inline-block;
  vertical-align: top;
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 3px;
  color: ${props => props.theme.neutralBase};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  width: ${props => (props.isMobile ? "92%" : "23.2%")};
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  margin-bottom: 30px;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.neutralBase};
    box-shadow: 0 10px 20px 0 rgba(123, 139, 167, 0.25);
  }

  &:first-child {
    margin-left: ${props => (props.isMobile ? "15px" : "0")};
  }
  &:last-child {
    margin-right: 0;
  }
`;

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const StyledTitle = styled.span`
  display: block;
  padding: 20px;
`;

const StyledArticle = styled.article`
  height: ${props => (props.isMobile ? "auto" : "360px")};
`;

const BlogPostCart = ({ title, image, link }) => (
  <StyledBlogPostCard
    href={link}
    isMobile={isMobileOnly}
    target="_blank"
    rel="noopenner"
  >
    <StyledArticle isMobile={isMobileOnly}>
      <StyledImage src={image} alt="" />
      <StyledTitle>{parse(title)}</StyledTitle>
    </StyledArticle>
  </StyledBlogPostCard>
);

export default BlogPostCart;
