import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import Icon from "../Icon";

const StyledCircledIcon = styled.div`
  display: block;
  border-radius: 50%;
  width: ${props => (props.isMobile ? "60px" : "80px")};
  height: ${props => (props.isMobile ? "60px" : "80px")};
  text-align: center;

  color: ${props => props.theme[props.color]};
  background-color: ${props => props.theme[props.backgroundColor]};

  & .icon {
    top: ${props => (props.isMobile ? "17px" : "27px")};
  }
`;

const StyledCircledImage = styled.div`
  display: block;
  border-radius: 50%;
  width: ${props => (props.isMobile ? "60px" : "80px")};
  height: ${props => (props.isMobile ? "60px" : "80px")};
  text-align: center;
  img{
    width:${props => (props.isMobile ? "60px" : "80px")};
    height:${props => (props.isMobile ? "60px" : "80px")};
    border-radius:50%;
  
  }

`;

const CircledIcon = ({ color, backgroundColor, icon, image }) => {
  if (image) {
    return (<StyledCircledImage isMobile={isMobileOnly}><img src={image}/></StyledCircledImage>)
  } else {
    return (<StyledCircledIcon
        isMobile={isMobileOnly}
        color={color}
        backgroundColor={backgroundColor}
        className="circled-icon"
    >
      <Icon icon={icon} size="xl"/>
    </StyledCircledIcon>)

  }
};

export default CircledIcon;
