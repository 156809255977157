import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import { validateRequired } from "../../common/validationRules";

import Hero from "../../components/Hero";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import KnowledgeBaseSearch from "../../components/KnowledgeBaseSearch";

import KnowledgeBaseSection from "../../components/Sections/KnowledgeBaseSection";

import * as helpdeskService from "../../services/helpdeskService";
import * as authService from "../../services/authService";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledSearchWrapper = styled.div`
  margin-top: ${props => (props.isMobile ? "-70px" : "-40px")};
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -147px;
`;

const StyledContent = styled.div`
  padding-top: 70px;
  padding-bottom: 150px;
`;

class OtherKnowledgeBasePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: null,
      categories: null,
      mostPopularArticles: null,
      searchArticles: null,
      search: null,
      isFetchingCategories: true,
      isFetchingArticles: true,
      isAuthenticated: false
    };
  }

  componentDidMount() {
    this.fetchData();
    this.setState({
      isAuthenticated: !!authService.getToken()
    });
  }

  fetchData = () => {
    this.setState({
      ...this.state,
      isFetchingCategories: true
    });
    helpdeskService
      .fetchRequestHelpDeskKnowledgeBaseCategories()
      .then(result => {
        const { categories } = result.data.data;

        this.setState({
          ...this.state,
          categories,
          isFetchingCategories: false
        });
      });

    this.setState({
      ...this.state,
      isFetchingArticles: true
    });
    helpdeskService.fetchMostPopularArticles().then(result => {
      const { articles } = result.data.data;
      this.setState({
        ...this.state,
        mostPopularArticles: articles,
        isFetchingArticles: false
      });
    });
  };

  validate = values => {
    return {
      search: validateRequired(values.search)
    };
  };

  onSearch = values => {
    return helpdeskService
      .fetchKnowledgeBaseArticlesByKeyword(values.search)
      .then(result => {
        const { articles } = result.data.data;
        this.setState({
          searchArticles:
            articles && articles.length > 0
              ? articles
              : this.state.mostPopularArticles
        });
        return result;
      });
  };

  onVoteArticle = (article, votes) => {
    return helpdeskService.voteArticle(article, votes).then(result => {
      if (votes === result.data.data.article.votes) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.title"),
          {
            error: true,
            messages: {
              0: this.props.translate("help-desk.already-voted")
            }
          }
        );
      } else if (votes !== result.data.data.article.votes) {
        this.updateArticleVotes(article, result.data.data.article.votes);
        displayToastMessageForResponse(
          this.props.translate("help-desk.title"),
          {
            error: false,
            messages: {
              0: this.props.translate("help-desk.vote-added")
            }
          }
        );
      }
    });
  };

  updateArticleVotes = (article, votes) => {
    this.setState({
      mostPopularArticles: this.state.mostPopularArticles.map(current => {
        if (current.id !== article) return { ...current };
        return {
          ...current,
          votes
        };
      })
    });
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_knowledgebase.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_knowledgebase
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  {translate("mkhost")}
                  <br />
                  {translate("knowledgebase")}
                </span>
              )}
              leftClassName="col-12 col-sm-12 col-md-7"
              rightClassName="col-12 col-sm-12 col-md-5"
              backgroundImage="/assets/backgrounds/bg-3-1.svg"
              backgroundPosition="top"
              backgroundPositionY={isMobileOnly ? "94px" : "92px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
              subtitle={() => (
                <span>
                  {translate(
                    "need-help?-search-or-browse-our-large-collection-of-articles-on-a"
                  )}{" "}
                  {translate(
                    "variety-of-topics-including-hosting,-domains,-emails-and-more"
                  )}
                  .
                </span>
              )}
            >
              <StyledBackgroundSpacer height="162px" isMobile={isMobileOnly} />
            </Hero>

            <StyledSearchWrapper className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <KnowledgeBaseSearch
                      onSubmit={this.onSearch}
                      validate={this.validate}
                      search={this.state.search}
                      searchPlaceholder={translate("search-knowledgebase")}
                    />
                  </div>
                </div>
              </div>
            </StyledSearchWrapper>

            {!this.state.searchArticles && (
              <KnowledgeBaseSection
                isLoading={
                  this.state.isFetchingCategories ||
                  this.state.isFetchingArticles
                }
                categories={this.state.categories}
                mostPopularArticles={this.state.mostPopularArticles}
                voteArticle={this.onVoteArticle}
                isAuthenticated={this.state.isAuthenticated}
              />
            )}

            {this.state.searchArticles && (
              <KnowledgeBaseSection
                isLoading={
                  this.state.isFetchingCategories ||
                  this.state.isFetchingArticles
                }
                categories={this.state.categories}
                mostPopularArticles={this.state.searchArticles}
                title={translate("search-results")}
                description="hide"
                voteArticle={this.onVoteArticle}
                isAuthenticated={this.state.isAuthenticated}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default withRouter(withLocalize(OtherKnowledgeBasePage));
