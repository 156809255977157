import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

const StyledHowSSLCertificatesWorkSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "155px 0")};
  margin: ${props => !props.isMobile && "75px 0"};
  background-image: url("/assets/backgrounds/bg-5.png");
  background-position: center center;
  background-size: ${props => (props.isMobile ? "cover" : "70%")};
  background-repeat: no-repeat;
`;

const StyledImage = styled.img`
  padding-top: 60px;
`;

const HowSSLCertificatesWorkSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledHowSSLCertificatesWorkSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("how-do-ssl-certificates-work")}?
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "ssl-(secure-sockets-layer)-is-the-standard-security-technology-used-to-establish-a-secure-encrypted-connection-between-a-browser-(user's-computer)-and-a-server-(your-website)"
                    )}
                  </SectionDescription>

                  <SectionDescription>
                    {translate(
                      "ssl-certificates-secure-sensitive-data,-such-as-credit-card-information,-logins,-passwords,-forms-and-all-over-data-exchanged-during-each-user-visit-(session)"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-6 col-sm-6 col-md-3 offset-md-1">
                  <StyledImage
                    className="img-fluid"
                    src="/assets/images/image-withssl.svg"
                  />
                </div>
                <div className="col-6 col-sm-6 col-md-3 offset-md-1">
                  <StyledImage
                    className="img-fluid"
                    src="/assets/images/image-withouthssl.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledHowSSLCertificatesWorkSection>
    )}
  </Translate>
);

export default HowSSLCertificatesWorkSection;
