import styled from "styled-components";

import Link from "../Link";

const PrimaryLink = styled(Link)`
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
  border: solid 1px ${props => props.theme.blueBase};

  &:hover {
    background-color: ${props => props.theme.blue4};
    border: 1px solid ${props => props.theme.blue4};
    color: ${props => props.theme.white};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.blue3};
  }

  &:active {
    border: 1px solid ${props => props.theme.blue5};
    background-color: ${props => props.theme.blue5};
    color: ${props => props.theme.white};
  }

  &:disabled {
    background-color: ${props => props.theme.neutral2};
    color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.neutral2};
  }
`;

PrimaryLink.defaultProps = {
  className: "primary-link"
};

export default PrimaryLink;
