import React from "react";
import ContentLoad from "react-content-loader";

const Loader = ({ width = 400, height = 160, children }) => (
  <ContentLoad
    primaryColor="rgb(245,245,247)"
    primaryOpacity={0.6}
    width={width}
    height={height}
  >
    {children}
  </ContentLoad>
);

export default Loader;
