import React from "react";
import moment from "moment";
import { Translate } from "react-localize-redux";

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: moment(this.props.deadline).diff(moment(), "days")
    };
  }

  render() {
    return (
      <span>
        {this.state.days === 0 ? (
          <span> 0 </span>
        ) : (
          <strong className="Clock-days"> {this.state.days} </strong>
        )}
      </span>
    );
  }
}
export default Clock;
