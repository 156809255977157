import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import Container from "../../../Containers/Container";
import DomainSearchResult from "./DomainSearchResult";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE,
  DOMAIN_STATUS_UNSUPPORTED,
  DOMAIN_STATUS_INVALID
} from "../../../../pages/Domains/DomainStatuses";

const TYPE_REGISTER = "register";
const TYPE_TRANSFER = "transfer";

const StyledContainer = styled.div`
  margin: 30px 0;
`;

const renderResult = (
  domain,
  status,
  search,
  onAddToCart,
  onAssignDomain,
  addedToCart,
  type,
  whoIs,
  submitting,
  translate,
  error,
  priceReduce
) => {
  //if (error) {
  //  return (
  //    <DomainSearchResult
  //      status={status}
  //      statusText={translate(
  //        `sections.domain_search.domain_unsupported_or_invalid_${type}`
  //      )}
  //      domain={domain}
  //      type={type}
  //    />
  //  );
  //}

  //if (!error && status === DOMAIN_STATUS_INVALID) {
  //  return (
  //    <DomainSearchResult
  //      status={status}
  //      statusText={translate(
  //        `sectins.domains_search.domain_unsupported_or_invalid_${type}`
  //      )}
  //      domain={domain}
  //      type={type}
  //    />
  //  );
  //}

  if (
    error ||
    status === DOMAIN_STATUS_INVALID ||
    status === DOMAIN_STATUS_UNSUPPORTED
  ) {
    return (
      <DomainSearchResult
        status={DOMAIN_STATUS_AVAILABLE}
        statusText={translate("sections.domain_search.we_found_your_domain")}
        note={translate("sections.domain_search.domain_unsupported_note")}
        domain={domain}
        type={TYPE_TRANSFER}
        onAssignDomain={onAssignDomain}
        submitting={submitting}
      />
    );
  }

  if (type === TYPE_REGISTER && status === DOMAIN_STATUS_AVAILABLE) {
    return (
      <DomainSearchResult
        status={status}
        statusText={translate("sections.domain_search.available_to_purchase")}
        description={translate(
          "sections.domain_search.domain_unregistered_and_available"
        )}
        domain={domain}
        onAddToCart={onAddToCart}
        addedToCart={addedToCart}
        submitting={submitting}
        type="register"
        priceReduce={priceReduce}
      />
    );
  }

  if (type === TYPE_REGISTER && status === DOMAIN_STATUS_UNAVAILABLE) {
    return (
      <DomainSearchResult
        status={status}
        domain={domain}
        type={type}
        showWhoIs={
          domain.name.endsWith(".mk") || domain.name.endsWith(".мкд")
            ? true
            : false
        }
      />
    );
  }

  if (type === TYPE_TRANSFER && status === DOMAIN_STATUS_AVAILABLE) {
    return (
      <DomainSearchResult
        status={status}
        statusText={translate("sections.domain_search.available_to_purchase")}
        description={translate(
          "sections.domain_search.you_can_register_domain_bold"
        )}
        domain={domain}
        onAddToCart={onAddToCart}
        onAssignDomain={onAssignDomain}
        addedToCart={addedToCart}
        submitting={submitting}
        type="register"
        priceReduce={priceReduce}
      />
    );
  }

  if (
    type === TYPE_TRANSFER &&
    status === DOMAIN_STATUS_UNAVAILABLE &&
    (!whoIs || !whoIs.results.info.registrar) &&
    domain.ours === false
  ) {
    return (
      <DomainSearchResult
        status={DOMAIN_STATUS_AVAILABLE}
        statusText={translate("sections.domain_search.we_found_your_domain")}
        description={translate(
          "sections.domain_search.you_can_transfer_domain_bold"
        )}
        domain={domain}
        type="transfer"
        onAddToCart={onAddToCart}
        onAssignDomain={onAssignDomain}
        addedToCart={addedToCart}
        submitting={submitting}
        priceReduce={priceReduce}
      />
    );
  }

  if (
    type === TYPE_TRANSFER &&
    status === DOMAIN_STATUS_UNAVAILABLE &&
    !whoIs | !whoIs.results.info.registrar &&
    domain.ours === true
  ) {
    return (
      <DomainSearchResult
        status="info"
        statusText={translate(
          "sections.domain_search.not_available_for_transfer"
        )}
        description={translate(
          "sections.domain_search.domain_already_in_mkhost"
        )}
        domain={domain}
        type="transfer"
        onAssignDomain={onAssignDomain}
        addedToCart={addedToCart}
        submitting={submitting}
        priceReduce={priceReduce}
      />
    );
  }

  if (
    type === TYPE_TRANSFER &&
    status === DOMAIN_STATUS_UNAVAILABLE &&
    (!whoIs || !whoIs.results)
  ) {
    return <DomainSearchResult status={status} domain={domain} type={type} />;
  }

  if (
    type === TYPE_TRANSFER &&
    status === DOMAIN_STATUS_UNAVAILABLE &&
    (!whoIs || !whoIs.results.info.registrar)
  ) {
    return <DomainSearchResult status={status} domain={domain} type={type} />;
  }

  if (
    type === TYPE_TRANSFER &&
    status === DOMAIN_STATUS_UNAVAILABLE &&
    whoIs.results.info.registrar
  ) {
    if (whoIs.results.info.registrar === "MKHOST-REG") {
      return (
        <DomainSearchResult
          status="info"
          statusText={translate(
            "sections.domain_search.not_available_for_transfer"
          )}
          description={translate(
            "sections.domain_search.domain_already_in_mkhost"
          )}
          domain={domain}
          type="transfer"
          onAssignDomain={onAssignDomain}
          addedToCart={addedToCart}
          submitting={submitting}
          priceReduce={priceReduce}
        />
      );
    }
    if (whoIs.results.info.registrar !== "MKHOST-REG") {
      return (
        <DomainSearchResult
          status={DOMAIN_STATUS_AVAILABLE}
          statusText={translate("sections.domain_search.we_found_your_domain")}
          description={translate(
            "sections.domain_search.you_can_transfer_domain_bold"
          )}
          domain={domain}
          type="transfer"
          onAddToCart={onAddToCart}
          onAssignDomain={onAssignDomain}
          addedToCart={addedToCart}
          submitting={submitting}
          priceReduce={priceReduce}
        />
      );
    }
  }

  return null;
};

const DomainSearchResults = ({
  domain,
  search,
  onAddToCart,
  onAssignDomain,
  addedToCart,
  type,
  whoIs,
  submitting,
  translate,
  error,
  priceReduce
}) => {
  const resultComponent = renderResult(
    domain,
    domain.status,
    domain.name,
    onAddToCart,
    onAssignDomain,
    addedToCart,
    type,
    whoIs,
    submitting,
    translate,
    error,
    priceReduce
  );
  return (
    <StyledContainer>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">{resultComponent}</div>
      </div>
    </StyledContainer>
  );
};

export default withLocalize(DomainSearchResults);
