import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const StyledSupportPlan = styled.div`
  position: relative;
  margin-top: ${props => props.isMobile && "20px"};

  border: 1px solid ${props => props.theme.neutral3};

  border-left: 1px solid ${props => props.theme.neutral3};

  border-left: ${props => !props.firstChild && !props.isMobile && "0"};

  border-bottom: ${props =>
    props.isMobile
      ? `1px solid ${props.theme.neutral3}`
      : "1px solid transparent"};
  &:last-child {
    border-right: 1px solid ${props => props.theme.neutral3};
  }
`;

const StyledTop = styled.div`

  padding: 30px!important;
  background-color:${props => props.theme.blue2};
  & .title {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.42;
    color: ${props => props.theme.neutralBase};
  }

  & .description {
   margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.theme.neutral6};
`;

const StypedBottom = styled.div`
  padding: 30px !important;
  background-color: ${props => props.theme.white};
`;

const StyledCustomBadgeImage = styled.img`
  position: absolute;
  top: -15px;
  left: 30px;
  width: 90px;
  z-index: 1;
`;

const SupportPlan = ({ title, description, content, ...rest }) => (
  <StyledSupportPlan
    className={`support-plan ${rest.className}`}
    isMobile={isMobileOnly}
    firstChild={rest.firstChild}
  >
    <div className="row no-gutters ">
      {rest.customBadgeImage && (
        <StyledCustomBadgeImage src={rest.customBadgeImage} alt="" />
      )}
      {title &&
        title.length > 0 && (
          <StyledTop className="col-12 col-sm-12 col-md-12 styled-top">
            <h3 className="title">{title}</h3>
            <p className="descrition">{description}</p>
          </StyledTop>
        )}
      {title &&
        title.length > 0 &&
        content && (
          <StypedBottom className="col-12 col-sm-12 col-md-12 styled-bottom">
            {typeof content === "function" ? content() : content}
          </StypedBottom>
        )}
    </div>
  </StyledSupportPlan>
);

export default SupportPlan;
