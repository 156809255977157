import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import SecondaryButton from "../Buttons/SecondaryButton";
import CustomTooltip from "../CustomTooltip";
import Icon from "../Icon";
import BadgeSuccess from "../Badges/BadgeSuccess";
import BadgeDanger from "../Badges/BadgeDanger";

import add from "./add.svg";
import subtract from "./subtract.svg";

import { ROUTE_DOMAINS_BULK } from "../../routes";

import { REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE } from "../../config/promotions";

const StyledDomainPricesTable = styled.div`
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 5px;
`;

const StyledTable = styled.table`
  & {
    background-color: ${props => props.theme.white};
    border-radius: 5px;
    margin-bottom: 0;
  }

  & thead th {
    border-bottom: 1px solid ${props => props.theme.neutral3};
    padding: 20px 30px;
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.neutral5};
    vertical-align: middle;
  }

  & thead,
  & thead tr:first-child,
  & thead tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }

  & thead,
  & thead tr:first-child,
  & thead tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

  & tbody tr td {
    padding: 15px 30px;
    font-size: 16px;
    color: ${props => props.theme.neutralBase};
    border-bottom: 1px solid ${props => props.theme.neutral3};

    & .icon {
      color: ${props => props.theme.neutral4};
    }

    & .badge {
      margin-bottom: 0;
    }
    & .badge-success {
      margin-left: 10px;
    }
  }

  & tbody,
  & tbody tr:last-child,
  & tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  & tbody,
  & tbody tr:last-child,
  & tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  & tbody tr td:first-child {
    border-right: 1px solid ${props => props.theme.neutral3};
  }

  & tbody tr:last-child td {
    border-bottom: 0;
  }
`;

const StyledDuration = styled.div`
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  position: ${props => (props.isMobile ? "relative" : "absolute")};
  top: ${props => (props.isMobile ? "0" : "14px")};
  margin-left: 10px;

  & .button {
    display: inline-block;
    position: relative;
    padding: 5px;
    font-size: 24px;
    color: ${props => props.theme.blueBase};
    width: 35px;
    height: 32px;
    vertical-align: top;
    cursor: pointer;
  }
  & .button:first-child {
    border-right: 1px solid ${props => props.theme.neutral3};
  }

  & .button > img {
    vertical-align: top;
    color: red;
  }
`;

const StyledInfoWrapper = styled.div`
  padding-top: ${props => (props.isMobile ? "80px" : "150px")};
`;

const StyledInfo = styled.div`
  color: ${props => props.theme.white};
  border-radius: 3px;
  padding: 30px;
  padding-right: 80px;
  margin-bottom: 30px;
  & .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    margin: 0;
  }

  & .description {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledBlueInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.blueBase};
`;

const StyledGreenInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.greenBase};
`;

const StyledDisclaimer = styled.span`
  display: block;
  font-size: 14px;
  color: ${props => props.theme.neutralBase};
  margin-top: 5px;
`;

class DomainPricesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 1
    };
  }

  onIncreaseDuration = () => {
    if (this.state.duration + 1 <= 10) {
      this.setState({
        duration: this.state.duration + 1
      });
    }
  };

  onDescreaseDuration = () => {
    if (this.state.duration - 1 >= 1) {
      this.setState({
        duration: this.state.duration - 1
      });
    }
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledDomainPricesTable
                className={`${isMobileOnly && "table-responsive"}`}
              >
                <StyledTable className="table table-borderless">
                  {!isMobileOnly && (
                    <thead>
                      <tr>
                        <th>{translate("tld")}</th>
                        <th>
                          {translate("duration")}{" "}
                          <StyledDuration isMobile={isMobileOnly}>
                            <div
                              onClick={() => this.onDescreaseDuration()}
                              className="button"
                            >
                              <img src={subtract} alt="" />
                            </div>
                            <div
                              onClick={() => this.onIncreaseDuration()}
                              className="button"
                            >
                              <img src={add} alt="" />
                            </div>
                          </StyledDuration>
                        </th>
                        <th>{translate("register")}</th>
                        <th>{translate("renew")}</th>
                        <th>{translate("transfer")}</th>
                        <th />
                      </tr>
                    </thead>
                  )}

                  {isMobileOnly && (
                    <React.Fragment>
                      <thead>
                        <tr>
                          <th colSpan="6">
                            {translate("duration")}{" "}
                            <StyledDuration isMobile={isMobileOnly}>
                              <div
                                onClick={() => this.onDescreaseDuration()}
                                className="button"
                              >
                                <img src={subtract} alt="" />
                              </div>
                              <div
                                onClick={() => this.onIncreaseDuration()}
                                className="button"
                              >
                                <img src={add} alt="" />
                              </div>
                            </StyledDuration>
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th>{translate("tld")}</th>
                          <th>{translate("register")}</th>
                          <th>{translate("renew")}</th>
                          <th>{translate("transfer")}</th>
                          <th />
                        </tr>
                      </thead>
                    </React.Fragment>
                  )}

                  <tbody>
                    {this.props.items &&
                      Object.keys(this.props.items).map(item => (
                        <tr key={`dp-${this.state.duration}-${item}`}>
                          <td>
                            <strong>
                              .{item}{" "}
                              {item === "org.mk" && (
                                <CustomTooltip
                                  className="float-right"
                                  inline={true}
                                  content={translate(
                                    "the-registrant-must-be-an-organization"
                                  )}
                                >
                                  <Icon icon="info" />
                                </CustomTooltip>
                              )}
                              {item === "edu.mk" && (
                                <CustomTooltip
                                  className="float-right"
                                  inline={true}
                                  content={translate(
                                    "the-registrant-must-be-an-educational-institution"
                                  )}
                                >
                                  <Icon icon="info" />
                                </CustomTooltip>
                              )}
                            </strong>
                            {isMobileOnly && (
                              <span>
                                {this.state.duration > 1
                                  ? `${this.state.duration} ${translate(
                                      "years"
                                    )}`
                                  : `${this.state.duration} ${translate(
                                      "year"
                                    )}`}
                              </span>
                            )}
                          </td>
                          {!isMobileOnly && (
                            <td>
                              {this.state.duration > 1
                                ? `${this.state.duration} ${translate("years")}`
                                : `${this.state.duration} ${translate("year")}`}
                            </td>
                          )}
                          <td>
                            <strong>
                              {this.props.items[item][this.props.currency]
                                .register &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] ==
                                  "0.00" &&
                                translate("general.free")}

                              {this.props.items[item][this.props.currency]
                                .register &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] &&
                                item !== "gr" &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] >
                                  0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .register[this.state.duration.toString()]}

                              {this.props.items[item][this.props.currency]
                                .register &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] &&
                                item === "gr" &&
                                this.state.duration % 2 === 0 &&
                                this.props.items[item][this.props.currency]
                                  .register[this.state.duration.toString()] >
                                  0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .register[this.state.duration.toString()]}
                              {item === "gr" && this.state.duration % 2 !== 0 && (
                                <CustomTooltip
                                  inline={true}
                                  content={parse(
                                    translate(
                                      "other.legal.gr-domains-can-register-2-year"
                                    )
                                  )}
                                >
                                  <Icon icon="info" />
                                </CustomTooltip>
                              )}
                              {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 &&(
                    <BadgeSuccess className="badge-success">
                      -{REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE}%
                    </BadgeSuccess>
                  )} 
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {this.props.items[item][this.props.currency]
                                .renew &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] ==
                                  "0.00" &&
                                translate("general.free")}

                              {this.props.items[item][this.props.currency]
                                .renew &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] &&
                                item !== "gr" &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] > 0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .renew[this.state.duration.toString()]}

                              {this.props.items[item][this.props.currency]
                                .renew &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] &&
                                item === "gr" &&
                                this.state.duration % 2 === 0 &&
                                this.props.items[item][this.props.currency]
                                  .renew[this.state.duration.toString()] > 0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .renew[this.state.duration.toString()]}
                              {item === "gr" && this.state.duration % 2 !== 0 && (
                                <CustomTooltip
                                  inline={true}
                                  content={parse(
                                    translate(
                                      "other.legal.gr-domains-can-register-2-year"
                                    )
                                  )}
                                >
                                  <Icon icon="info" />
                                </CustomTooltip>
                              )}
                            </strong>
                          </td>
                          <td>
                            <strong>
                              {this.props.items[item][this.props.currency]
                                .transfer &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] ==
                                  "0.00" &&
                                translate("general.free")}

                              {this.props.items[item][this.props.currency]
                                .transfer &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] &&
                                item !== "gr" &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] >
                                  0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .transfer[this.state.duration.toString()]}

                              {this.props.items[item][this.props.currency]
                                .transfer &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] &&
                                item === "gr" &&
                                this.state.duration % 2 === 0 &&
                                this.props.items[item][this.props.currency]
                                  .transfer[this.state.duration.toString()] >
                                  0 &&
                                this.props.currency.toUpperCase() +
                                  " " +
                                  this.props.items[item][this.props.currency]
                                    .transfer[this.state.duration.toString()]}
                              {item === "gr" && this.state.duration % 2 !== 0 && (
                                <CustomTooltip
                                  inline={true}
                                  content={parse(
                                    translate(
                                      "other.legal.gr-domains-can-register-2-year"
                                    )
                                  )}
                                >
                                  <Icon icon="info" />
                                </CustomTooltip>
                              )}
                            </strong>
                          </td>
                          <td>
                            {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 && (
                              <BadgeDanger className="badge float-right">
                                {translate("on-sale").toUpperCase()}
                              </BadgeDanger>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </StyledDomainPricesTable>
              {activeLanguage && activeLanguage.code === "mk" && (
                <StyledDisclaimer>
                  *{translate("price-without-vat")}
                </StyledDisclaimer>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <StyledInfoWrapper isMobile={isMobileOnly}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4 offset-md-2">
                    <StyledBlueInfo>
                      <h3 className="title">
                        {translate(
                          "looking-to-buy-or-transfer-domains-in-bulk"
                        )}
                      </h3>
                      <p className="description">
                        {translate(
                          "check-our-domain-registration-and-transfer-prices-for-bulk-amounts"
                        )}
                        .
                      </p>
                      <a
                        href={ROUTE_DOMAINS_BULK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SecondaryButton size="l">
                          {translate("view-bulk-options")}
                        </SecondaryButton>
                      </a>
                    </StyledBlueInfo>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <StyledGreenInfo>
                      <h3 className="title">
                        {translate("registering-a-macedonian-domain")}
                      </h3>
                      <p className="description">
                        {translate(
                          "mkhost-is-a-licensed-registrar-with-marnet"
                        )}
                        .
                      </p>
                      <a
                        href="http://marnet.mk/doc/pravilnik-mk-mkd.pdf"
                        target="_blank"
                        rel="nofollow noopener"
                      >
                        <SecondaryButton size="l">
                          {translate(
                            "pages.domains.whois.visit-marnet-rulebook"
                          )}
                        </SecondaryButton>
                      </a>
                    </StyledGreenInfo>
                  </div>
                </div>
              </StyledInfoWrapper>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default DomainPricesTable;
