import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import {
  ROUTE_EMAIL_TUTORIALS,
  ROUTE_EMAIL_TUTORIALS_ANDROID_IMAP
} from "../../../routes";
import { validateEmail } from "../../../common/validationRules";

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-ios.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

const StyledInput = styled.input`
  width: 60%;
  border-radius: 3px;
  border: 1px solid gray;
  line-height: 30px;
  margin-left: 10px;
`;

const StyledButton = styled.button`
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
`;

const validate = values => {
  return {
    email: validateEmail(values.email, true)
  };
};

class ErrorPage extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="container text-center">
            <div className="row my-3">
              <div className="col-md-10 offset-md-1">
                <h2>{translate("pages.email-tutorial.error.settings")}</h2>
              </div>
            </div>

            <div className="alert alert-danger p-5">
              <strong>{translate("pages.email-tutorial.error.error")}! </strong>
              {translate("pages.email-tutorial.error.not-registered")}.
            </div>
            <PrimaryButton
              size="m"
              className="float-right mb-2"
              onClick={this.goBack}
            >
              {translate("pages.email-tutorial.error.try-again")}
            </PrimaryButton>
          </div>
        )}
      </Translate>
    );
  }
}

export default withRouter(ErrorPage);
