const DefaultTheme = {
  blueBase: "#1B75BC",
  blue1: "#F6FBFF",
  blue2: "#EFF3F6",
  blue3: "#69BEFF",
  blue4: "#1E85D5",
  blue5: "#16639f",
  neutralBase: "#263857",
  neutral1: "#F5F5F7",
  neutral2: "#E4E5EA",
  neutral3: "#D8DDE6",
  neutral4: "#A5ADBA",
  neutral5: "#7B8BA7",
  neutral6: "#5E6C83",
  white: "#FFFFFF",
  redBase: "#D85653",
  red1: "#FFEBE7",
  red2: "#DF7775",
  red3: "#B62C29",
  yellowBase: "#FE9B34",
  yellow1: "#FFF5D0",
  yellow2: "#D2571B",
  greenBase: "#3AB27F",
  green1: "#E3FCEF",
  green2: "#066544",
  green3: "#78dcb4",
  green4: "#45c28d",
  green5: "#31976b"
};

export default DefaultTheme;
