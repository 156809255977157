import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import BulletPoint from "../../BulletPoint";

const StyledManageYourContactsSection = styled.div`
  padding: 75px 0;
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 38px;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.neutral6};
`;

const ManageYourContactsSection = ({ type }) => (
  <Translate>
    {({ translate }) => (
      <StyledManageYourContactsSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8">
                  <img
                    className="img-fluid"
                    src="/assets/images/image_cp-manage-contacts.png"
                    alt=""
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-4">
                  <StyledTitle>
                    {translate(type + "-manage-your-contacts")}
                  </StyledTitle>
                  <StyledDescription>
                    {translate(
                      type +
                        "-all-of-your-domain-contacts,-payers,-and-product-managers-in-one-place"
                    )}
                  </StyledDescription>
                  <BulletPoint>
                    {translate("save-contact-details")}{" "}
                    {translate("in-a-single-place,-easy-to-access")}
                  </BulletPoint>
                  <BulletPoint>
                    {translate("re-use-existing-contacts")}{" "}
                    {translate(
                      "as-payers,-domain-contacts,-or-assign-them-as-product-managers-(billers-&-developers)"
                    )}
                  </BulletPoint>
                  <BulletPoint>
                    {translate("get-an-overview")}{" "}
                    {translate("of-the-status-of-your-contacts")}
                  </BulletPoint>
                  <BulletPoint>
                    {translate("detailed-contact-information")}{" "}
                    {translate("shows-the-products")}{" "}
                    {translate("and-services-the-contact-is-assigned-to")}
                  </BulletPoint>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledManageYourContactsSection>
    )}
  </Translate>
);
export default ManageYourContactsSection;
