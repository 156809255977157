import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";

import Container from "../../Containers/Container";

const StyledOneClickAppInstallBlocksSection = styled.div`
  padding: ${props => (props.isMobile ? "55px 0" : "75px 0")};
`;

const StyledBlocksContainer = styled.div`
  margin-top: ${props => (props.isMobile ? "70px" : "-30px")};
`;

const StyledBlueContainer = styled(Container)`
  background-color: #1a8abe;
  border-color: #1a8abe;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledGreenContainer = styled(Container)`
  background-color: #83b563;
  border-color: #83b563;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledOrangeContainer = styled(Container)`
  background-color: #ef6a3a;
  border-color: #ef6a3a;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledDarkBlueContainer = styled(Container)`
  background-color: #263857;
  border-color: #263857;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;

const StyledIcon = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  margin-left: -10px;
  margin-bottom: 20px;
`;

const StyledTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 10px;
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
`;

const StyledBigTitle = styled.h4`
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  line-height: 0.5;
  margin-bottom: 60px;
  margin-top: 40px;
`;

const OneClickAppInstallBlocksSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledOneClickAppInstallBlocksSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate(
                      "install-your-favorite-apps-and-scripts-with-a-single-click"
                    )}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "install-your-favorite-hosting-app-or-script-with-a-single-click.-join-more-than-50%-of-our-clients-who-sucesfully-run-their-websites-using-ready-made-templates-from-various-platforms"
                    )}
                  </SectionDescription>
                  <SectionDescription>
                    <strong>
                      {translate(
                        "choose-from-300+-free-scripts-in-your-cpanel,-including"
                      )}
                    </strong>
                  </SectionDescription>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <BulletPoint>
                        {translate(
                          "content-of-management-systems-(cms)-portals"
                        )}
                      </BulletPoint>
                      <BulletPoint>{translate("online-shops")}</BulletPoint>
                      <BulletPoint>{translate("forums")}</BulletPoint>
                      <BulletPoint>{parse(translate("blog"))}</BulletPoint>
                      <BulletPoint>{translate("image-galleries")}</BulletPoint>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 offset-md-1">
                  <StyledBlocksContainer
                    className="row"
                    isMobile={isMobileOnly}
                  >
                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledBlueContainer>
                        <StyledIcon src="/assets/icons/app-bubbles/bubble_wordpress.svg" />
                        <StyledTitle>{translate("wordpress")}</StyledTitle>
                        <StyledDescription>
                          {translate(
                            "the-perfect-platform-for-bloggers,-small-businesses,-and-ecommerce-websites"
                          )}
                          .
                        </StyledDescription>
                      </StyledBlueContainer>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledGreenContainer>
                        <StyledIcon src="/assets/icons/app-bubbles/bubble_joomla.svg" />
                        <StyledTitle>{translate("joomla")}</StyledTitle>
                        <StyledDescription>
                          {translate(
                            "build-websites-and-online-applications-with-one-of-the-most-popular-cms-scripts"
                          )}
                          .
                        </StyledDescription>
                      </StyledGreenContainer>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledOrangeContainer>
                        <StyledIcon src="/assets/icons/app-bubbles/bubble_magento.svg" />
                        <StyledTitle>{translate("magento")}</StyledTitle>
                        <StyledDescription>
                          {translate(
                            "the-most-popular-open-source-shopping-cart,-used-by-over-240,000-merchants"
                          )}
                          .
                        </StyledDescription>
                      </StyledOrangeContainer>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledDarkBlueContainer>
                        <StyledBigTitle>300+</StyledBigTitle>
                        <StyledTitle>
                          {translate("free-apps-and-scripts")}
                        </StyledTitle>
                        <StyledDescription>
                          {translate(
                            "your-favorite-apps-and-scripts-are-one-click-away.-just-log-into-your-cpanel-and-install-them"
                          )}
                          .
                        </StyledDescription>
                      </StyledDarkBlueContainer>
                    </div>
                  </StyledBlocksContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledOneClickAppInstallBlocksSection>
    )}
  </Translate>
);
export default OneClickAppInstallBlocksSection;
