import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notice from "../Notice";

const StyledNoticeWarning = styled.div`
  background-color: ${theme.yellow1};
  border: 1px solid ${theme.yellowBase};
  color: ${theme.yellowBase};
  border-radius: 3px;
  display: inline-block;
  & a {
    color: ${theme.yellowBase};
  }
`;

const NoticeWarning = ({ children, ...rest }) => (
  <StyledNoticeWarning className="notice-warning">
    <Notice icon="warning" {...rest}>
      {children}
    </Notice>
  </StyledNoticeWarning>
);

NoticeWarning.defaultProps = {
  dismissable: false,
  onDismiss: () => {}
};

export default NoticeWarning;
