import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import Container from "../Containers/Container";
import Badge from "../Badges/Badge";
import BadgeSuccess from "../Badges/BadgeSuccess";
import Icon from "../Icon";

import converter from "cyrillic-to-latin"

import graphicLike from "./graphic-like-active.svg";

import { compileRoute, ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE } from "../../routes";
import manualCharConverter from "../../utils/manualCharConverter";

const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
`;

const StyledHeader = styled.div`
  margin-bottom: 40px;
`;
const StyledHeaderTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 5px;
`;

const StyledHeaderDescription = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.theme.neutral6};
`;

const StyledArticle = styled.div``;

const StyledTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin: 10px 0;
`;

const StyledDescription = styled.p`
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
`;

const StyledLikeImage = styled.img`
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  margin-top: -3px;
`;

const StyledBadgeSuccess = styled(BadgeSuccess)`
  cursor: pointer;
`;


const MostPopularArticles = ({
  title,
  description,
  articles,
  voteArticle,
  isAuthenticated
}) => (
  <Translate>
    {({ translate }) => (
      <StyledContainer isMobile={isMobileOnly}>
        <StyledHeader>
          <StyledHeaderTitle>
            {title ? title : translate("most-popular-artices")}
          </StyledHeaderTitle>
          <StyledHeaderDescription>
            {description
              ? description === "hide"
                ? ""
                : description
              : translate(
                  "the-following-articles-have-proven-to-be-most-useful-to-our-users"
                )}
          </StyledHeaderDescription>
        </StyledHeader>
        {articles &&
          articles.length > 0 &&
          articles.map((article, index) => (
            <StyledArticle key={article.title + article.id}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  {!isAuthenticated ? (
                    <BadgeSuccess>
                      <StyledLikeImage src={graphicLike} alt="" />{" "}
                      {article.votes}
                    </BadgeSuccess>
                  ) : (
                    <StyledBadgeSuccess
                      onClick={() => voteArticle(article.id, article.votes)}
                    >
                      <StyledLikeImage src={graphicLike} alt="" />{" "}
                      {article.votes}
                    </StyledBadgeSuccess>
                  )}

                  <Badge>{article.category_name}</Badge>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <Link
                    to={compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                      id: article.id
                    }) + manualCharConverter(article.title)}
                  >
                    <StyledTitle>{article.title}</StyledTitle>
                  </Link>
                  <StyledDescription>{article.description}</StyledDescription>
                </div>
              </div>
            </StyledArticle>
          ))}
      </StyledContainer>
    )}
  </Translate>
);

export default MostPopularArticles;
