import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import TechnologyAwsWeUseSection from "../../components/Sections/TechnologyAwsWeUseSection";

import WhyChooseAwsServerTeamSection from "../../components/Sections/WhyChooseAwsServerTeamSection";
import ProjectQuoteFormSection from "../../components/Sections/ProjectQuoteFormSection";

import seoData from "../../seo/seoData";
import {ROUTE_CLIENT_LOGIN, ROUTE_WEBSITE_DOMAIN} from "../../routes";

import WhyPurchaseAwsSection from "../../components/Sections/WhyPurchaseAwsSection";

import FreeAutoSSLCertificateSection from "../../components/Sections/FreeAutoSSLCertificateSection";
import IconTextInfo from "../../components/IconTextInfo";
import CaseStudyAWS from "../../components/Sections/CaseStudyAWS";

const StyledAwsCloudLandingPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: -101px;
  }
`;

const StyledPlan = styled.div`
  margin-bottom: 30px;
`;

const StyledQuote = styled.div`
  background-image: url("/assets/backgrounds/bg-1-2.svg");
  background-position: bottom;
  background-position-y: 90px;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 75px 0;
`;

const StyledQuoteSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 301px;
`;
const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "0")};
  left: 0;
  width: 100%;
`;
class AWSCloudLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.quoteRef = null;
  }

  onGoToQuoteForm = () => {
    if (this.quoteRef) {
      this.quoteRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
        <Translate>
          {({ translate, activeLanguage }) => (
              <StyledAwsCloudLandingPage>
                {activeLanguage && activeLanguage.code && (
                    <Helmet>
                      <title>
                        {seoData[activeLanguage.code].addons_aws.title}
                      </title>
                      <meta
                          name="keywords"
                          content={
                            seoData[activeLanguage.code].addons_aws
                                .meta_keywords
                          }
                      />
                      <meta
                          name="description"
                          content={
                            seoData[activeLanguage.code].addons_aws
                                .meta_description
                          }
                      />
                      <meta
                          property="og:title"
                          content={
                            seoData[activeLanguage.code].addons_aws.title
                          }
                      />
                      <meta
                          property="og:description"
                          content={
                            seoData[activeLanguage.code].addons_aws
                                .meta_description
                          }
                      />
                      <meta
                          property="og:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/aws-og.png`}
                      />

                      <meta
                          name="twitter:title"
                          content={
                            seoData[activeLanguage.code].addons_aws.title
                          }
                      />
                      <meta
                          name="twitter:description"
                          content={
                            seoData[activeLanguage.code].addons_aws
                                .meta_description
                          }
                      />
                      <meta
                          name="twitter:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/aws_og.png`}
                      />
                    </Helmet>
                )}
                <Hero
                    title={() => (
                        <span>
                  {translate("aws_cloud")}

                </span>
                    )}
                    image="/assets/illustrations/cloud-robot.png"
                    backgroundImage="/assets/backgrounds/bg-1-1.svg"
                    backgroundSize={isMobileOnly ? "cover" : "contain"}
                    paddingBottom={isMobileOnly ? "30px" : "0"}
                    leftClassName="col-12 col-sm-12 col-md-6"
                    rightClassName="col-12 col-sm-12 col-md-6 "
                    subtitle={translate(
                        "we-can-help-your-business-expand-and-thrive-through-the-benefits-of-the-cloud"
                    )}
                    action={() => (
                        <PrimaryButton onClick={() => this.onGoToQuoteForm()} size="l">
                          {translate("talk-to-our-team")}
                        </PrimaryButton>
                    )}
                />
                {!isMobileOnly && (
                    <div className="row">
                      <BackgroundSpacer height="150px" />
                    </div>
                )}
                <StyledAwsCloudLandingPage />
                <div className="col-12 col-sm-12 col-md-12">


                  <WhyPurchaseAwsSection />
                  <TechnologyAwsWeUseSection />
                  <CaseStudyAWS />
                  <WhyChooseAwsServerTeamSection/>

                </div>


                <StyledQuote ref={ref => (this.quoteRef = ref)} className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("request-an-offer")}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                  "tell-us-what-kind-of-project-you-have-in-mind-and-we'll-send-you-an-offer-as-soon-as-possible"
                              )}
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-8">
                            <ProjectQuoteFormSection companyName={true}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <StyledQuoteSpacer height="215px" />
                  </div>
                </StyledQuote>

              </StyledAwsCloudLandingPage>
          )}
        </Translate>
    );
  }
}
export default withRouter(AWSCloudLandingPage);
