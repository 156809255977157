import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import Slider from "react-slick";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import sliderData from "./sliderData";

const StyledOurPortfolioSection = styled.div`
  padding: ${props => (props.isMobile ? "0px 0" : "75px 0")};
`;

const StyledSlideWrapper = styled.div`
  position: relative;
  padding-left: ${props => !props.isMobile && "150px"};
  padding-right: ${props => !props.isMobile && "150px"};
  .custom-shadow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 280px;
    height: 100%;
    cursor: pointer;
  }

  .custom-left-shadow {
    left: 110px;
    top: 0;
    box-shadow: inset 191px 0px 107px -36px rgba(255, 255, 255, 1);
  }

  .custom-right-shadow {
    right: 110px;
    top: 0;
    box-shadow: inset -191px 0px 107px -36px rgba(255, 255, 255, 1);
  }

  .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .slick-list {
    height: ${props => props.isMobile && "450px"};
  }
  .slick-arrow {
    display: none !important;
  }
`;

const StyledSlide = styled.div`
  text-align: center;
  position: relative;
  background-color: ${props => props.theme.white} !important;
`;

const StyledSlideContent = styled.div`
  position: relative;
  padding: 30px;
  height: ${props =>
    props.centered
      ? props.isMobile
        ? "365px"
        : "500px"
      : props.isMobile
        ? "365px"
        : "450px"};

  width: ${props => (props.isMobile ? "280px" : "auto")};
  margin-top ${props => (!props.centered && !props.isMobile ? "30px" : "0")};
  margin-bottom ${props => (!props.centered && !props.isMobile ? "30px" : "0")};
  background-color: ${props => props.theme.neutral3};
  box-shadow: ${props =>
    props.centered
      ? "0 10px 20px 0 rgba(123, 139, 167, 0.25)"
      : "0 3px 5px 0 rgba(123, 139, 167, 0.25)"};


   & img {
    height: ${props =>
      !props.centered
        ? props.isMobile
          ? "250px"
          : "360px"
        : props.isMobile
          ? "250px"
          : "360px"};
   }
`;

const StyledGradientShadow = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  bottom: 110px;

  height: 20px;
  background-image: linear-gradient(
    to bottom,
    rgba(38, 56, 87, 0),
    rgba(38, 56, 87, 0.1)
  );
`;

const StyledOverview = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px;
  background-color: ${props => props.theme.white};
  & .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: ${props => props.theme.neutralBase};
    margin-bottom: 5px;
  }
  & .type {
    display: block;
    font-size: 16px;
    text-align: center;
    color: ${props => props.theme.neutral6};
  }
`;

const StyledSectionDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

class OurPortfolioSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: 1
    };

    this.sliderSettings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      initialSlide: 1,
      autoplay: true,
      autoPlaySpeed: 5000,
      variableWidth: true,
      beforeChange: (current, next) => this.setState({ sliderIndex: next })
    };

    this.sliderRef = null;
  }

  setSliderRef = slider => (this.sliderRef = slider);
  onNext() {
    this.sliderRef.slickNext();
  }

  onPrev() {
    this.sliderRef.slickPrev();
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledOurPortfolioSection className="row" isMobile={isMobileOnly}>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 text-center">
                      <SectionTitle>{translate("our-portfolio")}</SectionTitle>
                      <StyledSectionDescription>
                        {translate(
                          "browse-our-portfolio-of-web-solutions-we-have-completed-for-our-clients"
                        )}
                      </StyledSectionDescription>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledSlideWrapper
                        isMobile={isMobileOnly}
                        className="slider-wrapper"
                      >
                        {!isMobileOnly && (
                          <div
                            className="custom-shadow custom-left-shadow"
                            onClick={() => this.onPrev()}
                          />
                        )}
                        <Slider
                          {...this.sliderSettings}
                          ref={this.setSliderRef}
                          className="slider-slider"
                        >
                          {sliderData.map(slide => (
                            <StyledSlide
                              className="slider-slide"
                              key={slide.title}
                              centered={this.state.sliderIndex === slide.index}
                              left={this.state.sliderIndex > slide.index}
                              right={this.state.sliderIndex < slide.index}
                            >
                              <StyledSlideContent
                                isMobile={isMobileOnly}
                                centered={
                                  this.state.sliderIndex === slide.index
                                }
                                className="slider-content"
                              >
                                <img
                                  className="img-fluid"
                                  src={slide.image}
                                  alt={
                                    activeLanguage &&
                                    slide.title[activeLanguage.code]
                                  }
                                />
                                <StyledGradientShadow />
                                <StyledOverview className="slider-overview">
                                  <a
                                    href={slide.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="title">
                                      {activeLanguage &&
                                        slide.title[activeLanguage.code]}
                                    </span>
                                  </a>
                                  <span className="type">
                                    {activeLanguage &&
                                      slide.type[activeLanguage.code]}
                                  </span>
                                </StyledOverview>
                              </StyledSlideContent>
                            </StyledSlide>
                          ))}
                        </Slider>
                        {!isMobileOnly && (
                          <div
                            className="custom-shadow custom-right-shadow"
                            onClick={() => this.onNext()}
                          />
                        )}
                      </StyledSlideWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledOurPortfolioSection>
        )}
      </Translate>
    );
  }
}

export default OurPortfolioSection;
