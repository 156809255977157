import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SmallTitle from "../../SmallTitle";
import CircledIcon from "../../CircledIcon";
import BulletPoint from "../../BulletPoint";
import CircledImage from "../../CircledImage";

const StyledWhyChooseAwsServerTeamSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "75px 0")};

  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const StyledCol = styled.div`
  padding-left: ${props => !props.isMobile && "130px"};
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const WhyChooseAwsServerTeamSection = () => (
    <Translate>
      {({ translate }) => (
          <StyledWhyChooseAwsServerTeamSection
              className="row"
              isMobile={isMobileOnly}
          >
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <SectionTitle>
                        {translate("specialized and certified team")}
                      </SectionTitle>
                      <SectionDescription>
                        {translate(
                            "leading-hosting-providers-in-balkans"
                        )}
                      </SectionDescription>
                      <SectionDescription>
                        {translate(
                            "our-clients-are-always-our-main-focus"
                        )}
                      </SectionDescription>
                    </div>
                    <StyledCol
                        className="col-12 col-sm-12 col-md-4"
                        isMobile={isMobileOnly}
                    >
                        {/*<CircledImage image="/assets/images/employees/biljana_s.jpg"/>*/}
                      <CircledIcon

                          image="/assets/images/employees/ivan_p.jpg"
                      />
                      <SmallTitle>
                        {translate("ivan-petrushevski")}
                      </SmallTitle>

                        {translate(
                            "ivan-petrushevski-description"
                        )}


                    </StyledCol>
                    <StyledCol
                        className="col-12 col-sm-12 col-md-4"
                        isMobile={isMobileOnly}
                    >
                      <CircledIcon
                          image="/assets/images/employees/robert_j.jpg"
                      />
                      <SmallTitle>
                        {translate("robert-jakimovski")}
                      </SmallTitle>

                        {translate(
                            "robert-description"
                        )}

                    </StyledCol>
                  </div>
                </div>
              </div>
            </div>
          </StyledWhyChooseAwsServerTeamSection>
      )}
    </Translate>
);
export default WhyChooseAwsServerTeamSection;
