import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import check_success from "../../assets/images/check_success.svg";
import check_success_blue from "../../assets/images/check_success_blue.svg";
import Icon from "../Icon";
const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 0;
  top: 13px;
  color: ${props => props.theme[props.color]};
`;

const BulletPoint = ({ type, icon, color, children, blueIcon }) => (
  <StyledBulletPoint>
    {type !== "arrow" && (
      <StyledCheck src={blueIcon ? check_success_blue : check_success} />
    )}
    {type === "arrow" && <StyledIcon icon={icon} color={color} />} {children}
  </StyledBulletPoint>
);

export default BulletPoint;
