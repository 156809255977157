import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SplitSection from "../SplitSection";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SecondaryButton from "../../Buttons/SecondaryButton";

import RegisterDomainWidget from "../../RegisterDomainWidget";

import bg2 from "../../../assets/images/bg-2.svg";

const StyledThirdPartyControlPanelSection = styled.div`
  padding-top: ${props => (props.isMobile ? "0px" : "60px")};
  padding-bottom: 20px;
  background-image: url(${bg2});
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 55px;
`;

const FindMoreWrapper = styled.div`
  display: inline-block;
  margin-top: 24px;
`;

const ThirdPartyControlPanelSection = ({
  title,
  description,
  image,
  children
}) => (
  <StyledThirdPartyControlPanelSection className="row" isMobile={isMobileOnly}>
    <div className="col-12 col-sm-1 col-md-12">
      <SplitSection
        leftClassName="col-12 col-sm-12 col-md-4"
        rightClassName="col-12 col-sm-12 col-md-8"
        left={() => (
          <React.Fragment>
            <SectionTitle>
              {typeof title === "function" ? title() : title}
            </SectionTitle>
            <SectionDescription>{description}</SectionDescription>
            {children && <FindMoreWrapper>{children}</FindMoreWrapper>}
          </React.Fragment>
        )}
        right={() => image && <img className="img-fluid" src={image} alt="" />}
      />
    </div>
  </StyledThirdPartyControlPanelSection>
);
export default ThirdPartyControlPanelSection;
