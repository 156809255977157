import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../Containers/Container";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomTooltip from "../CustomTooltip";

import { ROUTE_DOMAINS_REGISTER_WITHOUT_PARAMS } from "../../routes";

const StyledNewTLDsDomain = styled.div`
  vertical-align: top;
  float: left;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 25px;
  float: left;
  width: 100%;
  border: 1px solid
    ${props => {
      if (props.isNew) return props.theme.greenBase;
      if (props.isOnSale) return props.theme.redBase;
      return props.theme.neutral3;
    }};
`;

const StyledContainerBadge = styled.span`
  display: inline-block;
  position: absolute;
  top: -15px;
  left: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 6px 11px;
  border-radius: 14px;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.isNew) return props.theme.greenBase;
    if (props.isOnSale) return props.theme.redBase;
  }};
  color: ${props => props.theme.white};
`;

const StyledExtension = styled.h3`
  display: block;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 12px;
`;

const StyledDiscountedPrice = styled.span`
  display: inline-block;
  font-size: 16px;
  color: ${props => props.theme.neutral5};
  text-decoration: line-through;
  margin-right: 10px;
`;
const StyledPrice = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.42;
  color: ${props => props.theme.neutral6};
  margin-bottom: 25px;
`;

const StyledButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  margin-right: 0;
`;

const NewTLDsDomain = ({
  extension,
  price,
  period,
  currency,
  discountedPrice,
  isNew,
  isOnSale,
  description
}) => (
  <Translate>
    {({ translate }) => (
      <StyledNewTLDsDomain>
        <StyledContainer isNew={isNew} isOnSale={isOnSale}>
          {isNew && (
            <StyledContainerBadge isNew={isNew}>
              {translate("new")}
            </StyledContainerBadge>
          )}
          {isOnSale && (
            <StyledContainerBadge isOnSale={isOnSale}>
              {translate("sale")}
            </StyledContainerBadge>
          )}
          <StyledExtension>{extension}</StyledExtension>
          {description && <StyledDescription>{description}</StyledDescription>}

          <StyledPrice>
            {discountedPrice && (
              <StyledDiscountedPrice>
                {/* prettier-ignore */}
                {discountedPrice && currency}
                {discountedPrice}
              </StyledDiscountedPrice>
            )}
            {`${currency.toUpperCase()} ${price}`}

            <StyledPeriod>
              {" "}
              /{period}
              {currency.toLowerCase() === "мкд" && (
                <CustomTooltip
                  inline={true}
                  content={translate("price-without-vat")}
                >
                  <span>*</span>
                </CustomTooltip>
              )}
            </StyledPeriod>
          </StyledPrice>
          <a
            href={ROUTE_DOMAINS_REGISTER_WITHOUT_PARAMS + `?ext=${extension}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledButton>{translate("register-now")}</StyledButton>
          </a>
        </StyledContainer>
      </StyledNewTLDsDomain>
    )}
  </Translate>
);
export default NewTLDsDomain;
