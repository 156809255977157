import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SecondaryButton from "../../Buttons/SecondaryButton";

const StyledCaseStudyAWS = styled.div`
  padding: 75px 0;
`;

const StyledWrapper = styled.div`
  display: inline-block;
  margin-top: 20px;
`;

const CaseStudyAWS = () => (
  <Translate>
    {({ translate }) => (
      <StyledCaseStudyAWS className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate(
                      "how-did-the-biggest-company-about-parking-solution"
                    )}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "electrosoft-is-one-of-the-balkan-leaders"
                    )}
                  </SectionDescription>

                  <SectionDescription>
                    <BulletPoint>
                      {translate("company-with-30-yeaes-expirience")}
                    </BulletPoint>
                    <BulletPoint>
                      {translate(
                        "over-100-parking-management-system"
                      )}
                    </BulletPoint>
                    <BulletPoint>
                      {translate(
                        "always-trying-to-implement-inovation-solutions"
                      )}
                    </BulletPoint>

                  </SectionDescription>
                  <StyledWrapper>
                    <a
                      href="https://aws-cloud-documents.s3.amazonaws.com/ElectroSOFT.pdf"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <SecondaryButton size="l">
                        {translate("learn-more-aws")}
                      </SecondaryButton>
                    </a>
                  </StyledWrapper>
                </div>
                {!isMobileOnly && (
                  <div className="col-12 col-sm-12 col-md-7">
                    <img
                      className="img-fluid"
                      src="/assets/images/image_premium.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledCaseStudyAWS>
    )}
  </Translate>
);

export default CaseStudyAWS;
