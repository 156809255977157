import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import FreeRegistrationFeaturesSection from "../../components/Sections/FreeRegistrationFeaturesSection";
import NewDomainExtensionsSection from "../../components/Sections/NewDomainExtensionsSection";
import MarketShareSection from "../../components/Sections/MarketShareSection";
import BulkDomainsSection from "../../components/Sections/BulkDomainsSection";

import AccordionItem from "../../components/Accordion/AccordionItem";
import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -52px;
`;

const StyledSpacing = styled.div`
  margin-top: ${props => props.isMobile && "45px"};
`;

class DomainsBulkOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExtension: null,
      domain: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      let domainName = this.props.match.params.name.split(".");
      const domain = domainName[0];
      domainName.shift();
      const extension = domainName.join(".");
      this.setState({
        selectedExtension: extension,
        domain
      });
    }
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].domains_bulk.title}</title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_bulk.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_bulk.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_bulk.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_bulk.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-bulk.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_bulk.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_bulk.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-bulk.png`}
                />
              </Helmet>
            )}
            <Hero
              className="domain-bulk"
              title={translate("bulk-options-for-domains")}
              subtitle={() => (
                <SectionDescription>
                  {translate(
                    "interested-in-purchasing-domains-in-bulk?-we-offer-various-discounts-based-on-your-needs,-as-well-as-extra-support"
                  )}
                </SectionDescription>
              )}
              image="/assets/illustrations/domains/illustration_domains-bulk.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              paddingBottom="0"
              backgroundPositionY={isMobileOnly && "-25px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer height="243px" />
            </Hero>

            <BulkDomainsSection />
            <FreeRegistrationFeaturesSection
              title={translate(
                "get-all-of-this-for-free-with-every-registration"
              )}
            />
            <NewDomainExtensionsSection />
            <StyledSpacing isMobile={isMobileOnly}>
              <MarketShareSection />
            </StyledSpacing>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default DomainsBulkOptions;
