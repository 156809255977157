import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import { ROUTE_HOSTING_WEB_HOSTING } from "../../../routes";

import Container from "../../Containers/Container";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import DomainsSearchSection from "../../Sections/DomainsSearchSection";

const StyledWebsiteSpaceSection = styled.div`
  padding: 75px 0;
  background-color: ${props => props.theme.blue1};
`;

const StyledCircle = styled.div`
  display: block;
  margin-bottom: 30px;
  padding-top: 15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
`;

const StyledBoldDescription = styled(SectionDescription)`
  font-weight: 600;
  margin-top: 20px;
`;

const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "30px 20px" : "30px")};
  margin-bottom: 30px;
  & .title {
    display: block;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.neutral6};
    margin-bottom: 25px;
  }

  & .size {
    display: block;
    font-size: ${props => (props.isMobile ? "27px" : "34px")};
    font-weight: 600;
    line-height: 1.39;
    color: ${props => props.theme.neutralBase};
  }

  & .type {
    display: block;
    font-size: 14px;
    line-height: 1.43;
    color: ${props => props.theme.neutral6};
  }
`;

const StyledBlock = styled.div`
  display: inline-block;
  border-right: 1px solid ${props => props.theme.neutral3};
  padding: ${props => (props.isMobile ? " 0 25px" : " 0 30px")};
  margin-bottom: 40px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border-right: 0;
  }
`;

const WebsiteSpaceSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWebsiteSpaceSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledCircle>2</StyledCircle>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate("each-website-needs-space")}
                  </SectionTitle>
                  <SectionDescription>
                    {parse(
                      translate(
                        "each-home-has-a-physical-space-where-it-is-built.-websites-also-require-space-in-order-to-be-built.-that-space-is-called"
                      )
                    )}{" "}
                    {parse(translate("that-hosting-space-can-be"))} (
                    {translate("part-of-a-larger-server")}),{" "}
                    {parse(translate("your-own-server"))}
                  </SectionDescription>

                  <SectionDescription>
                    {translate(
                      "websites-have-another-advantage-over-homes-their-space-is-not"
                    )}{" "}
                    {translate("limited-and")}{" "}
                    {parse(translate("can-be-constantly-upgraded"))}
                  </SectionDescription>

                  {isMobileOnly && (
                    <div className="text-center mb-5">
                      <img
                        width="60%"
                        className="img-fluid"
                        src="/assets/icons/icon_illustration_space.png"
                        alt=""
                      />
                    </div>
                  )}

                  <StyledBoldDescription>
                    {translate(
                      "how-much-space-on-the-internet-would-be-enough-for-you"
                    )}
                    ?
                  </StyledBoldDescription>
                  <SectionDescription>
                    {parse(
                      translate(
                        "different-websites-have-different-needs.-some-need-only-a-small-home,-while-other-require-a-large-home-that-can-accomodate-lots-of-furniture-,-and-lots-of-guests"
                      )
                    )}
                  </SectionDescription>
                </div>
                {!isMobileOnly && (
                  <div className="col-12 col-sm-12 col-md-6 offset-md-1 text-center">
                    <img
                      width="60%"
                      className="img-fluid"
                      src="/assets/icons/icon_illustration_space.png"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledContainer isMobile={isMobileOnly}>
                        <span className="title">
                          {translate(
                            "excellent-for-small-and-initial-websites"
                          )}
                        </span>
                        <div>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">2 GB</span>
                            {translate("space")}
                          </StyledBlock>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">100 GB</span>
                            <span className="type">
                              {translate("bandwidth")}
                            </span>
                          </StyledBlock>
                        </div>
                        <a
                          href={ROUTE_HOSTING_WEB_HOSTING}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PrimaryButton>
                            {translate("find-out-more")}
                          </PrimaryButton>
                        </a>
                      </StyledContainer>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledContainer isMobile={isMobileOnly}>
                        <span className="title">
                          {translate("ideal-for-a-larger-number-of-websites")}
                        </span>
                        <div>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">10 GB</span>
                            {translate("space")}
                          </StyledBlock>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">500 GB</span>
                            <span className="type">
                              {translate("bandwidth")}
                            </span>
                          </StyledBlock>
                        </div>
                        <a
                          href={ROUTE_HOSTING_WEB_HOSTING}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PrimaryButton>
                            {translate("find-out-more")}
                          </PrimaryButton>
                        </a>
                      </StyledContainer>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledContainer isMobile={isMobileOnly}>
                        <span className="title">
                          {translate(
                            "suitable-for-large-and-multiple-websites"
                          )}
                        </span>
                        <div>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">30 GB</span>
                            {translate("space")}
                          </StyledBlock>
                          <StyledBlock isMobile={isMobileOnly}>
                            <span className="size">700 GB</span>
                            <span className="type">
                              {translate("bandwidth")}
                            </span>
                          </StyledBlock>
                        </div>
                        <a
                          href={ROUTE_HOSTING_WEB_HOSTING}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <PrimaryButton>
                            {translate("find-out-more")}
                          </PrimaryButton>
                        </a>
                      </StyledContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWebsiteSpaceSection>
    )}
  </Translate>
);
export default WebsiteSpaceSection;
