import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import DomainsSearchSection from "../../Sections/DomainsSearchSection";

const StyledWebsiteLocationSection = styled.div`
  padding: 75px 0;
  background-image: url("/assets/backgrounds/bg-3.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
`;

const StyledCircle = styled.div`
  display: block;
  margin-bottom: 30px;
  padding-top: 15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
`;

const StyledBoldDescription = styled(SectionDescription)`
  font-weight: 600;
  margin-top: 20px;
`;

const WebsiteLocationSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWebsiteLocationSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledCircle>1</StyledCircle>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate("each-website-starts-with-a-location")}
                  </SectionTitle>
                  <SectionDescription>
                    {parse(
                      translate(
                        "as-each-home-requires-a-physical-address-in-order-to-exist,-each-website-needs-a-virtual-address,-called-a"
                      )
                    )}
                  </SectionDescription>
                  <SectionDescription>
                    {translate("websites-have-a-hidden-advantage")} –{" "}
                    {parse(translate("you-can-choose-your-own-address-name"))},{" "}
                    {translate("if-it-s-available")}
                  </SectionDescription>
                  {isMobileOnly && (
                    <div className="text-center mb-5">
                      <img
                        width="60%"
                        className="img-fluid m-auto"
                        src="/assets/icons/icon_illustration_address.png"
                        alt=""
                      />
                    </div>
                  )}
                  <StyledBoldDescription>
                    {translate("how-do-you-choose-an-ideal-internet-address")}
                  </StyledBoldDescription>
                  <SectionDescription>
                    {translate(
                      "your-internet-addresss-(domain-name)-should-be"
                    )}{" "}
                    {parse(translate("short,-simple,-and-easy-to-remember"))}.{" "}
                    {translate(
                      "you-can-also-choose-from-a-variety-of-different-extensions-like-.mk-and-.com"
                    )}
                    .
                  </SectionDescription>
                </div>
                {!isMobileOnly && (
                  <div className="col-12 col-sm-12 col-md-6 offset-md-1 text-center">
                    <img
                      width="60%"
                      className="img-fluid"
                      src="/assets/icons/icon_illustration_address.png"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
        </div>
      </StyledWebsiteLocationSection>
    )}
  </Translate>
);
export default WebsiteLocationSection;
