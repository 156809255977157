import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import { ROUTE_OTHER_FREE_EBOOK_THANK_YOU } from "../../../routes";

import * as mailchimpService from "../../../services/mailchimpService";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhoneNumberWithoutFormat, validateCheckbox
} from "../../../common/validationRules";

import Container from "../../Containers/Container";
import InputField from "../../InputField";
import TextArea from "../../TextArea";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Checkbox from "../../Checkbox";

const StyledProjectQuoteFormSection = styled.div``;
const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "20px" : "40px")};
`;
const StyledCheckbox = styled.div`
  & {
    width: 100%;
    cursor: pointer;
   
    padding-left: 0;
    margin-bottom:10px;
  }

`;
const validate = values => {

  return {
    first_name: validateFirstName(values.first_name, true),
    last_name: validateLastName(values.last_name, true),
    email: validateEmail(values.email, true),
    pp: validateCheckbox(values.pp, "validation.accept-privacy-policy-global"),
    pp2:validateCheckbox(values.pp2, "validation.accept-privacy-policy-global")
  };
};

class FreeEbookFormSection extends React.Component {
  onSubmit = values => {
    return mailchimpService
      .subscribe(values)
      .then(() => this.props.history.push(ROUTE_OTHER_FREE_EBOOK_THANK_YOU));
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledProjectQuoteFormSection className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledContainer isMobile={isMobileOnly}>
                <Form
                  validate={validate}
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <Field
                            component={InputField}
                            label={translate("sections.free-ebook.first-name")}
                            placeholder={translate(
                              "sections.free-ebook.first-name-placeholder"
                            )}
                            name="first_name"
                          />
                          <Field
                            component={InputField}
                            label={translate("sections.free-ebook.last-name")}
                            placeholder={translate(
                              "sections.free-ebook.last-name-placeholder"
                            )}
                            name="last_name"
                          />
                          <Field
                            component={InputField}
                            label={translate("sections.free-ebook.email")}
                            placeholder={translate(
                              "sections.free-ebook.email-placeholder"
                            )}
                            name="email"
                          />
                          <br/>
                          <br/>
                          <Field
                              component={Checkbox}
                              value={true}
                              label={translate("sections.free-ebook.agree-privacy-policy")}
                              name="pp"
                          />
                          <br/>

                          <Field
                              component={Checkbox}
                              value={true}
                              label={translate("sections.free-ebook.agreement")}
                              name="pp2"
                          />

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <PrimaryButton
                            disabled={invalid || submitting}
                            submitting={submitting}
                          >
                            {translate("sections.free-ebook.download")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </StyledContainer>
            </div>
          </StyledProjectQuoteFormSection>
        )}
      </Translate>
    );
  }
}

export default withRouter(FreeEbookFormSection);
