import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import DataContext from "../../DataContext";

import Hero from "../../components/Hero";
import NewTLDsSection from "../../components/Sections/NewTLDsSection";
import MoreAboutTLDsSection from "../../components/Sections/MoreAboutTLDsSection";

import AccordionItem from "../../components/Accordion/AccordionItem";
import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import check_success from "../../assets/images/check_success.svg";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.neutral6};
`;

const StyledTransferPrices = styled.span`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.neutral5};
  text-align: center;
`;

const domainNameOfTheMonth = 'travel'

const tlds = [
  {
    extension: ".travel",
    isNew: true,
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".coffee",
    isOnSale: true,
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    discountedPrice: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".turs",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".xyz",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".fit",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".top",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".online",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  },
  {
    extension: ".school",
    description: "Great for design agencies, art schools or artists",
    price: "16.20",
    currency: "€",
    period: "year"
  }
];

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -158px;
`;

class DomainsNewTLDsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExtension: null,
      domain: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      let domainName = this.props.match.params.name.split(".");
      const domain = domainName[0];
      domainName.shift();
      const extension = domainName.join(".");
      this.setState({
        selectedExtension: extension,
        domain
      });
    }
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].domains_tlds.title}</title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_tlds.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_tlds.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_tlds.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_tlds.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-new.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_tlds.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_tlds.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-new.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("new-domain-extensions")}
              subtitle={() => (
                <SectionDescription>
                  {translate(
                    "find-the-right-domain-extension-with-our-brand-new-tlds-including-design-coffee-and-travel"
                  )}
                </SectionDescription>
              )}
              image="/assets/illustrations/domains/illustration_domains-new.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              paddingBottom="100px"
              backgroundPositionY={isMobileOnly ? "-70px" : "150px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer height="269px" />
            </Hero>

            <DataContext.Consumer>
              {({ domains: { fresh }, currency }) => {
                let domainOfMonth = null;
                let rest = null;
                if (fresh && currency) {
                  domainOfMonth = {
                    extension: "." + domainNameOfTheMonth,
                    price: parseFloat(
                      fresh[domainNameOfTheMonth][currency.toLowerCase()].register["1"]
                    ).toFixed(currency === "eur" ? 2 : 0),
                    period: translate("year"),
                    currency: currency,
                    description: translate("new-domains-descriptions." + domainNameOfTheMonth)
                  };
                  rest = Object.keys(fresh)
                    .filter(domain => domain !== domainNameOfTheMonth)
                    .map(domain => {
                      return {
                        extension: "." + domain,
                        price: parseFloat(
                          fresh[domain][currency.toLowerCase()].register["1"]
                        ).toFixed(currency === "eur" ? 2 : 0),
                        period: translate("year"),
                        currency: currency,
                        description: translate(
                          `new-domains-descriptions.${domain}`
                        )
                      };
                    });
                }

                return <NewTLDsSection domainOfTheMonth={domainOfMonth} tlds={rest} />;
              }}
            </DataContext.Consumer>

            <MoreAboutTLDsSection />
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default DomainsNewTLDsPage;
