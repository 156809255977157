import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import CircledIcon from "../../CircledIcon";

const StyledPersonalizedServicesSection = styled.div`
  padding: 75px 0;
`;

const StypedTop = styled.div`
  margin-bottom: 50px;
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const PersonalizedServicesSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledPersonalizedServicesSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("what-kind-of-services-do-we-offer")}
                  </SectionTitle>
                </div>
              </StypedTop>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="websites"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("web-design")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "we-can-design-the-perfect-website-for-the-online-presence-of-your-company,-product,-or-service"
                        )}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="code"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>
                        {translate("software-architecture")}
                      </SmallTitle>
                      <SmallDescription>
                        {translate(
                          "need-a-custom-software-solution-for-your-business?-we-can-build-anything-to-fit-your-unique-needs"
                        )}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="tablet"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("web-applications")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "our-web-solutions-utilize-the-latest-technology-for-developing-web-based-hybrid-apps"
                        )}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="cart"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("e-commerce")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "ready-to-sell-online?-we-can-create-a-completely-custom-online-store,-and-let-you-focus-on-selling"
                        )}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="palette"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("branding")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "we-can-create-a-story-for-your-company,-product,-or-service,-complete-with-a-name,-logo,-and-graphical-assets"
                        )}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="cpanel"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>
                        {translate("website-maintenance")}
                      </SmallTitle>
                      <SmallDescription>
                        {translate(
                          "need-professionals-to-take-care-of-your-website?-our-development-team-is-here-for-all-of-your-requests"
                        )}
                      </SmallDescription>
                    </Feature>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledPersonalizedServicesSection>
    )}
  </Translate>
);
export default PersonalizedServicesSection;
