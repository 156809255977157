import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import SectionDescription from "../../../components/SectionDescription";
import ProjectQuoteFormSection from "../../../components/Sections/ProjectQuoteFormSection/ProjectQuoteFormSection";
import styled from "styled-components";
import BackgroundSpacer from "../../../components/BackgroundSpacer";
import {Translate} from "react-localize-redux";

const StyledQuote = styled.div`
  background-image: url("/assets/backgrounds/bg-1-2.svg");
  background-position: bottom;
  background-position-y: 90px;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 75px 0;
`;
const StyledQuoteSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 301px;
`;

class OnlineShopQuote extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Translate>
                {({translate, activeLanguage}) => (
                    <StyledQuote ref={ref => (this.quoteRef = ref)} className="row">
                        <div className="col-12 col-sm-12 col-md-12"  id={'contact-online'}>
                            <div className="row">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-4">
                                            <SectionTitle>
                                                {translate("request-an-offer")}
                                            </SectionTitle>
                                            <SectionDescription>
                                                {translate(
                                                    "tell-us-what-kind-of-project-you-have-in-mind-and-we'll-send-you-an-offer-as-soon-as-possible"
                                                )}
                                            </SectionDescription>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-8">
                                            <ProjectQuoteFormSection/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StyledQuoteSpacer height="215px"/>
                        </div>
                    </StyledQuote>
                )}
            </Translate>
        )
    }

}
export default OnlineShopQuote;