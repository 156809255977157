import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";

import WebsiteLocationSection from "../../components/Sections/WebsiteLocationSection";
import WebsiteSpaceSection from "../../components/Sections/WebsiteSpaceSection";
import WebsiteBuildSection from "../../components/Sections/WebsiteBuildSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import ProjectQuoteFormSection from "../../components/Sections/ProjectQuoteFormSection";
import YoungEnterpreneursFormSection from "../../components/Sections/YoungEnterpreneursFormSection";
import WhatWillYouGet from "../../components/Sections/WhatWillYouGet";
import Clock from "../../components/Sections/Clock";
import parse from "html-react-parser";

import {
  ROUTE_WEB_DESIGN_PREMIUM,
  ROUTE_HOSTING_WEB_HOSTING,
  ROUTE_WEBSITE_DOMAIN,
  ROUTE_CLOUD_SOLUTIONS_MANAGED_CLOUD
} from "../../routes";

import seoData from "../../seo/seoData";
import HostingCloudConsultationFormSection from "../../components/Sections/HostingCloudConsultationFormSection";
import WhatAreTheAdvantagesFromCloud from "../../components/Sections/WhatAreTheAdvantagesFromCloud";

const StyledHostingCloudConsultationWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4-blue.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 130px;
`;

const StyledQuoteSection = styled.div`
  margin-top: 70px;
  padding: 75px 0;
  padding-bottom: 150px;
`;


class OtherHostingCloudConsultationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: "August, 11, 2019"
    };
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_hosting_cloud_consultation.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_hosting_cloud_consultation.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_hosting_cloud_consultation
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_hosting_cloud_consultation.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_hosting_cloud_consultation
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/hosting_cloud_consultation_og.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_hosting_cloud_consultation.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_hosting_cloud_consultation
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/hosting_cloud_consultation_og.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate(
                "pages.other.hosting-cloud-consultation.why-cloud-is-ideal-for-your-business"
              )}
              backgroundColor="blueBase"
              color="white"
              paddingBottom="0"
               leftClassName="col-12 col-sm-12 col-md-6"
                            rightClassName="col-12 col-sm-12 col-md-6 "
              backgroundSize={"contain"}
              backgroundPosition={isTablet ? "bottom" : "bottom"}
              subtitle={parse(`
                <ul>
                  <li>${translate("pages.other.hosting-cloud-consultation.hosting-cloud-consultation-description-1")}</li>
                  <li>${translate("pages.other.hosting-cloud-consultation.hosting-cloud-consultation-description-2")}</li>
                  <li>${translate("pages.other.hosting-cloud-consultation.hosting-cloud-consultation-description-3")}</li>
                  <li>${translate("pages.other.hosting-cloud-consultation.hosting-cloud-consultation-description-4")}</li> </br>
                  <strong>${translate("pages.other.hosting-cloud-consultation.hosting-cloud-consultation-description-5")}</strong>
                </ul>
              `)}
              image={
                !isTablet &&
                "/assets/illustrations/hosting_cloud_consultation.png"
              }
              action={() =>
                !isMobileOnly && (
                  <a
                    href={ROUTE_CLOUD_SOLUTIONS_MANAGED_CLOUD}
                    rel="noopener noreferrer"
                  >
                    <SecondaryButton size="l">
                      {translate("pages.other.hosting-cloud-consultation.schedule-consultation")}
                    </SecondaryButton>
                  </a>
                )
              }
            />


                        
            <StyledHostingCloudConsultationWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                {/* <WhatAreTheAdvantagesFromCloud /> */}
                <StyledQuoteSection id="consultation-form" className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("sections.hosting-cloud-consultation.title")}
                            </SectionTitle>
                            <SectionDescription>
                              {parse(
                                translate("sections.hosting-cloud-consultation.description")
                              )}
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-8">
                            <HostingCloudConsultationFormSection />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledQuoteSection>
                
              </div>
            </StyledHostingCloudConsultationWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

export default OtherHostingCloudConsultationPage;
