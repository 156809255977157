import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";

import WhoAreYouSection from "../../components/Sections/WhoAreYouSection";

import WorksOnAllDevicesSection from "../../components/Sections/WorksOnAllDevicesSection";
import VisitHelpDeskSection from "../../components/Sections/VisitHelpDeskSection";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledControlPanelWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 130px;
`;

class OtherControlPanelPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_control_panel.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_control_panel
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_control_panel
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].other_control_panel.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_control_panel
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].other_control_panel.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_control_panel
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("a-control-panel-created-just-for-you")}
              backgroundColor="neutralBase"
              color="white"
              paddingBottom="0"
              subtitle={translate(
                "our-brand-new-control-panel-gives-you-easy-access-to-all-of-your-mkhost-products-and-services.-easily-manage-your-billing-documents,-contacts,-and-support-tickets"
              )}
              image="/assets/illustrations/illustration_control-panel.png"
            />

            <StyledControlPanelWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <WhoAreYouSection />

                <WorksOnAllDevicesSection />
                <VisitHelpDeskSection />
              </div>
            </StyledControlPanelWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherControlPanelPage;
