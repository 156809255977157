

import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Perk from "../../Perk";
import { isMobileOnly } from "react-device-detect";

const StyledWhyPurchaseSSLSection = styled.div`
  padding: ${props => (props.isMobile ? "75px 0 30px 0" : "75px 0")};
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const AWSPartners = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhyPurchaseSSLSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("aws_partner_title")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "aws_partner_description"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("aws_migration_title")}
                    description={() => (
                      <span>
                        {translate(
                          "aws_migration_description")}
                      </span>
                    )}
                    icon="ranking"
                    color="greenBase"
                    backgroundColor="green1"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("aws_management_title")}
                    description={() => (
                      <span>
                        {translate(
                          "aws_management_description"
                        )}
                      </span>
                    )}
                    icon="cpanel"
                    color="yellowBase"
                    backgroundColor="yellow1"
                  />
                </div>
               

              
              </div>
            </div>
          </div>
        </div>
      </StyledWhyPurchaseSSLSection>
    )}
  </Translate>
);
export default AWSPartners;
