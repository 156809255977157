const dictionaryCyrilicToLatin = {
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  ѓ: "gj",
  е: "e",
  ж: "zh",
  з: "z",
  ѕ: "dz",
  и: "i",
  ј: "j",
  к: "k",
  л: "l",
  љ: "lj",
  м: "m",
  н: "n",
  њ: "nj",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  ќ: "kj",
  у: "u",
  ф: "f",
  х: "h",
  ц: "c",
  ч: "ch",
  џ: "dzh",
  ш: "sh",
  А: "A",
  Б: "B",
  В: "V",
  Г: "G",
  Д: "D",
  Ѓ: "Gj",
  Е: "E",
  Ж: "Zh",
  З: "Z",
  Ѕ: "Dz",
  И: "I",
  Ј: "J",
  К: "K",
  Л: "L",
  Љ: "Lj",
  М: "M",
  Н: "N",
  Њ: "Nj",
  О: "O",
  П: "P",
  Р: "R",
  С: "S",
  Т: "T",
  Ќ: "Kj",
  У: "U",
  Ф: "F",
  Х: "H",
  Ц: "C",
  Ч: "Ch",
  Џ: "Dzh",
  Ш: "Sh"
};

const dictionaryLatinToCyrilic = {
  gj: "ѓ",
  dz: "ѕ",
  lj: "љ",
  zh: "ж",
  nj: "њ",
  kj: "ќ",
  dzh: "џ",
  ch: "ч",
  sh: "ш",
  a: "а",
  b: "б",
  v: "в",
  g: "г",
  d: "д",
  e: "е",
  z: "з",
  i: "и",
  j: "ј",
  k: "к",
  l: "л",
  m: "м",
  n: "н",
  o: "о",
  p: "п",
  r: "р",
  s: "с",
  t: "т",
  u: "у",
  f: "ф",
  h: "х",
  c: "ц",
  GJ: "ѓ",
  DZ: "ѕ",
  LJ: "љ",
  ZH: "ж",
  NJ: "њ",
  KJ: "ќ",
  DZH: "џ",
  CH: "ч",
  SH: "ш",
  A: "А",
  B: "Б",
  V: "В",
  G: "Г",
  D: "Д",
  E: "Е",
  Z: "З",
  I: "И",
  J: "Ј",
  K: "К",
  L: "Л",
  M: "М",
  N: "Н",
  O: "О",
  P: "П",
  R: "Р",
  S: "С",
  T: "Т",
  U: "У",
  F: "Ф",
  H: "Џ",
  C: "Ц"
};

const transliterate = (dictionary, word) => {
  for (const char in dictionary) {
    const re = new RegExp(char, "g");
    word = word.replace(re, dictionary[char]);
  }
  return word;
};

const transliterateToCyrilic = word =>
  transliterate(dictionaryLatinToCyrilic, word);

const transliterateToLatin = word =>
  transliterate(dictionaryCyrilicToLatin, word);

const isCyrilic = word => /^[\u0400-\u04FF0-9]+/g.test(word);

export { transliterateToCyrilic, transliterateToLatin, isCyrilic };
