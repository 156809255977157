import React from "react";
import styled from "styled-components";

const StyledContentWidget = styled.div`
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 3px;
  padding: 30px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  margin-bottom: 20px;
  color: ${props => props.theme.neutralBase};
`;
const StyledSubtitle = styled.span``;
const StyledContent = styled.div`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  margin-bottom: 30px;
`;
const StyledLink = styled.a`
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
  border: solid 1px ${props => props.theme.blueBase};
  position: relative;
  padding: 10px 20px;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.2px;
  margin-right: 15px;
  outline: none;
  &:hover {
    background-color: ${props => props.theme.blue4};
    border: 1px solid ${props => props.theme.blue4};
    cursor: pointer;
    text-decoration: none;
    color: ${props => props.theme.white};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.blue3};
  }

  &:active {
    border: 1px solid ${props => props.theme.blue5};
    background-color: ${props => props.theme.blue5};
    color: ${props => props.theme.white};
  }

  &:disabled {
    background-color: ${props => props.theme.neutral2};
    color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.neutral2};
    cursor: not-allowed;
  }

  &:only-child {
    margin-right: 0;
  }

  & > span[class^="icon-"] {
    left: -7px;
  }
`;

const ContentWidget = ({ title, subtitle, children, link, linkTitle }) => (
  <StyledContentWidget>
    {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
    <StyledTitle>{title}</StyledTitle>
    <StyledContent>{children}</StyledContent>
    {link && linkTitle && (
      <StyledLink href={link} target="_blank" rel="noopener noreferrer">
        {linkTitle}
      </StyledLink>
    )}
  </StyledContentWidget>
);

export default ContentWidget;
