import React from "react";
import styled from "styled-components";

import Icon from "../../Icon";

const StyledNotice = styled.div`
  display: inline-block;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  padding: 15px;
  padding-left:45px;
  padding-right: ${props => (props.dismissable ? "45px" : "15px")}
  position: relative;

  & .icon {
    top: 0;
  }

  & a {
    font-weight: 600;
  }
  & .row {
    margin-bottom: 0;
  }
`;

const StyledIcon = styled.div`
  display: inline-block;
  position: absolute;
  left: 10px;
`;

const StyledNoticeCloseIcon = styled.span`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
`;

const StyledContent = styled.div`
  display: inline-block;
`;

const NoticeCloseIcon = props => (
  <StyledNoticeCloseIcon {...props} onClick={props.onDismiss}>
    <Icon icon="close" />
  </StyledNoticeCloseIcon>
);

NoticeCloseIcon.defaultProps = {
  onDismiss: () => {}
};

const Notice = ({ children, ...rest }) => (
  <StyledNotice className="notice">
    {rest.dismissable && (
      <NoticeCloseIcon
        dismissable={rest.dismissable}
        onDismiss={rest.onDismiss}
      />
    )}
    <StyledContent>
      <StyledIcon>
        <Icon icon={rest.icon} />
      </StyledIcon>
      {children}
    </StyledContent>
  </StyledNotice>
);

Notice.defaultProps = {
  dismissable: false,
  onDismiss: () => {}
};

export default Notice;
