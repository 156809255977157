import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";

import WebsiteLocationSection from "../../components/Sections/WebsiteLocationSection";
import WebsiteSpaceSection from "../../components/Sections/WebsiteSpaceSection";
import WebsiteBuildSection from "../../components/Sections/WebsiteBuildSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import ProjectQuoteFormSection from "../../components/Sections/ProjectQuoteFormSection";
import YoungEnterpreneursFormSection from "../../components/Sections/YoungEnterpreneursFormSection";
import WhatWillYouGet from "../../components/Sections/WhatWillYouGet";
import Clock from "../../components/Sections/Clock";
import parse from "html-react-parser";

import {
  ROUTE_WEB_DESIGN_PREMIUM,
  ROUTE_HOSTING_WEB_HOSTING,
  ROUTE_WEBSITE_DOMAIN
} from "../../routes";

import seoData from "../../seo/seoData";

const StyledFirstWebsiteWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4-green.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 130px;
`;

const StyledQuoteSection = styled.div`
  margin-top: 70px;
  padding: 75px 0;
  padding-bottom: 150px;
`;

class OtherOtherYoungEnterpreneurs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: "August, 11, 2019"
    };
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_young_enterpreneurs.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/illustration_launch.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_young_enterpreneurs
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/illustration_launch.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("win-a-personalized-web-solution")}
              backgroundColor="greenBase"
              color="white"
              paddingBottom="0"
              backgroundSize={"contain"}
              backgroundPosition={isTablet ? "bottom" : "bottom"}
              subtitle={translate(
                "enter-your-business-into-the-digital-world-by-signing"
              )}
              image={
                !isTablet && "/assets/illustrations/illustration_launch.png"
              }
            />

            <StyledFirstWebsiteWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledQuoteSection className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("only")}
                              <Clock deadline={this.state.deadline} />
                              {parse(translate("days-left"))}!
                            </SectionTitle>
                            <SectionDescription>
                              {parse(
                                translate(
                                  "fill-in-your-information-until-august-10-for-a-chance-to-win"
                                )
                              )}
                              .
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-8">
                            <YoungEnterpreneursFormSection />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledQuoteSection>
                <WhatWillYouGet />
              </div>
            </StyledFirstWebsiteWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}

export default OtherOtherYoungEnterpreneurs;
