import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import DomainOfTheMonth from "../../DomainOfTheMonth";
import NewTLDsDomain from "../../NewTLDsDomain";

import ContentLoader from "../../Loading/ContentLoader";
import withLoading from "../../Loading/WithLoading";

const StyledNewTLDsSection = styled.div`
  margin-top: -71px;
  padding-bottom: ${props => !props.isMobile && "150px"};
`;

const StyledGridBlock = styled.div`
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
  width: ${props => (props.isMobile ? "100%" : "30%")};
  vertical-align: top;
  float: left;
`;

const StyledWrapper = styled.div``;
const StyledWithLoading = withLoading(StyledWrapper, ContentLoader);

const NewTLDsSection = ({ domainOfTheMonth, tlds }) => (
  <StyledNewTLDsSection
    isLoading={!domainOfTheMonth || !tlds}
    className="row"
    isMobile={isMobileOnly}
  >
    <div className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="container">
          <StyledWithLoading isLoading={!domainOfTheMonth || !tlds}>
            <React.Fragment>
              <StyledGridBlock isMobile={isMobileOnly}>
                <DomainOfTheMonth domain={domainOfTheMonth} />
              </StyledGridBlock>
              {tlds &&
                tlds.length > 0 &&
                tlds.map(tld => (
                  <StyledGridBlock key={tld.extension} isMobile={isMobileOnly}>
                    <NewTLDsDomain {...tld} />
                  </StyledGridBlock>
                ))}
            </React.Fragment>
          </StyledWithLoading>
        </div>
      </div>
    </div>
  </StyledNewTLDsSection>
);
export default NewTLDsSection;
