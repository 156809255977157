import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import SplitSection from "../SplitSection";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SecondaryButton from "../../Buttons/SecondaryButton";

import { ROUTE_OTHER_CONTROL_PANEL } from "../../../routes";

const StyledCustomControlPanelSection = styled.div`
  padding-top: ${props => (props.isMobile ? "40px" : "180px")};
  padding-bottom: ${props => (props.isMobile ? "96px" : "150px")};
`;

const StyledWrapper = styled.div`
  padding-top: ${props => (props.isMobile ? "0px" : "115px")};

  & img {
    margin-top: ${props => props.isMobile && "50px"};
    margin-bottom: ${props => props.isMobile && "40px"};
  }
`;

const GetStartedWrapper = styled.div`
  margin-top: 14px;
`;

const CustomControlPanelSection = props => (
  <Translate>
    {({ translate }) => (
      <StyledCustomControlPanelSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <SplitSection
            left={() =>
              !isMobileOnly && (
                <img
                  className="img-fluid"
                  alt="Helpdesk"
                  src="/assets/images/helpdesk.png"
                />
              )
            }
            leftClassName="col-12 col-sm-12 col-md-6"
            right={() => (
              <StyledWrapper isMobile={isMobileOnly}>
                <SectionTitle>
                  {translate("a-control-panel-created-just-for-you")}
                </SectionTitle>
                <SectionDescription>
                  {translate(
                    "our-brand-new-control-panel-gives-you-easy-access-to-all-of-your-mkhost-products-and-services.-easily-manage-your-billing-documents,-contacts,-and-support-tickets"
                  )}
                </SectionDescription>
                {isMobileOnly && (
                  <img
                    className="img-fluid"
                    alt="Helpdesk"
                    src="/assets/images/helpdesk.png"
                  />
                )}
                <GetStartedWrapper>
                  <a
                    href={ROUTE_OTHER_CONTROL_PANEL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SecondaryButton>
                      {translate("find-out-more")}
                    </SecondaryButton>
                  </a>
                </GetStartedWrapper>
              </StyledWrapper>
            )}
            rightClassName="col-12 col-sm-12 col-md-5 offset-md-1"
          />
        </div>
      </StyledCustomControlPanelSection>
    )}
  </Translate>
);

export default CustomControlPanelSection;
