import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";
import qs from "query-string";
import parse from "html-react-parser";

import * as sslService from "../../services/sslService";
import * as authService from "../../services/authService";
import * as cartService from "../../services/cartService";

import Hero from "../../components/Hero";
import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SupportPlan from "../../components/SupportPlan";
import FeaturesTable from "../../components/FeaturesTable";
import CustomTooltip from "../../components/CustomTooltip";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SelectBox from "../../components/SelectBox";

import Modal from "../../components/Modal";

import WhyPurchaseSSLSection from "../../components/Sections/WhyPurchaseSSLSection";
import HowSSLCertificatesWorkSection from "../../components/Sections/HowSSLCertificatesWorkSection";
import FreeAutoSSLCertificateSection from "../../components/Sections/FreeAutoSSLCertificateSection";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";

import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import check_success from "../../assets/images/check_success.svg";
import clear from "../../assets/images/clear.png";

import seoData from "../../seo/seoData";
import {
  ROUTE_WEBSITE_DOMAIN,
  ROUTE_CLIENT_LOGIN,
  ROUTE_CLIENT_CART
} from "../../routes";
import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";
import { FAQ_CATEGORY_SSL } from "../../config/faq";

const StyledAvaiable = styled.img``;
const StyledUnavaiable = styled.img``;

const StyledAddonsPremiumSupportPage = styled.div`
  padding: ${props => (props.isMobile ? "0px 0" : "75px 0")};
`;

const FeaturesTableSection = styled.div`
  margin-top: ${props => (props.isMobile ? "-1px" : "40px")};
  padding-bottom: ${props => (props.isMobile ? "5px" : "75px")};

  & .features-table {
    border-top: ${props => props.isMobile && "0"};
    margin-bottom: ${props => props.isMobile && "30px"};
  }

  & .feature-value {
    border-left: ${props => props.isMobile && "0"};
  }
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin-bottom: 15px;
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const StyledFreeBlock = styled(Container)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${props => props.theme.neutral4};
  text-align: center;
  padding: 14px;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "0")};
  left: 0;
  width: 100%;
`;

const StyledAddSslToCartPrimaryButton = styled(PrimaryButton)`
  float: right;
  margin-right: 0;
`;

const SupportPlanWithLoading = withLoading(SupportPlan, ContentLoader);

class AddonsSSLPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingSSL: true,
      ssl: null,
      isAuthenticated: false,
      featuresFormatted: null,
      showSelectHostingModal: false,
      selectedSSL: null,
      isFetchingHostings: false,
      selectedHosting: null,
      applicableHostings: null,
      addToCart: false
    };
  }

  componentDidMount() {
    this.setState({
      isFetchingSSL: true
    });
    sslService.allSSL().then(result => {
      const { services } = result.data.data;

      let featuresFormatted = [];

      services.forEach(service => {
        service.details.features.forEach((feature, index) => {
          featuresFormatted[index] = {
            ...featuresFormatted[index],
            name: Object.keys(feature)[0],
            [service.id]: Object.values(feature)[0]
          };
        });
      });

      this.setState({
        ssl: services,
        featuresFormatted,
        isFetchingSSL: false
      });

      if (authService.getToken()) {
        return authService.getMe().then(result => {
          if (result.status === 200) {
            const params = qs.parse(this.props.location.search);

            if (params.ssl) {
              const sslExists =
                services.find(s => s.id === parseInt(params.ssl, 10)) || null;

              this.setState({
                isAuthenticated: true,
                selectedSSL: sslExists,
                showSelectHostingModal: !!sslExists
              });
            }
          }

          if (result.status === 401) {
            this.setState({
              isAuthenticated: false
            });
          }
        });
      } else {
        this.setState({
          isAuthenticated: false
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {}

  onShowSelectHosting = ssl => {
    if (ssl) {
      this.setState({
        showSelectHostingModal: true,
        selectedSSL: ssl,
        isFetchingHostings: true
      });
    }
  };

  onHideSelectHosting = () => {
    this.setState({
      showSelectHostingModal: false,
      selectedSSL: null,
      isFetchingHostings: false,
      selectedHosting: null,
      applicableHostings: null
    });
  };

  remapApplicableHostings = (value, callback) => {
    sslService
      .fetchApplicableHostings(this.state.selectedSSL.id)
      .then(result => {
        const { products_grouped } = result.data.data;
        this.setState({
          isFetchingHostings: false,
          applicableHostings: products_grouped
        });
        const hostings = this.state.applicableHostings.map(group => {
          return {
            label: group.name,
            options: group.products.map(product => {
              return {
                label: `${product.name} (${product.domain})`,
                value: product.id,
                type: product.type,
                name: product.name,
                id: product.id,
                domain: product.domain
              };
            })
          };
        });
        callback(hostings);
      });
  };

  onSelectHosting = value => {
    if (value) {
      this.setState({
        selectedHosting: value
      });
    }
  };

  onAddToCart = () => {
    this.setState({
      addingToCart: true
    });
    return cartService
      .add({
        cart: cartService.getCartId(),
        type: `${this.state.selectedHosting.type}_service`,
        quantity: 1,
        purchased_item: {
          id: this.state.selectedHosting.id,
          name: this.state.selectedHosting.name,
          type: this.state.selectedHosting.type
        },
        service_id: this.state.selectedSSL.id,
        domain: this.state.selectedHosting.domain
      })
      .then(
        result => {
          this.setState({
            addingToCart: false
          });
          this.onHideSelectHosting();
          displayToastMessageForResponse(
            this.props.translate("general.cart"),
            {
              error: result.data.error,
              messages: result.data.data.messages
            },
            `${ROUTE_CLIENT_CART}?cart=${cartService.getCartId()}`,
            this.props.translate("general.view-cart")
          );
        },
        error => {
          const { messages } = error.response.data.data;
          this.setState({
            addingToCart: false
          });
          this.onHideSelectHosting();
          displayToastMessageForResponse(this.props.translate("general.cart"), {
            error: true,
            messages: messages
          });
        }
      )
      .catch(error => {
        this.setState({
          addingToCart: false
        });
        this.onHideSelectHosting();
        displayToastMessageForResponse(this.props.translate("general.cart"), {
          error: true,
          messages: error.message
        });
      });
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledAddonsPremiumSupportPage
            isMobile={isMobileOnly}
            className="row"
          >
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].addons_ssl.title}</title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].addons_ssl.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].addons_ssl.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].addons_ssl.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].addons_ssl.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_ssl.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].addons_ssl.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].addons_ssl.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustration_ssl.png`}
                />
              </Helmet>
            )}
            <div className="col-12 col-sm-12 col-md-12">
              <Hero
                title={translate("ssl-certificates")}
                leftClassName="col-12 col-sm-12 col-md-4"
                rightClassName="col-12 col-sm-12 col-md-7 offset-md-1"
                subtitle={parse(
                  translate(
                    "our-ssl-certificates-provide-your-website-with-the-highest-levels-of-security.-secure-your-e-commerce-transactions,-passwords,-credit-card-numbers,-and-your-customer-information"
                  )
                )}
                image="/assets/illustrations/illustration_ssl.png"
                backgroundImage="/assets/backgrounds/bg-1-1.svg"
                backgroundSize={isMobileOnly && "cover"}
                paddingBottom="30px"
              >
                <StyledBackgroundSpacer
                  height={isMobileOnly ? "160px" : "200px"}
                />
              </Hero>
              <FeaturesTableSection
                className="row no-gutters"
                isMobile={isMobileOnly}
              >
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row no-gutters">
                    <div className="container">
                      <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-3" />
                        <div className="col-12 col-sm-12 col-md-9">
                          <div className="row no-gutters">
                            {!this.state.ssl && (
                              <React.Fragment>
                                <div className="col-12 col-sm-12 col-md-4">
                                  <SupportPlanWithLoading isLoading={true} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-4">
                                  <SupportPlanWithLoading isLoading={true} />
                                </div>
                                <div className="col-12 col-sm-12 col-md-4">
                                  <SupportPlanWithLoading isLoading={true} />
                                </div>
                              </React.Fragment>
                            )}

                            {this.state.ssl &&
                              this.state.ssl.map((ssl, index) => {
                                
                                let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                                let pricing = null;

                                // const pricing = ssl.pricing.buy.billing_cycle.derivations.find(
                                //   d => d.numeric_code === 1
                                // );

                                // Temporary solution, should be sync with api derivation numeric codes
                                possiblePeriodNumericCodes.some(numericCode => {
                                  pricing = ssl.pricing.buy.billing_cycle.derivations.find(
                                    d => d.numeric_code === numericCode
                                  );

                                  if (typeof(pricing) !== 'undefined') {
                                    // console.log(numericCode);
                                    return true;
                                  }
                                });
                                // END

                                return (
                                  <div
                                    className="col-12 col-sm-12 col-md-4"
                                    key={`ssl-${ssl.id}`}
                                  >
                                    <SupportPlan
                                      firstChild={index === 0}
                                      title={ssl.name}
                                      description={ssl.description}
                                      customBadgeImage="/assets/images/comodo-transparent.png"
                                      content={() => (
                                        <div className="row">
                                          <div className="col-12 col-sm-12 col-md-12 text-center">
                                            <StyledPrice>
                                              {activeLanguage &&
                                              activeLanguage.code === "mk" ? (
                                                <span>
                                                  {pricing.price.value}{" "}
                                                  {pricing.price.currency.code.toUpperCase()}
                                                  /{translate("year")}
                                                  <CustomTooltip
                                                    inline={true}
                                                    content={translate(
                                                      "price-without-vat"
                                                    )}
                                                  >
                                                    <span>*</span>
                                                  </CustomTooltip>
                                                </span>
                                              ) : (
                                                <span>
                                                  {pricing.price.value}{" "}
                                                  {pricing.price.currency.code.toUpperCase()}
                                                  /{translate("year")}
                                                </span>
                                              )}
                                            </StyledPrice>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-12 text-center">
                                            {this.state.isAuthenticated && (
                                              <PrimaryButton
                                                size="l"
                                                className="w-100"
                                                onClick={() =>
                                                  this.onShowSelectHosting(ssl)
                                                }
                                              >
                                                {translate("order-now")}
                                              </PrimaryButton>
                                            )}

                                            {!this.state.isAuthenticated && (
                                              <CustomTooltip
                                                content={translate(
                                                  "pages.addons.ssl.login-required"
                                                )}
                                              >
                                                <PrimaryButton
                                                  size="l"
                                                  className="w-100"
                                                  onClick={() =>
                                                    (window.location.href = `${ROUTE_CLIENT_LOGIN}?redirect=${window.location.href}&ssl=${ssl.id}`)
                                                  }
                                                >
                                                  {translate("order-now")}
                                                </PrimaryButton>
                                              </CustomTooltip>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    />
                                    {isMobileOnly && (
                                      <FeaturesTable className="features-table row no-gutters">
                                        <div className="col-12 col-sm-12 col-md-12">
                                          {this.state.featuresFormatted &&
                                            this.state.featuresFormatted.map(
                                              (feature, index) => (
                                                <div
                                                  key={`feature-ssl-${Math.random()}`}
                                                  className="row no-gutters feature-row"
                                                >
                                                  <div className="col-12 col-sm-12 col-md-9">
                                                    <div className="row no-gutters">
                                                      <div className="col-12 col-sm-12 col-md-4">
                                                        <div className="feature-value">
                                                          {feature.name}
                                                          {feature[ssl.id] ? (
                                                            <StyledAvaiable
                                                              className="float-right"
                                                              src={
                                                                check_success
                                                              }
                                                            />
                                                          ) : (
                                                            <StyledUnavaiable
                                                              className="float-right"
                                                              src={clear}
                                                            />
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </FeaturesTable>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>

                      {!isMobileOnly && (
                        <FeaturesTable className="row no-gutters">
                          <div className="col-12 col-sm-12 col-md-12">
                            {this.state.featuresFormatted &&
                              this.state.featuresFormatted.map(
                                (feature, index) => (
                                  <div
                                    key={`feature-ssl-${Math.random()}`}
                                    className="row no-gutters feature-row"
                                  >
                                    <div className="col-12 col-sm-12 col-md-3 feature-wrapper">
                                      <div className="feature">
                                        {feature.name}
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-9">
                                      <div className="row no-gutters">
                                        {this.state.ssl &&
                                          this.state.ssl.map(ssl => {
                                            return (
                                              <div
                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                key={`ssl-feature-${Math.random()}`}
                                              >
                                                <div className="feature-value text-center">
                                                  {feature[ssl.id] ? (
                                                    <StyledAvaiable
                                                      src={check_success}
                                                    />
                                                  ) : (
                                                    <StyledUnavaiable
                                                      src={clear}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </FeaturesTable>
                      )}
                    </div>
                  </div>
                </div>
              </FeaturesTableSection>
              <WhyPurchaseSSLSection />
              <HowSSLCertificatesWorkSection />
              <FreeAutoSSLCertificateSection />
              <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_SSL} />
            </div>
            {this.state.showSelectHostingModal && (
              <Modal
                title={this.state.selectedSSL.name}
                subTitle={translate("pages.addons.ssl.choose-hosting")}
                onCloseModal={this.onHideSelectHosting}
                body={() => (
                  <div>
                    <SelectBox
                      loadOptions={this.remapApplicableHostings}
                      defaultOptions
                      async={true}
                      isSearchable={true}
                      onChange={this.onSelectHosting}
                    />
                    <StyledAddSslToCartPrimaryButton
                      disabled={
                        !this.state.selectedHosting || this.state.addingToCart
                      }
                      submitting={this.state.addingToCart}
                      className="ml-auto"
                      onClick={() => this.onAddToCart()}
                    >
                      {translate("add-to-cart")}
                    </StyledAddSslToCartPrimaryButton>
                  </div>
                )}
              />
            )}
          </StyledAddonsPremiumSupportPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(withLocalize(AddonsSSLPage));
