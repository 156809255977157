import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

import {
  ROUTE_EMAIL_TUTORIALS_ANDROID,
  ROUTE_EMAIL_TUTORIALS_OUTLOOK,
  ROUTE_EMAIL_TUTORIALS_THUNDERBIRD,
  ROUTE_EMAIL_TUTORIALS_MACOS,
  ROUTE_EMAIL_TUTORIALS_IOS,
  ROUTE_WEBSITE_DOMAIN
} from "../../../routes";
import { Helmet } from "react-helmet";
import seoData from "../../../seo/seoData";

const StyledParentImage = styled.div`
  &:hover {
    cursor: pointer;
  }
  margin-bottom: 30px;
`;

const StyledImage = styled.img`
  width: 120px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const StyledImageA = styled.img`
  width: 102px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background.jpg");
  background-size: cover;
  background-position: center;
`;

class AllChoices extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].other_email.title}</title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_email.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_email.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_email.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_email.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_email.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_email.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}

            <StyledHeader className="row py-5 ">
              <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-2 col-8">
                <h1>
                  <strong>
                    {translate("pages.email-tutorial.email-setup")}
                  </strong>
                </h1>
              </div>
            </StyledHeader>

            <div className="container">
              <div className="row my-4">
                <div className="col-md-6 offset-md-3 text-center">
                  <h2 className="text-uppercase">
                    <strong>
                      {translate("pages.email-tutorial.choose-platform")}
                    </strong>
                  </h2>
                </div>
              </div>
              <div className="row my-4">
                <div className="col-md-4 mt-3">
                  <Link to={ROUTE_EMAIL_TUTORIALS_ANDROID}>
                    <StyledParentImage className="card">
                      <div className="card-body text-center">
                        <StyledImageA
                          className="img-fluid"
                          src="/assets/images/email-setup/android.png"
                        />
                      </div>
                      <div className="card-footer text-center">
                        {translate("pages.email-tutorial.android")}
                      </div>
                    </StyledParentImage>
                  </Link>
                </div>

                <div className="col-md-4 mt-3">
                  <Link to={ROUTE_EMAIL_TUTORIALS_IOS}>
                    <StyledParentImage className="card">
                      <div className="card-body text-center">
                        <StyledImage
                          className="img-fluid"
                          src="/assets/images/email-setup/ios.png"
                        />
                      </div>
                      <div className="card-footer text-center text-uppercase">
                        {translate("pages.email-tutorial.ios")}
                      </div>
                    </StyledParentImage>
                  </Link>
                </div>

                <div className="col-md-4 mt-3">
                  <Link to={ROUTE_EMAIL_TUTORIALS_MACOS}>
                    <StyledParentImage className="card">
                      <div className="card-body text-center">
                        <StyledImage
                          className="img-fluid"
                          src="/assets/images/email-setup/macos.png"
                        />
                      </div>
                      <div className="card-footer text-center">
                        {translate("pages.email-tutorial.macos")}
                      </div>
                    </StyledParentImage>
                  </Link>
                </div>

                <div className="col-md-4 offset-md-2 mt-3">
                  <Link to={ROUTE_EMAIL_TUTORIALS_OUTLOOK}>
                    <StyledParentImage className="card">
                      <div className="card-body text-center">
                        <StyledImage
                          className="img-fluid"
                          src="/assets/images/email-setup/outlook.png"
                        />
                      </div>
                      <div className="card-footer text-center">
                        {translate("pages.email-tutorial.outlook")}
                      </div>
                    </StyledParentImage>
                  </Link>
                </div>

                <div className="col-md-4 mt-3">
                  <Link to={ROUTE_EMAIL_TUTORIALS_THUNDERBIRD}>
                    <StyledParentImage className="card">
                      <div className="card-body text-center">
                        <StyledImage
                          className="img-fluid"
                          src="/assets/images/email-setup/thunderbird.png"
                        />
                      </div>
                      <div className="card-footer text-center">
                        {translate("pages.email-tutorial.thunderbird")}
                      </div>
                    </StyledParentImage>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default AllChoices;
