import axios from "axios";
import Cookies from "universal-cookie";
import { trackAddToCart } from "./facebookAnalyticsService";

import {
  ROOT_DOMAIN_NO_HTTPS,
  CART_API_ENDPOINT,
  CART_INFO_API_ENDPOINT,
  CART_ADD_ITEM_API_ENDPOINT,
  CART_REMOVE_ITEM_API_ENDPOINT,
  CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT,
  CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT,
  CART_ATTACH_ASSISTANT_TO_ORDER_ITEM,
  CART_CLEAR_API_ENDPOINT,
  CART_CHECKOUT_API_ENDPOINT,
  CART_APPLY_PROMOTION_CODE_API_ENDPOINT
} from "./endpoints";

const DOMAIN_SERVICE = "domain_service";
const PRODUCT_SERVICE = "hosting_service";

const cartName = "mkhostCart";

export function all() {
  return axios.get(CART_API_ENDPOINT, {
    params: {
      cart: getCartId()
    }
  });
}

export function cartInfo() {
  return axios.get(CART_INFO_API_ENDPOINT, {
    params: {
      cart: getCartId() ? getCartId() : ""
    }
  });
}

export function add(product) {
  trackAddToCart(product);

  return axios.post(CART_ADD_ITEM_API_ENDPOINT, {
    ...product,
    cart: getCartId()
  });
}

export function remove(product) {
  return axios.delete(CART_REMOVE_ITEM_API_ENDPOINT, {
    data: {
      ...product,
      cart: getCartId()
    }
  });
}

export function checkoutCart(cart, paymentMethod) {
  return axios.post(CART_CHECKOUT_API_ENDPOINT, {
    cart,
    payment_method: paymentMethod
  });
}

export function attachServiceToProduct(service, product) {
  return axios.post(CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT, {
    order_item_id: product.id,
    services: [
      {
        service_id: service.id,
        type: product.type === "domain" ? DOMAIN_SERVICE : PRODUCT_SERVICE,
        billing_cycle: service.pricing.buy.billing_cycle.code
      }
    ],
    cart: getCartId()
  });
}

export function removeServiceFromProduct(service, product) {
  return axios.post(CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT, {
    order_item_id: product.id,
    detach_id: service.detach_id,
    cart: getCartId()
  });
}

export function attachDomainAssistantToOrderItem(domain, contactType, contact) {
  return axios.post(CART_ATTACH_ASSISTANT_TO_ORDER_ITEM, {
    order_item_id: domain.id,
    type: contactType,
    ...contact
  });
}

export function clearCart() {
  return axios.put(CART_CLEAR_API_ENDPOINT, {
    cart: getCartId()
  });
}

export function applyPromotionCode(promotionCode) {
  return axios.post(CART_APPLY_PROMOTION_CODE_API_ENDPOINT, {
    cart: getCartId(),
    promotion_code: promotionCode
  });
}

export function storeCartId(id) {
  const cookies = new Cookies();
  try {
    localStorage.setItem(cartName, id);
    cookies.set(cartName, id, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
}

export function getCartId() {
  try {
    let cartId = localStorage.getItem(cartName);

    if (!cartId || cartId === undefined || cartId === "undefined") {
      localStorage.removeItem(cartName);
      return null;
    }
    if (cartId) {
      return cartId;
    }

    const cookies = new Cookies();

    cartId = cookies.get(cartName);

    if (!cartId || cartId === undefined || cartId === "undefined") {
      cookies.remove(cartName);
      return null;
    }
    if (cartId) {
      return cartId;
    }
  } catch (e) {
    return null;
  }
}
