import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import qs from "query-string";

import Hero from "../../components/Hero";
import DomainSearchSection from "../../components/Sections/DomainsSearchSection";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import FreeRegistrationFeaturesSection from "../../components/Sections/FreeRegistrationFeaturesSection";
import NewDomainExtensionsSection from "../../components/Sections/NewDomainExtensionsSection";
import RegisterDomainStepsSection from "../../components/Sections/RegisterDomainStepsSection";
import MarketShareSection from "../../components/Sections/MarketShareSection";
import MKhostClientsSection from "../../components/Sections/MKhostClientsSection";

import AccordionItem from "../../components/Accordion/AccordionItem";
import ContentWidget from "../../components/ContentWidget";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import BulletPoint from "../../components/BulletPoint";

import check_success from "../../assets/images/check_success.svg";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_DOMAINS } from "../../config/faq";
import * as ebookDisplayService from "../../services/ebookDisplayService";
import SubscribeFreeEbookModal from "../../components/SubscribeFreeEbookModal/SubscribeFreeEbookModal";

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.neutral6};
`;

const StyledMarnetLogoContainer = styled.div`
  display:flex;
  justify-content:center;
  margin-top:5%;
`;

const StyledMarnetLogo = styled.img`
  width:200px;
`;

const StyledDomainSearchWrapper = styled.div`
  & .domain-search {
    top: 0;
  }

  & .domain-extensions-quick-prices {
    top: 25px;
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -47px;
`;

const StyledFreeRegistrationWrapper = styled.div`
  & .free-registration-features-section {
    padding: ${props => props.isMobile && "20px"};
    margin-top: ${props => props.isMobile && "100px"};
  }
`;

class DomainsRegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExtension: null,
      domain: null,
      showSubscribeFreeEbookModal: false,
      ebookSeen: ebookDisplayService.shouldEbookShow()
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      let domainName = this.props.match.params.name.split(".");
      const domain = domainName[0];
      domainName.shift();
      const extension = domainName.join(".");
      this.setState({
        selectedExtension: extension,
        domain
      });
    } else {
      let extParam = qs.parse(this.props.location.search);

      if (extParam.ext) {
        let ext = {
          label: extParam.ext.startsWith(".")
            ? extParam.ext
            : "." + extParam.ext,
          value: extParam.ext.startsWith(".")
            ? extParam.ext.substr(1, extParam.ext.length)
            : extParam.ext
        };

        this.setState({ selectedExtension: ext });
      }
    }
    // window.addEventListener("scroll", this.onScrollShowFreeEbookModal);
  }

  onSelectExtension = extension => {
    const ext = {
      label: extension,
      value: extension.startsWith(".")
        ? extension.substring(1, extension.length)
        : extension
    };
    this.setState({ selectedExtension: ext });
  };

  onHideSubscribeFreeEbookModal = () => {
    this.setState({
      showSubscribeFreeEbookModal: false
    });
  };

  onScrollShowFreeEbookModal = e => {
    if (
      window.scrollY > 200 &&
      !this.state.showSubscribeFreeEbookModal &&
      !this.state.ebookSeen
    ) {
      this.setState(
        {
          showSubscribeFreeEbookModal: true,
          ebookSeen: true
        },
        () => {
          ebookDisplayService.markEbookAsSeen();
        }
      );
    }
  };

  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.onScrollShowFreeEbookModal);
  // }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].domains_register.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_register.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_register
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_register.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_register
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_register.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_register
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains.png`}
                />
              </Helmet>
            )}
            <Hero
              title={parse(translate("pages.domains.register.title"))}
              backgroundImage="/assets/backgrounds/bg-1.svg"
              subtitle={() => (
                <React.Fragment>
                  <BulletPoint>
                    {parse(translate("pages.domains.register.content.p1"))}
                  </BulletPoint>
                  <BulletPoint>
                    {parse(translate("pages.domains.register.content.p2"))}
                  </BulletPoint>
                  <BulletPoint>
                    {parse(translate("pages.domains.register.content.p3"))}
                  </BulletPoint>
                </React.Fragment>
              )}
              image="/assets/illustrations/domains/illustration_domains.png"
              paddingBottom="0"
              backgroundPositionY={isMobileOnly ? "0" : "155px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "320px" : "240px"}
              />
              <StyledDomainSearchWrapper>
                <DomainSearchSection
                  domain={this.state.domain}
                  selectedExtension={this.state.selectedExtension}
                  searchPlaceholder={translate(
                    "enter-a-domain-name-you-are-looking-for"
                  )}
                  type="register"
                />

              </StyledDomainSearchWrapper>

            </Hero>
            <StyledFreeRegistrationWrapper isMobile={isMobileOnly}>
              <FreeRegistrationFeaturesSection
                title={translate("sections.free_registration_features.title")}
              />
            </StyledFreeRegistrationWrapper>
            <NewDomainExtensionsSection
              onSelectExtension={this.onSelectExtension}
            />
            <RegisterDomainStepsSection />
            <MarketShareSection />
            <MKhostClientsSection />

            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_DOMAINS} />
            {this.state.showSubscribeFreeEbookModal && (
              <SubscribeFreeEbookModal
                onCloseModal={this.onHideSubscribeFreeEbookModal}
              />
            )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default DomainsRegisterPage;
