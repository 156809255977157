import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import ContainerModal from "../../Containers/ContainerModal";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import {
  compileRoute,
  ROUTE_DOMAINS_PRICES,
  ROUTE_OTHER_CONTROL_PANEL
} from "../../../routes";

const StyledRegisterDomainStepsSection = styled.div`
  padding: 75px 0;
  margin: ${props => (props.isMobile ? "0" : " 150px 0")};
  background-image: ${props =>
    !props.isMobile && `url("/assets/backgrounds/bg-5.png")`};
  background-position: bottom;
  background-position-x: right;
  background-position-y: 180px;
  background-repeat: no-repeat;
  background-size: 80%;
`;

const StyledStepContainer = styled(ContainerModal)`
  padding: 30px;
  padding-top: 50px;
  margin-bottom: ${props => props.isMobile && "30px"};
`;

const StyledStep = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.neutral4};
  line-height: 1.38;
  text-transform: uppercase;
`;

const StyledStepTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  line-height: 1.56;
  margin-bottom: 20px;
`;

const StyledStepDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

const StyledStepContent = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.neutral6};
  margin-bottom: 30px;

  & p {
    margin: 0;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ScrollToTop = () => window.scrollTo(0, 0);

const RegisterDomainStepsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledRegisterDomainStepsSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("sections.register_domain_steps.title")}
                  </SectionTitle>
                  <StyledStepDescription>
                    {translate("sections.register_domain_steps.description")}
                  </StyledStepDescription>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledStepContainer isMobile={isMobileOnly}>
                    <StyledStep>
                      {translate("sections.register_domain_steps.step1.step")}
                    </StyledStep>
                    <StyledStepTitle>
                      {translate("sections.register_domain_steps.step1.title")}
                    </StyledStepTitle>
                    <StyledStepContent>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step1.content.p1"
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step1.content.p2"
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step1.content.p3"
                          )
                        )}
                      </p>
                    </StyledStepContent>
                    <PrimaryButton size="l" onClick={() => ScrollToTop()}>
                      {translate("sections.register_domain_steps.step1.button")}
                    </PrimaryButton>
                  </StyledStepContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledStepContainer isMobile={isMobileOnly}>
                    <StyledStep>
                      {translate("sections.register_domain_steps.step2.step")}
                    </StyledStep>
                    <StyledStepTitle>
                      {translate("sections.register_domain_steps.step2.title")}
                    </StyledStepTitle>
                    <StyledStepContent>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step2.content.p1"
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step2.content.p2"
                          ).replace(
                            "${LINK}",
                            compileRoute(ROUTE_DOMAINS_PRICES, {
                              category: "/international-domains"
                            })
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step2.content.p3"
                          ).replace(
                            "${LINK}",
                            compileRoute(ROUTE_DOMAINS_PRICES, {
                              category: "/generic-domains"
                            })
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step2.content.p4"
                          ).replace(
                            "${LINK}",
                            compileRoute(ROUTE_DOMAINS_PRICES, {
                              category: "/new-domains"
                            })
                          )
                        )}
                      </p>
                    </StyledStepContent>
                    <Link
                      to={compileRoute(ROUTE_DOMAINS_PRICES, {
                        category: ""
                      })}
                    >
                      <PrimaryButton size="l">
                        {translate(
                          "sections.register_domain_steps.step2.button"
                        )}
                      </PrimaryButton>
                    </Link>
                  </StyledStepContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledStepContainer isMobile={isMobileOnly}>
                    <StyledStep>
                      {translate("sections.register_domain_steps.step3.step")}
                    </StyledStep>
                    <StyledStepTitle>
                      {translate("sections.register_domain_steps.step3.title")}
                    </StyledStepTitle>
                    <StyledStepContent>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step3.content.p1"
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step3.content.p2"
                          )
                        )}
                      </p>
                      <p>
                        {parse(
                          translate(
                            "sections.register_domain_steps.step3.content.p3"
                          )
                        )}
                      </p>
                    </StyledStepContent>
                    <Link to={ROUTE_OTHER_CONTROL_PANEL}>
                      <PrimaryButton size="l">
                        {translate(
                          "sections.register_domain_steps.step3.button"
                        )}
                      </PrimaryButton>
                    </Link>
                  </StyledStepContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledRegisterDomainStepsSection>
    )}
  </Translate>
);
export default RegisterDomainStepsSection;
