import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { isMobileOnly } from "react-device-detect";

import Container from "../Containers/Container";
import InputField from "../InputField";
import SelectBox from "../SelectBox";
import PrimaryButton from "../Buttons/PrimaryButton";
import MarnetAdditionalInfo from "../MarnetAdditionalInfo"

const StyledDomainSearch = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;

  & .form-group {
    margin: 0;
  }

  & .domain-search-input {
    width: ${props => (props.isMobile ? "100%" : "60%")};
    display: inline-block;
    margin-right: 20px;
    margin-bottom: ${props => props.isMobile && "20px"};
    vertical-align: top;
  }
  & .domain-search-input input{
    text-transform: lowercase;
  }
  & .domain-extension-input {
    display: inline-block;
    width: ${props => (props.isMobile ? "50%" : "15%")};
    margin-right: ${props => (props.isMobile ? "16px" : "20px")};
    top: -1px;
    vertical-align: top;

  }
  & .domain-extension-input input{
    text-transform: lowercase;
} 
  & .btn {
    display: inline-block;
    width: ${props => (props.isMobile ? "44%" : "20%")};
    margin-right: ${props => props.isMobile && "0"};
    top: -2px;
    vertical-align: top;
  }
`;


class DomainSearch extends React.Component {
  constructor(props) {
    super(props);
    this.selectFocus = null;
  }
  onKeyPress = e => {
    // dot (.) button
    if (e.keyCode === 190) {
      if (this.selectFocus) {
        this.selectFocus.select.focus();
      }
    }
  };

  getSelectInternalFocusFunction = focus => {
    this.selectFocus = focus;
  };

  componentWillUnmount() {
    this.selectFocus = null;
  }

  render() {
    const domain = this.props.domain || "";
    const defaultExtension = { label: ".mk", value: "mk" };
    let extension =
      this.props.selectedExtension && this.props.extensions
        ? this.props.extensions.find(
          ext => ext.value === this.props.selectedExtension
        )
        : this.props.extensions.find(ext => ext.value === "mk");
    if (!extension) {
      extension = defaultExtension; // fallback in case domain.com.net happens for example
    }
    return (
      <div className="domain-search">
        <MarnetAdditionalInfo />

        <Container className="domain-search-container">

          <StyledDomainSearch isMobile={isMobileOnly}>
            <Translate>
              {({ translate }) => (
                <>

                  <Form
                    onSubmit={this.props.onSubmit}
                    validate={this.props.validate}
                    initialValues={{
                      domain: domain,
                      extension: extension
                    }}
                    render={({ handleSubmit, submitting, invalid, values }) => (
                      <form onSubmit={handleSubmit} id="domain-search-form">
                        <Field
                          onKeyDown={this.onKeyPress}
                          component={InputField}
                          className="domain-search-input"
                          name="domain"
                          size="l"
                          placeholder={this.props.searchPlaceholder}
                          normalize
                          tabIndex="1"
                          parse={value =>
                            value &&
                            value.replace(/[^0-9a-z\u0400-\u04FF-]/gi, "")
                          }
                          format={value =>
                            value.toLowerCase() &&
                            value.replace(/[^0-9a-z\u0400-\u04FF-]/gi, "").toLowerCase()
                          }
                        />
                        <Field
                          component={SelectBox}
                          className="domain-extension-input"
                          name="extension"
                          size="l"
                          options={this.props.extensions}
                          defaultValue={extension}
                          tabIndex="2"
                          getInternalFocus={this.getSelectInternalFocusFunction}
                        />
                        <PrimaryButton
                          type="submit"
                          disabled={submitting}
                          submitting={submitting}
                          size="l"
                          tabIndex="3"
                        >
                          {isMobileOnly
                            ? translate("general.search")
                            : translate("general.search") +
                            " " +
                            translate("general.domain").toLowerCase()}
                        </PrimaryButton>
                      </form>
                    )}
                  />
                </>
              )}

            </Translate>
          </StyledDomainSearch>
        </Container>
      </div>
    );
  }
}

export default DomainSearch;
