import React from "react";
import styled from "styled-components";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";

import FormGroup from "../FormGroup";
import Label from "../Label";
import InputFieldIcon from "../InputField/InputFieldIcon";
import InputHelpBlock from "../InputField/InputHelpBlock";

const StyledSelectBoxWrapper = styled.div`
  position: relative;

  & .input-icon {
    right: 45px;
    top: ${props => (props.size === "l" ? "25px" : "19px")};
  }
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  font-size: 14px;
  & .react-select__control {
    border: 1px solid transparent;
    background-color: ${props => props.theme.neutral1};
    color: ${props => props.theme.neutral5};
    height: ${props => props.size === "l" && "50px"};
  }

  & .react-select__control:hover {
    border: 1px solid ${props => props.theme.neutral3};
    cursor: pointer;
  }

  & .react-select__control:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.blue3};
    border: 1px solid ${props => props.theme.blueBase};
  }
  & .react-select__control:active {
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.blueBase};
    outline: none;
    box-shadow: 0;
  }
  & .react-select__control:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.neutral2};
  }

  &.react-select-container.is-valid {
    & .react-select__control {
      border: 1px solid ${props => props.theme.greenBase};
      background-color: ${props => props.theme.green1};
      color: ${props => props.theme.neutralBase};
    }

    & .react-select__control:hover {
      border: 1px solid ${props => props.theme.greenBase};
    }
  }

  &.react-select-container.is-invalid {
    & .react-select__control {
      border: 1px solid ${props => props.theme.redBase};
      background-color: ${props => props.theme.red1};
      color: ${props => props.theme.neutralBase};
    }

    & .react-select__control:hover {
      border: 1px solid ${props => props.theme.redBase};
    }
  }

  & .react-select__option:hover {
    cursor: pointer;
  }
`;
const StyledSelect = styled(Select)`
  font-size: 14px;
  & .react-select__control {
    border: 1px solid transparent;
    background-color: ${props => props.theme.neutral1};
    color: ${props => props.theme.neutral5};
    height: ${props => props.size === "l" && "50px"};
  }

  & .react-select__control:hover {
    border: 1px solid ${props => props.theme.neutral3};
    cursor: pointer;
  }

  & .react-select__control:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.blue3};
    border: 1px solid ${props => props.theme.blueBase};
  }
  & .react-select__control:active {
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.blueBase};
    outline: none;
    box-shadow: 0;
  }
  & .react-select__control:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.neutral2};
  }

  &.react-select-container.is-valid {
    & .react-select__control {
      border: 1px solid ${props => props.theme.greenBase};
      background-color: ${props => props.theme.green1};
      color: ${props => props.theme.neutralBase};
    }

    & .react-select__control:hover {
      border: 1px solid ${props => props.theme.greenBase};
    }
  }

  &.react-select-container.is-invalid {
    & .react-select__control {
      border: 1px solid ${props => props.theme.redBase};
      background-color: ${props => props.theme.red1};
      color: ${props => props.theme.neutralBase};
    }

    & .react-select__control:hover {
      border: 1px solid ${props => props.theme.redBase};
    }
  }

  & .react-select__option:hover {
    cursor: pointer;
  }
`;

class SelectBox extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = null;
  }

  componentDidMount() {
    if (this.selectRef && this.selectRef) {
      if (typeof this.props.getInternalFocus === "function") {
        this.props.getInternalFocus(this.selectRef);
      }
    }
  }

  render() {
    const { options, input, meta, theme, ...rest } = this.props;

    let fieldValidationClass = "";
    if (meta.dirty && meta.valid && !meta.invalid) {
      fieldValidationClass = "is-valid";
    }

    if (meta.dirty && !meta.valid && meta.invalid) {
      fieldValidationClass = "is-invalid";
    }
    return (
      <FormGroup className={`${rest.className}`}>
        {rest.label.length > 0 && (
          <Label icon={rest.labelIcon}>{rest.label}</Label>
        )}
        <StyledSelectBoxWrapper size={rest.size}>
          {meta.dirty &&
            meta.valid &&
            !meta.invalid && (
              <InputFieldIcon
                icon="success"
                valid={meta.valid}
                invalid={meta.invalid}
              />
            )}
          {meta.dirty &&
            meta.invalid &&
            !meta.valid && (
              <InputFieldIcon
                icon="warning"
                valid={meta.valid}
                invalid={meta.invalid}
              />
            )}
          {rest.async ? (
            <StyledAsyncSelect
              options={options}
              {...rest}
              {...input}
              onChange={value => {
                input &&
                  typeof input.onChange === "function" &&
                  input.onChange(value);
                rest.onChange &&
                  typeof rest.onChange === "function" &&
                  rest.onChange(value);
              }}
              className={`react-select-container ${fieldValidationClass}`}
              classNamePrefix="react-select"
              isDisabled={rest.disabled}
              isClearable={rest.isClearable}
              isSearchable={rest.isSearchable}
              ref={ref => {
                this.selectRef = ref;
              }}
            />
          ) : (
            <StyledSelect
              options={options}
              {...rest}
              {...input}
              onChange={value => {
                input &&
                  typeof input.onChange === "function" &&
                  input.onChange(value);
                rest.onChange &&
                  rest.onChange === "function" &&
                  rest.onChange(value);
              }}
              className={`react-select-container ${fieldValidationClass}`}
              classNamePrefix="react-select"
              isDisabled={rest.disabled}
              isClearable={rest.isClearable}
              isSearchable={rest.isSearchable}
              ref={ref => {
                this.selectRef = ref;
              }}
            />
          )}
          {meta.dirty &&
            meta.invalid &&
            !meta.valid && (
              <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
                {meta.error}
              </InputHelpBlock>
            )}
          {rest.helpText.length > 0 && (
            <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
              {rest.helpText}
            </InputHelpBlock>
          )}
        </StyledSelectBoxWrapper>
      </FormGroup>
    );
  }
}

SelectBox.defaultProps = {
  label: "",
  placeholder: null,
  async: false,
  disabled: false,
  helpText: "",
  labelIcon: "",
  inputIcon: "",
  meta: {
    valid: false,
    invalid: false,
    dirty: false
  }
};

export default SelectBox;
