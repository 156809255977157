import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import { Helmet } from "react-helmet";

import parse from "html-react-parser";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
// import BackgroundSpacer from "../../components/BackgroundSpacer";
// import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
// import Icon from "../../components/Icon";
// import PrimaryButton from "../../components/Buttons/PrimaryButton";
// import BulletPoint from "../../components/BulletPoint";
// import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";

import OpenTicket from "../../components/OpenTicket";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledContactWrapper = styled.div`
  background-color: ${props => props.theme.neutralBase};
`;

const StyledContent = styled.div`
  padding-top: ${props => (props.isMobile ? " 40px" : "70px")};
  padding-bottom: ${props => (props.isMobile ? " 40px" : "150px")};
`;

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 34px;
  line-height: 1.41;
  font-weight: 600;
  color: ${props => props.theme.white};
`;
const StyledDescription = styled(SectionDescription)`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.white};
  margin-bottom: 50px;
`;

const StyledBlackContainer = styled(Container)`
  padding: 30px;
  background-color: ${props => props.theme.neutralBase};
  border-color: ${props => props.theme.neutral5};
  margin-bottom: 30px;
  min-height: 276px;
  height: 276px;
  position: relative;
  & .department {
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    color: ${props => props.theme.white};
    margin-bottom: 5px;
  }

  & .email {
    display: block;
    font-size: 18px;
    line-height: 1.56;
    color: ${props => props.theme.white};
    margin-bottom: 30px;
  }

  & .content {
    display: block;
    margin-bottom: 42px;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.white};
  }
`;

const StyledContactNumber = styled(Container)`
  display: block;
  position: relative;
  margin-top: ${props => !props.isMobile && "180px"};
  margin-left: ${props => !props.isMobile && "100px"};
  margin-bottom: ${props => props.isMobile && "50px"};
  padding: ${props => (!props.isMobile ? "45px 140px" : "30px 20px")};
  padding-left: ${props => (!props.isMobile ? "180px" : "120px")};

  & span {
    display: inline-block;
    font-size: ${props => (!props.isMobile ? "44px" : "25px")};
    font-weight: 600;
    line-height: 0.77;
    color: ${props => props.theme.blueBase};

    a,
    a:hover {
      color: ${props => props.theme.blueBase};
    }
  }

  & .circled-icon {
    position: absolute;
    left: ${props => (!props.isMobile ? "85px" : "30px")};
    top: ${props => (!props.isMobile ? "25px" : "11px")};
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  position: absolute;
  bottom: 30px;
  left: 30px;
`;

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: 41.9861983, lng: 21.4663263 }}
    >
      <Marker position={{ lat: 41.9861983, lng: 21.4663263 }} />
    </GoogleMap>
  ))
);

// const DEP_BOUNCE = 8;
const DEP_WEBDESING = 7;
const DEP_DOMAINS = 12;
// const DEP_MISUSE = 6;
const DEP_INFO = 3;
const DEP_SUPPORT = 1;
const DEP_FINANCE = 2;

class OtherContactPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_contact.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_contact.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_contact.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_contact.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_contact.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_contact.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_contact.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("one-number-for-everything")}
              backgroundImage="/assets/backgrounds/bg-2-1-dark.svg"
              backgroundSize="initial"
              paddingBottom={isMobileOnly && "140px"}
              subtitle={translate(
                "call-our-247-available-line-for-any-questions-or-problems-you-may-have"
              )}
              image={() => (
                <StyledContactNumber>
                  <CircledIcon
                    color="blueBase"
                    backgroundColor="blue1"
                    icon="phone"
                  />{" "}
                  <span>
                    <a href="tel:+389 2 2779 444">+389 2 2779 444</a>
                  </span>
                </StyledContactNumber>
              )}
            />

            <StyledContactWrapper className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    {isMobileOnly && (
                      <StyledContactNumber isMobile={isMobileOnly}>
                        <CircledIcon
                          color="blueBase"
                          backgroundColor="blue1"
                          icon="phone"
                        />{" "}
                        <span>
                          <a href="tel:+389 2 2779 444">+389 2 2779 444</a>
                        </span>
                      </StyledContactNumber>
                    )}
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-5">
                        <StyledTitle>
                          {translate("other-ways-to-contact-us")}
                        </StyledTitle>
                        <StyledDescription>
                          {translate(
                            "you-can-also-open-a-support-ticket-or-contact-our-staff-directly"
                          )}
                          <br />
                          <br />
                          {translate(
                              "also-contact-remote"
                          )}
                        </StyledDescription>
                      </div>
                    </div>
                    <StyledContent className="row" isMobile={isMobileOnly}>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department">
                            {translate("general-info-and-sales")}
                          </span>
                          <p className="content">
                            {translate(
                              "find-out-about-our-services-and-request-a-quote-and-advice-for-your-needs"
                            )}
                          </p>
                          <a href="mailto:info@mkhost.com.mk" className="email">
                          info@mkhost.com.mk
</a>
<a href="tel:+389 2 2779 444" className="email">+389 2 2779 444  {translate("local")} 1</a>
                          {/* <OpenTicket department={DEP_INFO}>
                            {onShow => (
                              <StyledSecondaryButton onClick={() => onShow()}>
                                {translate("open-ticket")}
                              </StyledSecondaryButton>
                            )}
                          </OpenTicket> */}
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department">
                            {translate("postal-contact")}
                          </span>
                          <span className="email" />
                          <p className="content">
                            {parse(translate("mkhost-contact-details"))}
                          </p>{" "}
                          <br />
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department technical-support">
                            {translate("technical-support")}
                          </span>
                          <p className="content">
                            {translate(
                              "have-a-problem-or-a-technical-question"
                            )}
                          </p>
                          <a href="mailto:support@mkhost.com.mk" className="email">
                          support@mkhost.com.mk
</a>
<a href="tel:+389 2 2779 444" className="email">+389 2 2779 444  {translate("local")} 2</a>
                          {/* <OpenTicket department={DEP_SUPPORT}>
                            {onShow => (
                              <StyledSecondaryButton onClick={() => onShow()}>
                                {translate("open-ticket")}
                              </StyledSecondaryButton>
                            )}
                          </OpenTicket> */}
                          
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department">
                            {translate("web-development")}
                          </span>
                          <p className="content">
                            {translate(
                              "you-need-preparation-and-maintenance-for-your-website"
                            )}
                          </p>
                          <a href="mailto:web@mkhost.com.mk" className="email">
                          web@mkhost.com.mk
</a>
<a href="tel:+389 2 2779 444" className="email">+389 2 2779 444  {translate("local")} 4</a>

                          {/* <OpenTicket department={DEP_WEBDESING}>
                            {onShow => (
                              <StyledSecondaryButton onClick={() => onShow()}>
                                {translate("open-ticket")}
                              </StyledSecondaryButton>
                            )}
                          </OpenTicket> */}
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department">
                            {translate("domains")
                              .charAt(0)
                              .toUpperCase() + translate("domains").slice(1)}
                          </span>
                          <p className="content">
                            {translate(
                              "need-to-register-a-new-domain,-make-a-transfer,-renew-or-change-dns-servers"
                            )}
                          </p>
                          <a href="mailto:domains@mkhost.com.mk" className="email">
    domains@mkhost.com.mk
</a>
<a href="tel:+389 2 2779 444" className="email">+389 2 2779 444  {translate("local")} 3</a>

                          {/* <OpenTicket department={DEP_DOMAINS}>
                            {onShow => (
                              <StyledSecondaryButton onClick={() => onShow()}>
                                {translate("open-ticket")}
                              </StyledSecondaryButton>
                            )}
                          </OpenTicket> */}
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="department">
                            {translate("accounting,-invoices-&-orders")}
                          </span>
                         
                          <p className="content">
                            {translate(
                              "having-problems-with-a-payment,-or-maybe-you-need-a-provisional-invoice"
                            )}
                          </p>
                          <a href="mailto:billing@mkhost.com.mk" className="email">
    billing@mkhost.com.mk
</a>
<a href="tel:+389 2 2779 444" className="email">+389 2 2779 444   {translate("local")} 1</a>

                          {/* <OpenTicket department={DEP_FINANCE}>
                            {onShow => (
                              <StyledSecondaryButton onClick={() => onShow()}>
                                {translate("open-ticket")}
                              </StyledSecondaryButton>
                            )}
                          </OpenTicket> */}
                        </StyledBlackContainer>
                      </div>
                    </StyledContent>
{/*
                    <StyledContent className="row" isMobile={isMobileOnly}>
                      <div className="col-12 col-sm-12 col-md-5">
                        <StyledTitle>{translate("our-office")}</StyledTitle>
                        <StyledDescription>
                          {translate(
                            "you-can-find-us-in-our-skopje-office-during-working-hours"
                          )} <br />
                          {translate(
                              "location-2"
                          )}
                          <br />
                          {translate(
                              "location-3"
                          )}

                          {" "}
                          <br />
                           {translate("working-hours-time")}
                        </StyledDescription>
                      </div>
                      {navigator.userAgent !== "ReactSnap" &&
                        process.env.REACT_APP_MKHOST_GOOGLE_MAPS_KEY && (
                          <div className="col-12 col-sm-12 col-md-12">
                            <div
                              style={{
                                width: "100%",
                                height: "400px",
                                display: "block"
                              }}
                            >
                              <Map
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_MKHOST_GOOGLE_MAPS_KEY}`}
                                loadingElement={
                                  <div style={{ height: `100%` }}>
                                    {translate("loading")}
                                  </div>
                                }
                                containerElement={
                                  <div style={{ height: `100%` }} />
                                }
                                mapElement={<div style={{ height: `100%` }} />}
                              />
                            </div>
                          </div>
                        )}
                    </StyledContent>
*/}
                  </div>
                </div>
              </div>
            </StyledContactWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherContactPage;
