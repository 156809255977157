import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const StyledTab = styled.li`
  cursor: pointer;

  & .nav-link {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.neutral5};
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
  }

  & .nav-link:hover {
    border-bottom: 2px solid ${props => props.theme.neutral3};
  }
  & .nav-link.active {
    color: ${props => props.theme.neutralBase};
    background-color: transparent;
    border-bottom: 2px solid ${props => props.theme.blueBase};
  }

  &:first-child .nav-link {
    margin-left: 0;
  }
`;

const Tab = ({ children, label, active, onSelect }) => (
  <StyledTab className="nav-item" isMobile={isMobileOnly}>
    <span
      className={`nav-link ${active === label ? "active" : ""}`}
      onClick={e => onSelect(label)}
    >
      {children}
    </span>
  </StyledTab>
);

export default Tab;
