import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import Radiobox from "../Radiobox";
import PrimaryButton from "../Buttons/PrimaryButton";
import DomainSearch from "./DomainSearch";
import DomainTransferSearch from "./DomainSearch/DomainTransferSearch";
import DomainSearchResults from "./DomainSearch/DomainSearchResults";

import * as domainsService from "../../services/domainsService";
import * as cartService from "../../services/cartService";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
  validateOnlyDomainName,
  validateDomain
} from "../../common/validationRules";

import {
  DOMAIN_STATUS_UNAVAILABLE,
  DOMAIN_STATUS_AVAILABLE
} from "../../pages/Domains/DomainStatuses";

import { ROUTE_CLIENT_CART } from "../../routes";
import { applyPromotionCode } from "../../services/cartService";
import { storeCartId } from "../../services/cartService";
import { getCartId } from "../../services/cartService";

const StyledPurchaseHosting = styled.div``;
const StyledRadiobox = styled(Radiobox)`
  margin-bottom: 10px;
`;

const StyledAssignDomainButton = styled(PrimaryButton)`
  margin-top: 30px;
`;

const StyledDomainSearchWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  & .domain-search .domain-search-input {
    width: ${props => (props.isMobile ? "100%" : "59%")};
  }

  & .domain-search .primary-button {
    margin-right: 0;
  }
`;

const HOSTINGS_ON_PROMOTION = []; //[226, 228, 227, 180, 218, 182, 117, 219, 119];

const validate = values => {
  let domainName = "";
  if (typeof values.domain === "object") {
    domainName = values.domain.name.split(".")[0];
  } else {
    domainName = values.domain;
  }

  return {
    domain: validateOnlyDomainName(domainName, values.extension.value)
  };
};

const validateTransfer = values => {
  let domainName = "";
  if (typeof values.domain === "object") {
    domainName = values.domain.name;
  } else {
    domainName = values.domain;
  }
  return { domain: validateDomain(domainName) };
};

class PurchaseHosting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDomain: null,
      selectedExtension: null,
      type: "available",
      availableDomains: null,
      availableDomainsTypes: null,
      searching: false,
      domain: null,
      searchDomain: null,
      whoIs: null,
      addedToCart: false,
      error: null,
      searchPerformed: false
    };
  }

  onSelectDomain = (domain, type) => {
    this.setState({
      selectedDomain: domain,
      type
    });
  };

  onSetType = type => {
    this.setState({
      type,
      selectedDomain: null,
      domain: null,
      searchPerformed: false
    });
  };

  onSearchDomain = values => {
    this.setState({ searching: true });
    return domainsService
      .isDomainAvailable(values.domain + values.extension.label)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        if (
          !values.extension.label.endsWith("mk") &&
          !values.extension.label.endsWith("мкд")
        ) {
          this.setState({
            domain: domain,
            searchDomain: values.domain + values.extension.label,
            whoIs: null,
            searching: false,
            addedToCart: false,
            error: error,
            searchPerformed: true
          });
          return result;
        } else {
          if (domain.status === DOMAIN_STATUS_AVAILABLE) {
            this.setState({
              domain: domain,
              searchDomain: values.domain + values.extension.label,
              whoIs: null,
              searching: false,
              addedToCart: false,
              error: error,
              searchPerformed: true
            });
            return result;
          } else if (domain.status === DOMAIN_STATUS_UNAVAILABLE) {
            return domainsService.domainWhoIs(domain.name).then(result => {
              this.setState({
                domain: domain,
                searchDomain: values.domain + values.extension.label,
                whoIs: result.data.data,
                searching: false,
                addedToCart: false,
                error: error,
                searchPerformed: true
              });
              return result;
            });
          }
        }
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        this.setState({
          domain: {
            name: values.domain + values.extension.label,
            status: DOMAIN_STATUS_UNAVAILABLE
          },
          searchDomain: values.domain + values.extension.label,
          whoIs: null,
          searching: false,
          error: error,
          searchPerformed: true
        });
      });
  };

  onSearchDomainTransfer = values => {
    this.setState({ searching: true });
    return domainsService
      .isDomainAvailable(values.domain)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        if (!values.domain.endsWith("mk") && !values.domain.endsWith("мкд")) {
          this.setState({
            domain: domain,
            searchDomain: values.domain,
            whoIs: null,
            searching: false,
            addedToCart: false,
            error: error,
            searchPerformed: true
          });
          return result;
        } else {
          if (domain.status === DOMAIN_STATUS_AVAILABLE) {
            this.setState({
              domain: domain,
              searchDomain: values.domain,
              whoIs: null,
              searching: false,
              addedToCart: false,
              error: error,
              searchPerformed: true
            });
            return result;
          } else if (domain.status === DOMAIN_STATUS_UNAVAILABLE) {
            return domainsService.domainWhoIs(domain.name).then(result => {
              this.setState({
                domain: domain,
                searchDomain: values.domain,
                whoIs: result.data.data,
                searching: false,
                addedToCart: false,
                error: error,
                searchPerformed: true
              });
              return result;
            });
          }
        }
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        this.setState({
          domain: {
            name: values.domain,
            status: DOMAIN_STATUS_UNAVAILABLE
          },
          searchDomain: values.domain,
          whoIs: null,
          searching: false,
          error: error,
          searchPerformed: true
        });
      });
  };

  onAssignDomain = domain => {
    this.setState({
      submitting: true
    });

    this.props
      .onAddToCart({
        cart: this.props.cart ? this.props.cart.id : null,
        type: "hosting",
        quantity: 1,
        config: {
          type: "buy",
          period: 1
        },
        domain: domain.name !== undefined ? domain.name : domain,
        hosting_id: this.props.hosting.id
      })
      .then(() =>
        window.location.replace(
          `${ROUTE_CLIENT_CART}?cart=${this.props.cart.id}`
        )
      );
  };

  onAddToCart = (domain, type) => {
    this.setState({
      submitting: true
    });

    this.props
      .onAddToCart(
        {
          cart: this.props.cart ? this.props.cart.id : null,
          type: "domain",
          domain_id: null,
          domain: domain.name !== undefined ? domain.name : domain,
          quantity: 1,
          config: {
            type: type,
            period:
              domain.name !== undefined
                ? domain.pricing[type || "register"].billing_cycle
                    .derivations[0].numeric_code
                : 1
          }
        },
        false,
        false
      )
      .then(response => {
        storeCartId(this.props.cart ? this.props.cart.id : null);
        this.props
          .onAddToCart(
            {
              cart: getCartId(),
              type: "hosting",
              quantity: 1,
              config: {
                type: "buy",
                period: this.props.hosting.pricing.buy.billing_cycle
                  .derivations[0].numeric_code
              },
              domain: domain.name !== undefined ? domain.name : domain,
              hosting_id: this.props.hosting.id
            },
            false,
            true
          )
          .then(response => {
            storeCartId(this.props.cart ? this.props.cart.id : null);
            if (HOSTINGS_ON_PROMOTION.indexOf(this.props.hosting.id) !== -1) {
              if (type === "register") {
                // return cartService
                //   .applyPromotionCode("SUMMER20OFF")
                //   .then(
                //     () => {
                //       window.location.replace(
                //         `${ROUTE_CLIENT_CART}?cart=${
                //           this.props.cart ? this.props.cart.id : null
                //         }`
                //       );
                //     },
                //     err => {
                //       window.location.replace(
                //         `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
                //       );
                //     }
                //   )
                //   .catch(err => {
                //     window.location.replace(
                //       `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
                //     );
                //   });
                window.location.replace(
                  `${ROUTE_CLIENT_CART}?cart=${
                    this.props.cart ? this.props.cart.id : getCartId()
                  }`
                );
              } else if (type === "transfer") {
                return cartService
                  .applyPromotionCode("TRANSFERHOSTING50OFF")
                  .then(
                    () => {
                      window.location.replace(
                        `${ROUTE_CLIENT_CART}?cart=${
                          this.props.cart ? this.props.cart.id : null
                        }`
                      );
                    },
                    err => {
                      window.location.replace(
                        `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
                      );
                    }
                  )
                  .catch(err => {
                    window.location.replace(
                      `${ROUTE_CLIENT_CART}?cart=${getCartId()}`
                    );
                  });
              }
            } else {
              window.location.replace(
                `${ROUTE_CLIENT_CART}?cart=${
                  this.props.cart ? this.props.cart.id : null
                }`
              );
            }
          });
      });
  };

  componentDidMount() {
    let availableDomainsTypes = {};

    let cartDomains =
      this.props.cart &&
      this.props.cart.items
        .filter(item => item.type === "domain")
        .map(item => {
          availableDomainsTypes[item.domain] = item.config.type;
          return item.domain;
        });
    let usedDomains =
      this.props.cart &&
      this.props.cart.items
        .filter(item => item.type === "hosting")
        .map(item => item.domain);

    let availableDomains = null;
    if (cartDomains) {
      availableDomains = cartDomains.filter((value, index, self) => {
        return usedDomains.indexOf(value) === -1;
      });
      availableDomains = availableDomains.slice(
        0,
        availableDomains.length < 3 ? availableDomains.length : 3
      );

      this.setState({
        availableDomains,
        availableDomainsTypes
      });
    }

    if (availableDomains && availableDomains.length > 0) {
      this.setState({
        selectedDomain: availableDomains[0],
        type: "available"
      });
    } else {
      this.setState({
        type: "register"
      });
    }
  }

  render() {
    return (
      <StyledPurchaseHosting>
        <Translate>
          {({ translate }) => (
            <React.Fragment>
              {this.state.availableDomains &&
                this.state.availableDomains.length > 0 &&
                this.state.availableDomains.map(domain => (
                  <StyledRadiobox
                    key={`domain-${domain}`}
                    value={domain}
                    checked={
                      this.state.selectedDomain === domain &&
                      this.state.type === "available"
                    }
                    onClick={() => this.onSelectDomain(domain, "available")}
                  >
                    {domain}
                  </StyledRadiobox>
                ))}
              <StyledRadiobox
                value="register"
                checked={this.state.type === "register"}
                onClick={() => this.onSetType("register")}
              >
                {HOSTINGS_ON_PROMOTION.indexOf(this.props.hosting.id) !== -1
                  ? translate("purchase_hosting.register-new-domain-promotion")
                  : translate("purchase_hosting.want-to-register")}
              </StyledRadiobox>
              {this.state.type === "register" && (
                <StyledDomainSearchWrapper isMobile={isMobileOnly}>
                  <DomainSearch
                    type="register"
                    extensions={this.props.extensions}
                    currency={this.props.currency}
                    onSubmit={this.onSearchDomain}
                    validate={validate}
                    searching={this.state.searching}
                    searchPlaceholder={translate(
                      "enter-a-domain-name-you-are-looking-for"
                    )}
                    searchPerformed={this.state.searchPerformed}
                  />
                  {this.state.domain && (
                    <DomainSearchResults
                      domain={this.state.domain}
                      search={this.state.searchDomain}
                      onAddToCart={this.onAddToCart}
                      addedToCart={this.state.addedToCart}
                      submitting={this.state.submitting}
                      type="register"
                      whoIs={this.state.whoIs}
                      error={this.state.error}
                      priceReduce={this.props.hosting.details.prices.domain.register}
                    />
                  )}
                </StyledDomainSearchWrapper>
              )}
              <StyledRadiobox
                value="register"
                checked={this.state.type === "transfer"}
                onClick={() => this.onSetType("transfer")}
              >
                {HOSTINGS_ON_PROMOTION.indexOf(this.props.hosting.id) !== -1
                  ? translate("purchase_hosting.transfer-domain-promotion")
                  : translate("purchase_hosting.have-domain")}
              </StyledRadiobox>
              {this.state.type === "transfer" && (
                <StyledDomainSearchWrapper>
                  <DomainTransferSearch
                    type="transfer"
                    currency={this.props.currency}
                    onSubmit={this.onSearchDomainTransfer}
                    validate={validateTransfer}
                    searching={this.state.searching}
                    searchPerformed={this.state.searchPerformed}
                    searchPlaceholder={translate(
                      "enter-a-domain-name-you-are-looking-for"
                    )}
                  />
                  {this.state.domain && (
                    <DomainSearchResults
                      domain={this.state.domain}
                      search={this.state.searchDomain}
                      onAddToCart={this.onAddToCart}
                      onAssignDomain={this.onAssignDomain}
                      addedToCart={this.state.addedToCart}
                      submitting={this.state.submitting}
                      type="transfer"
                      whoIs={this.state.whoIs}
                      error={this.state.error}
                      priceReduce={this.props.hosting.details.prices.domain.transfer}
                    />
                  )}
                </StyledDomainSearchWrapper>
              )}

              {this.state.type === "available" && (
                <StyledAssignDomainButton
                  size="l"
                  disabled={this.state.submitting}
                  submitting={this.state.submitting}
                  onClick={() =>
                    this.onAssignDomain(
                      this.state.selectedDomain,
                      this.state.availableDomainsTypes[
                        this.state.selectedDomain
                      ]
                    )
                  }
                >
                  {translate("purchase_hosting.assign-domain")}
                </StyledAssignDomainButton>
              )}
            </React.Fragment>
          )}
        </Translate>
      </StyledPurchaseHosting>
    );
  }
}

export default withRouter(PurchaseHosting);
