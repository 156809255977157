import axios from "axios";

import {PROJECT_QUOTE_API_ENDPOINT, WEB_SOLUTION_FORM_ENDPOINT} from "./endpoints";

import { ENTERPRENEURS_QUOTE_API_ENDPOINT } from "./endpoints";

import { EMAIL_TUTORIAL_CHECK_DOMAIN_API_ENDPOINT } from "./endpoints";

export function createProjectQuote(data) {
  return axios.post(PROJECT_QUOTE_API_ENDPOINT, data);
}

export function createEnterpreneursQuote(data) {
  return axios.post(ENTERPRENEURS_QUOTE_API_ENDPOINT, data);
}

export function createWebSolution(data) {
  return axios.post(WEB_SOLUTION_FORM_ENDPOINT, data);
}