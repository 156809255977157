import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

const StyledTabs = styled.ul`
  &.nav-tabs {
    border-bottom: 1px solid transparent;

    overflow-x: ${props => props.isMobile && "auto"};
    overflow-y: ${props => props.isMobile && "hidden"};
    width: ${props => props.isMobile && "100%"};
    flex-wrap: ${props => props.isMobile && "nowrap"};
  }
`;
const StyledTabsContent = styled.div``;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.location;
    this.state = {
      activeTab: this.props.defaultTab
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultTab !== prevProps.defaultTab) {
      this.setState({ activeTab: this.props.defaultTab });
    }
  }

  onSelect = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledTabs
            className="nav nav-tabs list-unstyled"
            isMobile={isMobileOnly}
          >
            {this.props.renderTabs(this.state.activeTab, this.onSelect)}
          </StyledTabs>
          <StyledTabsContent className="tab-content">
            {this.props.renderTabContent(this.state.activeTab)}
          </StyledTabsContent>
        </div>
      </div>
    );
  }
}

export default withRouter(Tabs);
