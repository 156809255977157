import Cookies from "universal-cookie";

const EBOOK_COOKIE = "mkhost-ebook-cookie";

export function shouldEbookShow() {
  try {
    const cookies = new Cookies();
    return !!cookies.get(EBOOK_COOKIE);
  } catch {
    return false;
  }
}

export function markEbookAsSeen() {
  try {
    const cookies = new Cookies();
    cookies.set(EBOOK_COOKIE, true);
  } catch {
    return true;
  }
}
