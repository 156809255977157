import styled from "styled-components";

const SmallDescription = styled.p`
  display: block;
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

export default SmallDescription;
