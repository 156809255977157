import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import DomainSearchSection from "../../components/Sections/DomainsSearchSection";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import FreeRegistrationFeaturesSection from "../../components/Sections/FreeRegistrationFeaturesSection";
import NewDomainExtensionsSection from "../../components/Sections/NewDomainExtensionsSection";
import RegisterDomainStepsSection from "../../components/Sections/RegisterDomainStepsSection";
import MarketShareSection from "../../components/Sections/MarketShareSection";
import MKhostClientsSection from "../../components/Sections/MKhostClientsSection";
import DomainPricesSection from "../../components/Sections/DomainPricesSection";

import AccordionItem from "../../components/Accordion/AccordionItem";
import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import check_success from "../../assets/images/check_success.svg";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_DOMAINS } from "../../config/faq";

const StyledDomainsPricesPage = styled.div`
  & .domain-search {
    top: 0;
  }

  & .domain-extensions-quick-prices {
    top: 30px;
  }

  & .domain-prices-section {
    top: ${props => props.isMobile && "-60px"};
  }
`;

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.neutral6};
`;

const StyledDomainSearchWrapper = styled.div`
  & .domains-search-section {
    position: relative;
    top: ${props => (props.isMobile ? "-210px" : "-180px")};
    margin-bottom: -104px;
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: -338px;
`;

class DomainsPricesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: null
    };
  }

  componentDidMount() {
    const category = this.props.match.params.category;
    if (category) {
      if (
        [
          "macedonian-domains",
          "international-domains",
          "generic-domains",
          "new-domains"
        ].indexOf(category) > -1
      ) {
        this.setState({
          selectedTab: category
        });
      }
    }
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledDomainsPricesPage isMobile={isMobileOnly}>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].domains_prices.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_prices.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_prices.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_prices.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_prices.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-prices.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_prices.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_prices.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-prices.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("domain-prices")}
              subtitle={() => (
                <SectionDescription>
                  {translate(
                    "check-the-prices-on-registering,-renewing,-and-transferring-macedonian-and-international-domainsenter-a-domain-you-are-looking-for"
                  )}
                </SectionDescription>
              )}
              image="/assets/illustrations/domains/illustration_domains-prices.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              paddingBottom="220px"
              backgroundPositionY={isMobileOnly ? "-110px" : "90px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "230px" : "210px"}
                isMobile={isMobileOnly}
              />
              <StyledDomainSearchWrapper isMobile={isMobileOnly}>
                <DomainSearchSection
                  domain={this.state.domain}
                  selectedExtension={this.state.selectedExtension}
                  searchPlaceholder={translate(
                    "enter-a-domain-you-are-looking-for"
                  )}
                  quickPrices={() => {}}
                  type="register"
                />
              </StyledDomainSearchWrapper>
            </Hero>

            <DomainPricesSection selectedTab={this.state.selectedTab} />

            <FreeRegistrationFeaturesSection
              title={translate(
                "get-all-of-this-for-free-with-every-registration"
              )}
            />
            <MarketShareSection />
            <MKhostClientsSection />

            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_DOMAINS} />
          </StyledDomainsPricesPage>
        )}
      </Translate>
    );
  }
}
export default DomainsPricesPage;
