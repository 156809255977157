import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Perk from "../../Perk";
import { isMobileOnly } from "react-device-detect";

const StyledWhyPurchaseAwsSection = styled.div`
  padding: ${props => (props.isMobile ? "75px 0 30px 0" : "75px 0")};
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 10px;
  }
`;

const WhyPurchaseAwsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhyPurchaseAwsSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("benefits-of-using-aws-cloud-services")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "ready-for-website-software-and-app-launched-withe-cloud-infrastructure"
                    )}
                    <br/>
                    {translate(
                        "aws-the-largest-cloud-computing-platform"
                    )}
                    
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("scalability-aws")}
                    description={() => (
                      <span>
                        {translate(
                          "as-your-business-grow"
                        )}
                      </span>
                    )}
                    icon="ranking"
                    color="greenBase"
                    backgroundColor="green1"
                  />
                  <Perk
                      title={translate("cloud-native-approach")}
                      description={() => (
                          <span>
                        {translate(
                            "we-are-determined-into building-and-supporting-of-applications-that-work-fully-in-the-cloud"
                        )}
                      </span>
                      )}
                      icon="domains"
                      color="redBase"
                      backgroundColor="red1"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("security-aws")}
                    description={() => (
                      <span>
                        {translate(
                          "make-sure-about-every-aspekt-of-work"
                        )}
                      </span>
                    )}
                    icon="lock"
                    color="yellowBase"
                    backgroundColor="yellow1"
                  />
                  <Perk
                      title={translate("long-term-solution-aws")}
                      description={() => (
                          <span>
                        {translate(
                            "implementing-cloud-infrastructure"
                        )}
                      </span>
                      )}
                      icon="input-checkbox"
                      color="blueBase"
                      backgroundColor="blue1"
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </StyledWhyPurchaseAwsSection>
    )}
  </Translate>
);
export default WhyPurchaseAwsSection;
