import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notice from "../Notice";

const StyledNoticeDanger = styled.div`
  background-color: ${theme.red1};
  border: 1px solid ${theme.redBase};
  color: ${theme.redBase};
  border-radius: 3px;
  display: inline-block;
  & a {
    color: ${theme.redBase};
  }
`;

const NoticeDanger = ({ children, ...rest }) => (
  <StyledNoticeDanger className="notice-danger">
    <Notice icon="clear" {...rest}>
      {children}
    </Notice>
  </StyledNoticeDanger>
);

NoticeDanger.defaultProps = {
  dismissable: false,
  onDismiss: () => {}
};

export default NoticeDanger;
