import React from "react";

import BulletPoint from "../../components/BulletPoint";

export default [
  {
    index: 0,
    title: "NestBox",
    codename: "NestBox",
    description: {
      mk:
        "Онлајн продавница која што создава интерактивни кутии за новороденчиња и бебиња.",
      en:
        "A small business that creates interactive boxes for newborns and babies."
    },

    type: { mk: "Онлајн продавница за подароци за новороденчиња", en: "Online shop for gifts for newborns" },
    image: "/assets/images/themes/nest.png" ,
    titleLink: "http://nestbox.com.mk/",

    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Product categorization rules, subscription form",
        "Website made with two languages, integration with social media"
      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Правила за категоризација на продукти, форма за претплата",
         "Двојазичен сајт, интеграција со социјални медиуми"
      ]
    }
  },

  {
    index: 1,
    title: "Europrinty",
    codename: "Europrinty",
    description: {
      mk:
          "Компанија која функционира во Албанија и Косово и се занимава со продажба на разни типови на продукти и сервиси.",
      en:
        "A company that operates in Albania and Kosovo and is engaged in the sale of various types of products and services."
    },
    type: { mk: "Онлајн продавница за материјали", en: "Online shop for different types of materials" },
    image: "/assets/images/themes/Europrinty.png",
    titleLink:  "https://europrinty.net/",
    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Implementation of payment on delivery",
        "Rules for categorization and variable products",
        "Integration with social media, Google Ads и Google Translate"

      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Имплементација на плаќање при достава",
        "Правила за категоризација и варијабилни продукти",
        "Имплементација со социјални мрежи, Google Ads and Google Translate"
      ]
    }
  },

  {
    index: 2,
    title: "Slavika Libris",
    codename: "Slavika Libris",


    description: {
      mk:
        "Издавачка куќа која објавува врвни современи книжевни производи од словенската група јазици со македонски превод.",
      en:
        "A publishing house that publishes contemporary books from the Slavic group of languages with Macedonian translation."
    },
    type: { mk: "Онлајн продавница за книги", en: "Online bookstore" },
    image: "/assets/images/themes/slavika.png",
    titleLink: "https://slavikalibris.mk/",

    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Categorization by authors, number of pages, translations and genres",
        "Possibility to buy a package of books (a set of selected books)",
        "Enabled discounts according to the rules set by the publishing house"
      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Категоризација по автори, број на страници, преводи и жанрови",
        "Овозможено купување на пакет книги (сет од одбрани книги)",
        "Овозможено правење на попусти по зададени правила од страна на издавачката куќа"
      ]
    }
  },

  {
    index: 3,
    title: "Zastitna Oprema",
    codename: "Zastitna Oprema",


    description: {
      mk:
        "Компанија која нуди опрема од водечки европски и светски компании на полето на лична заштита при работа.",
      en:
        "A company that offers equipment from leading European and world companies in the field of personal protection at work."
    },
    type: { mk: "Онлајн продавница за заштитна опрема", en: "Online shop for products for protection at work" },
    image: "/assets/images/themes/zastitna.png",
    titleLink:  "https://zastitnaoprema.mk/",

    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Automatic notification of out-of-stock items",
        "Website made with two languages"
      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Автоматско известување за артикли кои се надвор од залиха",
        "Двојазичен сајт"
      ]
    }
  },

  {
    index: 4,
    title: "Lining Electronics",
    codename: "Lining Electronics",

    description: {
      mk:
        "Компанија која нуди решенија поврзани со аудио-видео и компјутерско поврзување, компјутерска галантерија, мрежна опрема и слично.",
      en:
        "A company that offers solutions related to audio/video and computer connection, computer accessories, network equipment and similar products."
    },
    type: { mk: "Онлајн продавница за електронска опрема", en: "Online shop for electronic products" },
    image: "/assets/images/themes/lining.png",
    titleLink:  "https://lining.com.mk/",

    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Shipping rules and Cash on Delivery ",
        "WooCommerce PDF Invoicing Module",
        "Website made with two languages, Facebook chat integrated"
      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Правила за достава и наплата при достава ",
        "Модул за профактури во PDF",
        "Двојазичен сајт, интегриран Facebook chat"
      ]
    }
  },

  {
    index: 5,
    title: "Prijateli 5",
    codename: "pet shop friends",


    description: {
      mk:
          "Со помош на едукациски пристап, овој пет шоп од Битола поставува нови стандарди околу пристапот за чување на домашни миленици",
      en:
        "With the help of an educational approach, this pet shop from Bitola sets new standards regarding the approach to nurturing pets."
    },
    type: { mk: "Онлајн продавница за домашни миленици", en: "Online shop for pets" },
    image: "/assets/images/themes/Prijateli.png",
    titleLink:  "https://prijateli5.com/",

    items: {
      en: [
        "Made with WordPress",
        "Online sales system through WooCommerce",
        "Shipping rules and Cash on Delivery",
        "Facebook chat integrated"
      ],
      mk: [
        "Направена со WordPress",
        "Интеграција на онлајн продажба преку WooCommerce",
        "Правила за достава и наплата при достава",
        "Интегриран Facebook chat"
      ]
    }
  }
  /*{
    index: 6,
    title: "Tabula",
    codename: "tabula",
    description: {
      mk:
        "Потребен ти е простор за објавување на твоите завршни сметки? Едноставно и идеално место кое одговара на овој проблем.",
      en:
        "Do you need a place where you can share your final accounts? Ideal design which responds to your problem. Here is your solution."
    },
    type: { mk: "За сметководствени потреби", en: "For accounting companies" },
    image: "/assets/images/themes/theme_tabula.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 7,
    title: "Tabula Plus",
    codename: "tabulaplus",
    description: {
      mk:
        "Потребен ти е простор за објавување на твоите завршни сметки? Едноставно и идеално место кое одговара на овој проблем.",
      en:
        "Do you need a place where you can share your final accounts? Ideal place which respond to your problem. Here is your solution."
    },
    type: { mk: "За сметководствени потреби", en: "For accounting companies" },
    image: "/assets/images/themes/theme_tabula-plus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 8,
    title: "Texo",
    codename: "texo",
    description: {
      mk:
        "Информатичкиот сектор и софтверските апликации се во постојан раст. Како подобро да се прикаже ваков бизнис, отколку со модерен и прогресивен дизајн?",
      en:
        "The IT sector and the software applications are growing at a constant rate. How can be a business like this be better presented, if not by a modern and progressive design?"
    },
    type: { mk: "Зa IT потреби", en: "For IT companies" },
    image: "/assets/images/themes/theme_texo.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 9,
    title: "Visum",
    codename: "visum",
    description: {
      mk:
        "Фотографијата и естетиката се твоето хоби? Овој дизајн ги отсликува формите во кои можеш да ја искажеш својата креативност.",
      en:
        "Photography and esthetics are your hobbies? This design reflects the shapes in which you can express your creativity."
    },
    type: { mk: "За визуелни потреби", en: "For visual purposes" },
    image: "/assets/images/themes/theme_visum.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 10,
    title: "Cogno",
    codename: "cogno",
    description: {
      mk:
        "Одлично решение за твојот мал или семеен бизнис. Дизајн кој идеално се вклопува во визијата за идеално претставување на компанија која штотуку го започнува својот раст.",
      en:
        "Excellent solution for your small or family business. A web template which fits the vision of ideal public showing for a starting company."
    },
    type: { mk: "За почетни бизниси", en: "For small businesses" },
    image: "/assets/images/themes/theme_cogno.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 11,
    title: "Impetus",
    codename: "impetus",
    description: {
      mk:
        "Бизнисот поврзан со рент а кар и транспорт е секогаш еден од најпрофитабилните, но еден од оние на кои навистина им е потребно онлајн присуство.",
      en:
        "The rent a car and transport business are always one of the most profitable, but at the same time, are one of those that really need an online presence."
    },
    type: { mk: "За рент а кар услуги", en: "For rent a car services" },
    image: "/assets/images/themes/theme_impetus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 12,
    title: "Negotium",
    codename: "negotium",
    description: {
      mk:
        "Големите корпорации секогаш имаат потреба од прецизно и добро претставување во јавноста. Овој дизајн е одговор токму на потребите на големите бизниси.",
      en:
        "Big corporations always have the need of precise public exposure. This design is the answer for those businesses."
    },
    type: { mk: "За корпорациски потреби", en: "For corporate usage" },
    image: "/assets/images/themes/theme_negotium.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 13,
    title: "Primo",
    codename: "primo",
    description: {
      mk:
        "Претприемачот секогаш треба да преземе ризик. Дизајнот идеален за старт-ап компанија е дефинитивно пресметан ризик во кој вреди да се инвестира.",
      en:
        "An entrepreneur must always take risks. Ideal for a start-up company, this design is a calculated risk worth investing at."
    },
    type: { mk: "За стартапи", en: "For startups" },
    image: "/assets/images/themes/theme_primo.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 14,
    title: "Sanitas",
    codename: "sanitas",
    description: {
      mk:
        "Грижата за здравјето е еден од основните човекови потреби за квалитетен живот. Нудиш здравствени услуги? Оваа тема е идеална за твојот бизнис.",
      en:
        "Health care is one of the basic human needs for quality life. Offering health services? This web template is ideal for your business."
    },
    type: { mk: "За услуги за здравје", en: "For health services" },
    image: "/assets/images/themes/theme_sanitas.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  }*/
];
