import React from "react";
import styled from "styled-components";

import FormGroup from "../FormGroup";
import Label from "../Label";
import InputHelpBlock from "./InputHelpBlock";
import InputFieldIcon from "./InputFieldIcon";

const StyledInputField = styled.input`
  height: ${props => (props.size === "l" ? "50px" : "40px")};
  font-size: 14px;
  background-color: ${props => props.theme.neutral1};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.neutral1};
  color: ${props => props.theme.neutral5};
  line-height: 1.43;
  padding: 10px 20px 10px 10px;

  &:hover {
    border: 1px solid ${props => props.theme.neutral3};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.blue3};
    border: 1px solid ${props => props.theme.blueBase};
  }
  &:active {
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.blueBase};
    outline: none;
    box-shadow: 0;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.neutral2};
  }

  &.is-valid {
    background-color: ${props => props.theme.green1};
    border: 1px solid ${props => props.theme.greenBase};
    color: ${props => props.theme.neutralBase};
  }

  &.is-invalid {
    background-color: ${props => props.theme.red1};
    border: 1px solid ${props => props.theme.redBase};
    color: ${props => props.theme.neutralBase};
  }

  & span.icon {
    display: absolute;
  }
`;

const StyledInputFieldWrapper = styled.div`
  position: relative;
`;

const InputField = ({ input, meta, ...rest }) => {
  let fieldValidationClass = "";
  if (meta.dirty && meta.valid && !meta.invalid) {
    fieldValidationClass = "is-valid";
  }

  if (meta.dirty && !meta.valid && meta.invalid) {
    fieldValidationClass = "is-invalid";
  }

  return (
    <FormGroup className={`${rest.size} ${rest.className}`}>
      {rest.label.length > 0 && (
        <Label icon={rest.labelIcon}>{rest.label}</Label>
      )}

      <StyledInputFieldWrapper>
        {rest.inputIcon.length > 0 && (
          <InputFieldIcon
            icon={rest.inputIcon}
            valid={meta.valid}
            invalid={meta.invalid}
            size={rest.size}
          />
        )}
        {meta.dirty &&
          meta.valid &&
          !meta.invalid && (
            <InputFieldIcon
              icon="success"
              valid={meta.valid}
              invalid={meta.invalid}
              size={rest.size}
            />
          )}
        {meta.dirty &&
          meta.invalid &&
          !meta.valid && (
            <InputFieldIcon
              icon="warning"
              valid={meta.valid}
              invalid={meta.invalid}
              size={rest.size}
            />
          )}

        <StyledInputField
          {...input}
          {...rest}
          className={`form-control ${fieldValidationClass}`}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck={false}
        />

        {meta.dirty &&
          meta.invalid &&
          !meta.valid && (
            <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
              {meta.error}
            </InputHelpBlock>
          )}
        {rest.helpText.length > 0 && (
          <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
            {rest.helpText}
          </InputHelpBlock>
        )}
      </StyledInputFieldWrapper>
    </FormGroup>
  );
};

InputField.defaultProps = {
  className: "",
  label: "",
  helpText: "",
  labelIcon: "",
  inputIcon: "",
  placeholder: "",
  meta: {}
};

export default InputField;
