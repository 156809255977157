import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const SectionDescription = styled.div`
  display: block;
  font-size: ${isMobileOnly ? "16px" : "18px"};
  line-height: ${isMobileOnly ? "1.63" : "1.56"};
  color: ${props => props.theme.neutral6};
  margin-bottom: 30px;
`;

export default SectionDescription;
