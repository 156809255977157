import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const StyledHeroWrapper = styled.div`
  background-image: url(${props => props.backgroundImage});

  background-position: ${props => props.backgroundPosition || "bottom"};
  background-position-y: ${props => props.backgroundPositionY};
  background-color: ${props => props.theme[props.backgroundColor]};
  background-repeat: no-repeat;
  background-size: ${props =>
    props.backgroundSize ? props.backgroundSize : "contain"};
  padding-bottom: ${props => props.paddingBottom || "100px"};
  margin-bottom: ${props => props.marginBottom || "-1px"};
  position: relative;
`;
const StyledHero = styled.div``;

const StyledTitle = styled.h1`
  margin-top: ${props => (!props.isMobile ? "160px" : "0px")};
  margin-bottom: ${props => (!props.isMobile ? "30px" : "20px")};
  color: ${props =>
    props.theme[props.color]
      ? props.theme[props.color]
      : props.theme.neutralBase};
  font-size: ${props => (!props.isMobile ? "54px" : "36px")};
  font-weight: 600;
  line-height: ${props => (!props.isMobile ? 1.33 : 1.19)};
`;
const StyledSubTitle = styled.h2`
  margin-bottom: 40px;
  color: ${props =>
    props.theme[props.color]
      ? props.theme[props.color]
      : props.theme.neutralBase};
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
`;

const StyledHeroImage = styled.img`
  margin-top: 50px;
`;

const StyledChildren = styled.div`
  background-color: ${props =>
    props.theme[props.backgroundColor] || "transparent"};
`;

const Hero = ({
  title,
  subtitle,
  action,
  image,
  backgroundImage,
  backgroundPosition,
  backgroundPositionY,
  backgroundColor,
  backgroundSize,
  paddingBottom,
  color,
  children,
  leftClassName,
  rightClassName,
  progressBar,
  ...rest
}) => (
  <React.Fragment>
    <StyledHeroWrapper
      className={`row hero ${rest.className}`}
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      backgroundPositionY={backgroundPositionY}
      backgroundColor={backgroundColor}
      paddingBottom={paddingBottom}
      backgroundSize={backgroundSize}
    >
      <div className="col-12 col-sm-12 col-md-12">
        <div className="row">
          <div className="container">
            <StyledHero className="row">
              <div
                className={
                  leftClassName ? leftClassName : "col-12 col-sm-12 col-md-4"
                }
              >
                <StyledTitle color={color} isMobile={isMobileOnly}>
                  {title && typeof title === "function" ? title() : title}
                </StyledTitle>
                <StyledSubTitle color={color}>
                  {subtitle && typeof subtitle === "function"
                    ? subtitle()
                    : subtitle}
                </StyledSubTitle>
                {progressBar}
                {action && typeof action === "function" && action()}
                
              </div>
              {!isMobileOnly && (
                <div
                  className={
                    rightClassName
                      ? rightClassName
                      : "col-12 col-sm-12 col-md-8"
                  }
                >
                  {typeof image === "function" ? (
                    image()
                  ) : (
                    <StyledHeroImage className="img-fluid" src={image} alt="" />
                  )}
                </div>
              )}
            </StyledHero>
          </div>
        </div>
      </div>
    </StyledHeroWrapper>
    {children && (
      <StyledChildren className="row" backgroundColor={backgroundColor}>
        <div className="col-12 col-sm-12 col-md-12">{children}</div>
      </StyledChildren>
    )}
  </React.Fragment>
);

export default Hero;
