import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import Tooltip from "rc-tooltip";

import * as localizatinService from "../../services/localizationService";

import DataContext from "../../DataContext";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";
import Icon from "../Icon";

import logo from "../../assets/images/logo.svg";
import logoWithoutName from "../../assets/images/logoWithoutName.png";
import menu from "../../assets/images/menu.svg";

import {
  ROUTE_HOME,
  ROUTE_CART,
  ROUTE_CLIENT_CART,
  ROUTE_CLIENT_LOGIN,
  ROUTE_WEBSITE_DOMAIN
} from "../../routes";
import { getCartId } from "../../services/cartService";

const StyledHeader = styled.div`
  background-color: ${props => props.theme.white};
`;

const StyledNavbar = styled.nav`
  padding: ${props => !props.isMobile && "0"};
  background-color: ${props => props.theme.white};
  & .navbar-brand {
    margin-right: ${props => (props.isMobile ? "0" : "30px")};
  }
`;
const StyledNavigation = styled.div`
  padding: 15px 0;
`;

const StyledBrandImage = styled.img`
  width: ${props => (props.isMobile ? "30px" : "150px")};
`;

const StyledNavbarToggler = styled.button`
  display: ${props => (props.isMobile ? "block !important" : "none")};
  &:focus {
    outline: none;
  }

  & .navbar-toggler-icon {
    background-image: url(${menu});
  }
`;

const StyledCartLink = styled.a`
  position: relative;

  color: ${props => props.theme.neutral4};
  &:hover {
    text-decoration: none;
  }

  & > .cart-count {
    position: absolute;
    background-color: ${props => props.theme.redBase};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    display: inline-block;
    width: 16px;
    height: 16px;
    top: 0;
    padding-top: 0;
    border-radius: 50%;
    right: 5px;
  }
`;

const StyledMobileLoginLink = styled.a`
  position: relative;

  color: ${props => props.theme.neutral4};
  &:hover {
    text-decoration: none;
  }
`;

const StyledLanguage = styled.span`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
  color: ${props => props.theme.blueBase};
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  margin-left: 15px;
  position: relative;
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null,
      activeLanguage:
        this.props.activeLanguage || localizatinService.getApplicationLocale(),
      switchLanguage: null
    };
  }

  componentDidUpdate() {
    const switchLanguage = this.props.languages.find(
      lang => lang.code !== this.state.activeLanguage
    );
    if (!this.state.switchLanguage) this.setState({ switchLanguage });
  }

  onChangeLanguage = () => {
    const other = this.props.languages.find(
      lang => lang.code !== this.state.activeLanguage
    );
    const switchLanguage = this.props.activeLanguage;
    this.props.setActiveLanguage(other.code);
    localizatinService.setApplicationLocale(other.code);
    this.setState({
      switchLanguage: switchLanguage,
      activeLanguage: other.code
    });
  };

  onGoToCart = () => {
    window.location.replace(`${ROUTE_CLIENT_CART}?cart=${getCartId()}`);
  };

  render() {
    const { children } = this.props;

    let navbarClass = "navbar mr-auto fixed-top";
    if (isMobileOnly) {
      navbarClass += " navbar-expand-xs";
    } else {
      navbarClass += " navbar-expand";
    }
    return (
      <StyledNavbar isMobile={isMobileOnly} className={navbarClass}>
        <div className="container">
          <Link className="navbar-brand" to={ROUTE_HOME}>
            <StyledBrandImage
              isMobile={isMobileOnly}
              src={isMobileOnly ? logoWithoutName : logo}
              className="d-inline-block align-top"
              alt="Mkhost Logo"
            />
          </Link>
          {!this.props.showMobileMenu && (
            <StyledNavbarToggler
              isMobile={isMobileOnly}
              onClick={() => this.props.onToggleMenu()}
              className="navbar-toggler mr-auto"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </StyledNavbarToggler>
          )}

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <StyledNavigation className="navbar-nav">
              {children}
            </StyledNavigation>
            <div className="ml-auto">
              <DataContext.Consumer>
                {({ cart, me }) => (
                  <React.Fragment>
                    <a href={ROUTE_CLIENT_LOGIN} className="mr-3">
                      <PrimaryButton>
                        {me
                          ? `${this.props.translate("general.hello")}, ${
                              me.first_name
                            }`
                          : this.props.translate("general.log-in")}
                      </PrimaryButton>
                    </a>
                    <a
                      href={
                        cart && cart.id
                          ? `${ROUTE_CLIENT_CART}?cart=${cart.id}`
                          : ROUTE_CLIENT_CART
                      }
                      className="mr-3"
                    >
                      <SecondaryButton>
                        <Icon icon="cart" />
                        {cart && cart.items ? cart.items.length : 0}
                      </SecondaryButton>
                    </a>
                  </React.Fragment>
                )}
              </DataContext.Consumer>
              {this.props.activeLanguage && (
                <StyledLanguage
                  onClick={() => this.onChangeLanguage()}
                  overlay=""
                >
                  <Tooltip
                    placement="bottom"
                    overlay={
                      <span>
                        {this.state.switchLanguage &&
                          this.state.switchLanguage.name}
                      </span>
                    }
                  >
                    <span>
                      {this.state.switchLanguage && (
                        <a
                          href={`${
                            this.props.history.location.pathname.startsWith(
                              "/en"
                            )
                              ? ROUTE_WEBSITE_DOMAIN +
                                this.props.history.location.pathname.replace(
                                  "/en",
                                  "/mk"
                                )
                              : ROUTE_WEBSITE_DOMAIN +
                                this.props.history.location.pathname.replace(
                                  "/mk",
                                  "/en"
                                )
                          }`}
                        >
                          {this.props.history.location.pathname.startsWith(
                            "/en"
                          )
                            ? "MK"
                            : "EN"}
                        </a>
                      )}
                    </span>
                  </Tooltip>
                </StyledLanguage>
              )}
            </div>
          </div>

          {isMobileOnly && !this.props.showMobileMenu && (
            <StyledMobileLoginLink
              className="nav-link"
              href={ROUTE_CLIENT_LOGIN}
            >
              <Icon icon="user" size="l" />
            </StyledMobileLoginLink>
          )}
          {isMobileOnly && !this.props.showMobileMenu && (
            <DataContext.Consumer>
              {({ cart }) => (
                <StyledCartLink
                  className="nav-link"
                  href={
                    cart && cart.id
                      ? `${ROUTE_CLIENT_CART}?cart=${cart.id}`
                      : ROUTE_CLIENT_CART
                  }
                >
                  <Icon icon="cart" size="l" />
                  <span className="cart-count">
                    {cart && cart.items ? cart.items.length : 0}
                  </span>
                </StyledCartLink>
              )}
            </DataContext.Consumer>
          )}
          {isMobileOnly && this.props.showMobileMenu && (
            <Icon icon="close" onClick={() => this.props.onHideMobileMenu()} />
          )}
        </div>
      </StyledNavbar>
    );
  }
}
export default withLocalize(withRouter(Navigation));
