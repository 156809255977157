import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import {
  getApplicationLocale,
  setApplicationLocale
} from "./services/localizationService";
import moment from "moment";

import DataContext from "./DataContext";

import ReactZenDeskChat from "@inlightmedia/react-zendesk-chat";

import * as cartService from "./services/cartService";
import * as hostingService from "./services/hostingService";
import * as authService from "./services/authService";
import * as domainsService from "./services/domainsService";

import englishTranslations from "./translations/en.json";
import macedonianTranslations from "./translations/mk.json";

import "./Website.css";
import "./index.css";

import {
  routes,
  homeRoute,
  additionalRoutes,
  ROUTE_CLIENT_CART
} from "./routes";

import displayToastMessageForResponse from "./utils/displayToastMessageForResponse";

import Page404 from "./pages/Page404";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./components/ScrollToTop";

import Modal from "./components/Modal";
import PurchaseHosting from "./components/PurchaseHosting";

import PromotionBanner from "./components/PromotionBanner";
import CookieBanner from "./components/CookieBanner";
import BannerNewYear from "./components/BannerNewYear";

const WebsiteWrapper = styled.div``;

class Website extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cart: null,
      currency: null,
      extensions: null,
      hostings: {
        web: null,
        windows: null,
        business: null,
        wordpress: null,
        premium: null,
        direct: null
      },
      domains: {
        fresh: null,
        generic: null,
        international: null,
        macedonian: null
      },
      me: null,
      showAssignDomainToHosting: false,
      assignHosting: null,
      zenchatWidget: !!document.getElementsByClassName("zopim")[0]
    };

    this.props.initialize({
      languages: [
        {
          name: "English",
          code: "en"
        },
        {
          name: "Македонски",
          code: "mk"
        }
      ],
      options: { renderToStaticMarkup }
    });
    this.props.addTranslationForLanguage(englishTranslations, "en");
    this.props.addTranslationForLanguage(macedonianTranslations, "mk");

    if (this.props.location.pathname.startsWith("/en")) {
      this.props.setActiveLanguage("en");
      setApplicationLocale("en");
      moment.locale("en");
    } else {
      this.props.setActiveLanguage("mk");
      setApplicationLocale("mk");
      moment.locale("mk");
    }
  }

  componentDidMount() {
    this.onFetchCartInfo();

    //this.onFetchCart();

    hostingService.allByGroup().then(result => {
      const { hostings_grouped } = result.data.data;
      const web = hostings_grouped.find(
        hosting => hosting.name === "Web Hosting"
      );
      const business = hostings_grouped.find(
        hosting => hosting.name === "Business Hosting"
      );
      const windows = hostings_grouped.find(
        hosting => hosting.name === "Windows Hosting"
      );

      const wordpress = hostings_grouped.find(
        hosting => hosting.name === "WordPress Hosting"
      );

      const premium = hostings_grouped.find(
        hosting => hosting.name === "Premium Hosting"
      );

      const direct = hostings_grouped.find(
        hosting => hosting.name === "Direct Admin Hosting"
      );

      this.setState({
        hostings: {
          web: web.hostings,
          business: business.hostings,
          windows: windows.hostings,
          wordpress: wordpress.hostings,
          premium: premium.hostings,
          // direct: direct.hostings
        }
      });
    });
    domainsService.fetchDomainsPrices().then(result => {
      const { tld } = result.data.data;

      let extensions = [];
      let currency =
        this.props.activeLanguage && this.props.activeLanguage.code === "en"
          ? "eur"
          : "мкд";

      Object.keys(tld).map(ext => {
        extensions.push({ label: `.${ext}`, value: ext });
        return ext;
      });

      this.setState({
        currency,
        extensions
      });
    });

    domainsService.fetchDomainsPricesByGroup().then(result => {
      this.setState({
        domains: {
          ...result.data.data.top_level_domains
        },
        domainGroups: Object.keys(result.data.data.top_level_domains)
          .map(i => {
            if (i === "macedonian") return { name: i, priority: 2 };
            if (i === "international") return { name: i, priority: 1 };
            return { name: i, priority: 0 };
          })
          .sort((a, b) => (a.priority > b.priority ? -1 : 1))
          .map(i => i.name)
      });
    });
    if (authService.getToken()) {
      authService.getMe().then(
        result => this.setState({ me: result.data.data.customer }),
        error => {
          if (typeof error.response === 'undefined' || error.response.status === 401) {
            authService.removeToken();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (process.env.NODE_ENV === "production") {
      const gtag = window.gtag;
      if (prevProps.location.pathname === this.props.location.pathname) return;

      if (this.props.history.action === "PUSH" && typeof gtag === "function") {
        gtag(
          "config",
          process.env.REACT_APP_MKHOST_GOOGLE_ANALYTICS_TRACKING_ID,
          {
            page_location: window.location.href,
            page_path: this.props.location.pathname
          }
        );
      }
    }
  }

  onAddToCart = (product, redirect = true, notify = true) => {
    if (product.type === "domain") {
      const domainsInCart =
        this.state.cart &&
        this.state.cart.items.filter(item => item.type === "domain");
      const hasDomain =
        domainsInCart &&
        domainsInCart.find(item => item.domain === product.domain);
      if (hasDomain) return Promise.resolve(product);
    }

    return cartService
      .add(product)
      .then(result => {
        const { error } = result.data;
        const { messages, cart } = result.data.data;
        const response = {
          error,
          messages
        };

        cartService.storeCartId(cart.id);
        this.setState({
          cart
        });

        if (notify) {
          displayToastMessageForResponse(
            this.props.translate("general.shop"),
            response,
            `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
            this.props.translate("general.view-cart")
          );
        }

        if (redirect) {
          this.onHideAssignDomainToHosting();
        }
        return result;
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        const { messages } = errorResult.response.data.data;
        const response = {
          error,
          messages
        };
        displayToastMessageForResponse(
          this.props.translate("general.shop"),
          response
        );
        this.onHideAssignDomainToHosting();
        return errorResult;
      });
  };

  onFetchCart = () => {
    cartService
      .all()
      .then(result => this.setState({ cart: result.data.data.cart }));
  };

  onFetchCartInfo = () => {
    cartService
      .cartInfo()
      .then(result => this.setState({ cart: result.data.data.cart }));
  };

  onShowAssignDomainToHosting = hosting => {
    this.setState({
      assignHosting: hosting,
      showAssignDomainToHosting: true
    });
  };

  onHideAssignDomainToHosting = () => {
    this.setState({
      showAssignDomainToHosting: false
    });
  };

  onAssignDomainToHosting = (domain, hosting) => {
    this.setState({
      assignHosting: {
        ...this.state.assignHosting,
        domain: domain
      }
    });
    this.onAddToCart(domain).then(() => this.onHideAssignDomainToHosting());
  };

  render() {
    return (
      <WebsiteWrapper isMobile={isMobileOnly} className="row">
        <BannerNewYear/>
        <PromotionBanner/>

        <ToastContainer
          position="top-right"
          autoClose={15000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ScrollToTop />
        <DataContext.Provider
          value={{
            ...this.state,
            onAddToCart: this.onAddToCart,
            onFetchCart: this.onFetchCart,
            onFetchCartInfo: this.onFetchCartInfo,
            onShowAssignDomainToHosting: this.onShowAssignDomainToHosting,
            onHideAssignDomainToHosting: this.onHideAssignDomainToHosting
          }}
        >
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Header cart={this.state.cart} />
              </div>

              <div className="col-12 col-sm-12 col-md-12">
                <Switch>
                  <Route
                    path={homeRoute.path}
                    exact={homeRoute.exact}
                    component={homeRoute.component}
                  />

                  {routes.map(parent =>
                    parent.groups.map(group =>
                      group.routes
                        .concat(parent)
                        .map(
                          (route, index) =>
                            route.path && (
                              <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                              />
                            )
                        )
                    )
                  )}
                  {additionalRoutes.map(route => (
                    <Route
                      className={route.path}
                      key={"additional" + route.path}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                  <Route component={Page404} />
                </Switch>
              </div>

              <div className="col-12 col-sm-12 col-md-12">
                <Footer />
              </div>
            </div>
          </div>
        </DataContext.Provider>

        {this.state.showAssignDomainToHosting && (
          <Modal
            title={this.props.translate("purchase_hosting.title")}
            subTitle={
              this.state.assignHosting.name +
              " " +
              this.props.translate("purchase_hosting.hosting-package")
            }
            subtitle="hosting"
            onCloseModal={this.onHideAssignDomainToHosting}
            size="xl"
            body={() => (
              <PurchaseHosting
                cart={this.state.cart}
                cartId={cartService.getCartId()}
                hosting={this.state.assignHosting}
                onAssignDomainToHosting={this.onAssignDomainToHosting}
                extensions={this.state.extensions}
                currency={this.state.currency}
                onAddToCart={this.onAddToCart}
              />
            )}
          />
        )}

        <CookieBanner/>
      </WebsiteWrapper>
    );
  }
}

export default withRouter(withLocalize(Website));
