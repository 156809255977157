import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";

const StyledHowSSLCertificatesWorkSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "20px 0")};
  margin: ${props => !props.isMobile && "75px 0"};
  background-image: url("/assets/backgrounds/bg-5.png");
  background-position: center center;
  background-size: ${props => (props.isMobile ? "cover" : "70%")};
  background-repeat: no-repeat;
`;

const StyledImage = styled.img`
  padding-top: 60px;
`;

const StyledTitleContainer = styled.div`
  text-align: center; /* Centers the title horizontally */
  margin-bottom: 30px; /* Adds space below the title */
`;

const ClientsCloud = () => (
  <Translate>
    {({ translate }) => (
      <StyledHowSSLCertificatesWorkSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12">
          <StyledTitleContainer>
            <SectionTitle>{translate("clients")}</SectionTitle>
          </StyledTitleContainer>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/Screenshot 2024-10-06 at 23.13.34.png"
                  alt="Client 1"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/vendor-logo-color.e6dddaf23bc4659c6978d09cef68e0b4.svg"
                  alt="Client 2"
                />
              </div>
              <div className="col-12 col-sm-4 col-md-3">
                <StyledImage
                  className="img-fluid"
                  src="/assets/images/partners/KINEMOE-final-logo-01-1.png"
                  alt="Client 3"
                />
              </div>
            </div>
          </div>
        </div>
      </StyledHowSSLCertificatesWorkSection>
    )}
  </Translate>
);

export default ClientsCloud;
