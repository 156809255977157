import styled from "styled-components";

const Link = styled.a`
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.white};
  position:relative;
  padding: 14px 20px;
  border: solid 1px ${props => props.theme.neutral3}
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.2px;
  margin-right: 15px;
  outline: none;
    &:hover {
      background-color: ${props => props.theme.neutral1};
      color: ${props => props.theme.white};
      cursor: pointer;
      text-decoration: none;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${props => props.theme.blue3};
    }

    &:active {
      border: 1px solid ${props => props.theme.neutral3};
      background-color: ${props => props.theme.neutral1};
      color: ${props => props.theme.blue5};
    }

    &:disabled {
      background-color: ${props => props.theme.neutral2};
      color: ${props => props.theme.white};
      border: 1px solid transparent;
      cursor: not-allowed;
    }


    &:only-child {
      margin-right:0;
    }


  & > span[class^="icon-"] {
    left: -7px;
  }
`;

Link.defaultProps = {
  className: ""
};

export default Link;
