import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import BulletPoint from "../../BulletPoint";

const StyledInviteBillersAndDevelopersSection = styled.div`
  padding: 75px 0;
  margin: 75px 0;
  background-image: url("/assets/backgrounds/bg-5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 38px;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.neutral6};
`;

const InviteBillersAndDevelopersSection = ({ type }) => (
  <Translate>
    {({ translate }) => (
      <StyledInviteBillersAndDevelopersSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledTitle>
                    {translate(
                      type +
                        "-invite-billers-and-developers-to-manage-your-products"
                    )}
                  </StyledTitle>
                  <StyledDescription>
                    {translate(
                      type +
                        "-delegate-tasks-like-invoicing,-product-renewals,-or-development-to-your-employees-or-contractors"
                    )}
                  </StyledDescription>
                  <BulletPoint>
                    {translate("product-billers")}{" "}
                    {translate(
                      "can-manage-invoices,-payments,-product-renewals-and-order-new-products-or-addons"
                    )}
                  </BulletPoint>
                  <BulletPoint>
                    {translate("domain-developers")}{" "}
                    {translate(
                      "can-manage-nameservers,-dns-zones,-domain-redirection,-and-domain-addons"
                    )}
                  </BulletPoint>
                  <BulletPoint>
                    {translate("hosting-developers")}{" "}
                    {translate(
                      "can-manage-the-hosting-plan,-access-cpanel,-manage-website-development,-create-emails,-manage-addons-etc"
                    )}
                  </BulletPoint>
                </div>

                <div className="col-12 col-sm-12 col-md-8">
                  <img
                    className="img-fluid"
                    src="/assets/images/image_cp-invite.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledInviteBillersAndDevelopersSection>
    )}
  </Translate>
);
export default InviteBillersAndDevelopersSection;
