import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import * as hostingService from "../../services/hostingService";
import * as cartService from "../../services/cartService";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import { ROUTE_CLIENT_CART, ROUTE_WEBSITE_DOMAIN } from "../../routes";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";
import Modal from "../../components/Modal";
import InputField from "../../components/InputField";
import Label from "../../components/Label";

import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import CustomControlPanelSection from "../../components/Sections/CustomControlPanelSection";
import OneClickAppInstallSection from "../../components/Sections/OneClickAppInstallSection";
import WhyHostWithMKhostSection from "../../components/Sections/WhyHostWithMKhostSection";
import SupportTypesSection from "../../components/Sections/SupportTypesSection";
import OneClickAppInstallBlocksSection from "../../components/Sections/OneClickAppInstallBlocksSection";
import WhyPurchaseBusinessHostingSection from "../../components/Sections/WhyPurchaseBusinessHostingSection";
import PremiumSupportOnly from "../../components/Sections/PremiumSupportOnly";

import DataContext from "../../DataContext";

import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import seoData from "../../seo/seoData";
import { FAQ_CATEGORY_HOSTING } from "../../config/faq";

const StyledHostingBusinessPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: ${props => (props.isMobile ? "30px" : "100px")};
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "0")};
  left: 0;
  width: 100%;
`;

const hostingPlansImages = {
  starter: "/assets/icons/icon_business1.png",
  professional: "/assets/icons/icon_business2.png",
  max: "/assets/icons/icon_business3.png"
};

const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

class HostingBusinessPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDomainModal: null,
      domain: "",
      hosting: "",
      submitting: false
    };
  }

  onChangeDomain = e => {
    this.setState({ domain: e.target.value });
  };

  onShowModal = product => {
    this.setState({
      hosting: product,
      showDomainModal: true
    });
  };

  onCloseModal = () => {
    this.setState({
      showDomainModal: false
    });
  };

  onAddToCart = () => {
    const product = {
      ...this.state.hosting,
      domain: this.state.domain
    };
    cartService.add(product).then(result => {
      const { error } = result.data;
      const { messages, cart } = result.data.data;
      displayToastMessageForResponse(
        "Shop",
        { error, messages },
        `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
        "View Cart"
      );
    });
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledHostingBusinessPage isMobile={isMobileOnly}>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].hosting_business_hosting.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].hosting_business_hosting
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  {translate("business")}
                  <br />
                  {translate("hosting")}
                </span>
              )}
              subtitle={translate(
                "fast-and-reliable-space-for-your-website.-choose-from-3-different-business-hosting-plans-and-get-started-on-your-website"
              )}
              image="/assets/illustrations/hosting/illustration_hosting.png"
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              paddingBottom="0px"
              backgroundSize={isMobileOnly ? "cover" : "auto"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "157px" : "253px"}
                isMobile={isMobileOnly}
              />
            </Hero>
            <HostingPageHostingPlans>
              <DataContext.Consumer>
                {({ hostings: { business }, onShowAssignDomainToHosting }) => {
                  return (
                    <div key={`hosting-plans-${Math.random()}`} className="row">
                      {!business && (
                        <React.Fragment>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                          <div className="col-12 col-sm-12 col-md-4">
                            <HostingPlanWithLoading isLoading={true} />
                          </div>
                        </React.Fragment>
                      )}
                      {business &&
                        business.map(hosting => {
                          if (
                            hosting.pricing.buy.billing_cycle.derivations
                              .length === 0
                          )
                            return;

                          let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                          let pricing = null;

                          // const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                          //   d => d.numeric_code === 0.1
                          // );

                          // Temporary solution, should be sync with api derivation numeric codes
                          possiblePeriodNumericCodes.some(numericCode => {
                            pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                              d => d.numeric_code === numericCode
                            );

                            if (typeof(pricing) !== 'undefined') {
                              // console.log(numericCode);
                              return true;
                            }
                          });
                          // END

                          if (!pricing) {
                            return;
                          }
                          return (
                            <div
                              key={`hosting-plan-${hosting.id}`}
                              className="col-12 col-sm-12 col-md-4"
                            >
                              <HostingPlan
                                title={hosting.name}
                                description={hosting.details.description}
                                descriptionClassName={
                                  isMobileOnly && "col-7 col-sm-7 col-md-7"
                                }
                                image={
                                  hostingPlansImages[hosting.name.toLowerCase()]
                                }
                                imageWidth="180px"
                                imageHeight="120px"
                                price={pricing.price.value}
                                period={translate(
                                  `derivations.${pricing.name.toLowerCase()}`
                                )}
                                currency={pricing.price.currency.code}
                                additional={hosting.details.extra}
                                additionalPrices={hosting.details.prices}
                                newFlag={hosting.details.is_new}
                                newContent={hosting.details.is_new_title}
                                saleFlag={hosting.details.on_sale}
                                saleContent={hosting.details.on_sale_title}
                                features={hosting.details.features}
                                tooltips={hosting.details.tooltips}
                                action={() => (
                                  <PrimaryButton
                                    size="l"
                                    className="w-100"
                                    onClick={() =>
                                      onShowAssignDomainToHosting(hosting)
                                    }
                                  >
                                    {translate("order-now")}
                                  </PrimaryButton>
                                )}
                              />
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              </DataContext.Consumer>
            </HostingPageHostingPlans>
            <WhyPurchaseBusinessHostingSection />
            <PremiumSupportOnly />
            <WhyHostWithMKhostSection />

            <OneClickAppInstallBlocksSection />

            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_HOSTING} />

            {this.state.showDomainModal && (
              <Modal
                title={translate("add-your-domain-to-your-hosting")}
                onCloseModal={this.onCloseModal}
                body={() => (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-8">
                      <InputField
                        name="domain"
                        value={this.state.domain.toLowerCase()}
                        onChange={this.onChangeDomain}
                        placeholder={translate("domain-name")}
                        label={translate("domain")}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <SecondaryButton
                        className="mt-4"
                        disabled={
                          !this.state.domain.length || this.state.submitting
                        }
                        submitting={this.state.submitting}
                        onClick={() => this.onAddToCart()}
                      >
                        {translate("assign-&-add")}
                      </SecondaryButton>
                    </div>
                  </div>
                )}
              />
            )}
          </StyledHostingBusinessPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(HostingBusinessPage);
