import React from "react";
import styled from "styled-components";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";

import { validateEmail } from "../../../../common/validationRules";
import * as emailValidatorService from "../../../../services/emailValidatorService";
import tutorialImagesMap from "../../../../utils/tutorialImagesMap";

import {
  ROUTE_EMAIL_TUTORIALS_IOS,
  ROUTE_EMAIL_TUTORIALS_ERROR
} from "../../../../routes";
import { Field, Form } from "react-final-form";
import { Link } from "react-router-dom";
import IosPopStepsPage from "./IosPopStepsPage";

import InputField from "../../../../components/InputField";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SuccessButton from "../../../../components/Buttons/SuccessButton";

const StyledImage = styled.img`
  width: ${props => {
    if (props.isMobile) return "380px";
    if (props.isTablet) return "380px";
    return "430px";
  }};
  margin: 0 auto;
`;

const StyledContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-ios.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

const validate = values => {
  return {
    email: validateEmail(values.email, true)
  };
};

const MAX_STEPS = 3;

class IosPopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSteps: false,
      currentStep: 0,
      email: null,
      images: null
    };
  }

  onNextStep = () => {
    if (this.state.currentStep + 1 <= MAX_STEPS) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  onPrevStep = () => {
    if (this.state.currentStep - 1 >= 1) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };

  onSetStep = step => {
    this.setState({
      currentStep: step
    });
  };

  onSubmit = values => {
    return emailValidatorService
      .checkDomainTutorials(values.email)
      .then(response => {
        if (response.data.data.ours === true) {
          this.setState({
            showSteps: true,
            currentStep: 1,
            email: values.email,
            images: tutorialImagesMap(response.data.data.images.ios)
          });
        } else {
          this.props.history.push(ROUTE_EMAIL_TUTORIALS_ERROR);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div>
            <StyledHeader className="row py-5 ">
              <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-2 col-8">
                <h2>
                  <strong>
                    {translate("pages.email-tutorial.email-setup")}
                  </strong>
                </h2>
                {this.state.email && <h3>{this.state.email}</h3>}
              </div>
            </StyledHeader>
            <StyledContainer className="container">
              <div className="row my-4">
                {!this.state.showSteps && (
                  <div className="col-md-10 offset-md-1">
                    <div className="card">
                      <div className="card-header text-center pt-4">
                        <h2>
                          <strong>
                            {translate("pages.email-tutorial.enter-your-email")}
                          </strong>
                        </h2>
                      </div>
                      <div className="card-body text-center pb-5">
                        <Form
                          validate={validate}
                          onSubmit={this.onSubmit}
                          render={({ handleSubmit, invalid, submitting }) => (
                            <form onSubmit={handleSubmit}>
                              <Field
                                component={InputField}
                                name="email"
                                label={translate("pages.email-tutorial.email")}
                              />

                              <SuccessButton
                                className="mx-2"
                                disabled={invalid || submitting}
                                submitting={submitting}
                                type="submit"
                              >
                                {translate("pages.email-tutorial.continue")}
                              </SuccessButton>
                            </form>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {this.state.showSteps && (
                  <div className="col-md-10 offset-md-1">
                    <IosPopStepsPage
                      currentStep={this.state.currentStep}
                      onSetStep={this.onSetStep}
                      email={this.state.email}
                      images={this.state.images}
                    />
                  </div>
                )}

                <div className="col-md-10 offset-md-1 mt-4">
                  {this.state.currentStep <= 1 && (
                    <Link to={ROUTE_EMAIL_TUTORIALS_IOS}>
                      <PrimaryButton size="m">
                        {translate("pages.email-tutorial.go-back")}
                      </PrimaryButton>
                    </Link>
                  )}

                  {this.state.currentStep > 1 &&
                    this.state.currentStep <= MAX_STEPS && (
                      <PrimaryButton
                        className="text-right"
                        onClick={() => this.onPrevStep()}
                      >
                        {translate("pages.email-tutorial.go-back")}
                      </PrimaryButton>
                    )}

                  {this.state.currentStep >= 1 &&
                    this.state.currentStep < MAX_STEPS && (
                      <PrimaryButton
                        className="float-right"
                        onClick={() => this.onNextStep()}
                      >
                        {translate("pages.email-tutorial.next-step")}
                      </PrimaryButton>
                    )}
                </div>
              </div>
            </StyledContainer>
          </div>
        )}
      </Translate>
    );
  }
}

export default IosPopPage;
