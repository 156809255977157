export class FilesEncoder {
  static base64Encode = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // remove file info header
        const result = reader.result.split(",")[1];
        resolve(result);
      };
      reader.onerror = error => reject(error);
    });
  };

  static prepareFileForSending = file => {
    return new Promise((resolve, reject) => {
      FilesEncoder.base64Encode(file)
        .then(result => {
          resolve({
            name: file.name,
            data: result
          });
        })
        .catch(error => reject(error));
    });
  };

  static async createFilesArray(files) {
    const promisesArray = files.map(async file => {
      const result = await FilesEncoder.prepareFileForSending(file);
      return result;
    });
    const filesArray = await Promise.all(promisesArray);
    return filesArray;
  }

  static createAttachments = files => {
    return new Promise((resolve, reject) => {
      FilesEncoder.createFilesArray(files).then(result => {
        const json = JSON.stringify(result);
        const encoded = Buffer.from(json).toString("base64");
        resolve(encoded);
      });
    });
  };
}
