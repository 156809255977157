import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobileOnly } from "react-device-detect";

import Hero from "../../components/Hero";
import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SupportPlan from "../../components/SupportPlan";
import FeaturesTable from "../../components/FeaturesTable";
import CustomTooltip from "../../components/CustomTooltip";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import OneTimePremiumSupportSection from "../../components/Sections/OneTimePremiumSupportSection";

import check_success from "../../assets/images/check_success.svg";
import clear from "../../assets/images/clear.png";

import { ROUTE_OTHER_CONTACT, ROUTE_WEBSITE_DOMAIN } from "../../routes";
import seoData from "../../seo/seoData";

const StyledAvaiable = styled.img``;
const StyledUnavaiable = styled.img``;

const StyledAddonsPremiumSupportPage = styled.div``;

const FeaturesTableSection = styled.div`
  margin-top: -159px;
  padding-bottom: 75px;
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin-bottom: 15px;
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const StyledFreeBlock = styled(Container)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${props => props.theme.neutral4};
  text-align: center;
  padding: 14px;
`;

const features = [
  {
    name: {
      en: "Priority in problem solving",
      mk: "Приоритет во решавање на проблемите"
    },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Creating an unlimited number of e-mail accounts",
      mk: "Креирање на неограничен број на емаил акаунти"
    },
    standard: false,
    premium: true
  },
  {
    name: { en: "Scan with CXS", mk: "Скенирање со CXS" },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Communication with other providers about problems",
      mk: "Комуникација со друг провајдер за решавање на проблем"
    },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Finding older logs",
      mk: "Наоѓање на логови од постаро време"
    },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Theme instalation (up to 2 a month)",
      mk: "Инсталација на тема на платформа (до 2 месечно)"
    },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Standardized link change (once on each domain)",
      mk: "Стандардизирана промена на линкови (еднократно за секој домен)"
    },
    standard: false,
    premium: true
  },
  {
    name: {
      en: "Problem in sending and receiving e-mails",
      mk: "Проблем со испраќање и пристигнување на маилови"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Migration of a new hosting on a carrier domain",
      mk: "Миграција на нов хостинг на носечки домен"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Platform installation (WordPress, Joomla, Drupal)",
      mk: "Инсталација на платформа (WordPress, Joomla, Drupal)"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Setting up proper php settings",
      mk: "Поставување на соодветни php сетинзи"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Setting up domain redirections",
      mk: "Поставување редирекции на домени"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Change of DNS and DNS records",
      mk: "Промена на DNS и DNS рекорди"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Forwarders on e-mail addresses",
      mk: "Forwarders на емаил адреси"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Free statistics (iNodes)",
      mk: "Бесплатни статистики (iNodes)"
    },
    standard: true,
    premium: true
  },
  {
    name: { en: "Resources usage", mk: "Искористеност на ресурси" },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Solving server problems",
      mk: "Решавање на серверски проблеми"
    },
    standard: true,
    premium: true
  },
  {
    name: {
      en: "Analysis and detection of design problems",
      mk: "Анализа и детекција на дизајнерски проблеми"
    },
    standard: true,
    premium: true
  }
];

class AddonsPremiumSupportPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledAddonsPremiumSupportPage>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].addons_support.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].addons_support.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].addons_support.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].addons_support.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].addons_support.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_support.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].addons_support.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].addons_support.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_support.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  {translate("premium")}
                  <br />
                  {translate("support")}
                </span>
              )}
              leftClassName="col-12 col-sm-12 col-md-4"
              rightClassName="col-12 col-sm-12 col-md-8 "
              subtitle={translate(
                "activate-our-premium-support-addon-for-priority-in-problem-solving-and-a-number-of-additional-support-benefits"
              )}
              image="/assets/illustrations/illustration_support.png"
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              paddingBottom="30px"
            >
              <BackgroundSpacer height="200px" />
            </Hero>
            <FeaturesTableSection>
              <div className="row no-gutters">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="container">
                      <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-7 offset-md-5">
                          <div className="row no-gutters">
                            <SupportPlan
                              firstChild={true}
                              className="col-12 col-sm-12 col-md-6"
                              title={translate("standard-support")}
                              description={translate(
                                "247-support-by-our-trained-professionals"
                              )}
                              content={() => (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <StyledPrice>
                                      {translate("free")}
                                    </StyledPrice>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <StyledFreeBlock>
                                      {translate("with-every-purchase")}
                                    </StyledFreeBlock>
                                  </div>
                                </div>
                              )}
                            />
                            {isMobileOnly && (
                              <FeaturesTable className="row no-gutters">
                                <div className="col-12 col-sm-12 col-md-12">
                                  {features.map(feature => (
                                    <div
                                      key={`feature-support-${Math.random()}`}
                                      className="row no-gutters feature-row"
                                    >
                                      <div className="col-12 col-sm-12 col-md-5 feature-wrapper">
                                        <div className="feature">
                                          {activeLanguage &&
                                            feature.name[activeLanguage.code]}
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-7">
                                        <div className="row no-gutters">
                                          <div className="col-12 col-sm-12 col-md-6 feature-value-wrapper">
                                            <div className="feature-value text-center">
                                              {feature.standard ? (
                                                <StyledAvaiable
                                                  src={check_success}
                                                />
                                              ) : (
                                                <StyledUnavaiable src={clear} />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </FeaturesTable>
                            )}

                            <SupportPlan
                              className="col-12 col-sm-12 col-md-6"
                              title={translate("premium-support")}
                              description={translate(
                                "247-priority-problem-solving,-personal-support-or-sales-agent"
                              )}
                              content={() => (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <StyledPrice>
                                      {activeLanguage &&
                                      activeLanguage.code === "mk" ? (
                                        <span>
                                          990 МКД/
                                          {translate("derivations.monthly")}
                                          <CustomTooltip
                                            inline={true}
                                            content={translate(
                                              "price-without-vat"
                                            )}
                                          >
                                            <span>*</span>
                                          </CustomTooltip>
                                        </span>
                                      ) : (
                                        <span>
                                          16.34 EUR/
                                          {translate("derivations.monthly")}
                                        </span>
                                      )}
                                    </StyledPrice>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 text-center">
                                    <a
                                      href={ROUTE_OTHER_CONTACT}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <PrimaryButton size="l" className="w-100">
                                        {translate("contact-us-to-order")}
                                      </PrimaryButton>
                                    </a>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      {isMobileOnly && (
                        <FeaturesTable className="row no-gutters">
                          <div className="col-12 col-sm-12 col-md-12">
                            {features.map(feature => (
                              <div
                                key={`feature-support-${Math.random()}`}
                                className="row no-gutters feature-row"
                              >
                                <div className="col-12 col-sm-12 col-md-5 feature-wrapper">
                                  <div className="feature">
                                    {activeLanguage &&
                                      feature.name[activeLanguage.code]}
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7">
                                  <div className="row no-gutters">
                                    <div className="col-12 col-sm-12 col-md-6">
                                      <div className="feature-value text-center">
                                        {feature.premium ? (
                                          <StyledAvaiable src={check_success} />
                                        ) : (
                                          <StyledUnavaiable
                                            src={check_success}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-12">
                            {activeLanguage && activeLanguage.code === "mk" && (
                              <p className="float-right mt-3">
                                *Во цената не е вклучено 18% ДДВ
                              </p>
                            )}
                          </div>
                        </FeaturesTable>
                      )}

                      {!isMobileOnly && (
                        <FeaturesTable className="row no-gutters">
                          <div className="col-12 col-sm-12 col-md-12">
                            {features.map(feature => (
                              <div
                                key={`feature-support-${Math.random()}`}
                                className="row no-gutters feature-row"
                              >
                                <div className="col-12 col-sm-12 col-md-5 feature-wrapper">
                                  <div className="feature">
                                    {activeLanguage &&
                                      feature.name[activeLanguage.code]}
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7">
                                  <div className="row no-gutters">
                                    <div className="col-12 col-sm-12 col-md-6 feature-value-wrapper">
                                      <div className="feature-value text-center">
                                        {feature.standard ? (
                                          <StyledAvaiable src={check_success} />
                                        ) : (
                                          <StyledUnavaiable src={clear} />
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6">
                                      <div className="feature-value text-center">
                                        {feature.premium ? (
                                          <StyledAvaiable src={check_success} />
                                        ) : (
                                          <StyledUnavaiable src={clear} />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-12">
                            {activeLanguage && activeLanguage.code === "mk" && (
                              <p className="float-right mt-3">
                                *Во цената не е вклучено 18% ДДВ
                              </p>
                            )}
                          </div>
                        </FeaturesTable>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FeaturesTableSection>
            <OneTimePremiumSupportSection />
          </StyledAddonsPremiumSupportPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(AddonsPremiumSupportPage);
