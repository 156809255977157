import React from "react";
import styled from "styled-components";

import Flag from "../Flag";

const StyledFlagNormalDanger = styled.div`
  & .icon:not(.icon-close) {
    color: ${props => props.theme.redBase};
  }
`;

const FlagNormalDanger = ({ children, ...rest }) => (
  <StyledFlagNormalDanger>
    <Flag icon={rest.icon || "clear"} {...rest}>
      {children}
    </Flag>
  </StyledFlagNormalDanger>
);

FlagNormalDanger.defaultProps = {
  onDismiss: () => {}
};

export default FlagNormalDanger;
