import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import BulletPoint from "../../components/BulletPoint";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";


import {
  ROUTE_CLIENT_REFERRAL,
  ROUTE_WEBSITE_DOMAIN
} from "../../routes";

import seoData from "../../seo/seoData";
import SmallTitle from "../../components/SmallTitle";
import MostPopularArticlesSectionReferral from "../../components/Sections/MostPopularArticlesSectionReferral";

const StyledWhyUseReferral = styled.div`
  background-image: url("/assets/backgrounds/bg-4.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding: ${props => (props.isMobile ? "130px 0" : "240px 0")};

  & .circled-icon {
    margin-bottom: 20px;
  }
`;
const StyledReferralProgramSection = styled.div`
  padding: 45px 0;
`;
const StyledWrapper = styled.div`
  display: inline-block;
  margin-top: 20px;
`;
const StyledCol = styled.div`
  padding-left: ${props => !props.isMobile && "130px"};
  margin-bottom: ${props => props.isMobile && "50px"};
`;
class OtherReferralPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: "August, 11, 2019"
    };
  }

  render() {
    return (
        <Translate>
          {({ translate, activeLanguage }) => (
              <React.Fragment>
                {activeLanguage && activeLanguage.code && (
                    <Helmet>
                      <title>
                        {seoData[activeLanguage.code].referral_admin.title}
                      </title>
                      <meta
                          name="keywords"
                          content={
                            seoData[activeLanguage.code].referral_admin.meta_keywords
                          }
                      />
                      <meta
                          name="description"
                          content={
                            seoData[activeLanguage.code].referral_admin
                                .meta_description
                          }
                      />
                      <meta
                          property="og:title"
                          content={seoData[activeLanguage.code].referral_admin.title}
                      />
                      <meta
                          property="og:description"
                          content={
                            seoData[activeLanguage.code].referral_admin
                                .meta_description
                          }
                      />
                      <meta
                          property="og:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/Referral-og.png`}
                      />

                      <meta
                          name="twitter:title"
                          content={seoData[activeLanguage.code].referral_admin.title}
                      />
                      <meta
                          name="twitter:description"
                          content={
                            seoData[activeLanguage.code].referral_admin
                                .meta_description
                          }
                      />
                      <meta
                          name="twitter:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/Referral-og.png`}
                      />
                    </Helmet>
                )}

                <Hero
                    title={translate("pages.other.referral.referral-program")}
                    backgroundColor="neutralBase"
                    color="white"
                    paddingBottom="0"
                    subtitle={translate(
                        "pages.other.referral.referral-friend"
                    )}
                    image="/assets/illustrations/landing_page-referral.png"
                    action={() => (
                        null
                        // <SuccessButton
                        //     onClick={() => this.onScrollToTemplate()}
                        //     size="l"
                        // >
                        //   {translate("start-now")}
                        // </SuccessButton>
                    )}
                />
                <StyledWhyUseReferral
                    className="row"
                    isMobile={isMobileOnly}
                >
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("pages.other.referral.what-is-referral-program")}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                  "pages.other.referral.referral-program-is"
                              )}
                            </SectionDescription>

                              <SectionDescription>


                                {translate(
                                    "pages.other.referral.successful-loyalty"
                                )}
                            </SectionDescription>
                            <SectionDescription>

                              {translate(
                                  "pages.other.referral.referral-program-is-part-two"
                              )}
                            </SectionDescription>
                          {/*  <SectionDescription>

                              {translate(
                                  "pages.other.referral.referral-program-info-1"
                              )}
                            </SectionDescription>*/}
                          </div>
                          <StyledCol
                              className="col-12 col-sm-12 col-md-4"
                              isMobile={isMobileOnly}
                          >
                            <CircledIcon
                                icon="question"
                                color="greenBase"
                                backgroundColor="green1"
                            />
                            <SmallTitle>
                              {translate("pages.other.referral.benefits")}
                            </SmallTitle>
                            <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                            >
                              {translate(
                                  "pages.other.referral.first-benefit"
                              )}
                            </BulletPoint>
                            <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                            >
                              {translate("pages.other.referral.second-benefit")}
                            </BulletPoint>
                            <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                            >
                              {translate(
                                  "pages.other.referral.third-benefit"
                              )}
                            </BulletPoint>
                            <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                            >
                              {translate(
                                  "pages.other.referral.four-benefit"
                              )}
                            </BulletPoint>
                            <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                            >
                              {translate(
                                  "pages.other.referral.five-benefit"
                              )}
                            </BulletPoint>
                            <BulletPoint
                              type="arrow"
                              icon="arrow_right"
                              color="greenBase"
                          >
                            {translate(
                                "pages.other.referral.six-benefit"
                            )}
                          </BulletPoint>

                          </StyledCol>
                          <StyledCol
                              className="col-12 col-sm-12 col-md-4"
                              isMobile={isMobileOnly}
                          >
                            <CircledIcon
                                icon="question"
                                color="blueBase"
                                backgroundColor="blue1"
                            />
                            <SmallTitle>
                              {translate("pages.other.referral.benefits-for-you")}
                            </SmallTitle>
                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.gift-for-you"
                              )}
                            </BulletPoint>
                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.credits"
                              )}
                            </BulletPoint>
                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.earn-credits"
                              )}
                            </BulletPoint>
                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.number-referral"
                              )}
                            </BulletPoint>

                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.unique-code"
                              )}
                            </BulletPoint>

                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.marketing"
                              )}
                            </BulletPoint>


                            <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                              {translate(
                                  "pages.other.referral.transparency"
                              )}
                            </BulletPoint>

                          </StyledCol>
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledWhyUseReferral>


                <StyledReferralProgramSection className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-5">
                            <SectionTitle>
                              {translate(
                                  "pages.other.referral.referral-program-atend"
                              )}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                  "pages.other.referral.referal-steps"
                              )}
                            </SectionDescription>

                            <SectionDescription>
                              <BulletPoint>
                                {translate("pages.other.referral.step-one")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate(
                                    "pages.other.referral.step-two"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate(
                                    "pages.other.referral.step-three"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("pages.other.referral.step-four")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("pages.other.referral.step-five")}
                              </BulletPoint>
                            </SectionDescription>
                            <StyledWrapper>
                              <a
                                  href={ROUTE_CLIENT_REFERRAL}
                                  target="_blank"
                                  rel="noreferrer noopener"
                              >
                                <SecondaryButton size="l">
                                  {translate("pages.other.referral.learn-more")}
                                </SecondaryButton>
                              </a>
                            </StyledWrapper>
                          </div>
                          {!isMobileOnly && (
                              <div className="col-12 col-sm-12 col-md-7">
                                <img
                                    className="img-fluid"
                                    src=" /assets/illustrations/landing-page-referral-ilustration.png"
                                />
                              </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </StyledReferralProgramSection>
               <MostPopularArticlesSectionReferral></MostPopularArticlesSectionReferral>
              </React.Fragment>
          )}
        </Translate>
    );
  }
}

export default OtherReferralPage;
