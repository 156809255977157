import React from "react";
import styled from "styled-components";

const FeaturesTable = styled.div`
  //border: 1px solid ${props => props.theme.neutral3};

  & .feature-row {
    background-color: ${props => props.theme.white};
    border-left: 1px solid ${props => props.theme.neutral3};
     border-right: 1px solid ${props => props.theme.neutral3};

     &:first-child {
      border-top: 1px solid ${props => props.theme.neutral3};
     }

     &:last-child {
      border-bottom: 1px solid ${props => props.theme.neutral3};
     }

    &:nth-child(even) {
      background-color: ${props => props.theme.blue1};
    }


  }

  & .feature {
    padding: 15px 20px;
  }

& .feature-wrapper, & .feature-value-wrapper{
      border-right: 1px solid ${props => props.theme.neutral3};
    }

  & .feature-value {
    padding: 15px 20px;

  }
`;

export default FeaturesTable;
