import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";

import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import WhyChooseCloudHosting from "../../components/Sections/WhyChooseCloudHosting";
import ThirdPartyControlPanelSection from "../../components/Sections/ThirdPartyControlPanelSection";
import WhyHostWithMKhostSection from "../../components/Sections/WhyHostWithMKhostSection";
import SupportTypesSection from "../../components/Sections/SupportTypesSection";

import DataContext from "../../DataContext";
import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";
import * as cloudServerService from "../../services/cloudServerService";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import seoData from "../../seo/seoData";
import { Helmet } from "react-helmet";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_CLOUD_SERVERS } from "../../config/faq";
import TechnicalCharacteristicsCloud from "../../components/Sections/TechnicalCharacteristicsCloud";
import SectionTitle from "../../components/SectionTitle";
import check_success from "../../assets/images/check_success.svg";

const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
`;

const StyledServersCloudPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: ${props => (props.isMobile ? "40px" : "100px")};
  }
`;

const StyledPlan = styled.div`
  margin-bottom: 30px;
`;
const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
`;

const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

class ServersCloudPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloudServers: null,
      isSubmitting: false,
      isFetchingCloudServers: true,
      submittingId: 0
    };
  }

  componentDidMount() {
    cloudServerService.all().then(response => {
      this.setState({
        cloudServers: response.data.data.servers_grouped[0]["servers"] || null,
        isFetchingCloudServers: false
      });
    });
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledServersCloudPage isMobile={isMobileOnly}>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].cloud_servers.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].cloud_servers.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].cloud_servers.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].cloud_servers.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].cloud_servers.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_cloud.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].cloud_servers.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].cloud_servers.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_cloud.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  {translate("cloud")}
                  <br />
                  {translate("servers")}
                </span>
              )}
              subtitle={() => (
                <p>
                  {translate(
                    "scalable-and-secure-hosting-plans-for-your-website"
                  )}
                </p>
              )}
              image="/assets/illustrations/illustration_cloud.png"
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              paddingBottom={isMobileOnly ? "0" : "0"}
              backgroundPosition={isMobileOnly && "center center"}
              backgroundPositionY={isMobileOnly && "0"}
              backgroundSize={isMobileOnly && "cover"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "142px" : "202px"}
              />
            </Hero>
            <HostingPageHostingPlans>
              <DataContext.Consumer>
                {({ onAddToCart }) => {
                  return (
                    <div key={`hosting-plans-${Math.random()}`} className="row">
                      {this.state.isFetchingCloudServers &&
                        !this.state.cloudServers && (
                          <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-4">
                              <HostingPlanWithLoading isLoading={true} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                              <HostingPlanWithLoading isLoading={true} />
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                              <HostingPlanWithLoading isLoading={true} />
                            </div>
                          </React.Fragment>
                        )}
                      {!this.state.isFetchingCloudServers &&
                        this.state.cloudServers &&
                        this.state.cloudServers.map(server => {
                          if (
                            server.pricing.buy.billing_cycle.derivations
                              .length === 0
                          )
                            return;

                          let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                          let pricing = null;

                          // const pricing = server.pricing.buy.billing_cycle.derivations.find(
                          //   d => d.numeric_code === 0.3 //monthly
                          // );

                          // Temporary solution, should be sync with api derivation numeric codes
                          possiblePeriodNumericCodes.some(numericCode => {
                            pricing = server.pricing.buy.billing_cycle.derivations.find(
                              d => d.numeric_code === numericCode
                            );

                            if (typeof(pricing) !== 'undefined') {
                              // console.log(numericCode);
                              return true;
                            }
                          });
                          // END

                          if (!pricing) {
                            return;
                          }
                          return (
                            <div
                              key={`server-plan-${server.id}`}
                              className="col-12 col-sm-12 col-md-4"
                            >
                              <HostingPlan
                                title={server.name}
                                descriptionClassName={
                                  isMobileOnly && "col-6 col-sm-6 col-md-6"
                                }
                                price={pricing.price.value}
                                period={translate(
                                  `derivations.${pricing.name.toLowerCase()}`
                                )}
                                currency={pricing.price.currency.code}
                                additional={server.details.extra}
                                additionalPrices={server.details.prices}
                                features={server.details.features}
                                tooltips={server.details.tooltips}
                                newFlag={server.details.is_new}
                                newContent={server.details.is_new_title}
                                saleFlag={server.details.on_sale}
                                saleContent={server.details.on_sale_title}
                                action={() => (
                                  <PrimaryButton
                                    size="l"
                                    className="w-100"
                                    disabled={
                                      this.state.isSubmitting &&
                                      this.state.submittingId === server.id
                                    }
                                    submitting={
                                      this.state.isSubmitting &&
                                      this.state.submittingId === server.id
                                    }
                                    onClick={() => {
                                      this.setState({
                                        isSubmitting: true,
                                        submittingId: server.id
                                      });
                                      onAddToCart({
                                        cart: this.props.cart
                                          ? this.props.cart.id
                                          : null,
                                        type: "cloud_server",
                                        quantity: 1,
                                        config: {
                                          type: "buy",
                                          period:
                                            server.pricing.buy.billing_cycle
                                              .derivations[0].numeric_code
                                        },
                                        cloud_server_id: server.id
                                      }).then(() => {
                                        this.setState({
                                          isSubmitting: false,
                                          submittingId: 0
                                        });
                                      });
                                      // onShowAssignDomainToHosting(server)
                                    }}
                                  >
                                    {translate("order-now")}
                                  </PrimaryButton>
                                )}
                              />
                            </div>
                          );
                        })}
                    </div>
                  );
                }}
              </DataContext.Consumer>
            </HostingPageHostingPlans>
            <WhyChooseCloudHosting />
            {!isMobileOnly && (
                <div style={{"margin":"100px 0"}}>&nbsp;</div>
            )}
            <TechnicalCharacteristicsCloud/>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
            <StypedTop className="row">
              <div className="col-12 col-sm-12 col-md-7">
                <SectionTitle>
                  {translate("what-include-cloud")}
                </SectionTitle>
                <StyledBulletPoint>
                  <StyledCheck src={check_success} />{" "}
                  {translate("the-server-is-up-and-running -99%-of-the-time")}
                </StyledBulletPoint>
                <StyledBulletPoint>
                  <StyledCheck src={check_success} />{" "}
                  {translate("troubleshooting-a-potential-hardware-problem-by-MKhost")}
                </StyledBulletPoint>
                <StyledBulletPoint>
                  <StyledCheck src={check_success} />{" "}
                  {translate("constant-server-access")}
                </StyledBulletPoint>
                <StyledBulletPoint>
                  <StyledCheck src={check_success} />{" "}
                  {translate("upon-agreement-with-the-client-on-the-first-setup-of-the-server-by-MKhost-an-initial-setting")}
                </StyledBulletPoint>

              </div>
            </StypedTop>
              </div>
              </div>
            </div>

            <SupportTypesSection />
            <MostPopularArticlesSection
              faqCategory={FAQ_CATEGORY_CLOUD_SERVERS}
            />
          </StyledServersCloudPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(ServersCloudPage);
