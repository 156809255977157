import NoticeInfo from "../components/Notices/NoticeInfo";
import NoticeDanger from "../components/Notices/NoticeDanger";
import NoticeWarning from "../components/Notices/NoticeWarning";
import NoticeSuccess from "../components/Notices/NoticeSuccess";

const notices = {
  active: NoticeSuccess,
  warning: NoticeWarning,
  danger: NoticeDanger,
  info: NoticeInfo,
  unavailable: NoticeDanger,
  available: NoticeSuccess,
  unsupported: NoticeDanger,
  invalid: NoticeDanger
};

export default function getNoticeForStatus(status) {
  return notices[status] ? notices[status] : NoticeInfo;
}
