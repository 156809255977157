import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notice from "../Notice";

const StyledNoticeInfo = styled.div`
  background-color: ${theme.blue1};
  border: 1px solid ${theme.blueBase};
  color: ${theme.blueBase};
  border-radius: 3px;
  display: inline-block;
  & .icon {
    top: 1px;
  }

  & a {
    font-weight: 600;
    color: ${theme.blueBase};
  }
`;

const NoticeInfo = ({ children, ...rest }) => (
  <StyledNoticeInfo className="notice-info">
    <Notice icon="info" {...rest}>
      {children}
    </Notice>
  </StyledNoticeInfo>
);

NoticeInfo.defaultProps = {
  dismissable: false,
  onDismiss: () => {}
};

export default NoticeInfo;
