import React from "react";

export default [
  {
    index: 0,
    title: { mk: "АЈДИ-ДИЗАЈН", en: "AJDI-DIZAJN" },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_ajdi.png",
    link: "https://iddesign.mk"
  },

  {
    index: 1,
    title: { mk: "Be-Da Logistics", en: "Be-Da Logistics" },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_beda.png",
    link: "https://be-da.mk"
  },

  {
    index: 2,
    title: { mk: "БЕГ Дистрибуција", en: "BEG Distribution" },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_beg.png",
    link: "http://beg-distribucija.com.mk"
  },

  {
    index: 3,
    title: { mk: "BlicGard", en: "BlicGard" },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_blitz.png",
    link: "https://blitzguard.mk"
  },

  {
    index: 4,
    title: { mk: "Кат-Инг Градба", en: "Kat-Ing Gradba" },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_kating.png",
    link: "http://kat-ing.mk"
  },

  {
    index: 5,
    title: {
      mk: "Спомен куќа на Мајка Тереза",
      en: "Memorial house of Mother Teresa"
    },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_mother-teresa.png",
    link: "http://memorialhouseofmotherteresa.com"
  },
  {
    index: 6,
    title: {
      mk: "Premium Energy",
      en: "Premium Energy"
    },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_premium-energy.png",
    link: "https://premiumenergy.com.mk"
  },

  {
    index: 7,
    title: {
      mk: "ТЕ-ТО АД Скопје",
      en: "TE-TO AD Skopje"
    },
    type: { mk: "Веб дизајн", en: "Web design" },
    image: "/assets/images/portfolio/portfolio_teto.png",
    link: "http://te-to.com.mk"
  }
];
