import React, {Component} from 'react'
import styled from "styled-components";
import {Translate} from "react-localize-redux";
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";

const PromotionBannerContent = styled.div`
  
  background-color: #1B75BC;
  
  color: white;  
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;

  margin-top: ${(isMobileOnly || isTablet) ? "-55px" : "0px"};
  margin-bottom: ${(isMobileOnly || isTablet) ? "30px" : "0px"};

  & .row {
    margin-left: 0px;
    margin-right: 0px;
  }

`;

const PromotionBannerLeft = styled.div`
 display:block;
 margin:0 auto;
 width:70%;
  
`;
const PromotionBannerLeftOne = styled.div`
  border-right-color: white;
  border-right-style: dotted;
  font-size: 23px;
  text-align:center;
  
`;

const UnderBannerContent = styled.span`
color : #263857;
font-size : 25px;
padding:10px 31px;
display:block;
`;

const UnderBannerDescription = styled.span`
color: #263857;
padding: 0 31px;
display:block;
`;

const PromotionBannerRight = styled.div`
  border-left-color: white;
  border-left-style: dotted;
  text-align: center;
`;


const PromotionBannerCenter = styled.div`

  & span {
    display: block;
    font-size: 22px;
    text-align:center;
  }

  & div {
    padding: 15px;
  }

`;
const PromotionBannerCenterRight = styled.div`

  & span {
    display: block;
    padding-bottom: 15px;
    border:2px;
    solid white;
    padding:19px;
    width:70%;
    text-align:center;
    font-size:22px;
    border:2px solid white;
    margin:0 auto;
    
  }

  & div {
    padding: 15px;
  }

`;

const Spacer = styled.div`
   height: 50px;
    width: 100%;
    `

const PromotionBannerCounter = styled.span`

  font-size: 3.5vw;
  color: white;
  font-weight: bold;
  
  position: absolute;
  top: 15%;
  right: 14%;

  @media (min-width: 1920px) {
    font-size: 2.6vw;
  }
 


`;


export default class PromotionBanner extends Component {
    state = {
        days: 0,
        hours: parseInt(process.env.REACT_APP_PROMOTION_BANNER_LENGTH, 10),
        minutes: 0,
        seconds: 0,
        mountBanner: false
    }

    componentDidMount() {
        const promoBannerLength = parseInt(process.env.REACT_APP_PROMOTION_BANNER_LENGTH, 10);
        const promoBannerStartOn = parseInt(process.env.REACT_APP_PROMOTION_BANNER_START_ON, 10);
        const promoBannerEnabled = process.env.REACT_APP_PROMOTION_BANNER_ENABLED;

        if (promoBannerEnabled === 'true') {
            this.displayPromoBanner = setInterval(() => {

                const currDate = new Date();
                const utcDate = currDate.valueOf();

                const endDate = new Date(promoBannerStartOn);
                endDate.setUTCHours(endDate.getUTCHours() + promoBannerLength)
                const endUtcDate = endDate.valueOf();

                if (promoBannerStartOn <= utcDate && utcDate <= endUtcDate) {

                    // calculate the difference if the page is reloaded
                    let difference = Math.abs(endUtcDate - utcDate) / 1000;

                    let  differenceDays = Math.floor(difference / 86400);
                    difference -= (differenceDays * 86400);
                    if (differenceDays < 10) {
                        differenceDays = '0' + differenceDays;
                    }

                    let differenceHours = Math.floor(difference / 3600) % 24;
                    difference -= (differenceHours * 3600);
                    if (differenceHours < 10) {
                        differenceHours = '0' + differenceHours;
                    }

                    let differenceMinutes = Math.floor(difference / 60) % 60;
                    difference -= (differenceMinutes * 60);
                    if (differenceMinutes < 10) {
                        differenceMinutes = '0' + differenceMinutes;
                    }

                    let differenceSeconds = Math.floor(difference % 60);
                    if (differenceSeconds < 10) {
                        differenceSeconds = '0' + differenceSeconds;
                    }

                    this.setState(({days, hours, minutes, seconds}) => ({
                        days : differenceDays,
                        hours : differenceHours,
                        minutes : differenceMinutes,
                        seconds : differenceSeconds,
                    }));

                    this.myInterval = setInterval(() => {
                        const {hours, seconds, minutes} = this.state;

                        if (seconds > 0) {
                            this.setState(({seconds}) => ({
                                seconds: seconds - 1
                            }))
                        }
                        if (seconds === 0) {
                            if (hours === 0 && minutes === 0) {
                                clearInterval(this.myInterval);

                                this.setState(({mountBanner}) => ({
                                    mountBanner: false
                                }));
                            } else {
                                if (minutes === 0) {
                                    this.setState(({hours}) => ({
                                        hours: hours - 1,
                                        minutes: 60
                                    }));
                                }

                                this.setState(({minutes}) => ({
                                    minutes: minutes - 1,
                                    seconds: 59
                                }));
                            }
                        }
                    }, 1000);

                    this.setState(({mountBanner}) => ({
                        mountBanner: true
                    }));

                    clearInterval(this.displayPromoBanner);
                }
            }, 1000);
        } else {
            this.setState(({mountBanner}) => ({
                mountBanner: false
            }));
        }
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
        clearInterval(this.displayPromoBanner);
    }

    render() {
        const {days, hours, minutes, seconds, mountBanner} = this.state;
        return (
            <Translate>
                {({translate}) => (
                    <>
                        {mountBanner && (
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="container">
                                        <PromotionBannerContent>
                                            <div className="row align-items-center">
                                                <PromotionBannerLeft className=" col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                    <img className="center-block" style={{'width': '100%'}}
                                                         src="/assets/promotions/50off.png"/>
                                                </PromotionBannerLeft>
                                                <PromotionBannerLeftOne className="col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                    <span>{translate("pages.home.offer-off-plans-shared-hosting")}  </span>


                                                </PromotionBannerLeftOne>


                                                <PromotionBannerCenter
                                                    className="col-sm-12 col-md-4 col-lg-3 col-xl-3 mobile-spacer">
                                                    <span>{translate("pages.home.promotion_end")}</span>
                                                </PromotionBannerCenter>
                                                <PromotionBannerCenterRight
                                                    className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mobile-spacer">
                                                    <span>{hours}H:{minutes}M:{seconds}S</span>
                                                </PromotionBannerCenterRight>


                                                <PromotionBannerRight
                                                    className="d-none d-md-block col-md-2 col-lg-1 col-xl-2">
                                                    <img className="center-block lg-100" src="/brand/icon-white-banner.png"/>
                                                </PromotionBannerRight>
                                            </div>
                                        </PromotionBannerContent>
                                        <div>
                                            <UnderBannerContent>{translate("pages.home.off-50-percent")}</UnderBannerContent>

                                            <UnderBannerDescription>{translate("pages.home.offer-is-for-fisry-year")}</UnderBannerDescription>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )}
                        <Spacer/>
                    </>
                )}
            </Translate>
        )
    }
}