import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Badge from "../Badge";

const BadgeSuccess = styled(Badge)`
  background-color: ${props => props.theme.green1}
  color: ${props => props.theme.green2};
`;

export default BadgeSuccess;
