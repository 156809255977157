import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import PrimaryButton from "../../Buttons/PrimaryButton";

import HostingPlan from "../../HostingPlan";

import DataContext from "../../../DataContext";

import ContentLoader from "../../Loading/ContentLoader";
import withLoading from "../../Loading/WithLoading";

const StyledLandingPageHostingPlans = styled.div`
  margin-top: -1px;
  padding-bottom: ${props => (props.isMobile ? "0" : "75px")};
`;

const StyledTopWrapper = styled.div`
  background-color: ${props => props.theme.blue1};
  padding-top: ${props => (props.isMobile ? "50px" : "150px")};
  padding-bottom: ${props => (props.isMobile ? "150px" : "240px")};
`;

const StyledBottomWrapper = styled.div`
  position: relative;
  top: ${props => (props.isMobile ? "-126px" : "-152px")};
  margin-bottom: -153px;
`;

const hostingPlansImages = {
  basic: "/assets/icons/icon_web1.png",
  regular: "/assets/icons/icon_web2.png",
  power: "/assets/icons/icon_web3.png"
};

const HostingPlanLoader = withLoading(HostingPlan, ContentLoader);

const LandingPageHostingPlans = props => (
  <Translate>
    {({ translate }) => (
      <StyledLandingPageHostingPlans
        className="landing-hosting-plans row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <StyledTopWrapper className="row" isMobile={isMobileOnly}>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-5 col-md-5">
                      <SectionTitle>
                        {translate("sections.landing_page_hosting_plans.title")}
                      </SectionTitle>
                      <SectionDescription>
                        {parse(
                          translate(
                            "sections.landing_page_hosting_plans.description"
                          )
                        )}
                      </SectionDescription>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledTopWrapper>
          <StyledBottomWrapper className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <DataContext.Consumer>
                    {({
                      hostings: { web },
                      onShowAssignDomainToHosting,
                      extensions
                    }) => {
                      return (
                        <div
                          key={`hosting-plans-${Math.random()}`}
                          className="row"
                        >
                          {!web && (
                            <React.Fragment>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanLoader isLoading={true} />
                              </div>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanLoader isLoading={true} />
                              </div>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanLoader isLoading={true} />
                              </div>
                            </React.Fragment>
                          )}
                          {web &&
                            web.map(hosting => {
                              if (
                                hosting.pricing.buy.billing_cycle.derivations
                                  .length === 0
                              )
                                return;

                              let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                              let pricing = null;

                              // const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                              //   d => d.numeric_code === 1
                              // );

                              // Temporary solution, should be sync with api derivation numeric codes
                              possiblePeriodNumericCodes.some(numericCode => {
                                pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                  d => d.numeric_code === numericCode
                                );

                                if (typeof(pricing) !== 'undefined') {
                                  // console.log(numericCode);
                                  return true;
                                }
                              });
                              // END

                              if (!pricing) {
                                return;
                              }
                              return (
                                <div
                                  key={`hosting-plan-${hosting.id}`}
                                  className="col-12 col-sm-12 col-md-4"
                                >
                                  <HostingPlan
                                    title={hosting.name}
                                    description={hosting.details.description}
                                    descriptionClassName={
                                      isMobileOnly && "col-6 col-sm-6 col-md-6"
                                    }
                                    image={
                                      hostingPlansImages[
                                        hosting.name.toLowerCase()
                                      ]
                                    }
                                    imageWidth="200px"
                                    imageHeight="120px"
                                    price={pricing.price.value}
                                    period={translate(
                                      `derivations.${pricing.name.toLowerCase()}`
                                    )}
                                    currency={pricing.price.currency.code}
                                    additional={hosting.details.extra}
                                    additionalPrices={hosting.details.prices}
                                    features={hosting.details.features}
                                    tooltips={hosting.details.tooltips}
                                    newFlag={hosting.details.is_new}
                                    newContent={hosting.details.is_new_title}
                                    saleFlag={hosting.details.on_sale}
                                    saleContent={hosting.details.on_sale_title}
                                    action={() => (
                                      <PrimaryButton
                                        size="l"
                                        className="w-100"
                                        disabled={!extensions}
                                        onClick={() =>
                                          onShowAssignDomainToHosting(hosting)
                                        }
                                      >
                                        {translate("order-now")}
                                      </PrimaryButton>
                                    )}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      );
                    }}
                  </DataContext.Consumer>
                </div>
              </div>
            </div>
          </StyledBottomWrapper>
        </div>
      </StyledLandingPageHostingPlans>
    )}
  </Translate>
);

export default LandingPageHostingPlans;
