import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link, withRouter } from "react-router-dom";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";

import { ROUTE_OTHER_FREE_EBOOK } from "../../routes";
import parse from "html-react-parser";

const StyledOfferSent = styled.div`
  margin: ${props => (props.isMobile ? "30px 0" : "100px 0")};
  background-image: url("/assets/backgrounds/bg-6.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledContent = styled.div`
  padding: ${props => (props.isMobile ? "80px 0" : "100px 0")};
`;

const StyledImage = styled.img`
  width: 200px;
`;

const OtherFreeEbookThankYouPage = props => (
  <Translate>
    {({ translate }) => (
      <StyledOfferSent className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <StyledContent
                  className="col-12 col-sm-12 col-md-12 text-center"
                  isMobile={isMobileOnly}
                >
                  <StyledImage
                    src="/assets/icons/icon_illustration_done.png"
                    className="img-fluid"
                    alt=""
                  />
                  <SectionTitle>
                    {translate("pages.other.free-ebook.thank-you-title")}
                  </SectionTitle>
                  <SectionDescription>
                    {parse(
                      translate("pages.other.free-ebook.thank-you-description")
                    )}
                  </SectionDescription>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 text-center">
                      <PrimaryButton
                        size="l"
                        onClick={() => props.history.goBack()}
                      >
                        {translate("help-desk.ticket.go-back")}
                      </PrimaryButton>
                    </div>
                  </div>
                </StyledContent>
              </div>
            </div>
          </div>
        </div>
      </StyledOfferSent>
    )}
  </Translate>
);

export default withRouter(OtherFreeEbookThankYouPage);
