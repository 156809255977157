import SectionTitle from "../../../components/SectionTitle";
import {isMobileOnly} from "react-device-detect";
import {ROUTE_DOMAINS_BULK} from "../../../routes";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import React from "react";
import styled from "styled-components";
import {Translate} from "react-localize-redux";


const StyledInfo = styled.div`
  color: ${props => props.theme.white};
  border-radius: 3px;
  padding: 30px;
  padding-right: 80px;
  margin-bottom: 30px;
  & .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    margin: 0;
  }

  & .description {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledBlueInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.blueBase};
`;

const StyledGreenInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.greenBase};
`;

const StyledInfoWrapper = styled.div`
  padding-top: ${props => (props.isMobile ? "80px" : "150px")};
`;

class WhyImportantShop extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Translate>
                {({translate, activeLanguage}) => (
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-5">
                                <SectionTitle>
                                    {translate(
                                        "online-shop-page.why-is-important-to-have-online-store"
                                    )}
                                </SectionTitle>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12">

                                <StyledInfoWrapper isMobile={isMobileOnly}>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-4 offset-md-2">
                                            <StyledBlueInfo>
                                                <h3 className="title">
                                                    {translate(
                                                        "online-shop-page.how-can-open-online-shop"
                                                    )}
                                                </h3>
                                              <p className="description">
                                                    {translate(
                                                        "online-shop-page.e-shop-desc"
                                                    )}
                                                    .
                                                </p>
                                                <a
                                                    href="https://blog.mkhost.com/kako-da-otvoram-e-prodavnica/  "
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <SecondaryButton size="l">
                                                        {translate("online-shop-page.viw-here")}
                                                    </SecondaryButton>
                                                </a>
                                            </StyledBlueInfo>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4">
                                            <StyledGreenInfo>
                                                <h3 className="title">
                                                    {translate("online-shop-page.what-is-important-for-online-shop")}
                                                </h3>
                                                <p className="description">
                                                    {translate(
                                                        "online-shop-page.trends-info"
                                                    )}
                                                    .
                                                </p>
                                                <a
                                                    href="https://blog.mkhost.com/e-commerce-trendovi-vo-sledniot-period"
                                                    target="_blank"
                                                    rel="nofollow noopener"
                                                >
                                                    <SecondaryButton size="l">
                                                        {translate(
                                                            "online-shop-page.viw-here"
                                                        )}
                                                    </SecondaryButton>
                                                </a>
                                            </StyledGreenInfo>
                                        </div>
                                    </div>
                                </StyledInfoWrapper>
                            </div>
                        </div>
                    </div>

                )}
            </Translate>
        )
    }
}
export default WhyImportantShop;