import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import InviteBillersAndDevelopersSection from "../../Sections/InviteBillersAndDevelopersSection";
import ManageYourContactsSection from "../../Sections/ManageYourContactsSection";

import Tabs from "../../Tabs";
import Tab from "../../Tabs/Tab";
import TabContent from "../../Tabs/TabContent";

import BulletPoint from "../../BulletPoint";

const StyledWhoAreYouSection = styled.div`
  padding: 75px 0;
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 38px;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.neutral6};
`;

const WhoAreYouSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhoAreYouSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>{translate("who-are-you")}</SectionTitle>
                  <SectionDescription>
                    {translate(
                      "whether-you-are-an-individual,-business-owner,-freelancer,-or-agency,-our-custom-made-control-panel-is-tailored-to-suit-your-every-need"
                    )}
                  </SectionDescription>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <Tabs
                    defaultTab="business"
                    renderTabs={(active, onSelect) => [
                      <Tab
                        key="key-business"
                        label="business"
                        active={active}
                        onSelect={onSelect}
                      >
                        {translate("business-owner")} /{" "}
                        {translate("invidividual")}
                      </Tab>,
                      <Tab
                        key="key-freelancer"
                        label="freelancer"
                        active={active}
                        onSelect={onSelect}
                      >
                        {translate("freelancer")} / {translate("agency")}
                      </Tab>
                    ]}
                    renderTabContent={active => (
                      <React.Fragment>
                        <TabContent label="business" active={active}>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                              <img
                                className="img-fluid"
                                src="/assets/images/image_cp-manage.png"
                                alt=""
                              />
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                              <StyledTitle>
                                {translate(
                                  "business-manage-your-products-and-services"
                                )}
                              </StyledTitle>
                              <StyledDescription>
                                {translate(
                                  "business-stay-up-to-date-with-your-domain-renewals,-hosting-space-and-bandwidth,-or-purchase-addons"
                                )}
                              </StyledDescription>
                              <BulletPoint>
                                {translate("quick-overview")}{" "}
                                {translate(
                                  "of-your-account-status-with-our-dashboard"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("purchase,-renew,-and-cancel")}{" "}
                                {translate("products-and-services-easily")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("get-notified")}{" "}
                                {translate(
                                  "when-products-and-services-require-your-attention"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("setup-email")}{" "}
                                {translate("addresses-quckly")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("dns,-redirection,-and-zone-files")}
                              </BulletPoint>
                            </div>
                          </div>
                          <InviteBillersAndDevelopersSection type="business" />
                          <ManageYourContactsSection type="business" />
                        </TabContent>
                        <TabContent label="freelancer" active={active}>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-8">
                              <img
                                className="img-fluid"
                                src="/assets/images/image_cp-manage.png"
                                alt=""
                              />
                            </div>
                            <div className="col-12 col-sm-12 col-md-4">
                              <StyledTitle>
                                {translate(
                                  "freelance-manage-your-products-and-services"
                                )}
                              </StyledTitle>
                              <StyledDescription>
                                {translate(
                                  "freelance-stay-up-to-date-with-your-domain-renewals,-hosting-space-and-bandwidth,-or-purchase-addons"
                                )}
                              </StyledDescription>
                              <BulletPoint>
                                {translate("quick-overview")}{" "}
                                {translate(
                                  "of-your-account-status-with-our-dashboard"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("purchase,-renew,-and-cancel")}{" "}
                                {translate("products-and-services-easily")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("get-notified")}{" "}
                                {translate(
                                  "when-products-and-services-require-your-attention"
                                )}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("setup-email")}{" "}
                                {translate("addresses-quckly")}
                              </BulletPoint>
                              <BulletPoint>
                                {translate("dns,-redirection,-and-zone-files")}
                              </BulletPoint>
                            </div>
                          </div>
                          <InviteBillersAndDevelopersSection type="freelance" />
                          <ManageYourContactsSection type="freelance" />
                        </TabContent>
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWhoAreYouSection>
    )}
  </Translate>
);
export default WhoAreYouSection;
