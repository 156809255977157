import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import parse from "html-react-parser";

import ProductPlan from "../../components/ProductPlan";

const StyledHostingPromotion = styled.div`
  & .hosting-plan {
  }

  & .product-plan-wrapper {
    margin-top: -2px;
  }
`;

const StyledLookingForHosting = styled.span`
  display: inline-block;
  float: right;
  color: ${theme.blueBase};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledRecommendedPlan = styled.div`
  background-color: ${theme.blue4};
  padding: 20px 30px;
  margin-top: ${props =>
    !props.isMobile && props.adjustForEn ? "-80px" : "-100px"};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;
  position: relative;

  & .title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.white};
    display: block;
  }

  & .description {
    display: block;
    font-size: 14px;
    color: ${theme.white};
  }
`;

const calculateHostingColSize = numberOfHostings => {
  const size = numberOfHostings > 1 ? Math.round(12 / numberOfHostings) : 4;

  return size > 4 ? size : 4;
};

class HostingPromotion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostingsType: "web"
    };
  }

  calculateHostingColSize = numberOfHostings => {
    const size = numberOfHostings > 1 ? Math.round(12 / numberOfHostings) : 4;

    return size > 4 ? size : 4;
  };

  onShowWindowsHostings = () => {
    this.setState({
      hostingsType: "windows"
    });
  };

  onShowWebHostings = () => {
    this.setState({
      hostingsType: "web"
    });
  };

  onAddToCart = hosting => {
    this.props.onAddToCart({ ...hosting, domain: this.props.domain });
  };

  render() {
    const {
      hostings,
      availableDomains,
      submitting,
      disabled,
      onAddToCart,
      domainType
    } = this.props;

    let web = null;
    let windows = null;
    if (Object.keys(this.props.hostings).length > 0) {
      web = this.props.hostings.web;
      windows = this.props.hostings.windows;
    }

    return (
      <StyledHostingPromotion
        isMobile={isMobileOnly}
        adjustForEn={
          this.props.activeLanguage && this.props.activeLanguage.code === "en"
        }
      >
        {this.state.hostingsType === "web" && (
          <div>
            {isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {web &&
                      web.length > 0 &&
                      web.map(hosting => {
                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-12 col-md-12 hosting-plan`}
                          >
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                              currency={this.props.currency}
                              domainType={domainType}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWindowsHostings()}
                  >
                    {this.props.translate(
                      "sections.hosting-promotion.looking-for-windows-hosting"
                    )}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
            {!isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {web &&
                      web.length > 0 &&
                      web.map(hosting => {
                        let colSize = this.calculateHostingColSize(
                          Object.keys(web).length
                        );

                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-${colSize} col-md-${colSize} hosting-plan`}
                          >
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                              currency={this.props.currency}
                              domainType={domainType}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWindowsHostings()}
                  >
                    {this.props.translate(
                      "sections.hosting-promotion.looking-for-windows-hosting"
                    )}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.hostingsType === "windows" && (
          <div>
            {isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {windows &&
                      windows.map(hosting => {
                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-12 col-md-12 hosting-plan`}
                          >
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                              currency={this.props.currency}
                              domainType={domainType}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWebHostings()}
                  >
                    {this.props.translate(
                      "sections.hosting-promotion.back-to-web-hosting"
                    )}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
            {!isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {windows &&
                      windows.map(hosting => {
                        let colSize = this.calculateHostingColSize(
                          Object.keys(windows).length
                        );

                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-${colSize} col-md-${colSize} hosting-plan`}
                          >
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                              currency={this.props.currency}
                              domainType={domainType}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWebHostings()}
                  >
                    {this.props.translate(
                      "sections.hosting-promotion.back-to-web-hosting"
                    )}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
          </div>
        )}
      </StyledHostingPromotion>
    );
  }
}

export default withLocalize(HostingPromotion);
