import {Helmet} from "react-helmet";
import seoData from "../../../seo/seoData";
import {ROUTE_DOMAINS_BULK, ROUTE_WEBSITE_DOMAIN} from "../../../routes";
import Hero from "../../../components/Hero";
import SuccessButton from "../../../components/Buttons/SuccessButton";
import React from "react";
import {Translate} from "react-localize-redux";
import SectionTitle from "../../../components/SectionTitle";
import SectionDescription from "../../../components/SectionDescription";
import styled from "styled-components";
import IconTextInfo from "../../../components/IconTextInfo";
import {isMobileOnly} from "react-device-detect";
import BulletPoint from "../../../components/BulletPoint";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import ProjectQuoteFormSection from "../../../components/Sections/ProjectQuoteFormSection/ProjectQuoteFormSection";
import BackgroundSpacer from "../../../components/BackgroundSpacer";
import OnlineShopQuote from "./Quote";
import WhyImportantShop from "./WhyImportantShop";
import OnlineShopSlider from "./OnlineShopSlider";


const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;


const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;


const StyledInfo = styled.div`
  color: ${props => props.theme.white};
  border-radius: 3px;
  padding: 30px;
  padding-right: 80px;
  margin-bottom: 30px;
  & .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
    margin: 0;
  }

  & .description {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    margin-bottom: 40px;
  }
`;

const StyledBlueInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.blueBase};
`;

const StyledGreenInfo = styled(StyledInfo)`
  background-color: ${props => props.theme.greenBase};
`;

const StyledInfoWrapper = styled.div`
  padding-top: ${props => (props.isMobile ? "80px" : "150px")};
`;
const StyledWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: ${props => (props.isMobile ? "130px" : "240px")};
`;


class WebDesignOnlineShop extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Translate>
                {({translate, activeLanguage}) => (
                    <React.Fragment>
                        {activeLanguage && activeLanguage.code && (
                            <Helmet>
                                <title>
                                    {seoData[activeLanguage.code].online_shop_promotion.title}
                                </title>
                                <meta
                                    name="keywords"
                                    content={
                                        seoData[activeLanguage.code].online_shop_promotion.meta_keywords
                                    }
                                />
                                <meta
                                    name="description"
                                    content={
                                        seoData[activeLanguage.code].online_shop_promotion
                                            .meta_description
                                    }
                                />
                                <meta
                                    property="og:title"
                                    content={seoData[activeLanguage.code].online_shop_promotion.title}
                                />
                                <meta
                                    property="og:description"
                                    content={
                                        seoData[activeLanguage.code].online_shop_promotion
                                            .meta_description
                                    }
                                />
                                <meta
                                    property="og:image"
                                    content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/hosting/og_online_shop.png`}
                                />

                                <meta
                                    name="twitter:title"
                                    content={seoData[activeLanguage.code].online_shop_promotion.title}
                                />
                                <meta
                                    name="twitter:description"
                                    content={
                                        seoData[activeLanguage.code].online_shop_promotion
                                            .meta_description
                                    }
                                />
                                <meta
                                    name="twitter:image"
                                    content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/hosting/og_online_shop.png`}
                                />
                            </Helmet>
                        )}

                        <Hero
                            title={translate("online-shop-page.title")}
                            backgroundColor="neutralBase"
                            color="white"
                            paddingBottom="0"
                            leftClassName="col-12 col-sm-12 col-md-6"
                            rightClassName="col-12 col-sm-12 col-md-6 "
                            subtitle={translate(
                                "online-shop-page.desc"
                            )}

                            image="/assets/illustrations/image-small.png"
                            action={() => (
                                <a
                                    href={'#contact-online'}
                                    rel="noopener noreferrer"
                                >
                                    <SuccessButton
                                        size="l"
                                    >
                                        {translate("start-now")}
                                    </SuccessButton>
                                </a>
                            )}

                        />
                        <StyledWrapper className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="container">
                                        {/* <StypedTop className="row">
                                            <div className="col-12 col-sm-12 col-md-7">
                                                <StypedTop className="row">
                                                    <SectionTitle>
                                                        {translate("online-shop-page.we-have-special-offer")}
                                                    </SectionTitle>
                                                    <SectionDescription>
                                                        {translate(
                                                            "online-shop-page.make-online-shop"
                                                        )}
                                                    </SectionDescription>
                                                    <SectionDescription>
                                                        {translate(
                                                            "online-shop-page.note"
                                                        )}
                                                    </SectionDescription>
                                                </StypedTop>
                                            </div>
                                        </StypedTop> */}
                                        <StypedTop className="row">
                                            <div className="col-12 col-sm-12 col-md-7">

                                                <SectionTitle>
                                                    {translate("online-shop-page.different-online-store")}
                                                </SectionTitle>
                                                <SectionDescription>
                                                    {translate(
                                                        "online-shop-page.your-online-store"
                                                    )}
                                                </SectionDescription>

                                            </div>
                                        </StypedTop>
                                    </div>
                                </div>
                            </div>
                        </StyledWrapper>

                        <div className="container">
                            <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <IconTextInfo
                                        icon={'help_desk'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.online-assistent"}
                                        description={"online-shop-page.online-asistent-des"}
                                    />
                                    <IconTextInfo
                                        icon={'code'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.ai"}
                                        description={"online-shop-page.ai-des"}
                                    />
                                    <IconTextInfo
                                        icon={'websites'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.social-media-integration"}
                                        description={"online-shop-page.social-med-des"}
                                    />
                                    <IconTextInfo
                                        icon={'company'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.three-preview"}
                                        description={"online-shop-page.3d-preview-des"}
                                    />

                                    <IconTextInfo
                                        icon={'chevron_down'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.dynamic-discounts"}
                                        description={"online-shop-page.dynamic-des"}
                                    />

                                    <IconTextInfo
                                        icon={'message'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.online-chat"}
                                        description={"online-shop-page.online-chat-des"}
                                    />
                                    <IconTextInfo
                                        icon={'cart'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.online-payment"}
                                        description={"online-shop-page.online-payment-des"
                                        }
                                    />


                                    <IconTextInfo
                                        icon={'search '}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.filter"}
                                        description={"online-shop-page.filter-des"
                                        }
                                    />
                                    <IconTextInfo
                                        icon={'ranking'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.dynamic-ship-pricing"}
                                        description={"online-shop-page.dynamic-shipping-des"
                                        }
                                    />


                                    <IconTextInfo
                                        icon={'cpanel'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.statistic-order"}
                                        description={"online-shop-page.statistic-des"
                                        }
                                    />
                                    <IconTextInfo
                                        icon={'files'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.modul-print-invoices"}
                                        description={"online-shop-page.invoice-des"
                                        }
                                    />
                                    <IconTextInfo
                                        icon={'notification'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.advertising"}
                                        description={"online-shop-page.adv-des"
                                        }
                                    />
                                    <IconTextInfo
                                        icon={'domains'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.shipping-tracking"}
                                        description={"online-shop-page.shipping-des"
                                        }
                                    />

                                    <IconTextInfo
                                        icon={'edit'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.size-recommendation-tools"}
                                        description={"online-shop-page.size-des"
                                        }
                                    />
                                    <IconTextInfo
                                        icon={'input-checkbox'}
                                        iconColor={'blueBase'}
                                        iconBgColor={'blue1'}
                                        title={"online-shop-page.affiliate-marketing"}
                                        description={"online-shop-page.loyal-des"
                                        }
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-5">
                                    <SectionTitle>
                                        {translate(
                                            "online-shop-page.benefits"
                                        )}
                                    </SectionTitle>

                                    <SectionDescription>
                                        <BulletPoint>
                                            {translate("online-shop-page.unique-for-e-business")}
                                        </BulletPoint>

                                        <BulletPoint>
                                            {translate(
                                                "online-shop-page.e-store-open-24-7"
                                            )}
                                        </BulletPoint>
                                        <BulletPoint>
                                            {translate("online-shop-page.benefit")}
                                        </BulletPoint>
                                        <BulletPoint>
                                            {translate("online-shop-page.offer-with-big-number-of-information")}</BulletPoint>


                                    </SectionDescription>

                                </div>
                                {!isMobileOnly && (
                                    <div className="col-12 col-sm-12 col-md-7">
                                        <img
                                            className="img-fluid"
                                            src="/assets/images/summer-second-image.png"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-7">
                                    <SectionTitle>
                                        {translate(
                                            "online-shop-page.portfolio"
                                        )}
                                    </SectionTitle>

                                    <SectionDescription>
                                        {translate(
                                            "online-shop-page.portfolio-desc"
                                        )}
                                    </SectionDescription>
                                </div>
                            </div>
                            <OnlineShopSlider/>
                        </div>

                        <br/>

                        {activeLanguage && activeLanguage.code === 'mk' &&  <WhyImportantShop/>}

                        <OnlineShopQuote/>


                    </React.Fragment>

                )}
            </Translate>
        )
    }
}

export default WebDesignOnlineShop;