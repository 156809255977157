import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";

import * as cartService from "../../../services/cartService";
import {
  domainWhoIs,
  isDomainAvailable
} from "../../../services/domainsService";

import InputField from "../../InputField";
import PrimaryButton from "../../Buttons/PrimaryButton";

import WhoIsSearch from "../../WhoIsSearch";
import WhoIsSearchResultsDomainAvailable from "../../WhoIsSearch/WhoIsSearchResultsDomainAvailable";
import WhoIsSearchResultsInfo from "../../WhoIsSearch/WhoIsSearchResultsInfo";

import { validateWhoIsMkDomain } from "../../../common/validationRules";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import { ROUTE_CLIENT_CART } from "../../../routes";

import { DOMAIN_STATUS_AVAILABLE } from "../../../pages/Domains/DomainStatuses";

const StyledDomainsWhoIsSearchSection = styled.div``;

const WhoIsSearchWrapper = styled.div`
  position: relative;
  //background-color: ${props => props.theme.blue1};
  & .whois-search {
    margin-top: ${props => (props.isMobile ? "200px" : "30px")};
  }
`;

const validate = values => {
  return {
    domain: validateWhoIsMkDomain(values.domain)
  };
};

class DomainsWhoIsSearchSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domain: null,
      results: null,
      isSearchingWhoIsData: false,
      searched: false
    };
  }

  onWhoIsSearch = values => {
    this.setState({ isSearchingWhoIsData: true, domain: null, results: null });

    domainWhoIs(values.domain)
      .then(result => {
        const { error } = result.data;
        const { results } = result.data.data;

        if (!error) {
          this.setState({ results: results });
        }
      })
      .finally(() => {
        this.setState({ isSearchingWhoIsData: false });
      });

    isDomainAvailable(values.domain)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        if (!error) {
          this.setState({ domain: domain });
        }
      })
      .finally(() => {
        this.setState({ isSearchingWhoIsData: false });
      });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.domain === null &&
      (this.props.domain !== null || this.props.domain !== "") &&
      !this.state.searched
    ) {
      document
        .getElementById("whois-search-form")
        .dispatchEvent(new Event("submit", { cancelable: true }));

      this.setState({
        searched: true
      });
    }
  }

  componentDidMount() {
    if (this.props.domain && this.props.selectedExtension) {
      document
        .getElementById("whois-search-form")
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  }

  onAddToCart = domain => {
    cartService
      .add({
        type: "domain",
        domain_id: null,
        domain: domain.name,
        quantity: 1,
        config: {
          type: "register",
          period:
            domain.pricing["register"].billing_cycle.derivations[0].numeric_code
        }
      })
      .then(result => {
        const { error } = result.data;
        const { messages, cart } = result.data.data;
        displayToastMessageForResponse(
          "Shop",
          { error, messages },
          `${ROUTE_CLIENT_CART}?cart=${cart.id}`,
          "View Cart"
        );
      });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <WhoIsSearchWrapper
              className="col-12 col-sm-12 col-md-12"
              isMobile={isMobileOnly}
            >
              <div className="row">
                <div className="container">
                  <WhoIsSearch
                    onSubmit={this.onWhoIsSearch}
                    domain={this.props.domain}
                    searchPlaceholder={this.props.searchPlaceholder}
                    validate={validate}
                    searchPlaceholder={translate(
                      "pages.domains.whois.enter-a-mk-domain-name"
                    )}
                    isSearchingWhoIsData={this.state.isSearchingWhoIsData}
                  />
                </div>
              </div>
            </WhoIsSearchWrapper>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <div className="container">
                      {this.state.domain &&
                        this.state.domain.status ===
                          DOMAIN_STATUS_AVAILABLE && (
                          <WhoIsSearchResultsDomainAvailable
                            domain={this.state.domain}
                            onAddToCart={this.onAddToCart}
                          />
                        )}
                      {this.state.results &&
                        (this.state.results.length > 0 ||
                          Object.keys(this.state.results).length > 0) && (
                          <WhoIsSearchResultsInfo
                            results={this.state.results}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default DomainsWhoIsSearchSection;
