import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { isMobileOnly } from "react-device-detect";

import InputField from "../../InputField";
import SelectBox from "../../SelectBox";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton";

const StyledDomainTransferSearch = styled.div`
  & .form-group {
    margin: 0;
  }

  & .domain-transfer-search-input {
    width: ${props => (props.isMobile ? "100%" : "76%")};
    display: inline-block;
    margin-right: 20px;
    margin-bottom: ${props => props.isMobile && "20px"};
    vertical-align: top;
  }

  & .input-icon {
    top: 24px;
  }

  & .btn {
    display: inline-block;
    width: ${props => (props.isMobile ? "44%" : "20%")};
    margin-right: ${props => props.isMobile && "0"};
    top: 0px;
    vertical-align: top;
  }
`;

class DomainTransferSearch extends React.Component {
  constructor(props) {
    super(props);
    this.selectFocus = null;
  }
  onKeyPress = e => {
    // dot (.) button
    if (e.keyCode === 190) {
      if (this.selectFocus) {
        this.selectFocus.select.focus();
      }
    }
  };

  getSelectInternalFocusFunction = focus => {
    this.selectFocus = focus;
  };

  componentWillUnmount() {
    this.selectFocus = null;
  }

  render() {
    const domain = this.props.domain || "";
    return (
      <div className="domain-transfer-search">
        <div className="domain-transfer-search-container">
          <StyledDomainTransferSearch isMobile={isMobileOnly}>
            <Translate>
              {({ translate }) => (
                <Form
                  onSubmit={this.props.onSubmit}
                  validate={this.props.validate}
                  initialValues={{
                    domain: domain
                  }}
                  render={({ handleSubmit, submitting, invalid, values }) => (
                    <form
                      onSubmit={handleSubmit}
                      id="domain-transfer-search-transfer-form"
                    >
                      <Field
                        onKeyDown={this.onKeyPress}
                        component={InputField}
                        className="domain-transfer-search-input"
                        name="domain"
                        size="l"
                        placeholder={this.props.searchPlaceholder}
                        normalize
                        tabIndex="1"
                        parse={value =>
                          value.toLowerCase() &&
                          value
                            .replace(/[^0-9a-z.\u0400-\u04FF-]/gi, "")
                            .replace(/^(https?:|)\/\/|www\./, "").toLowerCase()
                        }
                        format={value =>
                          value &&
                          value
                            .replace(/[^0-9a-z.\u0400-\u04FF-]/gi, "")
                            .replace(/^(https?:|)\/\/|www\./, "")
                        }
                      />

                      {this.props.searchPerformed && (
                        <SecondaryButton
                          type="submit"
                          disabled={submitting}
                          submitting={submitting}
                          size="l"
                          tabIndex="2"
                        >
                          {isMobileOnly
                            ? translate("general.search")
                            : translate("general.search") +
                              " " +
                              translate("general.domain").toLowerCase()}
                        </SecondaryButton>
                      )}
                      {!this.props.searchPerformed && (
                        <PrimaryButton
                          type="submit"
                          disabled={submitting}
                          submitting={submitting}
                          size="l"
                          tabIndex="2"
                        >
                          {isMobileOnly
                            ? translate("general.search")
                            : translate("general.search") +
                              " " +
                              translate("general.domain").toLowerCase()}
                        </PrimaryButton>
                      )}
                    </form>
                  )}
                />
              )}
            </Translate>
          </StyledDomainTransferSearch>
        </div>
      </div>
    );
  }
}

export default DomainTransferSearch;
