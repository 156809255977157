import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledBrandWrapper = styled.div`
  margin-top: -200px;
  margin-bottom: ${props => (props.isMobile ? "50px" : "150px")};
`;

const StyledContent = styled.div`
  padding-top: ${props => (props.isMobile ? "70px" : "100px")};
  padding-bottom: ${props => (props.isMobile ? "70px" : "150px")};
`;

const StyledTitle = styled.h2`
  margin: 0;
  margin-bottom: 18px;
  font-size: 28px;
  line-height: 1.33;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;
const StyledDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: 30px;
  & .title {
    display: block;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.36;
    color: ${props => props.theme.neutralBase};
    margin-bottom: 5px;
  }

  & .content {
    display: block;
    margin-bottom: 75px;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.neutral6};
  }

  & strong {
    display: block;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.neutral6};
  }
`;

const StyledBlackContainer = styled(Container)`
  padding: 30px;
  background-color: ${props => props.theme.neutralBase};
  border-color: ${props => props.theme.neutralBase};
  margin-bottom: 30px;
  & .title {
    display: block;
    font-size: 28px;
    font-weight: 600;
    line-height: 1.36;
    color: ${props => props.theme.white};
    margin-bottom: 5px;
  }

  & .content {
    display: block;
    margin-bottom: 75px;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.white};
  }
`;

const StyledImage = styled.img`
  margin-bottom: 40px;
`;

const StyledColorBlock = styled.div`
  display: block;
  border-radius: 5px;
  height: 150px;
  background-color: ${props => props.theme[props.backgroundColor]};
  border: 1px solid
    ${props =>
      props.backgroundColor === "white" ? props.theme.neutral3 : "transparent"};
`;

const StyledSuccess = styled(Icon)`
  color: ${props => props.theme.greenBase};
  margin-top: 20px;
`;

const StyledDanger = styled(Icon)`
  color: ${props => props.theme.redBase};
  margin-top: 20px;
`;

const StyledNameBlock = styled(Container)`
  font-size: 28px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  padding: 30px;
  border-bottom: 1px solid ${props => props.theme.neutral3};
`;

const StyledNameBlockWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "30px"};
`;

class OtherBrandPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_our_brand.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_our_brand.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_our_brand
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_our_brand.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_our_brand
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_our_brand.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_our_brand
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("mkhost-brand")}
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              subtitle={translate(
                "find-our-more-about-our-brand-colors-and-download-our-logos"
              )}
              backgroundSize={isMobileOnly && "cover"}
            >
              <BackgroundSpacer height="130px" />
            </Hero>

            <StyledBrandWrapper className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledContainer>
                          <span className="title">
                            {translate("standard-logo")}
                          </span>
                          <p className="content">
                            {translate(
                              "our-standard-logo-consisting-of-our-icon-and-wordmark.-use-this-logo-by-default"
                            )}
                          </p>
                          <StyledImage src="/brand/color.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/color.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/color@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/color@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/color.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledContainer>

                        <StyledContainer>
                          <span className="title">
                            {translate("icon-logo")}
                          </span>
                          <p className="content">
                            {translate(
                              "our-icon-logo,-only-to-be-used-when-the-brand-mkhost-is-well-established-within-the-page"
                            )}
                          </p>
                          <StyledImage src="/brand/icon-color.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/icon-color.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-color@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-color@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-color.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledBlackContainer>
                          <span className="title">
                            {translate("white-icon-logo")}
                          </span>
                          <p className="content">
                            {translate(
                              "the-white-version-of-our-logo-Use-this-logo-when-placing-it-on-dark-backgrounds"
                            )}
                          </p>
                          <StyledImage src="/brand/white.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/white.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/white@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/white@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/white.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledBlackContainer>
                        <StyledBlackContainer>
                          <span className="title">
                            {translate("white-icon-symbol")}
                          </span>
                          <p className="content">
                            {translate(
                              "our-white-icon-logo,-to-be-used-on-dark-backgrounds-only-when-the-brand-mkhost-is-well-established-within-the-page"
                            )}
                          </p>
                          <StyledImage src="/brand/icon-white.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/icon-white.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-white@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-white@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-white.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledBlackContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledContainer>
                          <span className="title">
                            {translate("black-logo")}
                          </span>
                          <p className="content">
                            {translate(
                              "the-black-version-of-our-logo.-use-this-logo-when-unable-to-print-in-color"
                            )}
                          </p>
                          <StyledImage src="/brand/black.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/black.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/black@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/black@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/black.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledContainer>

                        <StyledContainer>
                          <span className="title">
                            {translate("black-icon-logo")}
                          </span>
                          <p className="content">
                            {translate(
                              "our-black-icon-logo,-to-be-used-when-unable-to-print-in-color,-only-when-the-brand-mkhost-is-well-established-within-the-page"
                            )}
                          </p>
                          <StyledImage src="/brand/icon-black.png" />
                          <ButtonGroup size="s">
                            <a href="/brand/icon-black.png" download>
                              <SecondaryButton size="s">
                                {translate("small")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-black@2x.png" download>
                              <SecondaryButton size="s">
                                {translate("medium")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-black@3x.png" download>
                              <SecondaryButton size="s">
                                {translate("large")}
                              </SecondaryButton>
                            </a>
                            <a href="/brand/icon-black.svg" download>
                              <SecondaryButton size="s">.svg</SecondaryButton>
                            </a>
                          </ButtonGroup>
                        </StyledContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledBrandWrapper>

            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <SectionTitle>{translate("our-colors")}</SectionTitle>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledContainer>
                          <span className="title">blue-base</span>
                          <p className="content">
                            <strong>HEX 1B75BC</strong>
                            <strong>R27 G117 B188</strong>
                            <strong>C63 M28 Y0 K26</strong>
                          </p>
                          <StyledColorBlock backgroundColor="blueBase" />
                        </StyledContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledContainer>
                          <span className="title">neutral-base</span>
                          <p className="content">
                            <strong>HEX 263857</strong>
                            <strong>R38 G56 B87</strong>
                            <strong>C19 M12 Y0 K66</strong>
                          </p>
                          <StyledColorBlock backgroundColor="neutralBase" />
                        </StyledContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-4">
                        <StyledContainer>
                          <span className="title">white</span>
                          <p className="content">
                            <strong>HEX FFFFFF</strong>
                            <strong>R255 G255 B255</strong>
                            <strong>C0 M0 Y0 K0</strong>
                          </p>
                          <StyledColorBlock backgroundColor="white" />
                        </StyledContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <StyledContent className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <SectionTitle>{translate("our-name")}</SectionTitle>
                    <SectionDescription>
                      {translate("mk-is-always-written-with-uppercase-letters")}
                      <br />
                      {translate("while-host-is-always-written-in-lowercase")}
                    </SectionDescription>
                    <div className="row">
                      <StyledNameBlockWrapper
                        isMobile={isMobileOnly}
                        className="col-12 col-sm-12 col-md-4"
                      >
                        <StyledNameBlock isMobile={isMobileOnly}>
                          MKhost
                          <StyledSuccess
                            icon="success"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                        <StyledNameBlock isMobile={isMobileOnly}>
                          МКхост
                          <StyledSuccess
                            icon="success"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                      </StyledNameBlockWrapper>
                      <StyledNameBlockWrapper
                        isMobile={isMobileOnly}
                        className="col-12 col-sm-12 col-md-4"
                      >
                        <StyledNameBlock isMobile={isMobileOnly}>
                          MKHost
                          <StyledDanger
                            icon="clear"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                        <StyledNameBlock isMobile={isMobileOnly}>
                          МКХост
                          <StyledDanger
                            icon="clear"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                      </StyledNameBlockWrapper>
                      <StyledNameBlockWrapper
                        isMobile={isMobileOnly}
                        className="col-12 col-sm-12 col-md-4"
                      >
                        <StyledNameBlock isMobile={isMobileOnly}>
                          MkHost
                          <StyledDanger
                            icon="clear"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                        <StyledNameBlock isMobile={isMobileOnly}>
                          МкХост
                          <StyledDanger
                            icon="clear"
                            className="float-right"
                            size="xl"
                          />
                        </StyledNameBlock>
                      </StyledNameBlockWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </StyledContent>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherBrandPage;
