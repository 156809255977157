import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import KnowledgeBaseCategories from "../../KnowledgeBaseCategories";
import MostPopularArticles from "../../MostPopularArticles";

import withLoading from "../../Loading/WithLoading";
import ContentLoader from "../../Loading/ContentLoader";

const KnowledgeBaseCategoriesWithLoading = withLoading(
  KnowledgeBaseCategories,
  ContentLoader
);
const MostPopularArticlesWithLoading = withLoading(
  MostPopularArticles,
  ContentLoader
);
const StyledKnowledgeBaseSection = styled.div`
  padding: 100px 0;
`;

const StyledLoader = styled.div`
  width: 100%;
  height: 400px;
  display: block;
  background-color: red;
`;
const KnowledgeBaseSection = ({
  categories,
  mostPopularArticles,
  title,
  description,
  isLoading,
  voteArticle,
  isAuthenticated = false
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledKnowledgeBaseSection className="row" isMobile={isMobileOnly}>
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4">
                    <KnowledgeBaseCategoriesWithLoading
                      isLoading={isLoading}
                      title={translate("categories")}
                      categories={categories}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-8">
                    <MostPopularArticlesWithLoading
                      isLoading={isLoading}
                      title={title}
                      description={description}
                      articles={mostPopularArticles}
                      voteArticle={voteArticle}
                      isAuthenticated={isAuthenticated}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledKnowledgeBaseSection>
      )}
    </Translate>
  );
};

export default KnowledgeBaseSection;
