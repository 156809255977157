import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const StyledMarketShareSection = styled.div`
  padding: ${props => (props.isMobile ? "20px 0" : "138px 0")};
  margin: ${props => (props.isMobile ? "10px 0" : "150px 0")};
  background-image: url("/assets/images/image_map.png");
  background-position: center center;
  background-position-y: ${props => props.isMobile && "220px"};
  background-repeat: no-repeat;
  background-size: contain;
`;

const StyledTitle = styled.h3`
  margin: 0;
  display: block;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  margin-bottom: 50px;
  color: ${props => props.theme.neutralBase};
`;

const StyledStat = styled.div`
  display: block;
  width: 190px;
  margin-bottom: ${props => props.isMobile && "30px !important"};
`;

const StyledNumber = styled.span`
  display: block;
  font-size: 34px;
  font-weight: 600;
  text-align: center;
  line-height: 1.29;
  margin-bottom: 5px;
  color: ${props => props.theme.greenBase};
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 18px;
  line-height: 1.56;
  text-align: center;
  color: ${props => props.theme.neutral6};
`;

const MarketShareSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledMarketShareSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 offset-md-3">
                  <StyledTitle>
                    {translate("sections.market_share.title")}
                  </StyledTitle>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8 offset-md-2">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledStat
                        isMobile={isMobileOnly}
                        className={
                          isMobileOnly
                            ? "text-center m-auto"
                            : "text-left float-left"
                        }
                      >
                        <StyledNumber>
                          {translate("sections.market_share.stat1.number")}
                        </StyledNumber>
                        <StyledDescription>
                          {translate("sections.market_share.stat1.text")}
                        </StyledDescription>
                      </StyledStat>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledStat
                        isMobile={isMobileOnly}
                        className={
                          isMobileOnly
                            ? "text-center m-auto"
                            : "text-center m-auto"
                        }
                      >
                        <StyledNumber>
                          {translate("sections.market_share.stat2.number")}
                        </StyledNumber>
                        <StyledDescription>
                          {translate("sections.market_share.stat2.text")}
                        </StyledDescription>
                      </StyledStat>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <StyledStat
                        isMobile={isMobileOnly}
                        className={
                          isMobileOnly
                            ? "text-center m-auto"
                            : "text-center float-right"
                        }
                      >
                        <StyledNumber>
                          {translate("sections.market_share.stat3.number")}
                        </StyledNumber>
                        {translate("sections.market_share.stat3.text")}
                      </StyledStat>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledMarketShareSection>
    )}
  </Translate>
);
export default MarketShareSection;
