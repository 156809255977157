import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";

const StyledLearnMoreSection = styled.div`
  padding: ${props => (props.isMobile ? "50px 0" : "100px 0")};
  background-color: ${props => props.theme.blue2};
`;

const LearnMoreSection = ({ title, children }) => (
  <StyledLearnMoreSection className="row" isMobile={isMobileOnly}>
    <div className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              <SectionTitle>{title}</SectionTitle>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="container">{children}</div>
      </div>
    </div>
  </StyledLearnMoreSection>
);
export default LearnMoreSection;
