import React from "react";
import styled from "styled-components";
import Icon from "../Icon";

const StyledAccordion = styled.div`
  border-top: 1px solid
    ${props => (props.languageMenu ? props.theme.neutral3 : "transparent")};
  border-bottom: 1px solid
    ${props => (props.languageMenu ? props.theme.neutral3 : "transparent")};
  background-color: ${props => props.open && props.theme.blue1};
`;

const StyledTitle = styled.span`
  display: block;
  padding: 15px;
  font-size: ${props => (props.languageMenu ? "14px" : "16px")};
  font-weight: 600;
  color: ${props =>
    props.languageMenu ? props.theme.neutral6 : props.theme.blueBase};
  text-transform: ${props => props.languageMenu && "uppercase"};
  background-color: ${props => props.open && props.theme.blue1};
`;

const StyledLanguage = styled.span`
  position: relative;
  top: -3px;
  float: right;
  font-size: 18px;
  text-transform: uppercase;
  color: ${props => props.theme.blueBase};
`;

const StyledSubmenu = styled.div`
  background-color: ${props => props.theme.blue1};
`;

const StyledContent = styled.div`
  padding: 0 30px;
`;

class MobileMenuAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open || false
    };
  }

  onToggle = e => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    return (
      <StyledAccordion
        className={this.props.className}
        languageMenu={this.props.languageMenu}
        open={this.state.open}
        onClick={e => this.onToggle(e)}
      >
        <StyledTitle languageMenu={this.props.languageMenu}>
          {this.props.title}{" "}
          {this.props.languageMenu ? (
            <StyledLanguage>{this.props.language}</StyledLanguage>
          ) : (
            <span className="float-right">
              {this.state.open ? (
                <Icon icon="chevron_up" />
              ) : (
                <Icon icon="chevron_down" />
              )}
            </span>
          )}
        </StyledTitle>
        {this.state.open && (
          <StyledSubmenu className="row">
            <StyledContent className="col-12 col-sm-12 col-md-12">
              {this.props.children}
            </StyledContent>
          </StyledSubmenu>
        )}
      </StyledAccordion>
    );
  }
}

export default MobileMenuAccordion;
