import axios from "axios";

import {
  HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT,
  HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT,
  HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT,
  HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT,
  HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT, HELPDESK_KNOWLEDGE_BASE_CATEGORY_API_ENDPOINT
} from "./endpoints";
import { getCartId } from "./cartService";

export function fetchMostPopularArticles(faq_category = "") {
  return axios.get(HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT, {
    params: {
      faq_category: faq_category
    }
  });
}

export function fetchTicketDepartments() {
  return axios.get(HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT);
}

export function createTicket(ticket) {
  const normalizedTicket = {
    name: ticket.name,
    email: ticket.email,
    subject: ticket.subject,
    message: ticket.message,
    support_department_id: ticket.department.value,
    support_department_name: ticket.department.label,
    attachments: ticket.attachments ? ticket.attachments : [],
    priority: ticket.priority.value
  };

  let formData = new FormData();
  Object.keys(normalizedTicket).forEach(key => {
    formData.append(key, normalizedTicket[key]);
  });

  formData.append("attachments", normalizedTicket.attachments);

  return axios.post(HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
}

export function fetchRequestHelpDeskKnowledgeBaseCategories() {
  return axios.get(HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT);
}

export function fetchRequestHelpDeskKnowledgeBaseCategoryId(id) {
  return axios.get(HELPDESK_KNOWLEDGE_BASE_CATEGORY_API_ENDPOINT +  id);
}
export function fetchKnowledgeBaseArticle(id) {
  return axios.get(`${HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT}${id}`);
}

export function fetchKnowledgeBaseArticlesByKeyword(keyword, limit = 10) {
  return axios.get(
    `${HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT}${keyword}/by-keyword?limit=${limit}`
  );
}

export function voteArticle(article) {
  return axios.post(
    `${HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT}${article}/vote`
  );
}
