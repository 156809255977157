import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import BulletPoint from "../BulletPoint";
import Icon from "../Icon";
var parse = require('html-react-parser');


const StyledPerk = styled.div`
  padding-left: ${props => (props.isMobile ? "0" : "110px")};
  padding-left: ${props => (props.hideIcon ? "0" : "110px")};

  padding-left: ${props => {
    if (props.isMobile && !props.hideIcon) return "0";
    if (!props.isMobile && props.hideIcon) return "0";
    if (!props.isMobile && !props.hideIcon) return "110px";
  }};

  text-align: left;
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const StyledIconWrapper = styled.div`
  display: ${props => (props.isMobile ? "block" : "inline-block")};
  border-radius: 50%;
  width: ${props => (props.isMobile ? "60px" : "80px")};
  height: ${props => (props.isMobile ? "60px" : "80px")};
  text-align: center;
  position: ${props => !props.isMobile && "absolute"};
  margin-left: ${props => !props.isMobile && "-100px"};
  margin-bottom: ${props => props.isMobile && "20px"};

  color: ${props => props.theme[props.color]};
  background-color: ${props => props.theme[props.backgroundColor]};

  & .icon {
    top: ${props => (props.isMobile ? "17px" : "27px")};
  }
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledSubtitle = styled.h4`
  margin: 0;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.56;
  text-transform: uppercase;
  color: ${props => props.theme.neutral4};
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  margin-bottom: 20px;
`;

const Perk = ({
  title,
  subtitle,
  description,
  icon,
  color,
  backgroundColor,
  items,
  hideIcon,
  blueIcon
}) => (
  <StyledPerk className="row" hideIcon={hideIcon} isMobile={isMobileOnly}>
    {isMobileOnly &&
      !hideIcon && (
        <div className="col-12 col-sm-12 col-md-12">
          <StyledIconWrapper
            color={color}
            backgroundColor={backgroundColor}
            isMobile={isMobileOnly}
          >
            <Icon icon={icon} size="xl" />
          </StyledIconWrapper>
        </div>
      )}
    <div className="col-12 col-sm-12 col-md-12">
      {!hideIcon &&
        !isMobileOnly && (
          <StyledIconWrapper
            color={color}
            backgroundColor={backgroundColor}
            isMobile={isMobileOnly}
          >
            <Icon icon={icon} size="xl" />
          </StyledIconWrapper>
        )}
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
          <StyledTitle>{title}</StyledTitle>
          <StyledDescription>
            {typeof description === "function" ? description() : description}
          </StyledDescription>
        </div>
      </div>
      {items &&
        items.length > 0 && (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {items.map((item, index) => (
                <BulletPoint
                  color={color}
                  blueIcon={blueIcon}
                  key={index + Math.random()}
                >
                    {parse(item)}
                </BulletPoint>
              ))}
            </div>
          </div>
        )}
    </div>
  </StyledPerk>
);
export default Perk;
