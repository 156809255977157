import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";

import Icon from "../../Icon";

const StyledFreeRegistrationFeaturesSection = styled.div`
  padding: ${props => (props.isMobile ? "50px 0" : "150px 0")};
  padding-bottom: ${props => props.isMobile && "50px"};
`;

const StyledTop = styled.div`
  margin-bottom: 50px;
  @media (min-width: 425px) and (max-width: 823px) and (orientation: landscape) {
    margin-bottom: 100px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    margin-bottom: 50px;
  }
`;
const StyledBottom = styled.div``;

const StyledIconWrapper = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  margin-left: -100px;
  & .icon {
    top: 27px;
  }

  @media (min-width: 425px) and (max-width: 823px) and (orientation: landscape) {
    margin-left: 15px;
    top: -90px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    margin-left: -100px;
    top: auto;
  }
`;

const StyledNameserverManagementIcon = styled(StyledIconWrapper)`
  background-color: ${props => props.theme.blue1};
  color: ${props => props.theme.blueBase};
`;

const Styled247SupportIcon = styled(StyledIconWrapper)`
  background-color: ${props => props.theme.green1};
  color: ${props => props.theme.greenBase};
`;

const StyledControlPanelIcon = styled(StyledIconWrapper)`
  background-color: ${props => props.theme.yellow1};
  color: ${props => props.theme.yellowBase};
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

const StyledFeature = styled.div`
  padding-left: 110px;
  text-align: left;
  margin-bottom: ${props => props.isMobile && "40px"};

  @media (min-width: 425px) and (max-width: 823px) and (orientation: landscape) {
    padding-left: 0px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {
    padding-left: 110px;
  }
`;

const FreeRegistrationFeaturesSection = props => (
  <Translate>
    {({ translate }) => (
      <StyledFreeRegistrationFeaturesSection
        className="row free-registration-features-section"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <StyledTop className="row">
                <div className="col-12 col-sm-5 col-md-5">
                  <SectionTitle>{props.title}</SectionTitle>
                </div>
              </StyledTop>
              <StyledBottom className="row">
                <StyledFeature
                  className="col-12 col-sm-4 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <StyledNameserverManagementIcon>
                    <Icon icon="domains" size="xl" />
                  </StyledNameserverManagementIcon>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledTitle>
                        {translate(
                          "sections.free_registration_features.nameserver-management.title"
                        )}
                      </StyledTitle>
                      <StyledDescription>
                        {parse(
                          translate(
                            "sections.free_registration_features.nameserver-management.description"
                          )
                        )}
                      </StyledDescription>
                    </div>
                  </div>
                </StyledFeature>
                <StyledFeature
                  className="col-12 col-sm-4 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <Styled247SupportIcon>
                    <Icon icon="help_desk" size="xl" />
                  </Styled247SupportIcon>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledTitle>
                        {translate(
                          "sections.free_registration_features.247-support.title"
                        )}
                      </StyledTitle>
                      <StyledDescription>
                        {parse(
                          translate(
                            "sections.free_registration_features.247-support.description"
                          )
                        )}
                      </StyledDescription>
                    </div>
                  </div>
                </StyledFeature>
                <StyledFeature
                  className="col-12 col-sm-4 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <StyledControlPanelIcon>
                    <Icon icon="cpanel" size="xl" />
                  </StyledControlPanelIcon>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledTitle>
                        {translate(
                          "sections.free_registration_features.control-panel.title"
                        )}
                      </StyledTitle>
                      <StyledDescription>
                        {translate(
                          "sections.free_registration_features.control-panel.description"
                        )}
                      </StyledDescription>
                    </div>
                  </div>
                </StyledFeature>
              </StyledBottom>
            </div>
          </div>
        </div>
      </StyledFreeRegistrationFeaturesSection>
    )}
  </Translate>
);

export default FreeRegistrationFeaturesSection;
