import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import Container from "../../Containers/Container";

const StyledTechnologyAwsWeUseSection = styled.div`
  padding: ${props => (props.isMobile ? "40px 0 20px 0" : "75px 0")};
`;

const StypedTop = styled.div`
  margin-bottom: 50px;
`;

const Feature = styled.div`
  margin-bottom: 30px;
`;

const TechnologyBlock = styled(Container)`
  padding: 20px 18px;
  padding-left: 100px;
  position: relative;
  min-height: 150px;
  color: ${props => props.theme.white};
  & .title {
    color: ${props => props.theme.white};
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
  }

  & description {
    color: ${props => props.theme.white};
    display: block;
    font-size: 14px;
    line-height: 1.43;
  }
`;

const DarkBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blue5};
`;

const MediumBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blueBase};
`;

const LightBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blue4};
`;

const StyledImage = styled.img`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
`;

const TechnologyAwsWeUseSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledTechnologyAwsWeUseSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("the-process-of-cloud-implementation")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "our-approach-with-the-imolementation-of-cloud-solution"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <div className="row">
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <DarkBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles/bussiness-proposal.png" />
                        <span className="title">Bussiness-proposal</span>
                        <span className="description">
                          {translate(
                            "with-the-help-of-our-expret-team"
                          )}
                        </span>
                      </DarkBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <DarkBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles/full-infrastructure-analysis.png" />
                        <span className="title">Full infrastructure analysis</span>
                        <span className="description">
                          {translate(
                            "a-detailed-analysis-of-your-current-infrastructure"
                          )}
                        </span>
                      </DarkBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <MediumBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles/white-monitor.png" />
                        <span className="title">Implementation phase</span>
                        <span className="description">
                          {translate(
                            "whole-process-in-accordance-with-all-of-the-requirements-and-plans"
                          )}
                        </span>
                      </MediumBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <MediumBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles/profesional_support.png" />
                        <span className="title">Professional support</span>
                        <span className="description">
                          {translate(
                            "we-guarante-you-will-constantly-have-person"
                          )}
                        </span>
                      </MediumBlueBlock>
                    </Feature>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledTechnologyAwsWeUseSection>
    )}
  </Translate>
);
export default TechnologyAwsWeUseSection;
