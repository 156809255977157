import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Modal from "../../../../components/Modal";
import {
  ROUTE_EMAIL_TUTORIALS,
  ROUTE_EMAIL_TUTORIALS_ERROR,
  ROUTE_EMAIL_TUTORIALS_MACOS_IMAP,
  ROUTE_EMAIL_TUTORIALS_MACOS_POP
} from "../../../../routes";

import * as emailValidatorService from "../../../../services/emailValidatorService";

import { validateEmail } from "../../../../common/validationRules";
import tutorialImagesMap from "../../../../utils/tutorialImagesMap";

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-macos.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

const StyledInput = styled.input`
  width: 60%;
  border-radius: 3px;
  border: 1px solid gray;
  line-height: 30px;
  margin-left: 10px;
`;

const StyledButton = styled.button`
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  margin-bottom: 30px;
`;

const StyledParentImage = styled.div`
  &:hover {
    cursor: pointer;
  }

  margin-bottom: 30px;
`;

const StyledImage = styled.img`
  width: 120px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const validate = values => {
  return {
    email: validateEmail(values.email, true)
  };
};

const MAX_STEPS = 4;

class MacOsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSteps: false,
      currentStep: 0,
      email: null,
      images: null,
      showDomainModal: null
    };
  }

  onNextStep = () => {
    if (this.state.currentStep + 1 <= MAX_STEPS) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  onPrevStep = () => {
    if (this.state.currentStep - 1 >= 1) {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };

  onSetStep = step => {
    this.setState({
      currentStep: step
    });
  };

  onSubmit = values => {
    return emailValidatorService
      .checkDomainTutorials(values.email)
      .then(response => {
        if (response.data.data.ours === true) {
          this.setState({
            showSteps: true,
            currentStep: 1,
            email: values.email,
            images: tutorialImagesMap(response.data.data.images.mac)
          });
        } else {
          this.props.history.push(ROUTE_EMAIL_TUTORIALS_ERROR);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  onShowModal = product => {
    this.setState({
      showDomainModal: true
    });
  };

  onCloseModal = () => {
    this.setState({
      showDomainModal: false
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div>
            <StyledHeader className="row py-5 ">
              <div className="col-lg-8 offset-lg-2 col-md-6 offset-md-2 col-8">
                <h2>
                  <strong>
                    {translate("pages.email-tutorial.email-setup")}
                  </strong>
                </h2>
                {this.state.email && <h3>{this.state.email}</h3>}
              </div>
            </StyledHeader>

            <div className="row mt-3">
              <div className="col-md-12 text-center">
                <h2>
                  <strong>{translate("pages.email-tutorial.macos")}</strong>
                </h2>
                <p>{translate("pages.email-tutorial.choose-protocol")}</p>
              </div>
            </div>

            <div className="row my-4">
              <div className="col-md-4 offset-md-2 my-2">
                <Link to={ROUTE_EMAIL_TUTORIALS_MACOS_POP}>
                  <StyledParentImage className="card">
                    <div className="card-body text-center">
                      <StyledImage
                        className="img-fluid"
                        src="/assets/images/email-setup/pop.png"
                      />
                    </div>
                    <div className="card-footer text-center">POP</div>
                  </StyledParentImage>
                </Link>
              </div>

              <div className="col-md-4 my-2">
                <Link to={ROUTE_EMAIL_TUTORIALS_MACOS_IMAP}>
                  <StyledParentImage className="card">
                    <div className="card-body text-center">
                      <StyledImage
                        className="img-fluid"
                        src="/assets/images/email-setup/imap.png"
                      />
                    </div>
                    <div className="card-footer text-center">IMAP</div>
                  </StyledParentImage>
                </Link>
              </div>

              <div className="col-md-8 offset-md-2">
                <Link to={ROUTE_EMAIL_TUTORIALS}>
                  <PrimaryButton size="m">
                    {translate("pages.email-tutorial.go-back")}
                  </PrimaryButton>
                </Link>

                <PrimaryButton
                  size="m"
                  className="btn primary-button float-right"
                  onClick={this.onShowModal}
                >
                  {translate("pages.email-tutorial.imap-or-pop")}
                </PrimaryButton>
              </div>
            </div>
            {this.state.showDomainModal && (
              <Modal
                title={translate("pages.email-tutorial.imap-or-pop")}
                onCloseModal={this.onCloseModal}
                body={() => (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <p>
                        {translate(
                          "pages.email-tutorial.before-you-set-up-email"
                        )}
                        .
                      </p>
                      <h2>IMAP</h2>
                      <p>
                        {translate("pages.email-tutorial.if-you-choose-imap")}.
                      </p>
                      <h2>POP</h2>
                      <p>
                        {translate("pages.email-tutorial.if-you-choose-pop")}.
                      </p>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12">
                      <SecondaryButton
                        className="mt-4 float-right"
                        onClick={this.onCloseModal}
                      >
                        {translate("pages.email-tutorial.close")}
                      </SecondaryButton>
                    </div>
                  </div>
                )}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

export default MacOsPage;
