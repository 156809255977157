import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import parse from "html-react-parser";
import { isMobileOnly } from "react-device-detect";
import Slider from "react-slick";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

const StyledTestimonialsSection = styled.div`
  padding-top: ${props => (props.isMobile ? "10px" : "75px")};
  padding-bottom: ${props => (props.isMobile ? "10px" : "75px")};
  margin: ${props => (props.isMobile ? "50px 0" : "75px 0")};
`;

const StyledTestimonial = styled.div`
  padding: 40px 30px;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 3px;
  -webkit-transition: all 1s linear;
`;
const StyledContent = styled.div`
  font-size: 16px;
  line-height: 1.64;
  color: ${props => props.theme.neutral6};
  margin-bottom: 5px;
`;
const StyledPerson = styled.div`
  position: relative;
  padding-left: 70px;
`;
const StyledName = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.neutral6};
`;
const StyledLink = styled.a`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.blueBase};
  &:hover {
    text-decoration: none;
  }
`;
const StyledAvatar = styled.img`
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 30px;
`;

const StyledBulletPoint = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props =>
    props.active ? props.theme.neutral4 : props.theme.neutral3};
  margin-left: 7.5px;
  margin-right: 7.5px;
  cursor: pointer;
`;

const StyledSliderWrapper = styled.div`
  position: relative;

  & .slick-dots {
    position: relative;
    bottom: -5px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  & .slick-dots li,
  & .slick-dots li button {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  & .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    padding: 0;
  }

  & .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }

  & .slick-dots li.slick-active button:before {
    // opacity: 0.75;
    color: ${props => props.theme.neutral4};
  }

  & .slick-dots li button:before {
    font-family: slick;
    font-size: 25px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "\\2022";
    text-align: center;
    // opacity: 0.25;
    color: ${props => props.theme.neutral3};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;


const TESTIMONIALS_COUNT = 7;

class TestimonialsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1
    };
  }

  onSelectTestimonial = id => {
    this.setState({
      active: id
    });
  };

  onSwipeLeft = () => {
    if (this.state.active + 1 <= TESTIMONIALS_COUNT) {
      this.setState({
        active: this.state.active + 1
      });
    }
  };

  onSwipeRight = () => {
    if (this.state.active - 1 >= 1) {
      this.setState({
        active: this.state.active - 1
      });
    }
  };

  generateTestimonials() {
    let testimonials = [];
    for (let i = 1; i <= TESTIMONIALS_COUNT; i++) {
      testimonials = testimonials.concat(i);
    }

    return testimonials;
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledTestimonialsSection className="row" isMobile={isMobileOnly}>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-5">
                      <SectionTitle>
                        {translate("sections.testimonials_section.title")}
                      </SectionTitle>
                      <SectionDescription>
                        {translate("sections.testimonials_section.description")}
                      </SectionDescription>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 offset-md-1">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledSliderWrapper>
                            <Slider
                              infinite={true}
                              slidesToShow={1}
                              slidesToScroll={1}
                              speed={500}
                              initialSlide={0}
                              autoplay={true}
                              autoPlaySpeed={2000}
                              arrows={false}
                              swipe={true}
                              dots={true}
                            >
                              {this.generateTestimonials(translate).map(
                                index => (
                                  <StyledTestimonial
                                    key={`testimonials=${index}`}
                                  >
                                    <StyledContent>
                                      {parse(
                                        translate(
                                          "sections.testimonials_section.testimonials.t" +
                                            index +
                                            ".content"
                                        )
                                      )}
                                    </StyledContent>
                                    <StyledPerson>
                                      <StyledAvatar
                                        src={
                                          "/assets/testimonials/" +
                                          translate(
                                            "sections.testimonials_section.testimonials.t" +
                                              index +
                                              ".avatar"
                                          )
                                        }
                                      />
                                      <StyledName>
                                        {translate(
                                          "sections.testimonials_section.testimonials.t" +
                                            index +
                                            ".person"
                                        )}
                                      </StyledName>
                                      <StyledLink>
                                        {translate(
                                          "sections.testimonials_section.testimonials.t" +
                                            index +
                                            ".url"
                                        )}
                                      </StyledLink>
                                    </StyledPerson>
                                  </StyledTestimonial>
                                )
                              )}
                            </Slider>
                          </StyledSliderWrapper>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledTestimonialsSection>
        )}
      </Translate>
    );
  }
}

export default TestimonialsSection;
