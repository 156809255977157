import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import parse from "html-react-parser";

import { SOCIAL_LINK_FACEBOOK } from "../../../config/social";
import { ROUTE_OTHER_BLOG } from "../../../routes";

import questionBlue from "./question-blue.svg";
import questionGreen from "./question-green.svg";
import check_success from "../../../assets/images/check_success.svg";

const StyledMoreAboutTLDsSection = styled.div`
  padding: 75px 0;
`;

const StyledSectionDescription = styled(SectionDescription)`
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledIconWrapper = styled.div`
  display: block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  margin-bottom: 20px;
  & img {
    position: relative;
    top: 20px;
  }
`;

const StyledGreenIcon = styled(StyledIconWrapper)`
  background-color: ${props => props.theme.green1};
  color: ${props => props.theme.greenBase};
`;

const StyledBlueIcon = styled(StyledIconWrapper)`
  background-color: ${props => props.theme.blue2};
  color: ${props => props.theme.blueBase};
`;

const StyledTitle = styled.span`
  font-size: 18px;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 20px;
  font-weight: 600;
  display: block;
`;

const StyledDescription = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
`;

const StyledPaddedWrapper = styled.div`
  padding-left: ${props => !props.isMobile && "110px"};
  margin-top: ${props => props.isMobile && "50px"};
`;

const MoreAboutTLDsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledMoreAboutTLDsSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>{translate("tld")}</SectionTitle>
                  <StyledSectionDescription>
                    {translate("the-new-tlds-were")}{" "}
                    {parse(translate("introduced-in-2013"))}{" "}
                    {translate(
                      "by-icann-(the-internet-corporation-for-assigned-names-and-numbers),-as-a-result-from-a-global-demand-from-the-internet-community"
                    )}
                  </StyledSectionDescription>
                  <StyledSectionDescription>
                    {translate("as-of-2017-there-are")}{" "}
                    {parse(translate("over-500-ntlds-available"))}{" "}
                    {translate(
                      "for-registration.-it-is-expected-that-this-number-will-grow-to-over-1,000-in-the-next-few-years"
                    )}
                  </StyledSectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledPaddedWrapper isMobile={isMobileOnly}>
                    <StyledGreenIcon>
                      <img src={questionGreen} width="40px" alt="" />
                    </StyledGreenIcon>
                    <StyledTitle>
                      {translate("why-should-i-or-my-business-register-a-ntld")}
                    </StyledTitle>
                    <StyledDescription>
                      {translate(
                        "there-are-a-number-of-benefits-when-registering-a-ntld.-some-of-them-are"
                      )}
                    </StyledDescription>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate(
                        "improved-chanses-to-find-the-perfect-domain-name"
                      )}
                    </StyledBulletPoint>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate(
                        "make-yourself-or-your-business-stand-out-from-the-generic-tlds"
                      )}
                    </StyledBulletPoint>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate(
                        "make-your-domain-more-memorable-to-your-customers"
                      )}
                    </StyledBulletPoint>
                  </StyledPaddedWrapper>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledPaddedWrapper isMobile={isMobileOnly}>
                    <StyledBlueIcon>
                      <img src={questionBlue} width="40px" alt="" />
                    </StyledBlueIcon>
                    <StyledTitle>
                      {translate(
                        "will-there-be-additional-ntlds-available-for-registration"
                      )}
                    </StyledTitle>
                    <StyledDescription>
                      {translate(
                        "mkhost-will-continue-to-expand-the-selection-of-ntld-offered.-we-aim-to-offer-up-to-20-ntlds-by-the-end-of-the-year"
                      )}
                    </StyledDescription>
                    <StyledDescription>
                      {translate("follow-our")}{" "}
                      <a
                        href={ROUTE_OTHER_BLOG}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {parse(translate("blog"))}
                      </a>{" "}
                      {translate("and")}{" "}
                      <a
                        href={SOCIAL_LINK_FACEBOOK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {parse(translate("facebook"))}
                      </a>{" "}
                      {translate(
                        "page-for-updates-on-when-additional-ntlds-will-be-available"
                      )}
                    </StyledDescription>
                  </StyledPaddedWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledMoreAboutTLDsSection>
    )}
  </Translate>
);
export default MoreAboutTLDsSection;
