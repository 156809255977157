import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import {isMobileOnly} from "react-device-detect";
import CircledIcon from "../CircledIcon";
import SmallTitle from "../SmallTitle";
import SmallDescription from "../SmallDescription";
import {Translate} from "react-localize-redux";


const StyledFeature = styled.div`
  padding-left: 110px;
  text-align: left;
  margin-bottom: 40px;
`;

const StyledIconWrapper = styled.div`
  display: inline-block;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  margin-left: -100px;
  & .icon {
    top: 27px;
  }
`;


class IconTextInfo extends React.Component {

  render() {
    const { ...rest } = this.props;
    return (
        <Translate>
            {({ translate }) => (
          <StyledFeature
              isMobile={isMobileOnly}
              className="col-12 col-sm-12 col-md-4"
          >
              <div> {rest.test}</div>
              <StyledIconWrapper>
                  <CircledIcon
                      icon={rest.icon}
                      color={rest.iconColor}
                      backgroundColor={rest.iconBgColor}
                  />
              </StyledIconWrapper>
              <SmallTitle>{translate(rest.title)}</SmallTitle>
              <SmallDescription>
                  {translate(
                      rest.description
                  )}
              </SmallDescription>
          </StyledFeature>
            )}
        </Translate>
    );
  }
}

export default IconTextInfo;
