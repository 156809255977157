
import React, {Component} from 'react'
import styled from "styled-components";
import {Translate} from "react-localize-redux";
import {isBrowser, isMobileOnly, isTablet} from "react-device-detect";

const PromotionBannerContent = styled.div`
  
  background-color: #1B75BC;
  
  color: white;  
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;

  margin-top: ${(isMobileOnly || isTablet) ? "-55px" : "0px"};
  margin-bottom: ${(isMobileOnly || isTablet) ? "30px" : "0px"};

  & .row {
    margin-left: 0px;
    margin-right: 0px;
  }

`;

const BannerNewYearLeft = styled.div`
 display:block;
 margin:0 auto;
 width:70%;
  
`;
const PromotionBannerLeftOne = styled.div`
  font-size: 23px;
  text-align:left;
  font-weigh:bold;  
`;

const UnderBannerContent = styled.span`
color : #263857;
font-size : 25px;
padding:10px 31px;
display:block;
`;

const UnderBannerDescription = styled.span`
color: #263857;
padding: 0 31px;
display:block;
`;

const BannerNewYearRight = styled.div`
  text-align: center;
`;


const BannerNewYearCenter = styled.div`

  & span {
    display: block;
    font-size: 22px;
    text-align:center;
  }

  & div {
    padding: 15px;
  }

`;
const BannerNewYearCenterRight = styled.div`

  & span {
    display: block;
    padding-bottom: 15px;
    border:2px;
    solid white;
    padding:19px;
    width:70%;
    text-align:center;
    font-size:22px;
    border:2px solid white;
    margin:0 auto;
    
  }

  & div {
    padding: 15px;
  }

`;

const Spacer = styled.div`
   height: 0px;
    width: 100%;
    `

const BannerNewYearCounter = styled.span`

  font-size: 3.5vw;
  color: white;
  font-weight: bold;
  
  position: absolute;
  top: 15%;
  right: 14%;

  @media (min-width: 1920px) {
    font-size: 2.6vw;
  }
 


`;


export default class BannerNewYear extends Component {
    state = {
        days: 0,
        hours: parseInt(process.env.REACT_APP_PROMOTION_BANNER_LENGTH, 10),
        minutes: 0,
        seconds: 0,
        mountBanner: false
    }

    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
        clearInterval(this.displayPromoBanner);
    }

    render() {
        return (
            <Translate>
                {({translate}) => (
                    <>

                           {/* <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                    <div className="container">
                                        <PromotionBannerContent>
                                            <div className="row align-items-center">
                                                <BannerNewYearLeft className=" col-sm-6 col-md-6 col-lg-2 col-xl-2">
                                                    <img className="center-block" style={{'width': '100%'}}
                                                         src="/assets/promotions/20-percent-off.png"/>
                                                </BannerNewYearLeft>
                                                <PromotionBannerLeftOne className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                                                    <span>{translate("pages.home.offer-off-plans-shared-hosting")}   </span>
                                                <br/>
                                                    <span>{translate("pages.home.start-new-year-with-new-online-story")}  </span>

                                                </PromotionBannerLeftOne>

                                                <BannerNewYearRight
                                                    className="d-none d-md-block col-md-2 col-lg-1 col-xl-2">
                                                    <img className="center-block lg-100" src="/brand/icon-white-banner.png"/>
                                                </BannerNewYearRight>
                                            </div>
                                        </PromotionBannerContent>
                                        <div>
                                            <UnderBannerContent>{translate("pages.home.input-new-year-off")}</UnderBannerContent>

                                            <UnderBannerDescription>{translate("pages.home.offer-is-for-fisry-year")}</UnderBannerDescription>
                                        </div>

                                    </div>
                                </div>
                            </div>*/}

                        <Spacer/>
                    </>
                )}
            </Translate>
        )
    }
}
