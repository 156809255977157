import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import Container from "../../Containers/Container";
import NoticeSuccess from "../../Notices/NoticeSuccess";
import NoticeDanger from "../../Notices/NoticeDanger";
import DiscountedAmountBadge from "../../DiscountedAmountBadge";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Icon from "../../Icon";

import { DOMAIN_STATUS_AVAILABLE } from "../../../pages/Domains/DomainStatuses";

const StyledContainer = styled(Container)`
  margin: 30px 0;
`;

const StyledWhoIsSearchResultsDomainAvailable = styled.div`
  padding: 30px;

  & .notice-success,
  & .notice-danger {
    margin-right: 15px;
  }
`;

const StyledDomain = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledPrice = styled.div`
  display: inline-block;
  float: right;
  color: ${props => props.theme.neutral6};
  font-size: 14px;
  font-weight: 600;

  & .discounted-amount-badge {
    display: inline-block;
  }
`;

const StyledAddToCartButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  top: 5px;
`;

const WhoIsSearchResultsDomainAvailable = ({ domain, search, onAddToCart }) => (
  <Translate>
    {({ translate }) => (
      <StyledContainer>
        <StyledWhoIsSearchResultsDomainAvailable className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {domain.status === DOMAIN_STATUS_AVAILABLE && (
              <NoticeSuccess>
                {translate(`general.${domain.status}`)}
              </NoticeSuccess>
            )}
            {domain.status !== DOMAIN_STATUS_AVAILABLE && (
              <NoticeDanger>
                {translate(`general.${domain.status}`)}
              </NoticeDanger>
            )}
            <StyledDomain>{domain.name}</StyledDomain>

            {domain.status == DOMAIN_STATUS_AVAILABLE && (
              <StyledAddToCartButton onClick={() => onAddToCart(domain)}>
                <Icon icon="cart" /> {translate("add-to-cart")}
              </StyledAddToCartButton>
            )}
            {domain.status == DOMAIN_STATUS_AVAILABLE && (
              <StyledPrice>
                <DiscountedAmountBadge
                  amount={{
                    amount:
                      domain.pricing.register.billing_cycle.derivations[0].price
                        .value,
                    currency:
                      domain.pricing.register.billing_cycle.derivations[0].price
                        .currency.prefix,
                    period:
                      domain.pricing.register.billing_cycle.derivations[0].name
                  }}
                />
              </StyledPrice>
            )}
          </div>
        </StyledWhoIsSearchResultsDomainAvailable>
      </StyledContainer>
    )}
  </Translate>
);

export default WhoIsSearchResultsDomainAvailable;
