import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Button from "../Button";

const PrimaryButton = styled(Button)`
  background-color: ${theme.blueBase};
  color: ${theme.white};
  border: solid 1px ${theme.blueBase};

  &:hover {
    background-color: ${theme.blue4};
    border: 1px solid ${theme.blue4};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.blue3};
  }

  &:active {
    border: 1px solid ${theme.blue5};
    background-color: ${theme.blue5};
    color: ${theme.white};
  }

  &:disabled {
    background-color: ${theme.neutral2};
    color: ${theme.white};
    border: 1px solid ${theme.neutral2};
  }
`;

PrimaryButton.defaultProps = {
  className: "btn primary-button"
};

export default PrimaryButton;
