import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { calculateDomainReducedPrice } from "../../services/pricingService";
import { withLocalize } from "react-localize-redux";
import axios from "axios";
import * as domainsService from "../../services/domainsService";

import {
  isCyrilic,
  transliterateToLatin,
  transliterateToCyrilic
} from "../../services/transliterateService";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE,
  DOMAIN_STATUS_ERROR
} from "../../pages/Domains/DomainStatuses";

import SmallLogoLoader from "../../components/Loaders/SmallLogoLoader";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import Icon from "../../components/Icon";

import BadgeDanger from "../../components/Badges/BadgeDanger";
import BadgeSuccess from "../../components/Badges/BadgeSuccess";

import StatusTextDanger from "../StatusTexts/StatusTextDanger";

import { ROUTE_DOMAINS_WHOIS, compileRoute } from "../../routes";

const StyledTitle = styled.h3`
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  & > strong {
    font-weight: 700;
  }
`;

const StyledAvailableDomain = styled.div`
  padding: 15px 30px;
  border-top: 1px solid ${theme.neutral3};

  & .reduced {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.neutral6};
    text-decoration: line-through;
  }
  & .normal {
    font-size: 20px;
    font-weight: 600;
    color: ${theme.neutralBase};
  }

  & .period {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.neutral6};
  }

  & .whois {
    display: inline-block;
    font-size: 16px;
    float: right;
    color: #1b75bc;
    position: relative;
  }
`;

const StyledAddToCartButton = styled(SecondaryButton)`
  display: inline-block;
  float: right;
  margin-right: 0;
`;

class OtherAvailableDomains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otherAvailableDomains: [],
      isFetching: true
    };
  }

  componentDidMount() {
    this.onFindOtherAvailableDomains(this.props.domain, this.props.extension);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.domain && prevProps.domain !== this.props.domain) {
      this.onFindOtherAvailableDomains(this.props.domain, this.props.extension);
    }
  }

  transliterateDomainName = (domain, ext) => {
    let domainName = domain + ext;
    let domainNameWithoutExtension = domain;
    if (ext === ".мкд") {
      if (!isCyrilic(domain)) {
        domainName = transliterateToCyrilic(domain) + ext;
        domainNameWithoutExtension = transliterateToCyrilic(domain);
      }
    } else {
      if (isCyrilic(domain)) {
        domainName = transliterateToLatin(domain) + ext;
        domainNameWithoutExtension = transliterateToLatin(domain);
      }
    }
    return {
      domainName,
      domainNameWithoutExtension
    };
  };

  onFindOtherAvailableDomains = (domain, domainExt) => {
    let delayIndex = 0;
    this.setState({
      ...this.state,
      otherAvailableDomains: []
    });
    if (this.props.extensions) {
      this.props.topLevelDomainGroups.forEach(group => {
        let domainName = domain.toLowerCase();
        this.props.domain = domainName;

        setTimeout(() => {
          this.setState({
            isFetching: true
          });
          domainsService
            .domainSuggestionsByGroup(domainName, group)
            .then(result => {
              const { tlds, sld } = result.data.data;
              const { error } = result.data;

              let currency = this.props.customerCurrencyCode
                ? this.props.customerCurrencyCode.toLowerCase()
                : null;
              if (!currency) {
                currency =
                  this.props.activeLanguage &&
                  this.props.activeLanguage.code === "mk"
                    ? "мкд"
                    : "eur";
              }

              if (!error && tlds) {
                let availableTlds = tlds
                  .filter(
                    tld =>
                      tld.availability !== DOMAIN_STATUS_ERROR &&
                      tld.tld !== domainExt
                  )
                  .map(tld => {
                    let name = sld;
                    let nameWithoutExtension = sld;
                    let ext = tld.tld;

                    if (tld.tld === ".мкд") {
                      const {
                        domainName,
                        domainNameWithoutExtension
                      } = this.transliterateDomainName(name, ext);
                      name = domainName;
                      nameWithoutExtension = domainNameWithoutExtension;
                    }

                    const pricing = this.props.topLevelDomainsListingByGroup[
                      group
                    ][ext.substring(1, ext.length)]
                      ? this.props.topLevelDomainsListingByGroup[group][
                          ext.substring(1, ext.length)
                        ][currency]
                      : null;

                    return {
                      name: nameWithoutExtension + ext,
                      nameWithoutExtension,
                      ext,
                      fetching: false,
                      availability: tld.availability,
                      // api compatibility
                      config: {
                        type: "register",
                        period: 1
                      },
                      pricing: pricing ? { ...pricing } : null
                    };
                  });

                const domainWithoutExtension = domain.split(".")[0];
                if (domainWithoutExtension.length <= 2) {
                  availableTlds = availableTlds.filter(
                    domain => domain.ext !== ".мкд"
                  );
                }

                const final = this.state.otherAvailableDomains
                  .concat(availableTlds)
                  .sort((a, b) => {
                    let ret = 0;
                    if (
                      a.availability === DOMAIN_STATUS_AVAILABLE &&
                      b.availability === DOMAIN_STATUS_UNAVAILABLE
                    ) {
                      ret = -1;
                    } else if (
                      a.availability === DOMAIN_STATUS_UNAVAILABLE &&
                      b.availability === DOMAIN_STATUS_AVAILABLE
                    ) {
                      ret = 1;
                    }
                    return ret;
                  });

                this.setState({
                  ...this.state,
                  otherAvailableDomains: [...final],
                  isFetching: false
                });
              }
            });
        }, delayIndex++ * 50);
      });
    }
  };

  render() {
    let currency = this.props.customerCurrencyCode
      ? this.props.customerCurrencyCode.toLowerCase()
      : null;
    if (!currency) {
      currency =
        this.props.activeLanguage && this.props.activeLanguage.code === "mk"
          ? "мкд"
          : "eur";
    }
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledTitle>
            {this.props.translate(
              "sections.domain_search.other-available-extensions.title"
            )}
            <strong>{this.props.domain}</strong>
          </StyledTitle>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          {!this.state.isFetching &&
            this.state.otherAvailableDomains &&
            this.state.otherAvailableDomains.length > 0 &&
            this.state.otherAvailableDomains.map(domain => {
              let pricing = null;
              let reducedPricing = null;
              if (domain.pricing) {
                if (
                  !!this.props.priceReduceExtensions.find(
                    ext => ext === domain.ext
                  )
                ) {
                  pricing =
                    domain.ext === ".gr"
                      ? parseFloat(domain.pricing["register"]["2"])
                      : parseFloat(domain.pricing["register"]["1"]);

                  reducedPricing = calculateDomainReducedPrice(
                    pricing,
                    this.props.priceReduce,
                    currency
                  );
                } else {
                  pricing =
                    domain.ext === ".gr"
                      ? parseFloat(domain.pricing["register"]["2"])
                      : parseFloat(domain.pricing["register"]["1"]);

                  reducedPricing = pricing;
                }
              }

              const isOnSale =
                this.props.priceReduceExtensions.indexOf(domain.ext) > -1;

              const isAddedToCart = this.props.addedAdditionalDomains.find(
                i => i === domain.name
              );

              return (
                <div
                  className="row"
                  key={domain.nameWithoutExtension + domain.ext}
                >
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <StyledAvailableDomain>
                          {domain.nameWithoutExtension}
                          <strong>{domain.ext}</strong>

                          {isOnSale && this.props.priceReduce.value > 0 && (
                            <>
                              <BadgeDanger>
                                {this.props.translate("sale")}
                              </BadgeDanger>
                              <BadgeSuccess>
                                {this.props.priceReduce.discountType ===
                                "percentage"
                                  ? `%${this.props.priceReduce.value}`
                                  : `- ${currency}${this.props.priceReduce.value} `}
                              </BadgeSuccess>
                            </>
                          )}

                          {domain.availability === DOMAIN_STATUS_AVAILABLE &&
                            pricing && (
                              <StyledAddToCartButton
                                size="s"
                                disabled={
                                  this.props.submitting || isAddedToCart
                                }
                                submitting={this.props.submitting}
                                onClick={() =>
                                  this.props.onAddToCart(
                                    domain,
                                    "register",
                                    domain.ext === ".gr" ? 2 : 1,
                                    true
                                  )
                                }
                              >
                                <Icon icon="cart" />{" "}
                                {!isAddedToCart
                                  ? this.props.translate("add-to-cart")
                                  : this.props.translate("added-to-cart")}
                              </StyledAddToCartButton>
                            )}

                          {domain.availability === DOMAIN_STATUS_UNAVAILABLE &&
                            (domain.ext.endsWith(".mk") ||
                              domain.ext.endsWith(".мкд")) &&
                            this.props.activeLanguage && (
                              <span className="whois">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={compileRoute(ROUTE_DOMAINS_WHOIS, {
                                    name: domain.name
                                  })}
                                >
                                  WHOIS
                                </a>
                              </span>
                            )}
                          {domain.availability === DOMAIN_STATUS_UNAVAILABLE &&
                            (!domain.ext.endsWith(".mk") &&
                              !domain.ext.endsWith(".мкд")) &&
                            this.props.activeLanguage && (
                              <span className="d-inline-block float-right">
                                <StatusTextDanger>
                                  {this.props.translate("general.unavailable")}
                                </StatusTextDanger>
                              </span>
                            )}

                          <span className="float-right mr-4">
                            {" "}
                            {domain.availability ===
                              DOMAIN_STATUS_AVAILABLE && (
                              <>
                                {pricing && (
                                  <div>
                                    {reducedPricing < pricing ? (
                                      <>
                                        <span className="reduced">
                                          <span className="currency">
                                            {currency}
                                          </span>
                                          {currency.toLowerCase() === "мкд"
                                            ? pricing.toFixed(0)
                                            : pricing.toFixed(2)}
                                        </span>{" "}
                                        <span className="normal">
                                          <span className="currency">
                                            {currency}
                                          </span>
                                          {currency.toLowerCase() === "мкд"
                                            ? reducedPricing.toFixed(0)
                                            : reducedPricing.toFixed(2)}
                                          <span className="period">
                                            /
                                            {domain.ext === ".gr"
                                              ? this.props.translate("years")
                                              : this.props.translate("year")}
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <span className="normal">
                                        <span className="currency">
                                          {currency}
                                        </span>
                                        {currency.toLowerCase() === "мкд"
                                          ? pricing.toFixed(0)
                                          : pricing.toFixed(2)}
                                        <span className="period">
                                          /
                                          {domain.ext === ".gr"
                                            ? this.props.translate("years")
                                            : this.props.translate("year")}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </span>
                        </StyledAvailableDomain>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {this.state.isFetching && (
            <div className="text-center">
              <SmallLogoLoader />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withLocalize(OtherAvailableDomains);
