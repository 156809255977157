import axios from "axios";
import Cookies from "universal-cookie";

import { ROOT_DOMAIN_NO_HTTPS, AUTH_ME_API_ENDPOINT } from "./endpoints";
const jwtTokenName = "mkhostToken";

export function getMe() {
  return axios.get(AUTH_ME_API_ENDPOINT);
}

export function getToken() {
  const cookies = new Cookies();
  try {
    let token = localStorage.getItem(jwtTokenName);
    if (!token) {
      token = cookies.get(jwtTokenName);
    }
    return token;
  } catch (e) {
    //@todo log out user if we can't read the token?
    return null;
  }
}

export function getRefreshToken() {
  try {
    const tokens = localStorage.getItem(jwtTokenName);
    return tokens.refresh_token;
  } catch (e) {
    //@todo log out user if we can't read the token?
    return null;
  }
}

export function setToken(token) {
  const cookies = new Cookies();
  try {
    localStorage.setItem(jwtTokenName, token);
    cookies.set(jwtTokenName, token, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
  setAuthorizationToken(token);
}

export function removeToken() {
  const cookies = new Cookies();
  try {
    localStorage.removeItem(jwtTokenName);
    cookies.remove(jwtTokenName, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
}

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}
