import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import SplitSection from "../SplitSection";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SecondaryButton from "../../Buttons/SecondaryButton";

import { ROUTE_WEB_DESIGN_PREMIUM } from "../../../routes";

const StyledPremiumPackageSection = styled.div`
  padding-top: ${props => (props.isMobile ? "50px" : "75px")};
  padding-bottom: 30px;
  margin-bottom: 45px;
  background-image: url("/assets/backgrounds/bg-2.svg");
  background-position: bottom;
  background-repeat: no-repeat;
`;

const GetStartedWrapper = styled.div`
  display: inline-block;
  margin-top: 24px;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.blue2};
`;

const PremiumPackageSection = props => (
  <Translate>
    {({ translate }) => (
      <StyledPremiumPackageSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <SplitSection
            left={() => (
              <React.Fragment>
                <SectionTitle>
                  {translate("looking-for-more-than-a-hosting-plan")}
                </SectionTitle>
                <SectionDescription>
                  {parse(
                    translate(
                      "set-up-your-website-in-3-easy-steps-with-our-premium-package.-web-hosting-+-domain-+-website-template-for-only-73.74-year"
                    )
                  )}
                  .
                </SectionDescription>
                <GetStartedWrapper>
                  <a
                    href={ROUTE_WEB_DESIGN_PREMIUM}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SecondaryButton size="l">
                      {translate("get-started-with-premium")}
                    </SecondaryButton>
                  </a>
                </GetStartedWrapper>
              </React.Fragment>
            )}
            right={() =>
              !isMobileOnly && (
                <img
                  className="img-fluid"
                  src="/assets/images/image_premium.png"
                />
              )
            }
          />
        </div>
      </StyledPremiumPackageSection>
    )}
  </Translate>
);

export default PremiumPackageSection;
