import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";

import Container from "../../Containers/Container";
import Perk from "../../Perk";

const StyledDirectAdminAndCpanelComparisonSection = styled.div`
  padding: ${props => (props.isMobile ? "55px 0" : "75px 0")};
`;

const StyledBlocksContainer = styled.div`
  margin-top: ${props => (props.isMobile ? "70px" : "-30px")};
`;

const StyledBlueContainer = styled(Container)`
  background-color: #1a8abe;
  border-color: #1a8abe;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledGreenContainer = styled(Container)`
  background-color: #83b563;
  border-color: #83b563;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledOrangeContainer = styled(Container)`
  background-color: #ef6a3a;
  border-color: #ef6a3a;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;
const StyledDarkBlueContainer = styled(Container)`
  background-color: #263857;
  border-color: #263857;
  margin-bottom: 30px;
  color: ${props => props.theme.white};
  padding: 40px 20px;
  padding-bottom: 20px;
`;

const StyledIcon = styled.img`
  display: block;
  width: 100px;
  height: 100px;
  margin-left: -10px;
  margin-bottom: 20px;
`;

const StyledBlueIcon = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  margin-left: -10px;
  margin-bottom: 20px;
`;

const StyledTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 10px;
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
`;

const StyledBigTitle = styled.h4`
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  line-height: 0.5;
  margin-bottom: 60px;
  margin-top: 40px;
`;

const DirectAdminAndCpanelComparisonSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledDirectAdminAndCpanelComparisonSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate("sections.directadmin_vs_cpanel.title")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate("sections.directadmin_vs_cpanel.description_1")}
                  </SectionDescription>
                  <SectionDescription>
                    {translate("sections.directadmin_vs_cpanel.description_2")}
                  </SectionDescription>
                  <SectionDescription>
                    <strong>
                      {translate(
                        "sections.directadmin_vs_cpanel.description_3"
                      )}
                    </strong>
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-6 offset-md-1">
                  <StyledBlocksContainer
                    className="row"
                    isMobile={isMobileOnly}
                  >
                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledBlueContainer>
                        <StyledIcon src="/assets/icons/app-bubbles-no-bg/bubble_icon_directadmin.svg" />
                      </StyledBlueContainer>

                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <Perk
                            hideIcon={true}
                            isMobile={isMobileOnly}
                            title={translate(
                              "sections.directadmin_vs_cpanel.direct-admin-title"
                            )}
                            description={() => (
                              <strong>
                                {translate(
                                  "sections.directadmin_vs_cpanel.direct-admin-advantages"
                                )}
                              </strong>
                            )}
                            color="greenBase"
                            backgroundColor="green1"
                            items={[
                              translate(
                                "sections.directadmin_vs_cpanel.direct-admin-advantage_1"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.direct-admin-advantage_2"
                              )
                            ]}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6">
                      <StyledOrangeContainer>
                        <StyledIcon src="/assets/icons/app-bubbles-no-bg/bubble_icon_cpanel.svg" />
                      </StyledOrangeContainer>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <Perk
                            hideIcon={true}
                            isMobile={isMobileOnly}
                            title={translate(
                              "sections.directadmin_vs_cpanel.cpanel-title"
                            )}
                            description={() => (
                              <strong>
                                {translate(
                                  "sections.directadmin_vs_cpanel.cpanel-advantages"
                                )}
                              </strong>
                            )}
                            color="greenBase"
                            backgroundColor="green1"
                            items={[
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_1"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_2"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_3"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_4"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_5"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_6"
                              ),
                              translate(
                                "sections.directadmin_vs_cpanel.cpanel-advantage_7"
                              )
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </StyledBlocksContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDirectAdminAndCpanelComparisonSection>
    )}
  </Translate>
);
export default DirectAdminAndCpanelComparisonSection;
