import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";

import OpenTicket from "../../components/OpenTicket";

import { ROUTE_HOME } from "../../routes";

const StyledPage404 = styled.div`
  margin: ${props => (props.isMobile ? "30px 0" : "100px 0")};
  background-image: url("/assets/backgrounds/bg-6.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const StyledContent = styled.div`
  padding: ${props => (props.isMobile ? "80px 0" : "100px 0")};
`;

const StyledImage = styled.img`
  width: 200px;
`;

const Page404 = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledPage404 className="row" isMobile={isMobileOnly}>
        {activeLanguage && activeLanguage.code && (
          <Helmet>
            <title>404 - {translate("page-not-found")}</title>
          </Helmet>
        )}
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <StyledContent
                  className="col-12 col-sm-12 col-md-12 text-center"
                  isMobile={isMobileOnly}
                >
                  <StyledImage
                    src="/assets/icons/icon_illustration_search.png"
                    className="img-fluid"
                    alt="404"
                  />
                  <SectionTitle>{translate("page-not-found")}</SectionTitle>
                  <SectionDescription>
                    {translate(
                      "the-page-you-are-looking-for-does-not-exist-or-has-been-removed"
                    )}
                    .
                  </SectionDescription>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 text-center">
                      <OpenTicket>
                        {onShow => (
                          <SecondaryButton onClick={() => onShow()}>
                            {translate("contact-support")}
                          </SecondaryButton>
                        )}
                      </OpenTicket>

                      <Link to={ROUTE_HOME}>
                        <PrimaryButton>
                          {translate("visit-our-homepage")}
                        </PrimaryButton>
                      </Link>
                    </div>
                  </div>
                </StyledContent>
              </div>
            </div>
          </div>
        </div>
      </StyledPage404>
    )}
  </Translate>
);

export default Page404;
