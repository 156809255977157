import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SupportPlan from "../../SupportPlan";
import FeaturesTable from "../../FeaturesTable";
import check_success from "../../../assets/images/check_success.svg";
import parse from "html-react-parser";


const StyledAvaiable = styled.img``;


const StyledTop = styled.div`
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const FeaturesTableSection = styled.div`
  margin-top: -159px;
  padding-bottom: 75px;
`;
const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;
const StyledWrapper = styled.div`
  display: ${props => props.isMobile && "inline-block"};
`;
const features = [

  {
    name: {
      en: "Number of websites",
      mk: "Број на сајтови"
    },
    "Basic": "2",
    "Regular": "5",
    "Power": "10"
  },
  {
    name: {
      en: "Disk space",
      mk: "Хостинг простор"
    },
    "Basic": "2GB",
    "Regular": "10GB",
    "Power": "30GB"
  },
  {
    name: {
      en: "Traffic bandwidth",
      mk: "Трансфер"
    },
    translate:true,
    Basic: {mk: "200 GB Трансфер", en : "200 GB"},
    Regular: {mk: "500 GB Трансфер" , en : " 500 GB"},
    Power: {mk: "700 GB Трансфер", en : " 700 GB"},
  },
  {
    name: {
      en: "RAM memory",
      mk: "RAM меморија"
    },
    translate:true,
    Basic: {mk: "NVME SSD RAID ", en : "NVME SSD RAID "},
    Regular: {mk: "NVME SSD RAID " , en : " NVME SSD RAID "},
    Power: {mk: "NVME SSD RAID ", en : " NVME SSD RAID "},

  },
  {
    name: {
      en: "Parked domains",
      mk: "Parked домени"
    },
    "Basic": "1",
    "Regular": "5",
    "Power": "10"
  },
  {
    name: {
      en: "Addon domains",
      mk: "Addon домени"
    },
    "Basic": "1",
    "Regular": "5",
    "Power": "10"
  },
  {
    name: {
      en: "Number of emails",
      mk: "Број на емаил адреси"
    },
    translate:true,
    Basic: {mk: "неограничено", en : "unlimited"},
    Regular: {mk: "неограничено" , en : " unlimited"},
    Power: {mk: "неограничено", en : " unlimited"},
  },
  {
    name: {
      en: "Number of databases",
      mk: "Број на бази"
    },
    translate:true,
    Basic: {mk: "неограничено", en : "unlimited"},
    Regular: {mk: "неограничено" , en : " unlimited"},
    Power: {mk: "неограничено", en : " unlimited"},
  },
  {
    name: {
      en: "Server location",
      mk: "Серверска локација"
    },
    translate:true,
    Basic: {mk: "Европа и Америка", en : "Europe and America"},
    Regular: {mk: "Европа и Америка" , en : " Europe and America"},
    Power: {mk: "Европа и Америка", en : " Europe and America"},

  },
  {
    name: {
      en: "Physical memory",
      mk: "Физичка меморија"
    },
    "Basic": "1024MB (1GB)",
    "Regular": "2048MB (2GB)",
    "Power": "3062MB (3GB)"
  },
  {
    name: {
      en: "Processor speed",
      mk: "Процесорска брзина"
    },
    translate:true,
    Basic: {mk: "100% (1 jадро)", en : "100% (1 core)"},
    Regular: {mk: "200% (2 jадро)" , en : "200% (2 core)"},
    Power: {mk: "300% (3 jадро)", en : "300% (3 core)"},
  },
  {
    name: {
      en: "PHP version",
      mk: "Избор на PHP верзија"
    },
    "Basic": "5.6, 7.0, 7.1, 7.2, 7.3 , 7.4 и 8.0",
    "Regular": "5.6, 7.0, 7.1, 7.2, 7.3 и 7.4 и 8.0",
    "Power": "5.6, 7.0, 7.1, 7.2, 7.3 и 7.4 и 8.0"
  },
  {
    name: {
      en: "Files (inodes)",
      mk: "Files (inodes)"
    },
    "Basic": "300.000 - 350.000",
    "Regular": "350.000 - 400.000",
    "Power": "400.000 - 450.000"
  }
  , {
    name: {
      en: "Entry processes",
      mk: "Entry processes"
    },
    "Basic": "10-40",
    "Regular": "20-60",
    "Power": "40-100"
  },


  {
    name: {
      en: "Auto SSL  certificate",
      mk: "Auto SSL сертификат"
    },
    "Basic": true,
    "Regular": true,
    "Power": true
  },
  {
    name: {
      en: "Backup",
      mk: "Backup"
    },
    "Basic": true,
    "Regular": true,
    "Power": true
  },
  {
    name: {
      en: "Regular backup ",
      mk: "Редовен backup"
    },
    translate:true,
    Basic: {mk: "дневно, неделно и месечно", en : "daily, weekly and monthly"},
    Regular: {mk: "дневно, неделно и месечно", en : "daily, weekly and monthly"},
    Power: {mk: "дневно, неделно и месечно", en : "daily, weekly and monthly"},
  },
  {
    name: {
      en: "Offsite backup",
      mk: "Offsite backup"
    },
    translate:true,
    Basic: {mk: "3 пати годишно", en : "3 times per year"},
    Regular: {mk: "3 пати годишно", en : "3 times per year"},
    Power: {mk: "3 пати годишно", en : "3 times per year"},
  },
  {
    name: {
      en: "cPanel Control panel",
      mk: "cPanel Контролен панел"
    },
    "Basic": true,
    "Regular": true,
    "Power": true
  },

  {
    name: {
      en: "WordPress Installation",
      mk: "WordPress Инсталација"
    },
    "Basic": true,
    "Regular": true,
    "Power": true
  },
  {
    name: {
      en: "Softaculous",
      mk: "Softaculous"
    },
    "Basic": true,
    "Regular": true,
    "Power": true
  }


];
const WordpressHostingTechnicalCharacteristics = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
        <div className="col-12 col-sm-12 col-md-12" id={"WpTechnicalCharacteristics"}>
          <div className="row">
            <div className="container">
              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-8">

                  <div  id="technicalCharacteristics" style={{"margin": "100px 0"}}>&nbsp;</div>
                  <SectionTitle>
                    {translate("technical-characteristics-of-hosting-plans-web")}
                  </SectionTitle>
                </div>
              </StypedTop>


              <div style={{"margin": "100px 0"}}>&nbsp;</div>

              <FeaturesTableSection
                  className="row no-gutters"
                  isMobile={isMobileOnly}
              >
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row no-gutters">
                    <div className="container">
                      <div className="row no-gutters">
                        <div className="col-12 col-sm-12 col-md-3"/>
                        <div className="col-12 col-sm-12 col-md-9">
                          <div className="row no-gutters">
                            <div
                                className="col-12 col-sm-12 col-md-4"
                                key={`ssl-${Math.random()}`}
                            >
                              <SupportPlan
                                  firstChild={true}
                                  className={'text-center'}
                                  title={"Basic"}
                              />
                              {isMobileOnly && (
                                  <FeaturesTable
                                      className="features-table row no-gutters">
                                    <div
                                        className="col-12 col-sm-12 col-md-12">
                                      {features.map(
                                          (feature) => (
                                              <div
                                                  key={`feature-ssl-${Math.random()}`}
                                                  className="row no-gutters feature-row"
                                              >
                                                <div
                                                    className="col-12 col-sm-12 col-md-9">
                                                  <div
                                                      className="row no-gutters">
                                                    <div
                                                        className="col-12 col-sm-12 col-md-4">

                                                      {activeLanguage &&
                                                      (
                                                          <div
                                                              className="feature-value text-center">
                                                            {feature.name[activeLanguage.code]}
                                                            {feature.translate ?
                                                                (
                                                                    <div>
                                                                      {feature["Basic"][activeLanguage.code]}
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div>
                                                                      {feature["Basic"] === true ?
                                                                          <StyledAvaiable
                                                                              src={check_success}/> : parse(feature["Basic"])}
                                                                    </div>)

                                                            }
                                                          </div>
                                                      )
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          )
                                      )}
                                    </div>
                                  </FeaturesTable>
                              )}
                            </div>
                            <div
                                className="col-12 col-sm-12 col-md-4"
                                key={`ssl-${Math.random()}`}
                            >
                              <SupportPlan
                                  className={'text-center'}
                                  firstChild={false}
                                  title={"Regular"}
                              />
                              {isMobileOnly && (
                                  <FeaturesTable
                                      className="features-table row no-gutters">
                                    <div
                                        className="col-12 col-sm-12 col-md-12">
                                      {features.map(
                                          (feature) => (
                                              <div
                                                  key={`feature-ssl-${Math.random()}`}
                                                  className="row no-gutters feature-row"
                                              >
                                                <div
                                                    className="col-12 col-sm-12 col-md-9">
                                                  <div
                                                      className="row no-gutters">
                                                    <div
                                                        className="col-12 col-sm-12 col-md-4">

                                                      {activeLanguage &&
                                                      (
                                                          <div
                                                              className="feature-value text-center">
                                                            {feature.name[activeLanguage.code]}
                                                            {feature.translate ?
                                                                (
                                                                    <div>
                                                                      {feature["Regular"][activeLanguage.code]}
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div>
                                                                      {feature["Regular"] === true ?
                                                                          <StyledAvaiable
                                                                              src={check_success}/> : parse(feature["Regular"])}
                                                                    </div>)

                                                            }
                                                          </div>
                                                      )
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          )
                                      )}
                                    </div>
                                  </FeaturesTable>
                              )}
                            </div>
                            <div
                                className="col-12 col-sm-12 col-md-4"
                                key={`ssl-${Math.random()}`}
                            >
                              <SupportPlan
                                  firstChild={false}
                                  className={'text-center'}
                                  title={"Power"}
                              />
                              {isMobileOnly && (
                                  <FeaturesTable
                                      className="features-table row no-gutters">
                                    <div
                                        className="col-12 col-sm-12 col-md-12">
                                      {features.map(
                                          (feature) => (
                                              <div
                                                  key={`feature-ssl-${Math.random()}`}
                                                  className="row no-gutters feature-row"
                                              >
                                                <div
                                                    className="col-12 col-sm-12 col-md-9">
                                                  <div
                                                      className="row no-gutters">
                                                    <div
                                                        className="col-12 col-sm-12 col-md-4">

                                                      {activeLanguage &&
                                                      (
                                                          <div
                                                              className="feature-value text-center">
                                                            {feature.name[activeLanguage.code]}
                                                            {feature.translate ?
                                                                (
                                                                    <div>
                                                                      {feature["Power"][activeLanguage.code]}
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div>
                                                                      {feature["Power"] === true ?
                                                                          <StyledAvaiable
                                                                              src={check_success}/> : parse(feature["Power"])}
                                                                    </div>)
                                                            }
                                                          </div>
                                                      )
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          )
                                      )}
                                    </div>
                                  </FeaturesTable>
                              )}
                            </div>

                          </div>
                        </div>
                      </div>

                      {!isMobileOnly && (
                          <FeaturesTable className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-12">
                              {features.map(
                                  (feature) => (
                                      <div
                                          key={`feature-ssl-${Math.random()}`}
                                          className="row no-gutters feature-row"
                                      >
                                        <div
                                            className="col-12 col-sm-12 col-md-3 feature-wrapper">
                                          <div className="feature">
                                            {activeLanguage &&
                                            (feature.name[activeLanguage.code])
                                            }
                                          </div>
                                        </div>
                                        <div
                                            className="col-12 col-sm-12 col-md-9">
                                          <div
                                              className="row no-gutters">
                                            {feature.translate ?
                                                (
                                                    <>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {activeLanguage &&
                                                          parse(feature["Basic"][activeLanguage.code])
                                                          }
                                                        </div>
                                                      </div>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {activeLanguage &&
                                                          parse(feature["Regular"][activeLanguage.code])
                                                          }
                                                        </div>
                                                      </div>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {activeLanguage &&
                                                          parse(feature["Power"][activeLanguage.code])
                                                          }
                                                        </div>
                                                      </div>
                                                    </>
                                                ) : (
                                                    <>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {feature["Basic"] === true ?
                                                              <StyledAvaiable
                                                                  src={check_success}/> : parse(feature["Basic"])}
                                                        </div>
                                                      </div>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {feature["Regular"] === true ?
                                                              <StyledAvaiable
                                                                  src={check_success}/> : parse(feature["Regular"])}
                                                        </div>
                                                      </div>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div
                                                            className="feature-value text-center">
                                                          {feature["Power"] === true ?
                                                              <StyledAvaiable
                                                                  src={check_success}/> : parse(feature["Power"])}
                                                        </div>
                                                      </div>
                                                    </>
                                                )}


                                          </div>
                                        </div>
                                      </div>
                                  )
                              )}
                            </div>
                          </FeaturesTable>
                      )}
                    </div>
                  </div>
                </div>
              </FeaturesTableSection>
            </div>
          </div>

        </div>
    )}
  </Translate>
);
export default WordpressHostingTechnicalCharacteristics;
