import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { isMobileOnly } from "react-device-detect";

import Container from "../Containers/Container";
import InputField from "../InputField";
import SelectBox from "../SelectBox";
import PrimaryButton from "../Buttons/PrimaryButton";

const StyledWhoIsSearch = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;

  & .form-group {
    margin: 0;
  }

  & .whois-search-input {
    width: ${props => (props.isMobile ? "100%" : "78%")};
    display: inline-block;
    margin-right: 20px;
    margin-bottom: ${props => props.isMobile && "15px"};
  }
    & .whois-search-input input{
    text-transform: lowercase;
  }

  & .btn {
    display: inline-block;
    width: ${props => (props.isMobile ? "100%" : "20%")};
    top: -2px;
    margin-right: 0;
  }
  
  & p {   
    position: absolute;
    margin-top: 3px;
  }
`;

const WhoIsSearch = props => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="whois-search">
          <Container>
            <StyledWhoIsSearch isMobile={isMobileOnly}>
              <Form
                onSubmit={props.onSubmit}
                validate={props.validate}
                initialValues={{ domain: props.domain || "" }}
                render={({ handleSubmit, submitting, invalid, values }) => (
                  <form onSubmit={handleSubmit} id="whois-search-form">
                    <Field
                      component={InputField}
                      className="whois-search-input"
                      name="domain"
                      size="l"
                      placeholder={props.searchPlaceholder}
                      parse={value =>
                        value.toLowerCase()
                          && value.replace(/[^0-9a-z\u0400-\u04FF-.]/gi, "")
                              .toLowerCase()
                      }
                    />

                    <PrimaryButton
                      type="submit"
                      disabled={
                        submitting || invalid || props.isSearchingWhoIsData
                      }
                      submitting={props.isSearchingWhoIsData}
                      size="l"
                    >
                      {translate("pages.domains.whois.check-domain")}
                    </PrimaryButton>
                  </form>
                )}
              />
            </StyledWhoIsSearch>
          </Container>
        </div>
      )}
    </Translate>
  );
};
export default WhoIsSearch;
