import "./styledcomponents-snap.js";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
//import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/browser";
import Website from "./Website";
import * as serviceWorker from "./serviceWorker";

import { DefaultTheme } from "./design/Themes";
import "./assets/customcss/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
//import "./assets/customcss/rc-tooltip.css";
import "./index.css";

import { setAuthorizationToken, getToken } from "./services/authService";

import {
  setApplicationLocale,
  getApplicationLocale
} from "./services/localizationService";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: process.env.REACT_APP_MKHOST_SENTRY_DSN });
}

try {
  setAuthorizationToken(getToken());
  //setApplicationLocale(getApplicationLocale());
} catch (e) {
  console.log(e);
}

document.documentElement.setAttribute("data-user-color-scheme", "light");

const rootElement = document.getElementById("root");
// TO DO 
// if (rootElement.hasChildNodes()) {
//   hydrate(
//     <BrowserRouter>
//       <LocalizeProvider>
//         <ThemeProvider theme={DefaultTheme}>
//           <Website />
//         </ThemeProvider>
//       </LocalizeProvider>
//     </BrowserRouter>,
//     rootElement
//   );
// } else {
render(
  <BrowserRouter>
    <LocalizeProvider>
      <ThemeProvider theme={DefaultTheme}>
        <Website />
      </ThemeProvider>
    </LocalizeProvider>
  </BrowserRouter>,
  rootElement
);
// }
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
