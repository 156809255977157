import React from "react";
import styled from "styled-components";

const SmallTitle = styled.h5`
  display: block;
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
`;

export default SmallTitle;
