import React from "react";
import styled from "styled-components";
import { isBrowser, isMobileOnly, isTablet } from "react-device-detect";

const StyledWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  display: inline-block;
`;

const StyledImage = styled.img`
  width: ${props => {
    if (props.isMobile) return "380px";
    if (props.isTablet) return "380px";
    return "430px";
  }};
  top: 0;
  left: 0;
`;

const OutlookPopServerSettingsImage = ({ email, image }) => (
  <StyledWrapper>
    <StyledImage
      src={image}
      className="img-fluid"
      isMobile={isMobileOnly}
      isTablet={isTablet}
      isBrowser={isBrowser}
    />
  </StyledWrapper>
);

export default OutlookPopServerSettingsImage;
