import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SecondaryButton from "../../Buttons/SecondaryButton";

const StyledFreeAutoSSLCertificateSection = styled.div`
  padding: 75px 0;
`;

const StyledWrapper = styled.div`
  display: inline-block;
  margin-top: 20px;
`;

const FreeAutoSSLCertificateSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledFreeAutoSSLCertificateSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate(
                      "free-autossl-certificate-with-every-hosting-plan"
                    )}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "with-every-purchased-hosting-plan,-all-mkhost-clients-get-a-free-autossl-sertificate-which-is-installed-by-default"
                    )}
                  </SectionDescription>

                  <SectionDescription>
                    <BulletPoint>
                      {translate("non-trusted-let's-encrypt-ssl-certificate")}
                    </BulletPoint>
                    <BulletPoint>
                      {translate(
                        "intended-for-all-domains-and-subdomains-of-hosting"
                      )}
                    </BulletPoint>
                    <BulletPoint>
                      {translate(
                        "better-google-search-rating-for-improved-seo"
                      )}
                    </BulletPoint>
                    <BulletPoint>
                      {translate("professional-look-for-your-online-business")}
                    </BulletPoint>
                    <BulletPoint>{translate("free-installation")}</BulletPoint>
                  </SectionDescription>
                  <StyledWrapper>
                    <a
                      href="https://blog.mkhost.com.mk/6-prichini-zoshto-mora-da-imash-ssl-sertifikat-na-svojot-veb-sajt/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <SecondaryButton size="l">
                        {translate("learn-more")}
                      </SecondaryButton>
                    </a>
                  </StyledWrapper>
                </div>
                {!isMobileOnly && (
                  <div className="col-12 col-sm-12 col-md-7">
                    <img
                      className="img-fluid"
                      src="/assets/images/image_autossl.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledFreeAutoSSLCertificateSection>
    )}
  </Translate>
);

export default FreeAutoSSLCertificateSection;
