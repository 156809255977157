import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import CustomTooltip from "../../components/CustomTooltip";
import DomainSearchSection from "../../components/Sections/DomainsSearchSection";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import FreeRegistrationFeaturesSection from "../../components/Sections/FreeRegistrationFeaturesSection";
import NewDomainExtensionsSection from "../../components/Sections/NewDomainExtensionsSection";
import TransferDomainStepsSection from "../../components/Sections/TransferDomainStepsSection";
import MarketShareSection from "../../components/Sections/MarketShareSection";
import MKhostClientsSection from "../../components/Sections/MKhostClientsSection";

import AccordionItem from "../../components/Accordion/AccordionItem";
import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";

import check_success from "../../assets/images/check_success.svg";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_DOMAINS } from "../../config/faq";

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 18px;
  line-height: 1.56;
  color: ${props => props.theme.neutral6};
`;

const StyledSpacing = styled.div`
  margin-top: ${props => props.isMobile && "25px"};
`;

const StyledTransferPrices = styled.span`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.neutral5};
  text-align: center;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "-138px")};
`;

class DomainsTransferPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedExtension: null,
      domain: null
    };
  }

  componentDidMount() {
    if (this.props.match.params.name) {
      let domainName = this.props.match.params.name.split(".");
      const domain = domainName[0];
      domainName.shift();
      const extension = domainName.join(".");
      this.setState({
        selectedExtension: extension,
        domain
      });
    }
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].domains_transfer.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].domains_transfer.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].domains_transfer
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].domains_transfer.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].domains_transfer
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-transfer.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].domains_transfer.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].domains_transfer
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_domains-transfer.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("transfer-in-your-domain")}
              subtitle={() => (
                <SectionDescription>
                  {translate(
                    "join-the-biggest-domain-registry-in-macedonia-and-transfer-your-domain-to-mkhost-by-following-just-a-few-simple-steps"
                  )}
                </SectionDescription>
              )}
              image="/assets/illustrations/domains/illustration_domains-transfer.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              paddingBottom="90px"
              backgroundPositionY={isMobileOnly ? "0" : "155px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
              backgroundPosition={isMobileOnly ? "bottom" : "bottom"}
            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "230px" : "270px"}
                isMobile={isMobileOnly}
              />
              <DomainSearchSection
                domain={this.state.domain}
                type="transfer"
                selectedExtension={this.state.selectedExtension}
                searchPlaceholder={translate(
                  "enter-a-domain-you-would-like-to-transfer"
                )}
                quickPrices={() => (
                  <StyledTransferPrices>
                    <strong>
                      {translate("free-transfer")}{" "}
                      {translate("for-all-macedonian-domains")}
                    </strong>{" "}
                    |{" "}
                    <strong>
                      {activeLanguage && activeLanguage.code === "mk" ? (
                        <span>
                          МКД 999
                          <CustomTooltip
                            inline={true}
                            content={translate("price-without-vat")}
                          >
                            <span>*</span>
                          </CustomTooltip>
                        </span>
                      ) : (
                        <span>€16.48</span>
                      )}
                    </strong>{" "}
                    {translate("for-most-international-domains")}
                    <strong />
                  </StyledTransferPrices>
                )}
              />
            </Hero>

            <StyledSpacing isMobile={isMobileOnly}>
              <FreeRegistrationFeaturesSection
                title={translate("why-transfer-your-domains-to-mkhost")}
              />
            </StyledSpacing>
            <TransferDomainStepsSection />
            <StyledSpacing isMobile={isMobileOnly}>
              <MarketShareSection />
            </StyledSpacing>
            <MKhostClientsSection />

            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_DOMAINS} />
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default DomainsTransferPage;
