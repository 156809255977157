import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import Slider from "react-slick";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import * as domainsService from "../../services/domainsService";
import * as cartService from "../../services/cartService";
import {
  validateOnlyDomainName,
  validateDomain
} from "../../common/validationRules";

import "slick-carousel/slick/slick.css";

import Hero from "../../components/Hero";

import Container from "../../components/Containers/Container";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Icon from "../../components/Icon";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SuccessButton from "../../components/Buttons/SuccessButton";
import BulletPoint from "../../components/BulletPoint";
import ButtonGroup from "../../components/ButtonGroup";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import CircledIcon from "../../components/CircledIcon";
import Perk from "../../components/Perk";
import HostingPlan from "../../components/HostingPlan";
import DomainSearch from "../../components/DomainSearch";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import Radiobox from "../../components/Radiobox";

import sliderData from "./sliderData";

import DataContext from "../../DataContext";

import {
  ROUTE_CLIENT_CART,
  ROUTE_WEBSITE_DOMAIN,
  ROUTE_TEMPLATES_DOMAIN
} from "../../routes";

import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import seoData from "../../seo/seoData";

const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

const StyledPremiumWrapper = styled.div`
  background-image: url("/assets/backgrounds/bg-4.svg");
  background-repeat: no-repeat;
  background-position: top;
  padding-top: ${props => (props.isMobile ? "130px" : "240px")};
`;

const StyledProgressStepContainer = styled(Container)`
  padding: 15px;
  padding-left: 70px;
  position: relative;

  border-color: ${props =>
    props.active && !props.selected
      ? props.theme.blueBase
      : props.theme.neutral3};
  background-color: ${props =>
    props.active && !props.selected ? props.theme.blue1 : props.theme.white};

  & .circle {
    display: block;
    position: absolute;
    left: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding-top: 10px;
    text-align: center;
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.neutral4};
    background-color: ${props => props.active && props.theme.blueBase};
    background-color: ${props => props.selected && props.theme.greenBase};
  }

  & .title {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.43;
    color: ${props => props.theme.neutral5};
  }

  & .content {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${props => props.theme.neutral6};
    color: ${props => props.active && props.theme.blueBase};
    color: ${props => props.selected && props.theme.greenBase};
  }
`;
const ProgressStep = ({ number, title, children, active, selected }) => (
  <StyledProgressStepContainer active={active} selected={selected}>
    <div className="circle">{number}</div>
    <span className="title">{title}</span>
    <span className="content">{children || "- -"}</span>
    {selected && <StyledStepIcon icon="success" />}
  </StyledProgressStepContainer>
);

const StyledProgress = styled.div`
  margin-bottom: 50px;
`;
const Progress = ({ template, hosting, domain, translate }) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-3">
      <ProgressStep
        number="1"
        title={translate("template")}
        active={!hosting && !domain}
        selected={template !== null}
      >
        {template ? template.title : "- -"}
      </ProgressStep>
    </div>
    <div className="col-12 col-sm-12 col-md-3">
      <ProgressStep
        number="2"
        title={translate("hosting")}
        active={template && !domain}
        selected={hosting !== null}
      >
        {hosting ? hosting.name : "- -"}
      </ProgressStep>
    </div>
    <div className="col-12 col-sm-12 col-md-3">
      <ProgressStep
        number="3"
        title={translate("domain")}
        active={template && hosting}
        selected={domain !== null}
      >
        {domain ? domain.name : "- -"}
      </ProgressStep>
    </div>
  </div>
);

const StyledStep = styled.div`
  padding-top: 50px;
  padding-bottom: ${props => (props.isMobile ? "30px" : "100px")};
`;

const StyledStepIcon = styled(Icon)`
  position: absolute;
  right: 5px;
  top: 15px;
  color: ${props => props.theme.greenBase};
`;

const StyledSlide = styled.div`
  text-align: center;
  position: relative;
  background-color: ${props => props.theme.white} !important;
`;

const StyledSlideContent = styled.div`
  position: relative;
  padding: 30px;
  height: ${props => (props.centered ? "410px" : "360px")};
  margin-top ${props => (!props.centered ? "30px" : "0")};
  margin-bottom ${props => (!props.centered ? "30px" : "0")};
  background-color: ${props => props.theme.neutral3};
  box-shadow: ${props =>
    props.centered
      ? "0 10px 20px 0 rgba(123, 139, 167, 0.25)"
      : "0 3px 5px 0 rgba(123, 139, 167, 0.25)"};


   & img {
    height: ${props => (!props.centered ? "320px" : "auto")};
   }
`;

const StyledOverview = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px;
  background-color: ${props => props.theme.white};
  & .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: ${props => props.theme.neutralBase};
    margin-bottom: 5px;
  }
  & .type {
    display: block;
    font-size: 16px;
    text-align: center;
    color: ${props => props.theme.neutral6};
  }
`;

const StyledSlideWrapper = styled.div`
  position: relative;
  .custom-shadow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 150px;
    height: 100%;
    cursor: pointer;
  }

  .custom-left-shadow {
    left: 0;
    top: 0;
    box-shadow: inset 111px 0px 107px -26px rgba(255, 255, 255, 1);
  }

  .custom-right-shadow {
    right: 0;
    top: 0;
    box-shadow: inset -111px 0px 107px -26px rgba(255, 255, 255, 1);
  }

  .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .slick-list {
    height: ${props => props.isMobile && "450px"};
  }
  .slick-arrow {
    display: none !important;
  }
`;

const StyledArrows = styled.div`
  display: block;
`;

const StyledArrow = styled(Container)`
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const StyledTemplateStep = styled.div`
  position: relative;
  padding: ${props => (props.isMobile ? "40px 0" : "75px 0")};
`;
const StyledHostingStep = styled.div`
  position: relative;
  padding: ${props => (props.isMobile ? "40px 0" : "75px 0")};
`;

const StyledDomainStep = styled.div`
  position: relative;
  padding: ${props => (props.isMobile ? "40px 0" : "75px 0")};

  & .domain-search {
    top: 10px;
  }

  & .domain-search-section-results {
    background-color: transparent;
  }
`;

const DisabledStep = styled.div`
  position: absolute;
  z-index: 10;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.white};
  opacity: 0.5;
`;

const StyledRadioBox = styled(Radiobox)`
  display: inline-block;
  margin-left: 30px;
`;

const StyledTemplateActions = styled.div`
  margin-top: 40px;
`;

const StyledLivePreviewLink = styled.a`
  margin-right: 15px;
`;

const validate = values => {
  return {
    domain: validateOnlyDomainName(values.domain, values.extension.value)
  };
};

class WebDesignPremiumPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      template: null,
      hosting: null,
      domain: null,
      selectedDomain: null,
      extension: null,
      sliderIndex: 1,
      extensions: null,
      domainType: "register"
    };

    this.templateRef = null;
    this.hostingRef = null;
    this.domainRef = null;

    this.sliderSettings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      initialSlide: 1,
      variableWidth: true,
      beforeChange: (current, next) => this.setState({ sliderIndex: next })
    };

    this.sliderRef = null;
  }

  setSliderRef = slider => (this.sliderRef = slider);

  onNext() {
    this.sliderRef.slickNext();
  }

  onPrev() {
    this.sliderRef.slickPrev();
  }

  onSelectTemplate = template => {
    this.setState({ template });
  };

  onSelectHosting = hosting => {
    this.setState({ hosting });
  };

  onSelectDomain = domain => {
    this.setState({ selectedDomain: domain });
  };
  componentDidMount() {
    domainsService.fetchDomainsPrices().then(result => {
      const { tld } = result.data.data;

      let extensions = [];
      Object.keys(tld).map(ext =>
        extensions.push({ label: `.${ext}`, value: ext })
      );
      this.setState({ extensions });
    });
  }

  onSearchDomain = () => {};

  onChangeDomainType = domainType => {
    this.setState({ domainType });
  };

  onScrollToTemplate = () => {
    if (this.templateRef) {
      this.templateRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  onScrollToHosting = () => {
    if (this.hostingRef) {
      this.hostingRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  onScrollToDomain = () => {
    if (this.domainRef) {
      this.domainRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].webdesign_premium.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].webdesign_premium.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].webdesign_premium
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].webdesign_premium.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].webdesign_premium
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_premium.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].webdesign_premium.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].webdesign_premium
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_premium.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("a-complete-website-in-3-simple-steps")}
              backgroundColor="neutralBase"
              color="white"
              paddingBottom="0"
              subtitle={translate(
                "web-hosting-+-domain-+-web-template-in-one-package.-the-ideal-web-solution-for-your-business"
              )}
              image="/assets/illustrations/illustration_premium.png"
              action={() => (
                <SuccessButton
                  onClick={() => this.onScrollToTemplate()}
                  size="l"
                >
                  {translate("start-now")}
                </SuccessButton>
              )}
            />

            <StyledPremiumWrapper className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <StyledTemplateStep
                      ref={ref => (this.templateRef = ref)}
                      className="row"
                      isMobile={isMobileOnly}
                    >
                      <div className="col-12 col-sm-12 col-md-12">
                        <Progress
                          template={this.state.template}
                          hosting={this.state.hosting}
                          domain={this.state.selectedDomain}
                          translate={translate}
                        />
                        <StyledStep className="row" isMobile={isMobileOnly}>
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("choose-template")}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                "choose-from-10+-templates-suited-for-a-wide-range-od-business-types,-including-corporate,-health-&-fitness,-creative,-agency-and-many-more"
                              )}
                            </SectionDescription>
                          </div>
                        </StyledStep>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-7">
                                <StyledSlideWrapper isMobile={isMobileOnly}>
                                  <div
                                    className="custom-shadow custom-left-shadow"
                                    onClick={() => this.onPrev()}
                                  />
                                  <Slider
                                    {...this.sliderSettings}
                                    ref={this.setSliderRef}
                                  >
                                    {sliderData.map(slide => (
                                      <StyledSlide
                                        key={slide.title}
                                        centered={
                                          this.state.sliderIndex === slide.index
                                        }
                                        left={
                                          this.state.sliderIndex > slide.index
                                        }
                                        right={
                                          this.state.sliderIndex < slide.index
                                        }
                                      >
                                        <StyledSlideContent
                                          centered={
                                            this.state.sliderIndex ===
                                            slide.index
                                          }
                                        >
                                          <img
                                            className="img-fluid"
                                            src={slide.image}
                                            alt={slide.title}
                                          />
                                          <StyledOverview>
                                            <span className="title">
                                              {slide.title}
                                            </span>
                                            <span className="type">
                                              {activeLanguage &&
                                                slide.type[activeLanguage.code]}
                                            </span>
                                          </StyledOverview>
                                        </StyledSlideContent>
                                      </StyledSlide>
                                    ))}
                                  </Slider>
                                  <div
                                    className="custom-shadow custom-right-shadow"
                                    onClick={() => this.onNext()}
                                  />
                                </StyledSlideWrapper>
                                <StyledArrows className="text-center">
                                  <StyledArrow onClick={() => this.onPrev()}>
                                    <Icon icon="arrow_left" />
                                  </StyledArrow>
                                  <StyledArrow onClick={() => this.onNext()}>
                                    <Icon icon="arrow_right" />
                                  </StyledArrow>
                                </StyledArrows>
                              </div>
                              <div className="col-12 col-sm-12 col-md-5">
                                <Perk
                                  hideIcon={true}
                                  subtitle="template"
                                  title={
                                    sliderData[this.state.sliderIndex].title
                                  }
                                  description={
                                    activeLanguage &&
                                    sliderData[this.state.sliderIndex]
                                      .description[activeLanguage.code]
                                  }
                                  items={
                                    activeLanguage &&
                                    sliderData[this.state.sliderIndex].items[
                                      activeLanguage.code
                                    ]
                                  }
                                />

                                <StyledTemplateActions>
                                  <StyledLivePreviewLink
                                    href={`https://${sliderData[this.state.sliderIndex].codename}.${ROUTE_TEMPLATES_DOMAIN}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <SecondaryButton size="l">
                                      <Icon icon="websites" />{" "}
                                      {translate(
                                        "pages.webdesign.premium.live-preview"
                                      )}
                                    </SecondaryButton>
                                  </StyledLivePreviewLink>
                                  {(!this.state.template ||
                                    this.state.template.codename !==
                                      sliderData[this.state.sliderIndex]
                                        .codename) && (
                                    <PrimaryButton
                                      size="l"
                                      onClick={() => {
                                        this.onSelectTemplate(
                                          sliderData[this.state.sliderIndex]
                                        );
                                        this.onScrollToHosting();
                                      }}
                                    >
                                      <Icon icon="success" />{" "}
                                      {translate(
                                        "pages.webdesign.premium.choose-template"
                                      )}
                                    </PrimaryButton>
                                  )}
                                  {this.state.template &&
                                    this.state.template.codename ===
                                      sliderData[this.state.sliderIndex]
                                        .codename && (
                                      <SuccessButton size="l">
                                        <Icon icon="success" />{" "}
                                        {translate(
                                          "pages.webdesign.premium.template-chosen"
                                        )}
                                      </SuccessButton>
                                    )}
                                </StyledTemplateActions>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledTemplateStep>

                    <StyledHostingStep
                      ref={ref => (this.hostingRef = ref)}
                      className="row"
                      isMobile={isMobileOnly}
                    >
                      {!this.state.template && <DisabledStep />}
                      <div className="col-12 col-sm-12 col-md-12">
                        <Progress
                          template={this.state.template}
                          hosting={this.state.hosting}
                          domain={this.state.selectedDomain}
                          translate={translate}
                        />
                        <StyledStep className="row" isMobile={isMobileOnly}>
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("hosting")
                                .charAt(0)
                                .toUpperCase() + translate("hosting").slice(1)}
                            </SectionTitle>
                            <SectionDescription>
                              {translate(
                                "choose-from-3-different-hosting-plans,-tailored-to-fit-your-specific-website-needs"
                              )}
                              .
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12">
                            <DataContext.Consumer>
                              {({ hostings: { premium } }) => {
                                return (
                                  <div
                                    key={`hosting-plans-${Math.random()}`}
                                    className="row"
                                  >
                                    {!premium && (
                                      <React.Fragment>
                                        <div className="col-12 col-sm-12 col-md-4">
                                          <HostingPlanWithLoading
                                            isLoading={true}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4">
                                          <HostingPlanWithLoading
                                            isLoading={true}
                                          />
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-4">
                                          <HostingPlanWithLoading
                                            isLoading={true}
                                          />
                                        </div>
                                      </React.Fragment>
                                    )}
                                    {premium &&
                                      premium.map(hosting => {

                                        let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                                        let pricing = null;

                                        // const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                        //   d => d.numeric_code === 1
                                        // );

                                        // Temporary solution, should be sync with api derivation numeric codes
                                        possiblePeriodNumericCodes.some(numericCode => {
                                          pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                            d => d.numeric_code === numericCode
                                          );

                                          if (typeof(pricing) !== 'undefined') {
                                            // console.log(numericCode);
                                            return true;
                                          }
                                        });
                                        // END


                                        return (
                                          <div
                                            key={`hosting-plan-${hosting.id}`}
                                            className="col-12 col-sm-12 col-md-4"
                                          >
                                            <HostingPlan
                                              dark={true}
                                              title={hosting.name}
                                              descriptionClassName="col-12 col-sm-12 col-md-9"
                                              description={
                                                hosting.details.description
                                              }
                                              imageWidth="180px"
                                              imageHeight="120px"
                                              price={pricing.price.value}
                                              period={translate(
                                                `derivations.${pricing.name.toLowerCase()}`
                                              )}
                                              currency={
                                                pricing.price.currency.code
                                              }
                                              additional={hosting.details.extra}
                                              additionalPrices={hosting.details.prices}
                                              isNew={hosting.details.is_new}
                                              features={
                                                hosting.details.features
                                              }
                                              tooltips={
                                                hosting.details.tooltips
                                              }
                                              action={() => (
                                                <React.Fragment>
                                                  {(!this.state.hosting ||
                                                    this.state.hosting.id !==
                                                      hosting.id) && (
                                                    <PrimaryButton
                                                      size="l"
                                                      className="w-100"
                                                      onClick={() => {
                                                        this.onSelectHosting(
                                                          hosting
                                                        );
                                                        this.onScrollToDomain();
                                                      }}
                                                    >
                                                      <Icon icon="success" />{" "}
                                                      {translate(
                                                        "choose-hosting-plan"
                                                      )}
                                                    </PrimaryButton>
                                                  )}
                                                  {this.state.hosting &&
                                                    this.state.hosting.id ===
                                                      hosting.id && (
                                                      <SuccessButton
                                                        size="l"
                                                        className="w-100"
                                                      >
                                                        <Icon icon="success" />{" "}
                                                        {translate(
                                                          "pages.webdesign.premium.hosting-chosen"
                                                        )}
                                                      </SuccessButton>
                                                    )}
                                                </React.Fragment>
                                              )}
                                            />
                                          </div>
                                        );
                                      })}
                                  </div>
                                );
                              }}
                            </DataContext.Consumer>
                          </div>
                        </StyledStep>
                      </div>
                    </StyledHostingStep>
                    <StyledDomainStep
                      ref={ref => (this.domainRef = ref)}
                      className="row"
                      isMobile={isMobileOnly}
                    >
                      {(!this.state.template || !this.state.hosting) && (
                        <DisabledStep />
                      )}
                      <div className="col-12 col-sm-12 col-md-12">
                        <Progress
                          template={this.state.template}
                          hosting={this.state.hosting}
                          domain={this.state.selectedDomain}
                          translate={translate}
                        />
                        <StyledStep className="row" isMobile={isMobileOnly}>
                          <div className="col-12 col-sm-12 col-md-4">
                            <SectionTitle>
                              {translate("domain")
                                .charAt(0)
                                .toUpperCase() + translate("domain").slice(1)}
                            </SectionTitle>
                            <SectionDescription>
                              {translate("search-for-your-ideal-domain-name")}
                            </SectionDescription>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 text-right">
                                <StyledRadioBox
                                  checked={this.state.domainType === "register"}
                                  onClick={() =>
                                    this.onChangeDomainType("register")
                                  }
                                >
                                  {translate("i-want-to-register-a-new-domain")}
                                  .
                                </StyledRadioBox>
                                <StyledRadioBox
                                  checked={this.state.domainType === "transfer"}
                                  onClick={() =>
                                    this.onChangeDomainType("transfer")
                                  }
                                >
                                  {translate("i-already-have-a-domain")}.
                                </StyledRadioBox>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12">
                                <DataContext.Consumer>
                                  {({ extensions, onAddToCart }) => (
                                    <DomainsSearchSection
                                      onAddToCart={(domain, type) => {
                                        this.onSelectDomain(domain);
                                        return onAddToCart({
                                          cart: cartService.getCartId()
                                            ? cartService.getCartId()
                                            : null,
                                          type: "domain",
                                          domain_id: null,
                                          domain:
                                            domain.name !== undefined
                                              ? domain.name
                                              : domain,
                                          quantity: 1,
                                          config: {
                                            type: this.state.domainType,
                                            period:
                                              domain.name !== undefined
                                                ? (domain.ext === ".gr" ? 2 : 1)
                                                // ? domain.pricing[
                                                //     this.state.domainType ||
                                                //       "register"
                                                //   ].billing_cycle.derivations[0]
                                                //     .numeric_code
                                                : 1
                                          }
                                        }).then(() => {
                                          onAddToCart({
                                            cart: cartService.getCartId()
                                              ? cartService.getCartId()
                                              : null,
                                            type: "hosting",
                                            quantity: 1,
                                            config: {
                                              type: "buy",
                                              period: 1
                                            },
                                            domain:
                                              domain.name !== undefined
                                                ? domain.name
                                                : domain,
                                            hosting_id: this.state.hosting.id
                                          }).then(() =>
                                            window.location.replace(
                                              `${ROUTE_CLIENT_CART}?cart=${cartService.getCartId()}`
                                            )
                                          );
                                          return Promise.resolve(true);
                                        });
                                      }}
                                      domain={this.state.domain}
                                      selectedExtension={this.state.extension}
                                      hideQuickPrices={true}
                                      searchPlaceholder={translate(
                                        "enter-a-domain-name-you-are-looking-for"
                                      )}
                                      type={this.state.domainType}
                                    />
                                  )}
                                </DataContext.Consumer>
                              </div>
                            </div>
                          </div>
                        </StyledStep>
                      </div>
                    </StyledDomainStep>
                  </div>
                </div>
              </div>
            </StyledPremiumWrapper>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default WebDesignPremiumPage;
