import {isMobileOnly} from "react-device-detect";
import Slider from "react-slick";
import sliderData from "../SliderDataOnlineStore";
import Icon from "../../../components/Icon";
import styled from "styled-components";
import Container from "../../../components/Containers/Container";
import * as domainsService from "../../../services/domainsService";
import React from "react";
import {Translate} from "react-localize-redux";
import Perk from "../../../components/Perk";
import {ROUTE_TEMPLATES_DOMAIN} from "../../../routes";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SuccessButton from "../../../components/Buttons/SuccessButton";

const StyledTemplateActions = styled.div`
  margin-top: 40px;
`;

const StyledLivePreviewLink = styled.a`
  margin-right: 15px;
`;

const StyledSlide = styled.div`
  text-align: center;
  position: relative;
  background-color: ${props => props.theme.white} !important;
`;

const StyledSlideContent = styled.div`
  position: relative;
  padding: 30px;
  height: ${props => (props.centered ? "410px" : "360px")};
  margin-top ${props => (!props.centered ? "30px" : "0")};
  margin-bottom ${props => (!props.centered ? "30px" : "0")};
  background-color: ${props => props.theme.neutral3};
  box-shadow: ${props =>
    props.centered
        ? "0 10px 20px 0 rgba(123, 139, 167, 0.25)"
        : "0 3px 5px 0 rgba(123, 139, 167, 0.25)"};


   & img {
    height: ${props => (!props.centered ? "320px" : "auto")};
   }
`;

const StyledOverview = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px;
  background-color: ${props => props.theme.white};
  & .title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: ${props => props.theme.neutralBase};
    margin-bottom: 5px;
  }
  & .type {
    display: block;
    font-size: 16px;
    text-align: center;
    color: ${props => props.theme.neutral6};
  }
`;

const StyledSlideWrapper = styled.div`
  position: relative;
  .custom-shadow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 150px;
    height: 100%;
    cursor: pointer;
  }

  .custom-left-shadow {
    left: 0;
    top: 0;
    box-shadow: inset 111px 0px 107px -26px rgba(255, 255, 255, 1);
  }

  .custom-right-shadow {
    right: 0;
    top: 0;
    box-shadow: inset -111px 0px 107px -26px rgba(255, 255, 255, 1);
  }

  .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }

  .slick-list {
    height: ${props => props.isMobile && "450px"};
  }
  .slick-arrow {
    display: none !important;
  }
`;

const StyledArrows = styled.div`
  display: block;
`;

const StyledArrow = styled(Container)`
  padding: 8px;
  display: inline-block;
  cursor: pointer;
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;


class OnlineShopSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderIndex: 1
        };

        this.templateRef = null;

        this.sliderSettings = {
            centerMode: true,
            infinite: true,
            slidesToShow: 1,
            speed: 500,
            initialSlide: 1,
            variableWidth: true,
            beforeChange: (current, next) => this.setState({sliderIndex: next})
        };

        this.sliderRef = null;
    }

    setSliderRef = slider => (this.sliderRef = slider);

    onNext() {
        this.sliderRef.slickNext();
    }

    onPrev() {
        this.sliderRef.slickPrev();
    }


    render() {
        return (
            <Translate>
                {({translate, activeLanguage}) => (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-7">
                                    <StyledSlideWrapper isMobile={isMobileOnly}>
                                        <div
                                            className="custom-shadow custom-left-shadow"
                                            onClick={() => this.onPrev()}
                                        />
                                        <Slider
                                            {...this.sliderSettings}
                                            ref={this.setSliderRef}
                                        >
                                            {sliderData.map(slide => (
                                                <StyledSlide
                                                    key={slide.title}
                                                    centered={
                                                        this.state.sliderIndex === slide.index
                                                    }
                                                    left={
                                                        this.state.sliderIndex > slide.index
                                                    }
                                                    right={
                                                        this.state.sliderIndex < slide.index
                                                    }
                                                >
                                                    <StyledSlideContent
                                                        centered={
                                                            this.state.sliderIndex ===
                                                            slide.index
                                                        }
                                                    >
                                                        <img
                                                            className="img-fluid"
                                                            src={slide.image}
                                                            alt={slide.title}
                                                        />
                                                        <StyledOverview>
                                                            {slide.titleLink && <a target={'_blanks'} href={slide.titleLink} className="title">

                                                                {slide.title}
                                                            </a> }
                                                            {!slide.titleLink && <span className="title">

                                                                {slide.title}
                                                            </span> }

                                                            <span className="type">
                                              {activeLanguage &&
                                              slide.type[activeLanguage.code]}
                                            </span>
                                                        </StyledOverview>
                                                    </StyledSlideContent>
                                                </StyledSlide>
                                            ))}
                                        </Slider>
                                        <div
                                            className="custom-shadow custom-right-shadow"
                                            onClick={() => this.onNext()}
                                        />
                                    </StyledSlideWrapper>
                                    <StyledArrows className="text-center">
                                        <StyledArrow onClick={() => this.onPrev()}>
                                            <Icon icon="arrow_left"/>
                                        </StyledArrow>
                                        <StyledArrow onClick={() => this.onNext()}>
                                            <Icon icon="arrow_right"/>
                                        </StyledArrow>
                                    </StyledArrows>
                                </div>
                                <div className="col-12 col-sm-12 col-md-5">
                                    <Perk
                                        hideIcon={true}
                                    /*    subtitle="template"*/
                                        title={
                                            sliderData[this.state.sliderIndex].title
                                        }
                                        titleLink={ sliderData[this.state.sliderIndex].titleLink}
                                        description={
                                            activeLanguage &&
                                            sliderData[this.state.sliderIndex]
                                                .description[activeLanguage.code]
                                        }
                                        items={
                                            activeLanguage &&
                                            sliderData[this.state.sliderIndex].items[
                                                activeLanguage.code
                                                ]
                                        }
                                    />

                                  {/*  <StyledTemplateActions>
                                        <StyledLivePreviewLink
                                            href={`https://${sliderData[this.state.sliderIndex].codename}.${ROUTE_TEMPLATES_DOMAIN}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <SecondaryButton size="l">
                                                <Icon icon="websites"/>{" "}
                                                {translate(
                                                    "pages.webdesign.premium.live-preview"
                                                )}
                                            </SecondaryButton>
                                        </StyledLivePreviewLink>
                                        {(!this.state.template ||
                                            this.state.template.codename !==
                                            sliderData[this.state.sliderIndex]
                                                .codename) && (
                                            <PrimaryButton
                                                size="l"
                                            >
                                                <Icon icon="success"/>{" "}
                                                {translate(
                                                    "pages.webdesign.premium.choose-template"
                                                )}
                                            </PrimaryButton>
                                        )}
                                        {this.state.template &&
                                        this.state.template.codename ===
                                        sliderData[this.state.sliderIndex]
                                            .codename && (
                                            <SuccessButton size="l">
                                                <Icon icon="success"/>{" "}
                                                {translate(
                                                    "pages.webdesign.premium.template-chosen"
                                                )}
                                            </SuccessButton>
                                        )}
                                    </StyledTemplateActions>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Translate>
        )
    }
}

export default OnlineShopSlider;