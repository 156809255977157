import React from "react";

import Loader from "../Loader";

const ContentLoader = () => (
  <Loader width="650" height="350">
    <rect x="0" y="30" width="100" height="30" rx="3" />
    <rect x="0" y="65" width="400" height="30" rx="3" />
    <rect x="0" y="135" width="590" height="200" rx="3" />
  </Loader>
);

export default ContentLoader;
