import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import CircledIcon from "../../CircledIcon";


const StyledPersonalizedServicesSection = styled.div`

`;

const StypedTop = styled.div`
  margin-bottom: 50px;
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const CloudServices = () => (
  <Translate>
    {({ translate }) => (
      <StyledPersonalizedServicesSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("partner")}
                  </SectionTitle>
                </div>
              </StypedTop>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="contacts"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("personalized_solutions")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "scalable_solutions")}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="hosting"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>
                        {translate("financial_efficiency")}
                      </SmallTitle>
                      <SmallDescription>
                        {translate(
                          "cloud_migration_costs")}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="ranking"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("flexibility-cloud")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "cloud_resources_flexibility")}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="setup"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("adaptability")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "scaling_business_resources")}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="lock"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>{translate("security")}</SmallTitle>
                      <SmallDescription>
                        {translate(
                          "eliminated_downtime")}
                      </SmallDescription>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-4">
                      <CircledIcon
                        icon="cpanel"
                        color="blueBase"
                        backgroundColor="blue1"
                      />
                      <SmallTitle>
                        {translate("experienced_support_team")}
                      </SmallTitle>
                      <SmallDescription>
                        {translate(
                          "cloud_solution_support")}
                      </SmallDescription>
                    </Feature>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledPersonalizedServicesSection>
    )}
  </Translate>
);
export default CloudServices;
