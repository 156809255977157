import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import Icon from "../Icon";

const StyledCircledImage = styled.div`
  display: block;
  border-radius: 50%;
  width: ${props => (props.isMobile ? "60px" : "80px")};
  height: ${props => (props.isMobile ? "60px" : "80px")};
  text-align: center;
  img{
    width:${props => (props.isMobile ? "60px" : "80px")};
    height:${props => (props.isMobile ? "60px" : "80px")};
    border-radius:50%;
  
  }

`;

const CircledImage = ({ image }) => (
  // <StyledCircledImage
  //   isMobile={isMobileOnly}
  //   color={color}
  //   backgroundColor={backgroundColor}
  //   className="circled-icon"
  // >
  //   <Icon icon={icon} size="xl" />
  // </StyledCircledImage>
    <StyledCircledImage isMobile={isMobileOnly}><img src={image}/></StyledCircledImage>
);

export default CircledImage;
