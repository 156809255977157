export function trackAddToCart(product) {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const fbq = window.fbq;

  if (typeof fbq !== "function") {
    return false;
  }

  fbq("track", "AddToCart", {
    content_name: `${product.type.toUpperCase()} ${product.config.type} ${
      product.domain
    }`,
    content_type: product.type
  });
}
