import React from "react";
import { toast } from "react-toastify";
import FlagNormalInfo from "../../components/Flags/FlagNormalInfo";
import FlagNormalSuccess from "../../components/Flags/FlagNormalSuccess";
import FlagNormalWarning from "../../components/Flags/FlagNormalWarning";
import FlagNormalDanger from "../../components/Flags/FlagNormalDanger";
import FlagDanger from "../../components/Flags/FlagDanger";

export function toastInfo(title, message) {
  toast(({ closeToast }) => (
    <FlagNormalInfo title={title} onDismiss={closeToast}>
      {typeof message === "function" ? message() : message}
    </FlagNormalInfo>
  ));
}

export function toastSuccess(title, message) {
  toast(({ closeToast }) => (
    <FlagNormalSuccess title={title} onDismiss={closeToast}>
      {typeof message === "function" ? message() : message}
    </FlagNormalSuccess>
  ));
}

export function toastWarning(title, message) {
  toast(({ closeToast }) => (
    <FlagNormalWarning title={title} onDismiss={closeToast}>
      {typeof message === "function" ? message() : message}
    </FlagNormalWarning>
  ));
}

export function toastDanger(title, message) {
  toast(({ closeToast }) => (
    <FlagNormalDanger title={title} onDismiss={closeToast}>
      {typeof message === "function" ? message() : message}
    </FlagNormalDanger>
  ));
}

export function toastCritical(title, message) {
  toast(({ closeToast }) => (
    <FlagDanger title={title} onDismiss={closeToast}>
      {typeof message === "function" ? message() : message}
    </FlagDanger>
  ));
}
