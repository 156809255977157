import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import Container from "../../Containers/Container";

const StyledTechnologyWeUseSection = styled.div`
  padding: ${props => (props.isMobile ? "40px 0 20px 0" : "75px 0")};
`;

const StypedTop = styled.div`
  margin-bottom: 50px;
`;

const Feature = styled.div`
  margin-bottom: 30px;
`;

const TechnologyBlock = styled(Container)`
  padding: 20px 18px;
  padding-left: 100px;
  position: relative;
  min-height: 150px;
  color: ${props => props.theme.white};
  & .title {
    color: ${props => props.theme.white};
    display: block;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.56;
  }

  & description {
    color: ${props => props.theme.white};
    display: block;
    font-size: 14px;
    line-height: 1.43;
  }
`;

const DarkBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blue5};
`;

const MediumBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blueBase};
`;

const LightBlueBlock = styled(TechnologyBlock)`
  background-color: ${props => props.theme.blue4};
`;

const StyledImage = styled.img`
  position: absolute;
  left: 20px;
  top: 20px;
  width: 60px;
`;

const TechnologyWeUseSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledTechnologyWeUseSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("what-technologies-do-we-use")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "with-experience-in-ecommerce,-web-applications,-and-websites,-we-can-create-the-perfect-digital-product-for-all-of-your-needs.-your-website-or-web-app-will-be-fully-responsive,-so-that-your-customers-can-access-it-from-desktop,-tablet,-and-smartphone"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <div className="row">
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <DarkBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_html-5.svg" />
                        <span className="title">HTML5</span>
                        <span className="description">
                          {translate(
                            "we-use-html5-for-contents-structure-and-presentation.-have-an-optimized-and-fast-website-created-with-the-latest-technologies"
                          )}
                        </span>
                      </DarkBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <DarkBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_mysql.svg" />
                        <span className="title">MYSQL</span>
                        <span className="description">
                          {translate(
                            "you-want-all-data-from-your-web-project-to-be-saved-and-stored-in-the-database?-along-with-the-mysql-database-we-can-achieve-that"
                          )}
                        </span>
                      </DarkBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <MediumBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_css-3.svg" />
                        <span className="title">CSS3</span>
                        <span className="description">
                          {translate(
                            "the-visual-appearance-of-your-website-is-most-important-to-customers-and-visitors.-mkhost-follows-the-latest-technologies.-with-css3-the-design-will-look-impeccable"
                          )}
                        </span>
                      </MediumBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <MediumBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_php.svg" />
                        <span className="title">PHP</span>
                        <span className="description">
                          {translate(
                            "the-most-famous-sites-in-the-world-are-made-with-the-php-programming-language-which-is-used-by-mkhost.-have-a-robust-and-fast-website"
                          )}
                        </span>
                      </MediumBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <LightBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_jquery.svg" />
                        <span className="title">JQUERY</span>
                        <span className="description">
                          {translate(
                            "would-you-like-to-have-animations-and-descending-effects-making-your-website-easy-to-use-by-customers"
                          )}
                        </span>
                      </LightBlueBlock>
                    </Feature>
                    <Feature className="col-12 col-sm-12 col-md-6">
                      <LightBlueBlock>
                        <StyledImage src="/assets/icons/app-bubbles-no-bg/bubble_icon_wordpress.svg" />
                        <span className="title">WordPress</span>
                        <span className="description">
                          {translate(
                            "let-your-website-be-based-on-the-most-famous-and-most-used-platform-on-the-internet"
                          )}
                        </span>
                      </LightBlueBlock>
                    </Feature>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledTechnologyWeUseSection>
    )}
  </Translate>
);
export default TechnologyWeUseSection;
