export default function manualCharConverter(word){

    word = word.replaceAll(' ', '-')
        .replaceAll(',', '')
        .replaceAll('/', '')
        .replaceAll('?', '')
        .replaceAll('"', '')
        .replaceAll('.', '')
        .replaceAll("'", '')
        .replaceAll('*', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('[', '')
        .replaceAll(']', '')
        .replaceAll('{', '')
        .replaceAll('}', '')


        .toLowerCase();

    let answer = "";
    let a = {
        'а' : 'a',
        'б' : 'b',
        'в' : 'v',
        'г' : 'g',
        'д' : 'd',
        'ѓ' : 'gj',
        'е' : 'e',
        'ж' : 'dz',
        'з' : 'z',
        'ѕ' : 'dze',
        'и' : 'i',
        'j' : 'j',
        'к' : 'k',
        'л' : 'l',
        'љ' : 'lj',
        'м' : 'm',
        'н' : 'n',
        'њ' : 'nj',
        'о' : 'o',
        'п' : 'p',
        'р' : 'r',
        'с' : 's',
        'т' : 't',
        'ќ' : 'kj',
        'у' : 'u',
        'ф' : 'f',
        'х' : 'h',
        'ц' : 'ts',
        'ч' : 'ch',
        'џ' : 'dz',
        'ш' : 'sh',


    }
    //
    // a["а"]="a";
    // a["б"]="b";
    // a["в"]="v";
    // a["г"]="g";
    // a["д"]="d";
    // a["ѓ"]="gj";
    // a["е"]="e";
    // a["ж"]="dz";
    // a["з"]="z";
    // a["ѕ"]="dze";
    // a["и"]="i";
    // a["ј"]="j";
    // a["к"]="k";
    // a["л"]="l";
    // a["љ"]="lj";
    // a["м"]="m";
    // a["н"]="n";
    // a["њ"]="nj";
    // a["о"]="o";
    // a["п"]="p";
    // a["р"]="r";
    // a["с"]="s";
    // a["т"]="t";
    // a["ќ"]="kj";
    // a["y"]="u";
    // a["ф"]="f";
    // a["х"]="h";
    // a["ц"]="u";
    // a["ч"]="ch";
    // a["џ"]="dz";
    // a["ш"]="sh";



    for(const letter of word){
        answer += a[letter] === undefined ? letter : a[letter];
    }

    return answer
}
