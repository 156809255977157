import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

import HostingPlan from "../../HostingPlan";

const StyledHostingPageHostingPlans = styled.div`
  padding-bottom: ${props => (props.isMobile ? "30px" : "75px")};
`;

const StyledBottomWrapper = styled.div`
  position: relative;
`;
const HostingPageHostingPlans = props => (
  <StyledHostingPageHostingPlans
    className="hosting-hosting-plans row"
    isMobile={isMobileOnly}
  >
    <StyledBottomWrapper className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="container">{props.children}</div>
      </div>
    </StyledBottomWrapper>
  </StyledHostingPageHostingPlans>
);

export default HostingPageHostingPlans;
