import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import check_success from "../../../assets/images/check_success.svg";

import SecondaryButton from "../../Buttons/SecondaryButton";
import SectionDescription from "../../SectionDescription";
import ExternalLink from "../../ExternalLink";

const StyledWhoIsServiceInfoSection = styled.div`
  padding-top: ${props => (props.isMobile ? "30px" : "120px")};
  padding-bottom: ${props => (props.isMobile ? "55px" : "150px")};
`;

const StyledInfo = styled.div`
  padding: ${props => !props.isMobile && "0 45px"};
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  line-height: 1.56;
  margin-bottom: 20px;
`;

const StyledContent = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.neutral6};
  margin-bottom: 30px;

  & p {
    margin: 0;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
`;

const WhoIsServiceInfoSection = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledWhoIsServiceInfoSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <StyledInfo
                  className="col-12 col-sm-12 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <StyledTitle>
                    {translate(
                      "pages.domains.whois.what-is-the-whois-service-for-mk-domains"
                    )}
                    ?
                  </StyledTitle>
                  <StyledContent>
                    {translate(
                      "pages.domains.whois.whois-information-is-public"
                    )}
                    :
                  </StyledContent>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("pages.domains.whois.registrant-information")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("pages.domains.whois.administrative-contact")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("pages.domains.whois.technical-contact")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate(
                      "pages.domains.whois.dns-servers-of-the-domain-name"
                    )}
                  </StyledBulletPoint>
                </StyledInfo>
                <StyledInfo
                  className="col-12 col-sm-12 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <StyledTitle>
                    {translate(
                      "pages.domains.whois.can-i-protect-my-privacy-for-mk-domains"
                    )}
                    ?
                  </StyledTitle>
                  <StyledContent>
                    <p>
                      {translate(
                        "pages.domains.whois.according-to-the-marnet-rulebook"
                      )}
                      .
                    </p>

                    <p>
                      {translate(
                        "pages.domains.whois.visit-the-marnet-rulebook"
                      )}
                      .
                    </p>
                  </StyledContent>
                  <ExternalLink href="http://marnet.mk/doc/pravilnik-mk-mkd.pdf">
                    <SecondaryButton>
                      {translate("pages.domains.whois.visit-marnet-rulebook")}
                    </SecondaryButton>
                  </ExternalLink>
                </StyledInfo>
                <StyledInfo
                  className="col-12 col-sm-12 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <StyledTitle>
                    {translate(
                      "pages.domains.whois.can-i-use-privacy-protection-on-my-international-domains"
                    )}
                    ?
                  </StyledTitle>
                  <StyledContent>
                    <p>
                      {translate(
                        "pages.domains.whois.mkhost-offers-privacy-protection"
                      )}
                      .
                    </p>

                    <p>
                      {translate(
                        "pages.domains.whois.if-you-purchase-this-addon"
                      )}
                      .
                    </p>
                  </StyledContent>
                  <ExternalLink
                    href={
                      activeLanguage &&
                      `https://mkhost.com/${
                        activeLanguage.code
                      }/other/knowledgebase/262`
                    }
                  >
                    <SecondaryButton>
                      {translate("pages.domains.whois.learn-more")}
                    </SecondaryButton>
                  </ExternalLink>
                </StyledInfo>
              </div>
            </div>
          </div>
        </div>
      </StyledWhoIsServiceInfoSection>
    )}
  </Translate>
);
export default WhoIsServiceInfoSection;
