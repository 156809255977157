import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import CircledIcon from "../../CircledIcon";
import Perk from "../../Perk";
import SupportPlan from "../../SupportPlan";
import FeaturesTable from "../../FeaturesTable";
import check_success from "../../../assets/images/check_success.svg";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {
  ROUTE_HOSTING_BUSINESS_HOSTING,
  ROUTE_HOSTING_WINDOWS_HOSTING,
  ROUTE_HOSTING_WORDPRESS_HOSTING,
  // ROUTE_SERVERS_CLOUD
} from "../../../routes";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Container from "../../Containers/Container";

const StyledWhyChooseWordpressHosting = styled.div`
  padding: 75px 0;
  padding-top: ${props => props.isMobile && "30px"};
`;

const Feature = styled.div`
  position: relative;
  margin-bottom: ${props => (props.isMobile ? "20px" : "30px")};
  padding-left: ${props => props.isMobile && "100px"};
  & .circled-icon {
    margin-bottom: 20px;
    position: ${props => props.isMobile && "absolute"};
    left: ${props => props.isMobile && "15px"};
    top: ${props => props.isMobile && "0"};
  }
`;
const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;
const StyledAvaiable = styled.img``;

const StyledSmallDescription = styled(SmallDescription)`
  margin-bottom: 50px;
`;

const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: 30px;
`;

const StyledCircledIcon = styled.div`
  & .circled-icon {
    transform: rotate(-90deg);
  }
`;

const StyledTop = styled.div`
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const StyledWrapper = styled.div`
  display: ${props => props.isMobile && "inline-block"};
`;
const tutorialTextMk = "Брз и едноставен <a href='https://mkhost.com/mk/other/email/' target='_blank'>туторијал</a> за подесување на твојот маил.";
const tutorialTextEN = "Fast and easy <a href='https://mkhost.com/en/other/email/' target='_blank'>tutorial</a> with instructions how to set up your email on any device.";
const WhyChooseWordpressHosting = () => (
    <Translate>
      {({translate, activeLanguage}) => (
          <StyledWhyChooseWordpressHosting className="row" isMobile={isMobileOnly}>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("fast-and-optimized-website")}
                          description={translate(
                              "number-one-configuration-ssd-raid"
                          )}
                          icon="ranking"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate(
                                "99-percent-quarante-time"
                            ),
                            translate("1000-views-per-day"),
                            translate(
                                "servers-connect-to-1gbps-network"
                            ),
                            translate("serves-in-europe-and-america")
                          ]}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("professional-email-address")}
                          description={translate(
                              "create-and-setup-a-professinal-email-that-contains-your-doman,-like-yourname@yourdomain.com"
                          )}
                          icon="email"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate("webmail-(access-your-mailbox-through-any-browser)"),
                            translate("set-up-you-email-addresses-on-any-smart-phone"),
                            activeLanguage && activeLanguage.code === 'mk' ? tutorialTextMk : tutorialTextEN,
                            // translate("fast-and-clean-tutorial-for-settuping-mail"),
                            translate("smtp,-imap-or-pop3")
                          ]}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("safe-data-fiirst")}
                          description={translate(
                              "mkhost-offers-free-regular-backups-of-all-of-your-data,-so-you-never-need-to-worry-about-loosing-any-of-your-emails,-databases-or-websites"
                          )}
                          icon="privacy"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate("1-copy-is-kept-on-monthly-basis"),
                            translate("three-months-backup"),
                            translate(
                                "protection-in-the-event-of-a-technical-problem,-natural-disaster-or-hack"
                            ),
                            translate("completely-free-and-available-at-any-time")
                          ]}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("authorized-partner-cpanel")}
                          description={translate(
                              "mkhost-authorized-panel"
                          )}
                          icon="cpanel"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate("unique-hosting-settings"),
                            translate(
                                "installing-app-on-hosting"
                            )
                          ]}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("easy-menagement")}
                          description={translate(
                              "client-portal-mkhost-available-full-control"
                          )}
                          icon="setup"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate("increment-resources"),
                            translate(
                                "easy-order-for-plus-functionality"
                            )
                          ]}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <Perk
                          isMobile={isMobileOnly}
                          title={translate("security-from-viruses")}
                          description={translate(
                              "imunify-use"
                          )}
                          icon="lock"
                          color="greenBase"
                          backgroundColor="green1"
                          items={[
                            translate("weekend-scan"),
                            translate(
                                "notification-for-virus-detected"
                            )
                          ]}
                      />
                    </div>
                  </div>
                </div>




              </div>
            </div>
          </StyledWhyChooseWordpressHosting>
      )}
    </Translate>
);
export default WhyChooseWordpressHosting;
