import React from "react";
import styled from "styled-components";

const StyledFormGroup = styled.div`
  position: relative;
`;

const FormGroup = ({ children, ...rest }) => (
  <StyledFormGroup {...rest} className={`form-group ${rest.className}`}>
    {children}
  </StyledFormGroup>
);
FormGroup.defaultProps = {
  className: "form-group"
};

export default FormGroup;
