import isEmpty from "lodash/isEmpty";
import trim from "validator/lib/trim";
import isUrl from "validator/lib/isURL";

import { getApplicationLocale } from "../services/localizationService";

import en from "../translations/en.json";
import mk from "../translations/mk.json";
const lang = {
  en: en,
  mk: mk
};

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const translate = key =>
  resolvePath(lang[getApplicationLocale()], key, undefined);

export function validateRequired(value, fieldName) {
  if (!value || isEmpty(value) || !value.length) {
    return translate("validation.required");
  }

  return undefined;
}

export function validateCompany(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-company");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateTaxNumber(value) {
  if (!value || isEmpty(value) || !value.length) {
    return translate("validation.enter-tax-number");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateFirstName(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-first-name");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateLastName(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-last-name");
  }

  const name = trim(value);
  return minLength(3)(name);
}

export function validateFullName(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-full-name");
    }
  }

  const fullName = value ? trim(value) : "";
  return minLength(3)(fullName);
}

export function validateCompanyName(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-company-name");
    }
  }

  const CompanyName = value ? trim(value) : "";
  return minLength(3)(CompanyName);
}

export function validateEmail(value) {
  if (!value) {
    return translate("validation.enter-email");
  }
  const email = trim(value);

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(email)) {
    return translate("validation.invalid-email");
  }

  return undefined;
}

export function validateCheckbox(value, errorMessageKey) {
  console.log(errorMessageKey)
  if (value === true) return undefined;
  if (value === false) return translate(errorMessageKey)
  if (value === undefined) return false;
}

export function validateRole(value) {
  return undefined;
}

export function validatePassword(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-password.");
  }

  const password = trim(value);

  //if (password.length < 8) {
  //  return translate("Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character.";
  //}
  //
  //!@#$%^&*()-_+=.
  if (!password.match(/^.*$/)) {
    return translate(
      "Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character."
    );
  }

  return undefined;
}

export function validatePasswordWithConfirm(value, confirmValue) {
  if (!confirmValue || isEmpty(value)) {
    return translate("validation.enter-confirm-password");
  }
  const password = trim(value);
  const confirmPassword = trim(confirmValue);

  if (!password.match(/^.*$/)) {
    return translate(
      "Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character."
    );
  }

  ///^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*\.+=<>?])[a-zA-Z0-9!@#$%^&*\.+=<>?]*$/
  if (!confirmPassword.match(/^.*$/)) {
    return translate(
      "Confirm Password must be at least 8 characters long and should contain least 1 uppercase letter, 1 number and 1 special character."
    );
  }

  if (value !== confirmPassword) {
    return translate("validation.password-confirm-no-match");
  }

  return undefined;
}

export function validateNotRequiredPassword(value) {
  if (!value || isEmpty(value)) {
    return undefined;
  }

  const password = trim(value);

  if (validatePassword(password) !== undefined) {
    return validatePassword(password);
  }

  return undefined;
}

export function validateAddress(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      //return translate("Please enter an Address.";
      return translate("validation.enter-address");
    }
  }

  const address = value ? trim(value) : "";
  return minLength(3)(address);
}

export function validatePhoneNumber(value, required = true) {
  if (required) {
    if (!value || isEmpty(value) || value.length === 0) {
      return translate("validation.enter-phone-number");
    }
  }

  const phone = trim(value);
  if (!phone.match(/\+\d{1,3}\.\d{8,12}/gm)) {
    return translate("validation.enter-phone-number");
  }

  return undefined;
}

export function validatePhoneNumberWithoutFormat(value, required = true) {
  if (required) {
    if (!value || isEmpty(value) || value.length === 0) {
      return translate("validation.enter-phone-number-simple");
    }
  }
  if (value && value.length > 0) {
    const phone = trim(value);
    if (!phone.match(/\d{5,12}/)) {
      return translate("validation.enter-phone-number-simple");
    }
  }
  return undefined;
}
export function validateWebsite(value) {
  if (!value || isEmpty(value)) {
    return translate("Please enter a Website.");
  }

  const website = trim(value);
  return isUrl(website, ["http", "https"])
    ? undefined
    : "Please enter a valid Website URL.";
}

export function validateCity(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-city");
  }
  const city = trim(value);
  return minLength(2)(city);
}

export function validateState(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-state");
  }
  const city = trim(value);
  return minLength(2)(city);
}

export function validateCountry(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-country");
  }
  const country = trim(value);
  return minLength(2)(country);
}

export function validateZipCode(value) {
  if (!value || isEmpty(value.toString())) {
    return translate("validation.enter-zip");
  }
  const zip = trim(value.toString());

  if (!/^\b\d{4}(-\d{4})?\b$/.test(zip)) {
    return translate("validation.invalid-zip");
  }

  return undefined;
}

export function validateTermsAndConditions(value) {
  if (!value || value === false) {
    return translate("validation.accept-terms");
  }
  return undefined;
}

export function validateBiggerOrEqualThan(value, biggerThan, required = true) {
  if (required) {
    if (value === "") {
      return translate("validation.required");
    }
  }
  const valueToCheck = parseFloat(value);
  if (isNaN(valueToCheck)) {
    return translate("Please enter a valid value.");
  }
  if (valueToCheck < biggerThan) {
    return translate("Please enter a value bigger or equal than ") + biggerThan;
  }

  return undefined;
}

export function validateMaxAmount(value, max) {
  if (!value) {
    return translate("validation.enter-amount");
  }
  if (isNaN(value)) {
    return translate("validation.enter-amount");
  }

  if (value > max) {
    return `Please enter a value up to ${max}`;
  }

  return undefined;
}

export function validateRangeAmount(value, min, max) {
  if (!value) {
    return translate("enter-amount");
  }
  if (isNaN(value)) {
    return translate("enter-amount");
  }

  if (value < min) {
    return translate("enter-amount-min").replace("${min}", min);
  }

  if (value > max) {
    return translate("enter-amount-max").replace("${max}", max);
  }

  return undefined;
}

export function validateNameserver(value, required = false) {
  if (required && !value) {
    return translate("validation.enter-nameserver");
  }
  if (value && /^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}$/g.test(value)) {
    return translate("validation.enter-nameserver");
  }
  return undefined;
}

export function validateSubject(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-subject");
    }
  }

  const subject = value ? trim(value) : "";
  return minLength(3)(subject);
}

export function validateMessage(value, required) {
  if (required) {
    if (!value || isEmpty(value)) {
      return translate("validation.enter-message");
    }
  }

  const message = value ? trim(value) : "";
  return minLength(3)(message);
}

// select box
export function validateDepartment(value, required) {
  if (required) {
    if (!value || !value.value) {
      return translate("validation.select-department");
    }
  }

  return undefined;
}

export function validatePriority(value, required) {
  if (!value || isEmpty(value.value)) {
    return translate("validation.enter-priority");
  }

  const priority = trim(value.value);
  return ["Low", "Medium", "High"].includes(priority)
    ? undefined
    : "validation.enter-priority";
}
//const maxLength = max => value =>
//  value && value.length > max
//    ? `Must be ${max} characters or less.`
//    : undefined;
const minLength = min => value =>
  value && value.length < min
    ? translate("validation.min-length").replace("${min}", min)
    : undefined;

// select box
export function validateSecurityQuestion(value, required) {
  if (required) {
    if (!value || !value.value) {
      return translate("validation.enter-securit-question.");
    }
  }

  return undefined;
}

export function validateSecurityAnswer(value, confirmationValue, required) {
  if (required) {
    if (
      !value ||
      isEmpty(value) ||
      !confirmationValue ||
      isEmpty(confirmationValue)
    ) {
      return translate("validation.enter-security-answer");
    }

    if (value !== confirmationValue) {
      return translate("validation.enter-security-confirmation-answer");
    }
  }

  return undefined;
}
export function validateDomain(value) {
  if (!value || value.length === 0) {
    return translate("validation.enter-domain");
  }
  if (value.endsWith(".мкд")) {
    if (
      /^[\u0400-\u04FF0-9][\u0400-\u04FF0-9-]{1,61}[\u0400-\u04FF0-9](?:\.[\u0400-\u04FF]{2,})+$/g.test(
        value
      )
    ) {
      return undefined;
    }
    return translate("validation.invalid-cyrillic-latin-domain");
  }

  if (!value.endsWith(".мкд")) {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}(?:\.[a-zA-Z]{2,})+$/g.test(value)) {
      return minLength(1)(value);
    } else {
      if (
        /^[a-zA-Z0-9\u0400-\u04FF-]{0,61}(?:\.[a-zA-Z0-9\u0400-\u04FF]{2,})+$/g.test(
          value
        )
      ) {
        return translate("validation.invalid-cyrillic-latin-domain");
      }

      return translate("validation.enter-domain");
    }
  }

  return undefined;
}

export function validateOnlyDomainName(value, extension) {
  if (!value || isEmpty(value)) {
    return translate("validation.enter-domain");
  }

  if (!extension || isEmpty(extension)) {
    return translate("validation.enter-extension");
  }

  if (Array.isArray(extension) && extension.length === 0) {
    return translate("validation.enter-extension");
  }

  const name = trim(value);
  if (extension.endsWith("мкд")) {
    if (name.length <= 2) {
      return translate("validation.mkd-domain-must-be-min-3-chars");
    }

    if (/^[\u0400-\u04FF0-9][\u0400-\u04FF0-9-]{0,61}$/g.test(name)) {
      return undefined;
    }

    return translate("validation.invalid-cyrillic-latin-domain");
  }

  if (extension.endsWith("al")) {
    if (name.length < 2) {
      return translate("validation.al-domain-must-be-min-2-chars");
    }
  }

  if (!extension.endsWith("мкд")) {
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}$/g.test(value)) {
      return minLength(1)(name);
    } else {
      if (/^[a-zA-Z0-9\u0400-\u04FF-]{0,61}$/g.test(name)) {
        return translate("validation.invalid-cyrillic-latin-domain");
      }

      return undefined;
    }
  }

  return minLength(3)(name);
}

export function validateWhoIsMkDomain(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.please-enter-a-domain-name");
  }

  const name = trim(value);
  if (minLength(1)(name)) {
    return minLength(3)(name);
  }

  if (!name.endsWith(".mk") && !name.endsWith(".мкд")) {
    return translate("validation.this-domain-is-not-valid");
  }
  return undefined;
}

export function validateExtension(value) {
  if (!value || isEmpty(value)) {
    return translate("validation.select-extension");
  }

  if (Array.isArray(value) && value.length === 0) {
    return translate("validation.select-extension");
  }

  return undefined;
}
