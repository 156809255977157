import React from "react";
import styled from "styled-components";
import { Form, Field, FieldArray, FormSpy } from "react-final-form";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import { FilesEncoder } from "../../common/filesEncoder";
import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import * as helpdeskService from "../../services/helpdeskService";

import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import InputField from "../../components/InputField";
import TextArea from "../../components/TextArea";
import FileUpload from "../../components/FileUpload";
import Checkbox from "../../components/Checkbox";
import Label from "../../components/Label";
import FormGroup from "../../components/FormGroup";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import OpenNewTicketForm from "./OpenNewTicketForm";

import { ROUTE_TICKET_CREATED } from "../../routes";

import {
  validateSubject,
  validateMessage,
  validateRequired,
  validatePriority,
  validateDepartment,
  validateFullName,
  validateEmail, validateCheckbox
} from "../../common/validationRules";

const StyledOpenTicket = styled.div``;

class OpenTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOpenTicket: false,
      submitting: false,
      invalid: false,
      departments: null
    };
  }

  onShow = () => {
    this.setState({ showOpenTicket: true });
  };

  onHide = () => {
    this.setState({ showOpenTicket: false });
  };

  prepareAttachments = files => {
    return new Promise((resolve, reject) => {
      FilesEncoder.createAttachments(files).then(result => {
        resolve(result);
      });
    });
  };

  remapTicketDepartments = (value, callback) => {
    helpdeskService.fetchTicketDepartments().then(result => {
      const { support_departments } = result.data.data;
      const deparments = support_departments.map(department => {
        return {
          label: department.name,
          value: department.id
        };
      });
      callback(deparments);
      this.setState({ deparments });
    });
  };

  validateTicket = values => {
    return {
      name: validateFullName(values.name, true),
      email: validateEmail(values.email),
      subject: validateSubject(values.subject, true),
      message: validateMessage(values.message, true),
      department: validateDepartment(values.department, true),
      priority: validatePriority(values.priority, true),
      pp: validateCheckbox(values.pp, "validation.accept-privacy-policy-global"),
      pp2:validateCheckbox(values.pp2, "validation.accept-privacy-policy-global")
    };
  };

  onSubmit = values => {
    this.setState({ submitting: true });
    if (values.attachments) {
      return this.prepareAttachments(values.attachments).then(attachments => {
        const ticket = {
          ...values,
          attachments
        };
        return this.doCreateTicket(ticket);
      });
    }
    return this.doCreateTicket(values);
  };

  doCreateTicket = values => {
    return helpdeskService.createTicket(values).then(result => {
      const { error } = result.data;
      const { ticket, messages } = result.data.data;

      this.onHide();
      if (!error) {
        this.props.history.push(ROUTE_TICKET_CREATED);
      }
      return ticket;
    });
  };

  render() {
    if (this.state.showOpenTicket) {
      return (
        <Translate>
          {({ translate }) => (
            <React.Fragment>
              <Modal
                title={translate("help-desk.ticket.open-new-ticket")}
                subTitle={translate(
                  "help-desk.ticket.open-new-ticket-description"
                )}
                onCloseModal={this.onHide}
                body={() => (
                  <OpenNewTicketForm
                    onSubmit={this.onSubmit}
                    fetchDepartments={this.remapTicketDepartments}
                    onCancel={this.onHide}
                    validate={this.validateTicket}
                    departments={this.state.deparments}
                    selectedDepartment={this.props.department || null}
                  />
                )}
              />
              {this.props.children()}
            </React.Fragment>
          )}
        </Translate>
      );
    }

    return this.props.children(this.onShow);
  }
}

export default withRouter(withLocalize(OpenTicket));
