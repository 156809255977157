import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledButtonGroup = styled.div`
  background-color: transparent;
  border-color: ${props => props.theme.neutral3};

  margin-right: 10px;
  &:only-child: {
    margin-right: 0;
  }
  & > button {
    margin-right: 0;
  }

  & > button:disabled {
    background-color: ${props => props.theme.white};
    border-color: ${props => props.theme.neutral3};
    color: ${props => props.theme.neutral4};
  }
`;

StyledButtonGroup.defaultProps = {
  className: "btn-group"
};

const ButtonGroup = props => (
  <StyledButtonGroup {...props} className={`btn-group ${props.className}`} />
);

export default ButtonGroup;
