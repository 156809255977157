import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import ContainerModal from "../../Containers/ContainerModal";
import SecondaryButton from "../../Buttons/SecondaryButton";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import BulletPoint from "../../BulletPoint";
import Tabs from "../../Tabs";
import Tab from "../../Tabs/Tab";
import TabContent from "../../Tabs/TabContent";

import check_success from "../../../assets/images/check_success.svg";
import arrow_right_success from "../../../assets/images/arrow-right-success.svg";

import { ROUTE_OTHER_KNOWLEDGEBASE } from "../../../routes";

const StyledTransferDomainStepsSection = styled.div`
  padding: ${props => (props.isMobile ? "0" : "75px 0")};
  margin: ${props => (props.isMobile ? "0" : "75px 0")};
  background-image: url(/assets/backgrounds/bg-5.png);
  background-position: center right;
  background-position-x: 480px;
  background-position-y: 195px;
  background-repeat: no-repeat;
  background-size: 70%;
`;

const StyledStepContainer = styled(ContainerModal)`
  padding: ${props => (props.isMobile ? "30px 20px" : "30px")};
  padding-top: 50px;
  margin-bottom: ${props => props.isMobile && "30px"};
`;

const StyledStep = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.neutral4};
  line-height: 1.38;
  text-transform: uppercase;
`;

const StyledStepTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  line-height: 1.56;
  margin-bottom: 20px;
`;

const StyledStepDescription = styled(SectionDescription)`
  margin-bottom: 50px;
`;

const StyledStepContent = styled.div`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.neutral6};
  margin-bottom: 30px;

  & p.description {
    margin: 0;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
`;

const UnderlinedBold = styled.span`
  font-weight: 600;
  line-height: 1.63;
  //text-decoration: underline dashed;
  text-decoration-color: ${props => props.theme.neutral6};
  text-underline-position: under;
`;

const TransferDomainStepsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledTransferDomainStepsSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("not-sure-where-to-start")}
                  </SectionTitle>
                  <StyledStepDescription>
                    {translate(
                      "here-are-a-few-simple-steps-you-should-follow-when-deciding-to-register-a-new-name"
                    )}
                    .
                  </StyledStepDescription>
                </div>
              </div>
              <Tabs
                defaultTab="macedonian-domains"
                renderTabs={(active, onSelect) => [
                  <Tab
                      key="key-macedonian-domains"
                      label="macedonian-domains"
                      active={active}
                      onSelect={onSelect}
                  >
                    {translate("macedonian-domains")}
                  </Tab>,
                  <Tab
                    key="key-international-domains"
                    label="international-domains"
                    active={active}
                    onSelect={onSelect}
                  >
                    {translate("international-domains")}
                  </Tab>

                ]}
                renderTabContent={active => (
                  <React.Fragment>
                    <TabContent
                      key="tab-content-international-domains"
                      label="international-domains"
                      active={active}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-4">
                          <StyledStepContainer isMobile={isMobileOnly}>
                            <StyledStep>{translate("step-1")}</StyledStep>
                            <StyledStepTitle>
                              {translate("prepare-your-domain")}
                            </StyledStepTitle>
                            <StyledStepContent isMobile={isMobileOnly}>
                              <p className="description">
                                {translate(
                                  "before-you-initiate-a-domain-transfer,-you-first-need-to-check-the-following"
                                )}
                                :
                              </p>
                              <BulletPoint>
                                {translate(
                                  "your-domain-should-be-registered-for-at-least"
                                )}{" "}
                                <UnderlinedBold>
                                  60 {translate("days")}
                                </UnderlinedBold>
                              </BulletPoint>
                              <BulletPoint>
                                <StyledCheck src={check_success} />{" "}
                                {translate("your-domain-should-be")}{" "}
                                <UnderlinedBold>
                                  {translate("unlocked")}
                                </UnderlinedBold>{" "}
                                {translate("at-your-current-registrar")}.
                              </BulletPoint>
                              <BulletPoint>
                                {translate(
                                  "your-email-address-should-be-listed-as"
                                )}{" "}
                                <UnderlinedBold>
                                  {translate("the-domain-contact")}
                                </UnderlinedBold>
                              </BulletPoint>
                            </StyledStepContent>
                          </StyledStepContainer>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                          <StyledStepContainer isMobile={isMobileOnly}>
                            <StyledStep>{translate("step-2")}</StyledStep>
                            <StyledStepTitle>
                              {translate("start-the-domain-transfer")}
                            </StyledStepTitle>
                            <StyledStepContent isMobile={isMobileOnly}>
                              <p className="description">
                                {translate(
                                  "to-begin-the-domain-transfer,-you-need-to-complete-the-following-steps"
                                )}
                              </p>
                              <BulletPoint>
                                {translate("obtain-the")}{" "}
                                <UnderlinedBold>
                                  {translate("epp")}
                                </UnderlinedBold>{" "}
                                {translate("code-from-your-current-registrar")}.
                              </BulletPoint>
                              <BulletPoint>
                                <UnderlinedBold>
                                  {translate("confirm-the-domain-transfer")}
                                </UnderlinedBold>{" "}
                                {translate(
                                  "from-the-email-that-will-be-sent-from-your-current-registrar"
                                )}
                                .
                              </BulletPoint>
                            </StyledStepContent>
                          </StyledStepContainer>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                          <StyledStepContainer isMobile={isMobileOnly}>
                            <StyledStep>{translate("step-3")}</StyledStep>
                            <StyledStepTitle>
                              {translate("complete-the-domain-transfer")}
                            </StyledStepTitle>
                            <StyledStepContent isMobile={isMobileOnly}>
                              <p className="description">
                                {translate(
                                  "there-are-3-options-to-complete-your-domain-transfer"
                                )}
                              </p>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate(
                                  "send-us-the-domain-name-and-epp-code"
                                )}{" "}
                                <strong>{translate("next-step-epp-code")}</strong>{" "}
                              </BulletPoint>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate("use-the-form-via-portal")}{" "}
                                {translate("step-by-ste-portal")}{" "}

                              </BulletPoint>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate("help-transfer")}{" "}
                                {translate("via-email")}{" "}
                                {translate("to")}.
                              </BulletPoint>
                            </StyledStepContent>
                            <Link to={ROUTE_OTHER_KNOWLEDGEBASE +'55'}>
                              <SecondaryButton size="l">
                                {translate("learn-more")}
                              </SecondaryButton>
                            </Link>
                          </StyledStepContainer>
                        </div>
                      </div>
                    </TabContent>
                    <TabContent
                      key="tab-content-macedonian-domains"
                      label="macedonian-domains"
                      active={active}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-4">
                          <StyledStepContainer isMobile={isMobileOnly}>
                            <StyledStep>{translate("step-1")}</StyledStep>
                            <StyledStepTitle>
                              {translate("start-the-domain-transfer")}
                            </StyledStepTitle>
                            <StyledStepContent isMobile={isMobileOnly}>
                              <p className="description">
                                {translate(
                                  "to-begin-the-domain-transfer,-you-need-to-complete-the-following-steps"
                                )}
                              </p>
                              <BulletPoint>
                                {translate("obtain-the")}{" "}
                                <UnderlinedBold>
                                  {translate("epp")}
                                </UnderlinedBold>{" "}
                                {translate("code-from-your-current-registrar")}.
                              </BulletPoint>
                            </StyledStepContent>
                          </StyledStepContainer>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                          <StyledStepContainer isMobile={isMobileOnly}>
                            <StyledStep>{translate("step-2")}</StyledStep>
                            <StyledStepTitle>
                              {translate("complete-the-domain-transfer")}
                            </StyledStepTitle>
                            <StyledStepContent isMobile={isMobileOnly}>
                              <p className="description">
                                {translate(
                                  "there-are-3-options-to-complete-your-domain-transfer"
                                )}
                              </p>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate(
                                  "send-us-the-domain-name-and-epp-code"
                                )}{" "}
                                {translate("next-step-epp-code")}
                              </BulletPoint>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate("use-the-form-via-portal")}{" "}
                                {translate("step-by-ste-portal")}{" "}
                              </BulletPoint>
                              <BulletPoint
                                type="arrow"
                                icon="arrow_right"
                                color="greenBase"
                              >
                                {translate("help-transfer")}{" "}
                                {translate("via-email")}{" "}
                                {translate("to")}.
                              </BulletPoint>
                            </StyledStepContent>

                            <Link to={ROUTE_OTHER_KNOWLEDGEBASE +'55'}>
                              <SecondaryButton size="l">
                                {translate("learn-more")}
                              </SecondaryButton>
                            </Link>
                          </StyledStepContainer>
                        </div>
                      </div>
                    </TabContent>
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        </div>
      </StyledTransferDomainStepsSection>
    )}
  </Translate>
);
export default TransferDomainStepsSection;
