import React from "react";
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

import * as cartService from "../../services/cartService";

import {
  Navigation,
  NavigationItem,
  DropdownNavigationItem,
  DropdownNavigationGroupItems
} from "../../components/Navigation";

import MobileMenu from "../../components/MobileMenu";

import { routes } from "../../routes";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
      cartItemsCount: 0
    };
  }

  componentDidMount() {
    cartService.all().then(result => {
      const { cart } = result.data.data;
      this.setState({
        cartItemsCount: cart.items.length
      });
      cartService.storeCartId(cart.id);
    });
  }

  onToggleMobileMenu = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu });
  };

  onHideMobileMenu = () => {
    this.setState({
      showMobileMenu: false
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <header>
            <Navigation
              onToggleMenu={this.onToggleMobileMenu}
              showMobileMenu={this.state.showMobileMenu}
              onHideMobileMenu={this.onHideMobileMenu}
              cartItemsCount={this.state.cartItemsCount}
            >
              {routes.map((route, index) =>
                route.groups && route.groups.length > 0 ? (
                  <DropdownNavigationItem
                    className={`nav-link ${
                      this.props.location.pathname.startsWith(route.path)
                        ? "active"
                        : ""
                    }`}
                    key={`nav-item-${index}`}
                    to={route.path}
                    title={translate(route.title)}
                    id={`menu-${index}`}
                  >
                    <DropdownNavigationGroupItems groups={route.groups} />
                  </DropdownNavigationItem>
                ) : (
                  <NavigationItem
                    key={`nav-item-${index}`}
                    to={route.path}
                    id={`menu-${index}`}
                    className={`nav-link ${
                      route.path === this.props.location.path ? "active" : ""
                    }`}
                  >
                    {translate(route.title)}
                  </NavigationItem>
                )
              )}
            </Navigation>
            {this.state.showMobileMenu && (
              <MobileMenu routes={routes} onHideMenu={this.onHideMobileMenu} />
            )}
          </header>
        )}
      </Translate>
    );
  }
}

export default withRouter(Header);
