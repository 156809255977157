import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import WhyChooseDedicatedServerSection from "../../components/Sections/WhyChooseDedicatedServerSection";
import ThirdPartyControlPanelSection from "../../components/Sections/ThirdPartyControlPanelSection";
import WhyHostWithMKhostSection from "../../components/Sections/WhyHostWithMKhostSection";
import SupportTypesSection from "../../components/Sections/SupportTypesSection";

import withLoading from "../../components/Loading/WithLoading";
import ContentLoader from "../../components/Loading/ContentLoader";

import DataContext from "../../DataContext";

import * as dedicatedServersService from "../../services/dedicatedServersService";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_DEDICATED_SERVERS } from "../../config/faq";

const StyledServersDedicatedPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: 81px;
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
`;

const serverPlansImages = {
  basic: "/assets/icons/icon_dedicated1.png",
  regular: "/assets/icons/icon_dedicated2.png",
  power: "/assets/icons/icon_dedicated3.png"
};

const HostingPlanWithLoading = withLoading(HostingPlan, ContentLoader);

class ServersDedicatedPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingDedicatedServers: true,
      dedicatedServers: null,
      isSubmitting: false,
      submittingId: 0
    };
  }

  componentDidMount() {
    dedicatedServersService.all().then(result => {
      const { servers_grouped } = result.data.data;
      this.setState({
        dedicatedServers: servers_grouped[0]["servers"] || null,
        isFetchingDedicatedServers: false
      });
    });
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledServersDedicatedPage className="row">
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].servers_dedicated.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].servers_dedicated.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].servers_dedicated
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].servers_dedicated.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].servers_dedicated
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting-dedicated.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].servers_dedicated.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].servers_dedicated
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_hosting-dedicated.png`}
                />
              </Helmet>
            )}
            <div className="col-12 col-sm-12 col-md-12">
              <Hero
                title={() => (
                  <span>
                    {translate("dedicated-plural")}
                    <br />
                    {translate("servers").toLowerCase()}
                  </span>
                )}
                subtitle={translate(
                  "do-you-want-complete-control-over-the-performance-of-your-hosting-choose-a-dedicated-server-for-optimal-space-and-maximum-security-ideal-for-large-online-businesses"
                )}
                image="/assets/illustrations/hosting/illustration_hosting-dedicated.png"
                backgroundImage="/assets/backgrounds/bg-1-1.svg"
                paddingBottom={isMobileOnly ? "0" : "0"}
                backgroundPosition={isMobileOnly && "center center"}
                backgroundPositionY={isMobileOnly && "0"}
                backgroundSize={isMobileOnly && "cover"}
              >
                <StyledBackgroundSpacer
                  isMobile={isMobileOnly}
                  height="208px"
                />
              </Hero>
              <HostingPageHostingPlans>
                <DataContext.Consumer>
                  {({ onAddToCart }) => {
                    return (
                      <div
                        key={`hosting-plans-${Math.random()}`}
                        className="row"
                      >
                        {this.state.isFetchingDedicatedServers &&
                          !this.state.dedicatedServers && (
                            <React.Fragment>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanWithLoading isLoading={true} />
                              </div>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanWithLoading isLoading={true} />
                              </div>
                              <div className="col-12 col-sm-12 col-md-4">
                                <HostingPlanWithLoading isLoading={true} />
                              </div>
                            </React.Fragment>
                          )}
                        {!this.state.isFetchingDedicatedServers &&
                          this.state.dedicatedServers &&
                          this.state.dedicatedServers.map(server => {
                            if (
                              server.pricing.buy.billing_cycle.derivations
                                .length === 0
                            )
                              return;
                            
                            let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];  
                            let pricing = null;

                            // const pricing = server.pricing.buy.billing_cycle.derivations.find(
                            //   d => d.numeric_code === 0.1 //monthly
                            // );

                            // Temporary solution, should be sync with api derivation numeric codes
                            possiblePeriodNumericCodes.some(numericCode => {
                              pricing = server.pricing.buy.billing_cycle.derivations.find(
                                d => d.numeric_code === numericCode
                              );

                              if (typeof(pricing) !== 'undefined') {
                                // console.log(numericCode);
                                return true;
                              }
                            });
                            // END

                            
                            if (!pricing) {
                              return;
                            }
                            return (
                              <div
                                key={`server-plan-${server.id}`}
                                className="col-12 col-sm-12 col-md-4"
                              >
                                <HostingPlan
                                  title={server.name}
                                  description={server.details.description}
                                  descriptionClassName={
                                    isMobileOnly && "col-6 col-sm-6 col-md-6"
                                  }
                                  image={
                                    serverPlansImages[server.name.toLowerCase()]
                                  }
                                  imageWidth="200px"
                                  imageHeight="120px"
                                  price={pricing.price.value}
                                  period={translate(
                                    `derivations.${pricing.name.toLowerCase()}`
                                  )}
                                  currency={pricing.price.currency.code}
                                  additional={server.details.extra}
                                  additionalPrices={server.details.prices}
                                  features={server.details.features
                                    .filter((feature) => !feature.toLowerCase().includes('self-managed'))
                                   }
                                  tooltips={server.details.tooltips}
                                  newFlag={server.details.is_new}
                                  newContent={server.details.is_new_title}
                                  saleFlag={server.details.on_sale}
                                  saleContent={server.details.on_sale_title}
                                  action={() => (
                                    <PrimaryButton
                                      size="l"
                                      className="w-100"
                                      disabled={
                                        this.state.isSubmitting &&
                                        this.state.submittingId === server.id
                                      }
                                      submitting={
                                        this.state.isSubmitting &&
                                        this.state.submittingId === server.id
                                      }
                                      onClick={() => {
                                        this.setState({
                                          isSubmitting: true,
                                          submittingId: server.id
                                        });
                                        onAddToCart({
                                          cart: this.props.cart
                                            ? this.props.cart.id
                                            : null,
                                          type: "dedicated_server",
                                          quantity: 1,
                                          config: {
                                            type: "buy",
                                            period:
                                              server.pricing.buy.billing_cycle
                                                .derivations[0].numeric_code
                                          },
                                          dedicated_server_id: server.id
                                        }).then(() => {
                                          this.setState({
                                            isSubmitting: false,
                                            submittingId: 0
                                          });
                                        });
                                        // onShowAssignDomainToHosting(server)
                                      }}
                                    >
                                      {translate("order-now")}
                                    </PrimaryButton>
                                  )}
                                />
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                </DataContext.Consumer>
              </HostingPageHostingPlans>
              <WhyChooseDedicatedServerSection />
              <SupportTypesSection />

              <MostPopularArticlesSection
                faqCategory={FAQ_CATEGORY_DEDICATED_SERVERS}
              />
            </div>
          </StyledServersDedicatedPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(ServersDedicatedPage);
