import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  margin: 0 auto;
  position: relative;
  display: inline-block;
`;

const StyledImage = styled.img`
  width: 830px;
  top: 0;
  left: 0;
`;

const StyledEmail = styled.span`
  display: block;
  position: absolute;
  left: 120px;
  top: 50px;
  z-index: 1;
  font-size: 13px;
`;

const ThunderbirdPopServerSettingsImage = ({ email, image }) => (
  <StyledWrapper>
    <StyledImage src={image} className="img-fluid" />
  </StyledWrapper>
);

export default ThunderbirdPopServerSettingsImage;
