import React from "react";
import styled from "styled-components";

const StyledDropdownNavigationItem = styled.span`
  & {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 10px 20px !important;
    color: ${props => props.theme.blueBase};
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }

  &.active {
    border-bottom: 2px solid ${props => props.theme.blueBase};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover {
    background-color: ${props => props.theme.blue1};
    cursor: pointer;
  }

  & .dropdown-navigation-menu {
    display: ${props => (props.show ? "block" : "none")};
  }
`;

const StyledDropdownMenu = styled.div.attrs({
  className: "dropdown-navigation-menu"
})`
  display: none;
  position: fixed;
  top: 56px; // body top padding (71) - navigation padding (15)) = 56px
  left: 0;
  padding-top: 25px;
  width: 100%;
  background-color: ${props => props.theme.white};

  & > .row {
    border-top: 1px solid ${props => props.theme.neutral3};
    border-bottom: 1px solid ${props => props.theme.neutral3};
    padding-top: 40px;
    padding-bottom: 40px;
    box-shadow: 0 3px 5px 0 rgba(123, 139, 167, 0.25);
  }
`;

class DropdownNavigationItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }

  onHover = () => {
    this.setState({
      show: true
    });
    document.addEventListener("click", this.onClick);
  };

  onLeave = () => {
    this.setState({
      show: false
    });
    document.removeEventListener("click", this.onClick);
  };

  onClick = () => {
    this.setState({
      show: false
    });
    document.removeEventListener("click", this.onClick);
  };

  render() {
    const { children, title, ...rest } = this.props;
    return (
      <StyledDropdownNavigationItem
        onMouseEnter={this.onHover}
        onMouseLeave={this.onLeave}
        show={this.state.show}
        {...rest}
      >
        {title}
        <StyledDropdownMenu>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">{children}</div>
              </div>
            </div>
          </div>
        </StyledDropdownMenu>
      </StyledDropdownNavigationItem>
    );
  }
}

export default DropdownNavigationItem;
