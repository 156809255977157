import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Perk from "../../Perk";
import IconTextInfo from "../../IconTextInfo";

const StyledWhyChooseCloudHosting = styled.div`
  padding: ${props => (props.isMobile ? "30px 0 0 0" : "75px 0")};
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const StyledHeader = styled.h2`
  font-size: 36px;
  color: #263857;
  font-weight: 600;
  margin-bottom: 18px;
`;

const StyledSpan = styled.span`
  font-weight: 600;
`;

const WhyChooseCloudHosting = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledWhyChooseCloudHosting className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-7">
                  <StyledHeader>
                    {translate("why-choose-cloud-hosting")}?
                  </StyledHeader>
                  <SectionDescription>
                    {translate(
                      "when-you-have-a-quickly-growing-website,-shared-hosting-might-not-be-enogh.-cloud-hosting-allows-you-to-utilize-the-resources-of-multiple-servers,-which-makes-allocating-additional-power-and-space-easy,-allowing-for-unlimited-expansion-and-growth"
                    )}
                    .
                  </SectionDescription>
                </div>


                <div className="row">
                  <IconTextInfo
                      icon={'lock'}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"data-security"}
                      description={"cloud-hosting-stores-copies"}
                  />
                  <IconTextInfo
                      icon={'contacts'}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"user-managed"}
                      description={"unlimited-user-access-and-control-over-the-entire-server"}
                  />
                  <IconTextInfo
                      icon={'ranking'}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"flexibility"}
                      description={"your-cloud-resources-are-completely-flexible"}
                  />

                  <IconTextInfo
                      icon={'setup'}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"scalability"}
                      description={"as-your-business-grows-you-can-easily-allocate"}
                  />

                  <IconTextInfo
                      icon={'input-checkbox '}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"guaranteed-uptime"}
                      description={"of-the-servers-stops-working"}
                  />
                  <IconTextInfo
                      icon={'files'}
                      iconColor={'blueBase'}
                      iconBgColor={'blue1'}
                      title={"data-storage"}
                      description={"back-up-7-days"
                      }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWhyChooseCloudHosting>
    )}
  </Translate>
);
export default WhyChooseCloudHosting;
