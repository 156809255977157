import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import SupportPlan from "../../SupportPlan";
import FeaturesTable from "../../FeaturesTable";
import check_success from "../../../assets/images/check_success.svg";
import parse from "html-react-parser";
import SectionTitle from "../../SectionTitle";
const StyledAvaiable = styled.img``;

const FeaturesTableSection = styled.div`
  margin-top: -159px;
  padding-bottom: 75px;
`;


const features = [

  {
    name: {
      en: "CPU",
      mk: "CPU"
    },
    "1vCPU": "1vCPU",
    "2vCPU": "2vCPU",
    "4vCPU": "4vCPU"
  },
  {
    name: {
      en: "RAM",
      mk: "RAM"
    },
    "1vCPU": "1GB RAM",
    "2vCPU": "4 GB RAM",
    "4vCPU": "16GB RAM"
  },
  {
    name: {
      en: "Hosting space",
      mk: "Хостинг простор"
    },
    "1vCPU": "40GB",
    "2vCPU": "80GB",
    "4vCPU": "160GB"
  },
  {
    name: {
      en: "Bandwidth",
      mk: "Сообраќај"
    },
    "1vCPU": "unlimited",
    "2vCPU": "unlimited",
    "4vCPU": "unlimited"
  },
  {
    name: {
      en: "Full root access",
      mk: "Full root access"
    },
    "1vCPU": true,
    "2vCPU": true,
    "4vCPU": true
  },
  {
    name: {
      en: "Hosting resources",
      mk: "Дедицирани ресурси"
    },
    "1vCPU": true,
    "2vCPU": true,
    "4vCPU": true
  },
  {
    name: {
      en: "Self-managed",
      mk: "Self-managed"
    },
    "1vCPU": true,
    "2vCPU": true,
    "4vCPU": true
  },
  {
    name: {
      en: "Choosing OS",
      mk: "Избор оперативен систем"
    },
    "1vCPU": true,
    "2vCPU": true,
    "4vCPU": true
  },
  {
    name: {
      en: "Snapshot from the last 7 days",
      mk: "Snapshot од претходните 7 дена"
    },
    "1vCPU": true,
    "2vCPU": true,
    "4vCPU": true
  }

];
const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;


const TechnicalCharacteristicsCloud = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
        <>
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <div className="container">
        <StypedTop className="row">
          <div className="col-12 col-sm-12 col-md-8">
            <SectionTitle>
              {translate("detailed-technical-characteristics-of-hosting-plans")}
            </SectionTitle>
          </div>
        </StypedTop>
              </div>
            </div>
          </div>

            <div style={{"margin":"50px 0"}}>&nbsp;</div>

        <FeaturesTableSection
            className="row no-gutters"
            isMobile={isMobileOnly}
        >
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row no-gutters">
              <div className="container">
                <div className="row no-gutters">
                  <div className="col-12 col-sm-12 col-md-3" />
                  <div className="col-12 col-sm-12 col-md-9">
                    <div className="row no-gutters">
                      <div
                          className="col-12 col-sm-12 col-md-4"
                          key={`ssl-${Math.random()}`}
                      >
                        <SupportPlan
                            firstChild={true}
                            className={'text-center'}
                            title={"1vCPU"}
                        />
                        {isMobileOnly && (
                            <FeaturesTable className="features-table row no-gutters">
                              <div className="col-12 col-sm-12 col-md-12">
                                {features.map(
                                    (feature) => (
                                        <div
                                            key={`feature-ssl-${Math.random()}`}
                                            className="row no-gutters feature-row"
                                        >
                                          <div className="col-12 col-sm-12 col-md-9">
                                            <div className="row no-gutters">
                                              <div className="col-12 col-sm-12 col-md-4">

                                                {activeLanguage &&
                                                (
                                                    <div className="feature-value text-center">
                                                      {feature.name[activeLanguage.code]}
                                                      {feature.translate ?
                                                          (
                                                              <div>
                                                                {feature["1vCPU"][activeLanguage.code]}
                                                              </div>
                                                          )
                                                          :  (<div>
                                                            {feature["1vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["1vCPU"])}
                                                          </div>)

                                                      }
                                                    </div>
                                                )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    )
                                )}
                              </div>
                            </FeaturesTable>
                        )}
                      </div>
                      <div
                          className="col-12 col-sm-12 col-md-4"
                          key={`ssl-${Math.random()}`}
                      >
                        <SupportPlan
                            className={'text-center'}
                            firstChild={false}
                            title={"2vCPU"}
                        />
                        {isMobileOnly && (
                            <FeaturesTable className="features-table row no-gutters">
                              <div className="col-12 col-sm-12 col-md-12">
                                {features.map(
                                    (feature) => (
                                        <div
                                            key={`feature-ssl-${Math.random()}`}
                                            className="row no-gutters feature-row"
                                        >
                                          <div className="col-12 col-sm-12 col-md-9">
                                            <div className="row no-gutters">
                                              <div className="col-12 col-sm-12 col-md-4">

                                                {activeLanguage &&
                                                (
                                                    <div className="feature-value text-center">
                                                      {feature.name[activeLanguage.code]}
                                                      {feature.translate ?
                                                          (
                                                              <div>
                                                                {feature["2vCPU"][activeLanguage.code]}
                                                              </div>
                                                          )
                                                          :  (<div>
                                                            {feature["2vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["2vCPU"])}
                                                          </div>)

                                                      }
                                                    </div>
                                                )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    )
                                )}
                              </div>
                            </FeaturesTable>
                        )}
                      </div>
                      <div
                          className="col-12 col-sm-12 col-md-4"
                          key={`ssl-${Math.random()}`}
                      >
                        <SupportPlan
                            firstChild={false}
                            className={'text-center'}
                            title={"4vCPU"}
                        />
                        {isMobileOnly && (
                            <FeaturesTable className="features-table row no-gutters">
                              <div className="col-12 col-sm-12 col-md-12">
                                {features.map(
                                    (feature) => (
                                        <div
                                            key={`feature-ssl-${Math.random()}`}
                                            className="row no-gutters feature-row"
                                        >
                                          <div className="col-12 col-sm-12 col-md-9">
                                            <div className="row no-gutters">
                                              <div className="col-12 col-sm-12 col-md-4">

                                                {activeLanguage &&
                                                (
                                                    <div className="feature-value text-center">
                                                      {feature.name[activeLanguage.code]}
                                                      {feature.translate ?
                                                          (
                                                              <div>
                                                                {feature["4vCPU"][activeLanguage.code]}
                                                              </div>
                                                          )
                                                          :  (<div>
                                                            {feature["4vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["4vCPU"])}
                                                          </div>)
                                                      }
                                                    </div>
                                                )
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    )
                                )}
                              </div>
                            </FeaturesTable>
                        )}
                      </div>

                    </div>
                  </div>
                </div>

                {!isMobileOnly && (
                    <FeaturesTable className="row no-gutters">
                      <div className="col-12 col-sm-12 col-md-12">
                        {features.map(
                            (feature) => (
                                <div
                                    key={`feature-ssl-${Math.random()}`}
                                    className="row no-gutters feature-row"
                                >
                                  <div className="col-12 col-sm-12 col-md-3 feature-wrapper">
                                    <div className="feature">
                                      {activeLanguage &&
                                      (feature.name[activeLanguage.code])
                                      }
                                    </div>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-9">
                                    <div className="row no-gutters">
                                      {feature.translate ?
                                          (
                                              <>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {activeLanguage &&
                                                    parse(feature["1vCPU"][activeLanguage.code])
                                                    }
                                                  </div>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {activeLanguage &&
                                                    parse(feature["2vCPU"][activeLanguage.code])
                                                    }
                                                  </div>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {activeLanguage &&
                                                    parse(feature["4vCPU"][activeLanguage.code])
                                                    }
                                                  </div>
                                                </div>
                                              </>
                                          ) : (
                                              <>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {feature["1vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["1vCPU"])}
                                                  </div>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {feature["2vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["2vCPU"])}
                                                  </div>
                                                </div>
                                                <div
                                                    className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                    key={`ssl-feature-${Math.random()}`}
                                                >
                                                  <div className="feature-value text-center">
                                                    {feature["4vCPU"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["4vCPU"])}
                                                  </div>
                                                </div>
                                              </>
                                          )}


                                    </div>
                                  </div>
                                </div>
                            )
                        )}
                      </div>
                    </FeaturesTable>
                )}
              </div>
            </div>
          </div>
        </FeaturesTableSection>
        </>
    )}
  </Translate>
);
export default TechnicalCharacteristicsCloud;
