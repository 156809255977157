import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Form, Field } from "react-final-form";
import { withRouter } from "react-router-dom";
import {ROUTE_OFFER_SENT, ROUTE_WEBSOLUTION_SENT} from "../../../routes";

import * as quoteService from "../../../services/quoteService";

import {
  validateFullName,
  validateEmail,
  validatePhoneNumberWithoutFormat,
  validateMessage
} from "../../../common/validationRules";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Container from "../../Containers/Container";
import InputField from "../../InputField";
import TextArea from "../../TextArea";
import PrimaryButton from "../../Buttons/PrimaryButton";
import {createWebSolution} from "../../../services/quoteService";

const StyledProjectQuoteFormSection = styled.div``;
const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "20px" : "40px")};
`;

const validate = values => {
  return {
    full_name: validateFullName(values.full_name, true),
    email: validateEmail(values.email, true),
    phone: validatePhoneNumberWithoutFormat(
      values.phone,
      false
    ),
    message: validateMessage(values.message, true)
  };
};

class OtherWebSolutionsForm extends React.Component {
  onSubmit = values => {
    values.mail_title = 'Добиј веб решение'
    return quoteService
      .createWebSolution(values)
      .then(() => this.props.history.push(ROUTE_WEBSOLUTION_SENT));
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledProjectQuoteFormSection className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledContainer isMobile={isMobileOnly}>
                <Form
                  validate={validate}
                  onSubmit={this.onSubmit}
                  render={({ handleSubmit, invalid, submitting }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("name")}
                            placeholder={translate("enter-name")}
                            name="full_name"
                          />
                          <Field
                            component={InputField}
                            label={translate("email")}
                            placeholder={translate("enter-email")}
                            name="email"
                          />
                          <Field
                              component={InputField}
                              label={translate("phone-number-optional")}
                              placeholder={translate("your-phone-number")}
                              name="phone"
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={TextArea}
                            rows="9"
                            name="message"
                            label={translate("young-enterpre-must-message")}
                            placeholder={translate(
                                "tell-us-more-about-your-business-and-why-you-think-you-should-be-chosen-as-the-winner"
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 offset-md-6 text-right">
                          <PrimaryButton
                            disabled={invalid || submitting}
                            submitting={submitting}
                          >
                            {translate("enter-to-win")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </form>
                  )}
                />
              </StyledContainer>
            </div>
          </StyledProjectQuoteFormSection>
        )}
      </Translate>
    );
  }
}

export default withRouter(OtherWebSolutionsForm);
