import React from "react";
import styled from "styled-components";

const ICON_SIZE_SMALL = "20px";
const ICON_SIZE_MEDIUM = "24px";
const ICON_SIZE_LARGE = "30px";
const ICON_SIZE_EXTRA_LARGE = "40px";

const StyledIcon = styled.span.attrs(({ iconsize }) => ({
  iconsize: props => {
    if (!props.size) return ICON_SIZE_MEDIUM;
    switch (props.size.toLowerCase()) {
      case "s":
      case "small":
        return ICON_SIZE_SMALL;

      case "m":
      case "medium":
      case "d":
      case "default":
        return ICON_SIZE_MEDIUM;

      case "l":
      case "large":
        return ICON_SIZE_LARGE;

      case "xl":
      case "extra-large":
        return ICON_SIZE_EXTRA_LARGE;

      default:
        return ICON_SIZE_MEDIUM;
    }
  }
}))`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: ${props => props.iconsize};
  top: -1px;
  vertical-align: middle;
`;

const Icon = props => (
  <StyledIcon
    {...props}
    className={`icon icon-${props.icon} ${props.className}`}
  />
);
export default Icon;
