export const FAQ_CATEGORY_GENERAL = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_GENERAL
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_GENERAL
  : null;

export const FAQ_CATEGORY_DOMAINS = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_DOMAINS
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_DOMAINS
  : null;

export const FAQ_CATEGORY_HOSTING = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_HOSTING
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_HOSTING
  : null;

export const FAQ_CATEGORY_DEDICATED_SERVERS = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_DEDICATED_SERVERS
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_DEDICATED_SERVERS
  : null;

export const FAQ_CATEGORY_CLOUD_SERVERS = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_CLOUD_SERVERS
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_CLOUD_SERVERS
  : null;

export const FAQ_CATEGORY_WEB_DESIGN = process.env
  .REACT_APP_MKHOST_FAQ_CATEGORY_WEB_DESIGN
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_WEB_DESIGN
  : null;

export const FAQ_CATEGORY_SSL = process.env.REACT_APP_MKHOST_FAQ_CATEGORY_SSL
  ? process.env.REACT_APP_MKHOST_FAQ_CATEGORY_SSL
  : null;
