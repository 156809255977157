import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly, isTablet } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import Perk from "../../Perk";

const StyledWhatWillYouGetSection = styled.div`
  padding: ${props => !props.isMobile && "0px"};
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("/assets/backgrounds/bg-3-1.svg");
  background-repeat: no-repeat;
  background-position: top;
`;

const StyledTop = styled.div`
  margin-bottom: ${props => (props.isMobile ? "0px" : "40px")};
  padding-top: ${props => (props.isTablet ? "48px" : "130px")};
`;

const StyledBottom = styled.div`
  background-color: ${props => props.theme.blue1};
  padding-bottom: 130px;
`;

const WhatWillYouGetSection = props => (
  <Translate>
    {({ translate }) => (
      <StyledWhatWillYouGetSection
        className="row"
        isMobile={isMobileOnly}
        isTablet={isTablet}
      >
        <StyledTop className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("what-will-you-get-if-you-win")}?
                  </SectionTitle>
                </div>
              </div>
            </div>
          </div>
        </StyledTop>
        <StyledBottom className="col-12 col-sm-12 col-md-12">
          <div>
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate("web-hosting")}
                      description={translate(
                        "fast-and-reliable-space-where-you-can-host-your-website-and-emails"
                      )}
                      icon="hosting"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        parse(translate("2-gb-space")),
                        parse(translate("1-domain")),
                        parse(translate("100gb-bandwidth")),
                        parse(translate("unlimited-email-addresses"))
                      ]}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate("domain-name")}
                      description={parse(
                        translate(
                          "choose-the-best-domain-name-for-your-business-with-a-mk-extension"
                        )
                      )}
                      icon="domains"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        parse(
                          translate("instant-activation-in-only-5-minutes")
                        ),
                        parse(translate("free-redirection-dns-hosting"))
                      ]}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate("website-design")}
                      description={translate(
                        "stand-out-from-other-businesses-and-get-a-custom-website-design-created-specifically-for-your-needs"
                      )}
                      icon="websites"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        translate("latest-web-development-technologies"),
                        parse(translate("responsive-design")),
                        parse(translate("custom-style"))
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledBottom>
      </StyledWhatWillYouGetSection>
    )}
  </Translate>
);

export default WhatWillYouGetSection;
