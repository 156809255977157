import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../Containers/Container";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomTooltip from "../CustomTooltip";

import { ROUTE_DOMAINS_REGISTER } from "../../routes";

const StyledRegisterDomainWidget = styled.div`
  display: inline-block;
  width: 190px;
  height: 230px;
  vertical-align: top;
  margin-right: 21px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 20px;

  border: 1px solid
    ${props => {
      if (props.isNew) return props.theme.greenBase;
      if (props.isOnSale) return props.theme.redBase;
      return props.theme.neutral3;
    }};
`;

const StyledContainerBadge = styled.span`
  display: inline-block;
  position: absolute;
  top: -15px;
  left: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 6px 11px;
  border-radius: 14px;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.isNew) return props.theme.greenBase;
    if (props.isOnSale) return props.theme.redBase;
  }};
  color: ${props => props.theme.white};
`;

const StyledExtension = styled.h3`
  display: block;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledDiscountedPrice = styled.span`
  display: block;
  font-size: 16px;
  color: ${props => props.theme.neutral5};
  text-decoration: line-through;
`;
const StyledPrice = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin-bottom: 45px;
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const RegisterDomainWidget = ({
  extension,
  price,
  period,
  currency,
  discountedPrice,
  isNew,
  isOnSale,
  onSelectExtension
}) => (
  <Translate>
    {({ translate, activeLanguage }) => {
      return (
        <StyledRegisterDomainWidget className="domain-widget">
          <StyledContainer isNew={isNew} isOnSale={isOnSale}>
            {isNew && (
              <StyledContainerBadge isNew={isNew}>
                {translate("general.new")}
              </StyledContainerBadge>
            )}
            {isOnSale && (
              <StyledContainerBadge isOnSale={isOnSale}>
                {translate("general.sale")}
              </StyledContainerBadge>
            )}
            <StyledExtension>{extension}</StyledExtension>
            <StyledDiscountedPrice>
              {/* prettier-ignore */}
              {discountedPrice && currency}
              {discountedPrice || (
                <span className="invisible">{extension}</span>
              )}
            </StyledDiscountedPrice>
            <StyledPrice>
              {currency.toUpperCase()} {price}
              <StyledPeriod>
                /
                {currency.toLowerCase() === "мкд" && period !== "year"
                  ? period.slice(0, 3)
                  : period}
                {currency.toLowerCase() === "мкд" && (
                  <CustomTooltip
                    inline={true}
                    content={translate("price-without-vat")}
                  >
                    <span>*</span>
                  </CustomTooltip>
                )}
              </StyledPeriod>
            </StyledPrice>

            <PrimaryButton
              onClick={() => {
                typeof onSelectExtension === "function" &&
                  onSelectExtension(extension);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              size="l"
            >
              {activeLanguage && activeLanguage.code === "mk"
                ? translate("register")
                : translate("general.register-now")}
            </PrimaryButton>
          </StyledContainer>
        </StyledRegisterDomainWidget>
      );
    }}
  </Translate>
);
export default RegisterDomainWidget;
