import React from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";
import axios from "axios";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import LandingPageHostingPlans from "../../components/Sections/LandingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import CustomControlPanelSection from "../../components/Sections/CustomControlPanelSection";
import OneClickAppInstallSection from "../../components/Sections/OneClickAppInstallSection";

import {ROUTE_HOSTING_WEB_HOSTING, ROUTE_OTHER_FIRST_WEBSITE, ROUTE_WEBSITE_DOMAIN} from "../../routes";

import seoData from "../../seo/seoData";

import defaultBlogPostImage from "../../assets/images/defaultBlogPostImage.svg";
import { FAQ_CATEGORY_GENERAL } from "../../config/faq";

import ProgressBarDonation from "../../components/ProgressBars/ProgressBarSuccess";
import * as donationService from "../../services/donationService";

const BlogPosts = styled.div`
  padding: 75px 0;
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
`;

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { blogPosts: null,  donationPercentage: null};
  }

  componentDidMount() {
    axios
      .get("https://blog.mkhost.com.mk/wp-json/wp/v2/posts?per_page=4&_embed")
      .then(result => this.setState({ blogPosts: result.data }));


      // donationService.fetchDonationPercentage().then(
      //   result => this.setState({ donationPercentage: result.data.data.donation })
      // );
  }

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>{seoData[activeLanguage.code].homepage.title}</title>
                <meta
                  name="keywords"
                  content={seoData[activeLanguage.code].homepage.meta_keywords}
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].homepage.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].homepage.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].homepage.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_home-new.png`}
                />

                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].homepage.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].homepage.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_home-new.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("pages.home.title")}
              image="/assets/illustrations/illustration_home_main.png"
              backgroundImage="/assets/backgrounds/bg-1.svg"
              backgroundSize={isMobileOnly ? "cover" : "contain"}
              backgroundPosition={isMobileOnly ? "center center" : "bottom"}
              paddingBottom={isMobileOnly ? "70px" : "140px"}
              subtitle={translate("pages.home.description")}

            >
              <StyledBackgroundSpacer
                height={isMobileOnly ? "250px" : "130px"}
              />
              <DomainsSearchSection
                searchPlaceholder={translate(
                  "enter-a-domain-name-you-are-looking-for"
                )}
                type="register"
              />
            </Hero>
           <LandingPageHostingPlans />
            <PremiumPackageSection />
            <CustomControlPanelSection />
            <OneClickAppInstallSection />
            <TestimonialsSection />
            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_GENERAL} />
            {this.state.blogPosts &&
              activeLanguage &&
              activeLanguage.code === "mk" && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <div className="container">
                        <BlogPosts>
                          {this.state.blogPosts.map(post => (
                            <BlogPostCard
                              key={`bp-${post.id}`}
                              title={post.title.rendered}
                              link={post.link}
                              image={
                                post._embedded &&
                                post._embedded["wp:featuredmedia"]
                                  ? post._embedded["wp:featuredmedia"][0]
                                      .media_details.sizes.medium_large
                                      .source_url
                                  : defaultBlogPostImage
                              }
                            />
                          ))}
                        </BlogPosts>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default withRouter(HomePage);
