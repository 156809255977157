import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import ContainerModal from "../../Containers/ContainerModal";
import Perk from "../../Perk";
import SecondaryButton from "../../Buttons/SecondaryButton";

import { ROUTE_ADDONS_SUPPORT } from "../../../routes";

const StyledSupportTypesSection = styled.div`
  padding: 75px 0;
  background-image: url("/assets/backgrounds/bg-6-1.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: ${props => (props.isMobile ? "90%" : "30%")};
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 30px;
`;

const StyledContainerModal = styled(ContainerModal)`
  padding: ${props => (props.isMobile ? "40px 20px" : "50px 30px")};
  padding-bottom: ${props => (props.isMobile ? "20px" : "40px")};
`;

const StyledImage = styled.img`
  width: 150%;
  max-width: 150%;
  margin-left: -210px;
  margin-top: 50px;
`;

const SupportTypesSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledSupportTypesSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledContainerModal isMobile={isMobileOnly}>
                    <Perk
                      title={translate("standard-support")}
                      subtitle={translate("macedonian-&-english")}
                      description={translate(
                        "every-mkhost-client-receives-personalized-treatment-and-support-every-issue-and-problem-is-treated-professionally-and-responsibly-as-quickly-as-possible"
                      )}
                      hideIcon={true}
                      items={[
                        translate("247-support"),
                        translate("personal-support-and-sales-agents"),
                        translate(
                          "access-to-a-large-and-ever-growing-knowledgebase"
                        )
                      ]}
                    />
                  </StyledContainerModal>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-4 text-center"
                  style={{ height: isMobileOnly && "250px" }}
                >
                  {!isMobileOnly && (
                    <StyledImage
                      className="img-fluid"
                      src="/assets/illustrations/illustration_support.png"
                    />
                  )}
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledContainerModal isMobile={isMobileOnly}>
                    <Perk
                      title={translate("premium-support")}
                      subtitle={translate("macedonian-&-english")}
                      description={translate(
                        "you-can-purchase-our-premium-support-addon-for-priority-in-probelm-solving-and-additional-benefits-including-the-following"
                      )}
                      hideIcon={true}
                      items={[
                        translate("help-with-setting-up-email-accounts"),
                        translate("resolving-ip-blocking-issues"),
                        translate("installing-platforms-and-themes")
                      ]}
                    />
                    <a
                      href={ROUTE_ADDONS_SUPPORT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledSecondaryButton size="l">
                        {translate("learn-more")}
                      </StyledSecondaryButton>
                    </a>
                  </StyledContainerModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledSupportTypesSection>
    )}
  </Translate>
);

export default SupportTypesSection;
