import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SmallTitle from "../../SmallTitle";
import CircledIcon from "../../CircledIcon";
import BulletPoint from "../../BulletPoint";

const StyledWhyPurchaseBusinessHostingSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "75px 0")};

  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const StyledCol = styled.div`
  padding-left: ${props => !props.isMobile && "130px"};
  margin-bottom: ${props => props.isMobile && "50px"};
`;

const WhyPurchaseBusinessHostingSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhyPurchaseBusinessHostingSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("why-host-your-website-with-mkhost-business")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "businesses-often-have-more-specific-needs-when-it-comes-to-hosting-websites-and-applications.-this-includes-more-robust-solutions,-flexibility,-and-scalability"
                    )}
                  </SectionDescription>
                  <SectionDescription>
                    {translate(
                      "our-business-plans-allow-your-hosting-to-grow-with-your-business,-ensuring-that-you-always-have-adequate-space-and-traffic-bandwidth-to-meet-your-needs"
                    )}
                  </SectionDescription>
                </div>
                <StyledCol
                  className="col-12 col-sm-12 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <CircledIcon
                    icon="question"
                    color="greenBase"
                    backgroundColor="green1"
                  />
                  <SmallTitle>
                    {translate("what-does-my-company-and-business-get")}
                  </SmallTitle>
                  <BulletPoint
                    type="arrow"
                    icon="arrow_right"
                    color="greenBase"
                  >
                    {translate(
                      "greater-technical-performances-ideal-for-larger-businesses-with-larger-technical-needs"
                    )}
                  </BulletPoint>
                  <BulletPoint
                    type="arrow"
                    icon="arrow_right"
                    color="greenBase"
                  >
                    {translate("premium-support-and-service-priority")}
                  </BulletPoint>
                  <BulletPoint
                    type="arrow"
                    icon="arrow_right"
                    color="greenBase"
                  >
                    {translate(
                      "enhanced-spam-protection-and-security-make-sure-your-data-is-safe"
                    )}
                  </BulletPoint>
                  <BulletPoint
                    type="arrow"
                    icon="arrow_right"
                    color="greenBase"
                  >
                    {translate(
                      "a-choice-of-10+-free-business-templates-to-use-for-your-website"
                    )}
                  </BulletPoint>
                </StyledCol>
                <StyledCol
                  className="col-12 col-sm-12 col-md-4"
                  isMobile={isMobileOnly}
                >
                  <CircledIcon
                    icon="question"
                    color="blueBase"
                    backgroundColor="blue1"
                  />
                  <SmallTitle>
                    {translate("what-do-my-clients-and-customers-get")}
                  </SmallTitle>
                  <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                    {translate(
                      "uptime-guarantees-your-customers-will-always-be-served"
                    )}
                  </BulletPoint>
                  <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                    {translate(
                      "speeds-guarantee-your-websites-and-apps-will-always-load-fast"
                    )}
                  </BulletPoint>
                  <BulletPoint type="arrow" icon="arrow_right" color="blueBase">
                    {translate(
                      "secure-servers-make-sure-that-your-customer-s-data-and-privacy-is-safe"
                    )}
                  </BulletPoint>
                </StyledCol>
              </div>
            </div>
          </div>
        </div>
      </StyledWhyPurchaseBusinessHostingSection>
    )}
  </Translate>
);
export default WhyPurchaseBusinessHostingSection;
