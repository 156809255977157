import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import Perk from "../../Perk";
import questionGreen from "../MoreAboutTLDsSection/question-green.svg";

const StyledWhyHostWithMKHostSection = styled.div`
  padding: ${props => !props.isMobile && "75px 0"};
`;

const StyledTop = styled.div`
  margin-bottom: 40px;
`;

const ReadMoreAboutWebSolution = props => (
  <Translate>
    {({ translate }) => (
      <StyledWhyHostWithMKHostSection className="row" isMobile={isMobileOnly}>
        <StyledTop className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <div style={{"margin":"100px 0"}}>&nbsp;</div>
                  <SectionTitle>
                    {translate("read-more-about-project")}
                  </SectionTitle>
                </div>
              </div>
            </div>
          </div>
        </StyledTop>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    isMobile={isMobileOnly}
                    title={translate("why-we-do-it")}
                    description={translate(
                      "inspiration-from-macedonian-creativity-people"
                    )}
                    icon="question"
                    color="greenBase"
                    backgroundColor="green1"
                    items={[
                      translate(
                        "our-goal-is"
                      ),
                      translate("we-love-to-support-macedonian-people"),

                    ]}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    isMobile={isMobileOnly}
                    title={translate("who-can-attend")}
                    description={translate(
                      "with-this-opportunity"
                    )}
                    icon="contacts"
                    color="greenBase"
                    backgroundColor="green1"
                    items={[
                      translate("workshop"),
                      translate("designed-clothes"),
                      translate("handmade")
                    ]}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    isMobile={isMobileOnly}
                    title={translate("the-gift-is")}
                    description={translate(
                      "the-offer-will-be-full-online-solution"
                    )}
                    icon="websites"
                    color="greenBase"
                    backgroundColor="green1"
                    items={[
                      translate("first-gift-solution"),
                      translate(
                        "mk-domains-one-year"
                      ),
                      translate("online-shop-solution")
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWhyHostWithMKHostSection>
    )}
  </Translate>
);

export default ReadMoreAboutWebSolution;
