import React from "react";
import styled from "styled-components";
import { Translate, withLocalize } from "react-localize-redux";
import ExternalLink from "../ExternalLink/ExternalLink";

const StyledMarnetLogo = styled.img`
  width: 100px;
  margin-right: 15px;
`;

const AdditionalMarnetInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  margin-bottom: 15px;
`;

const MarnetInfoDiv = styled.div`
display:flex;
align-items:center;`

const MarnetInfoComponent = ({ translate, activeLanguage }) => (
    <AdditionalMarnetInfo>
        <MarnetInfoDiv>
            <a href="https://marnet.mk" target="_blank">
                <StyledMarnetLogo alt="Marnet" src="/assets/images/marnet_logo.png" />
            </a>
            <ExternalLink
                underline
                className="nav-link-marnet text-uppercase ml-auto"
                href="https://mkhost-public-assets.s3.eu-central-1.amazonaws.com/pravilnik-mk-mkd.pdf"
            >
                {translate("marnet-info")}
            </ExternalLink>
        </MarnetInfoDiv>
        <div>

            <ExternalLink
                underline
                small
                className="nav-link-marnet text-uppercase ml-auto"
                href={
                    activeLanguage.code === "mk"
                        ? "https://mkhost-public-assets.s3.eu-central-1.amazonaws.com/baranje-za-registracija.pdf"
                        : "https://mkhost-public-assets.s3.eu-central-1.amazonaws.com/baranje_en.pdf"
                } >
                {translate("marnet-request")}
            </ExternalLink>

        </div>
    </AdditionalMarnetInfo>
);

export default withLocalize(MarnetInfoComponent);
