import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import * as mailchimpService from "../../services/mailchimpService";
import { Field, Form } from "react-final-form";
import {
  validateCheckbox,
  validateEmail,
  validateFirstName,
  validateLastName
} from "../../common/validationRules";

import { ROUTE_OTHER_FREE_EBOOK_THANK_YOU } from "../../routes";

import ContainerModal from "../Containers/ContainerModal";
import Icon from "../Icon";
import InputField from "../InputField";
import PrimaryButton from "../Buttons/PrimaryButton";
import Checkbox from "../Checkbox";

const MODAL_SIZE_SMALL = "400px";
const MODAL_SIZE_DEFAULT = "600px";
const MODAL_SIZE_LARGE = "800px";
const MODAL_SIZE_EXTRA_LARGE = "1000px";
const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9999;
  overflow-y: auto;
`;
const StyledCheckbox = styled.div`
  & {
    width: 100%;
    cursor: pointer;
   
    padding-left: 0;
    margin-bottom:10px;
  }

`;

const StyledModal = styled.div.attrs({
  modalsize: props => {
    if (!props.size) return MODAL_SIZE_DEFAULT;
    switch (props.size.toLowerCase()) {
      case "s":
      case "small":
        return MODAL_SIZE_SMALL;

      case "m":
      case "medium":
      case "d":
      case "default":
        return MODAL_SIZE_DEFAULT;

      case "l":
      case "large":
        return MODAL_SIZE_LARGE;

      case "xl":
      case "extralarge":
      case "extra-large":
        return MODAL_SIZE_EXTRA_LARGE;

      default:
        return MODAL_SIZE_DEFAULT;
    }
  }
})`

  display: block;
  margin-top: 100px;
  position: relative;
  min-height: 70%;

  & > .modal-dialog {
    font-family: "Open Sans", sans-serif;
    width: ${props => (props.isMobile ? "95%" : props.modalsize)};
    max-width: ${props => (props.isMobile ? "95%" : props.modalsize)};
  }

  & > .modal-dialog > .modal-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${props => props.theme.neutral6};
  }

  & > .modal-dialog > .modal-content .modal-header {
    padding: ${props => (props.isMobile ? "15px" : "30px")};
    padding-bottom: 15px;
    border-bottom: ${props =>
      props.size === "s" ? 0 : `1px solid ${props => props.theme.neutral3}`};
  }

  & > .modal-dialog > .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${props => props.theme.neutralBase};
  }

  & > .modal-dialog > .modal-content .modal-header .modal-subTitle {
    font-size: 16px;
    line-height: 1.5l
    color: ${props => props.theme.neutral6};
    margin-bottom: 0;
  }

  & > .modal-dialog > .modal-content .modal-header .close {
    margin: ${props =>
      props.isMobile ? "-5rem -1.5rem -1rem auto" : "-3rem -1.5rem -1rem auto"};
    outline: 0;
  }

  & > .modal-dialog > .modal-content > .modal-body {
    padding: ${props => (props.size === "s" ? "0 30px" : "30px 30px")};
  }

  & > .modal-dialog > .modal-content > .modal-footer {
    padding: 30px;
    border-top: ${props =>
      props.size === "s" ? 0 : `1px solid ${props => props.theme.neutral3}`};
  }

  & > .modal-dialog > .modal-content > .modal-footer .btn:last-child {
    margin-right: 0;
  }
  & > .modal-dialog > .modal-content > .modal-footer .ghost-button {
    margin-right: auto !important;
  }
`;

const StyledEbookImage = styled.img`
  width: ${props => (props.isMobile ? "120px" : "140px")};
  position: absolute;
  top: ${props => !props.isMobile && "-60px"};
`;

const StyleHeaderTitleWrapper = styled.div`
  padding-left: ${props => (props.isMobile ? "125px" : "150px")};
`;

const modalRoot = document.getElementById("modal-root");

const validate = values => {
  return {
    first_name: validateFirstName(values.first_name, true),
    last_name: validateLastName(values.last_name, true),
    email: validateEmail(values.email, true),
    pp: validateCheckbox(values.pp, "validation.accept-privacy-policy-global"),
    pp2:validateCheckbox(values.pp2, "validation.accept-privacy-policy-global")
  };
};

class SubscribeFreeEbookModal extends React.Component {
  onSubmit = values => {
    return mailchimpService
      .subscribe(values)
      .then(() => this.props.history.push(ROUTE_OTHER_FREE_EBOOK_THANK_YOU));
  };

  renderModal = () => {
    return (
      <StyledModalWrapper>
        <Translate>
          {({ translate }) => (
            <StyledModal
              className="modal"
              tabindex="-1"
              role="dialog"
              size={isMobileOnly ? "95%" : this.props.size}
              isMobile={isMobileOnly}
            >
              <div className="modal-dialog" role="document">
                <ContainerModal className="modal-content">
                  <div className="modal-header d-block">
                    <div className="d-flex position-relative">
                      <StyledEbookImage
                        isMobile={isMobileOnly}
                        src="/assets/illustrations/illustration_free_ebook_launch_small.png"
                        alt="Бесплатна е-книга од МКхост"
                      />
                      <StyleHeaderTitleWrapper isMobile={isMobileOnly}>
                        <h5 className="modal-title">
                          Бесплатна е-книга од МКхост
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={e => this.props.onCloseModal()}
                        >
                          <span aria-hidden="true">
                            <Icon icon="close" />
                          </span>
                        </button>
                        <p className="modal-subTitle">
                          Научи успешно да го лансираш твојот онлајн бизнис!
                          Симни го нашиот прирачник и започни со твојот прв веб
                          сајт.
                        </p>
                      </StyleHeaderTitleWrapper>
                    </div>
                  </div>
                  <div className="modal-body">
                    <Form
                      validate={validate}
                      onSubmit={this.onSubmit}
                      render={({ handleSubmit, invalid, submitting }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <Field
                                component={InputField}
                                label={translate(
                                  "sections.free-ebook.first-name"
                                )}
                                placeholder={translate(
                                  "sections.free-ebook.first-name-placeholder"
                                )}
                                name="first_name"
                              />
                              <Field
                                component={InputField}
                                label={translate(
                                  "sections.free-ebook.last-name"
                                )}
                                placeholder={translate(
                                  "sections.free-ebook.last-name-placeholder"
                                )}
                                name="last_name"
                              />
                              <Field
                                component={InputField}
                                label={translate("sections.free-ebook.email")}
                                placeholder={translate(
                                  "sections.free-ebook.email-placeholder"
                                )}
                                name="email"
                              />

                              <br/>
                              <br/>
                              <Field
                                  component={Checkbox}
                                  value={true}
                                  label={translate("sections.free-ebook.agree-privacy-policy")}
                                  name="pp"
                              />
                              <br/>

                              <Field
                                  component={Checkbox}
                                  value={true}
                                  label={translate("sections.free-ebook.agreement")}
                                  name="pp2"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 text-right">
                              <PrimaryButton
                                disabled={invalid || submitting}
                                submitting={submitting}
                              >
                                {translate("sections.free-ebook.download")}
                              </PrimaryButton>
                            </div>


                          </div>
                        </form>
                      )}
                    />
                  </div>
                  {typeof this.props.footer === "function" && (
                    <div className="modal-footer">{this.props.footer()}</div>
                  )}
                </ContainerModal>
              </div>
            </StyledModal>
          )}
        </Translate>
      </StyledModalWrapper>
    );
  };

  render() {
    const Modal = this.renderModal();
    return ReactDOM.createPortal(Modal, modalRoot);
  }
}

SubscribeFreeEbookModal.defaultProps = {
  body: () => <p>No content...</p>
};

export default withRouter(SubscribeFreeEbookModal);
