import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { isMobileOnly } from "react-device-detect";

import Container from "../Containers/Container";
import InputField from "../InputField";
import SelectBox from "../SelectBox";
import PrimaryButton from "../Buttons/PrimaryButton";

const StyledKnowledgeBaseSearch = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;

  & .form-group {
    margin: 0;
  }

  & .knowledgebase-search-input {
    width: ${props => (props.isMobile ? "100%" : "77%")};
    display: inline-block;
    margin-right: ${props => !props.isMobile && "20px"};
    margin-bottom: ${props => props.isMobile && "15px"};
  }

  & .btn {
    display: inline-block;
    width: ${props => (props.isMobile ? "100%" : "21%")};
    vertical-align: top;
    margin-right: 0;
  }
`;

const KnowledgeBaseSearch = props => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="knowledgebase-search">
          <Container>
            <StyledKnowledgeBaseSearch isMobile={isMobileOnly}>
              <Form
                onSubmit={props.onSubmit}
                validate={props.validate}
                initialValues={{
                  search: props.search || ""
                }}
                render={({ handleSubmit, submitting, invalid, values }) => (
                  <form onSubmit={handleSubmit} id="knowledgebase-search-form">
                    <Field
                      component={InputField}
                      className="knowledgebase-search-input"
                      name="search"
                      size="l"
                      placeholder={props.searchPlaceholder}
                    />

                    <PrimaryButton
                      type="submit"
                      disabled={submitting}
                      submitting={submitting}
                      size="l"
                    >
                      {translate("search-knowledgebase")}
                    </PrimaryButton>
                  </form>
                )}
              />
            </StyledKnowledgeBaseSearch>
          </Container>
        </div>
      )}
    </Translate>
  );
};
export default KnowledgeBaseSearch;
