import React from "react";
import styled, { css } from "styled-components";
import { theme } from "../../design/Themes";
import { isBrowser, isMobileOnly, isTablet } from "react-device-detect";

const StyledExternalLink = styled.a`
font-size: ${(isMobileOnly || isTablet) ? "8px" : "14px"};
  font-weight: 400;
  line-height: 1.38;
  color: ${props => props.color || theme.blueBase};
  text-decoration: ${props => (props.underline ? "underline" : "none")};
  ${props =>
    props.small &&
    css`с
      font-size: 14px;
      color: ${props.color ? props.color : theme.neutralBase}; 
    `}
`;

const ExternalLink = ({ color, ...props }) => (
  <StyledExternalLink {...props} target="_blank" rel="noreferrer noopener" color={color} />
);

export default ExternalLink;
