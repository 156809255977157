import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Badge from "../Badge";

const BadgeDanger = styled(Badge)`
  background-color: ${props => props.theme.red1}
  color: ${props => props.theme.red3};
`;

export default BadgeDanger;
