import React from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";

const StyledNavigationItem = styled(NavLink)`
  & {
    border-radius: 3px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.2px;
    padding: 10px 20px !important;
    color: ${props => props.theme.blueBase};
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  &.active {
    border-bottom: 2px solid ${props => props.theme.blueBase};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:hover {
    background-color: ${props => props.theme.blue1};
  }
`;

const NavigationItem = ({ children, ...rest }) => (
  <StyledNavigationItem {...rest}>{children}</StyledNavigationItem>
);
export default NavigationItem;
