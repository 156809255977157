import axios from "axios";
import {
  DOMAIN_AVAILABILITY_ENDPOINT,
  DOMAINS_LISTING_API_ENDPOINT,
  DOMAINS_LISTING_BY_GROUP_API_ENDPOINT,
  DOMAINS_SUGGESTIONS_ENDPOINT,
  DOMAINS_WHOIS_API_ENDPOINT
} from "./endpoints";

export function isDomainAvailable(domain) {
  return axios.get(
    `${DOMAIN_AVAILABILITY_ENDPOINT}/${encodeURIComponent(domain.toLowerCase())}`
  );
}

export function fetchDomainsPrices() {
  return axios.get(`${DOMAINS_LISTING_API_ENDPOINT}`);
}

export function fetchDomainsPricesByGroup() {
  return axios.get(DOMAINS_LISTING_BY_GROUP_API_ENDPOINT);
}

export function domainWhoIs(domain) {
  return axios.get(
    `${DOMAINS_WHOIS_API_ENDPOINT}/${encodeURIComponent(domain)}`
  );
}

export function domainSuggestionsByGroup(domain, group) {
  return axios.get(`${DOMAINS_SUGGESTIONS_ENDPOINT}/${domain}/${group}`);
}
