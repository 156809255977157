import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  HOSTING_LISTING_API_ENDPOINT,
  HOSTING_LISTING_BY_GROUP_API_ENDPOINT,
  HOSTING_API_ENDPOINT
} from "./endpoints";

export function all() {
  return axios.get(HOSTING_LISTING_API_ENDPOINT);
}

export function allByGroup() {
  return axios.get(HOSTING_LISTING_BY_GROUP_API_ENDPOINT);
}
