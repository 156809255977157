import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";

const StyledMKhostClientsSection = styled.div`
  padding: ${props => (props.isMobile ? "40px 0" : "75px 0")};
  margin: ${props => !props.isMobile && "150px 0"};
  background-image: url("/assets/backgrounds/bg-6.svg");
  background-position: center;
  background-position-x: ${props => !props.isMobile && "200px"};
  background-repeat: no-repeat;
  background-size: ${props => (props.isMobile ? "cover" : "50%")};
`;

const StyledVerticalSlider = styled.div`

  float:right;
  overflow: hidden;
  position: relative;
  height: 470px;

  &:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  //box-shadow:inset 0px 20px 26px -6px #fff,inset 0px -20px 26px -6px #fff;
  }

  & .slider-track {
    animation: logo-scroll 10s linear infinite;
    display: block;
    height: calc(190px * 7);
    animation-duration: 20s;
    animation-iteration-count: infinite;
  }

  & .slider-track .slide {
    display: block;
    margin-bottom: 30px;
  }
  & .slider-track .slide img {
    width: 189px;
    height: 120px;
    display: inline-block;
    margin-left: 15px;
    margin:right: 15px;
  }

  & .white-shadow-top{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 2;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 33%,rgba(255,255,255,0) 100%);
  }

 & .white-shadow-bottom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 67%,rgba(255,255,255,1) 100%);
 }

  @keyframes logo-scroll {
	0% {
  			-webkit-transform: translateY(400px);
		transform: translateY(400px);

	}

	100% {


    -webkit-transform: translateY(calc(-150px * 7));
		transform: translateY(calc(-150px * 7));

	}
}
`;

const MKhostClientsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledMKhostClientsSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate(
                      "join-the-top-companies-who-have-registered-with-us"
                    )}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "some-of-the-leading-macedonian-companies-have-entrusted-us-with-their-domain-registration.-join-them-and-over-2,000-other-individuals-and-companies-who-have-registered-a-domain-with-us."
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <StyledVerticalSlider>
                    <div className="white-shadow-top" />
                    <div className="slider-track">
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_atlantis.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_adora.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_badel.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_bucenkozjak.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_capitol.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_cineplexx.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_duna.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_eyehospital.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_hbo.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_hyundai.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_lukoil.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_peugeot.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_procredit.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_prospera.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_sistina.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_stonebridge.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_termalift.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_tikves.png"
                        />
                      </div>
                      <div className="slide">
                        <img
                          alt=""
                          src="/assets/images/partners/partner_tocka.png"
                        />
                        <img
                          alt=""
                          src="/assets/images/partners/partner_zikol.png"
                        />
                      </div>
                    </div>
                    <div className="white-shadow-bottom" />
                  </StyledVerticalSlider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledMKhostClientsSection>
    )}
  </Translate>
);
export default MKhostClientsSection;
