export default function tutorialImagesMap(items) {
  let images = {};
  items.forEach(item => {
    const value = item;
    const key = item.split(/[/]+/).pop();
    images[`${key}`] = value;
  });

  return images;
}
