import React from "react";
import { Translate } from "react-localize-redux";
import styled, { keyframes } from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import ContainerFlag from "../../Containers/ContainerFlag";
import PrimaryLink from "../../Links/PrimaryLink";
import PrimaryButton from "../../Buttons/PrimaryButton";

import bubble_ajax from "../../../assets/bubbles/bubble_ajax.svg";
import bubble_angular from "../../../assets/bubbles/bubble_angular.svg";
import bubble_dolphin from "../../../assets/bubbles/bubble_dolphin.svg";
import bubble_dropzonejs from "../../../assets/bubbles/bubble_dropzonejs.svg";
import bubble_drupal from "../../../assets/bubbles/bubble_drupal.svg";
import bubble_hubzilla from "../../../assets/bubbles/bubble_hubzilla.svg";
import bubble_joomla from "../../../assets/bubbles/bubble_joomla.svg";
import bubble_magento from "../../../assets/bubbles/bubble_magento.svg";
import bubble_movabletype from "../../../assets/bubbles/bubble_movabletype.svg";
import bubble_phplist from "../../../assets/bubbles/bubble_phplist.svg";
import bubble_pyro from "../../../assets/bubbles/bubble_pyro.svg";
import bubble_wordpress from "../../../assets/bubbles/bubble_wordpress.svg";
import bubble_zenkart from "../../../assets/bubbles/bubble_zenkart.svg";

import { ROUTE_HOSTING_WEB_HOSTING } from "../../../routes";

const StyledOneClickAppInstall = styled.div`
  background-color: ${props => props.theme.blue1};
  padding: ${props => (props.isMobile ? "250px 0 50px 0" : "68px 0")};
  position: relative;
`;

const StyledContainer = styled(ContainerFlag)`
  padding: ${props => (props.isMobile ? "40px 25px" : "40px 70px")};
`;

const StyledTitle = styled.h3`
  margin: 0;
  font-size: ${props => (props.isMobile ? "28px" : "34px")};
  font-weight: 600;
  line-height: ${props => (props.isMobile ? "1.36" : "1.41")};
  text-align: center;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 10px;
  display: block;
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: ${props => (props.isMobile ? "16px" : "18px")};
  line-height: ${props => (props.isMobile ? "1.63" : "1.56")};
  text-align: center;
  color: ${props => props.theme.neutral6};
  margin-bottom: 30px;
  display: block;
`;

const StyledLink = styled(PrimaryLink)`
  display: inline-block;
`;

const FadeIn = keyframes`
    from { opacity: 0; }
  to { opacity: 1; }
`;

const Float = keyframes`
  from { transform: translateX(110%) translateZ(0); }

  to { transform: translateX(-100%) translateZ(0); }
`;

const FadeFloat = keyframes`
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
`;

const Apps = styled.div`
  position: absolute;

  top: ${props => (props.isMobile ? "-240px" : "0")};
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  overflow: hidden;
  animation: ${FadeIn} 3s ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-play-state: running;
  user-select: none;
`;

const App = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  height: ${props => (props.isMobile ? "80px" : "120px")};
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-image: url(${props => props.image});
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-name: ${Float}, ${FadeFloat};
  top: 0;
  right: 0;
  z-index: 1;
`;

const AppAjax = styled(App)`
  top: 0;
  right: 5%;
`;

const AppAngular = styled(App)`
  top: 120px;
  right: 15%;
`;

const AppDolphin = styled(App)`
  top: 150px;
  right: ${props => (props.isMobile ? "-13%" : "-3px")};
`;

const AppDropzone = styled(App)`
  top: ${props => (props.isMobile ? "40px" : "5px")};
  right: ${props => (props.isMobile ? "-23%" : "-13%")};
`;

const AppDrupal = styled(App)`
  top: 125px;
  right: ${props => (props.isMobile ? "-40%" : "-23%")};
`;

const AppHubzilla = styled(App)`
  top: 0px;
  right: ${props => (props.isMobile ? "-50%" : "-34%")};
`;

const AppJoomla = styled(App)`
  top: 140px;
  right: ${props => (props.isMobile ? "-68%" : "-45%")};
`;

const AppMagento = styled(App)`
  top: ${props => (props.isMobile ? "35px" : "5px")};
  right: ${props => (props.isMobile ? "-76%" : "-56%")};
`;

const AppMovableType = styled(App)`
  top: 140px;
  right: -68%;
`;

const AppPhplist = styled(App)`
  top: 15px;
  right: ${props => (props.isMobile ? "-106%" : "-79%")};
`;

const AppPyro = styled(App)`
  top: ${props => (props.isMobile ? "125px" : "150px")};
  right: ${props => (props.isMobile ? "-98%" : "-92%")};
`;

const AppWordpress = styled(App)`
  top: ${props => (props.isMobile ? "30px" : "10px")};
  right: ${props => (props.isMobile ? "-136%" : "-106%")};
`;

const AppZenkart = styled(App)`
  top: 140px;
  right: ${props => (props.isMobile ? "-127%" : "-117%")};
`;

const OneClickAppInstallSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledOneClickAppInstall className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <Apps isMobile={isMobileOnly}>
            <AppAjax image={bubble_ajax} isMobile={isMobileOnly} />
            <AppAngular image={bubble_angular} isMobile={isMobileOnly} />
            <AppDolphin image={bubble_dolphin} isMobile={isMobileOnly} />
            <AppDropzone image={bubble_dropzonejs} isMobile={isMobileOnly} />
            <AppDrupal image={bubble_drupal} isMobile={isMobileOnly} />
            <AppHubzilla image={bubble_hubzilla} isMobile={isMobileOnly} />
            <AppJoomla image={bubble_joomla} isMobile={isMobileOnly} />
            <AppMagento image={bubble_magento} isMobile={isMobileOnly} />
            <AppMovableType
              image={bubble_movabletype}
              isMobile={isMobileOnly}
            />
            <AppPhplist image={bubble_phplist} isMobile={isMobileOnly} />
            <AppPyro image={bubble_pyro} isMobile={isMobileOnly} />
            <AppWordpress image={bubble_wordpress} isMobile={isMobileOnly} />
            <AppZenkart image={bubble_zenkart} isMobile={isMobileOnly} />
          </Apps>
          <div className="row">
            <div className="container">
              <div className="row">
                <div
                  className={
                    isMobileOnly
                      ? "col-10 offset-1 col-sm-10 offset-sm-1"
                      : "col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3"
                  }
                >
                  <StyledContainer
                    className="text-center"
                    isMobile={isMobileOnly}
                  >
                    <StyledTitle>
                      {translate("1-click-app-install")}
                    </StyledTitle>
                    <StyledDescription>
                      {translate(
                        "choose-from-300+-of-your-favorite-hosting-apps-&-scripts-and-install-them-with-a-single-click"
                      )}
                    </StyledDescription>
                    <Link to={ROUTE_HOSTING_WEB_HOSTING}>
                      <PrimaryButton size="l">
                        {translate("find-out-more")}
                      </PrimaryButton>
                    </Link>
                  </StyledContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledOneClickAppInstall>
    )}
  </Translate>
);
export default OneClickAppInstallSection;
