import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  SSL_API_ENDPOINT,
  SSL_APPLICABLE_HOSTINGS_API_ENDPOINT
} from "./endpoints";

export function allSSL() {
  return axios.get(SSL_API_ENDPOINT);
}

export function fetchApplicableHostings(sslId) {
  return axios.get(
    sprintf(SSL_APPLICABLE_HOSTINGS_API_ENDPOINT, { id: sslId })
  );
}
