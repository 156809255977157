import React from "react";
import styled from "styled-components";

const StyledMobileMenuItem = styled.div`
  display: block;
  padding: 0 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
`;

const MobileMenuItem = ({ title, link, description, onGoTo }) => (
  <StyledMobileMenuItem onClick={() => onGoTo(link)}>
    <StyledTitle>{title}</StyledTitle>
    <StyledDescription>{description}</StyledDescription>
  </StyledMobileMenuItem>
);

export default MobileMenuItem;
