import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Translate, withLocalize } from "react-localize-redux";
import {DOMAIN_PRICES_ENDPOINT} from "../../../services/endpoints"

import Tabs from "../../Tabs";
import Tab from "../../Tabs/Tab";
import TabContent from "../../Tabs/TabContent";

import DomainPricesTable from "../../DomainPricesTable";

import DataContext from "../../../DataContext";
import ExternalLink from "../../ExternalLink";

const StyledDomainPricesSection = styled.div`
  position: relative;


  & .nav-tabs {
    border-bottom: none;
    @media (min-width: 768px) {
      .nav-item:last-child {
        right: 0;
        position: absolute;
        top: 5px;
      }
    }
    @media (max-width: 767px) {
      .nav-link {
        font-size: 12px;
        margin-left: 7px;
        margin-right: 7px;
      }
    }
    @media (max-width: 576px) {
      .nav-item {
        display: block;
        width: 100%;
      }
    }
  }

  & .nav-link-marnet {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.neutral5};
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;
  }

  & .nav-link-marnet:hover {
    border-bottom: 2px solid ${props => props.theme.neutral3};
    text-decoration: none;
  }
  & .nav-link-marnet.active {
    color: ${props => props.theme.neutralBase};
    background-color: transparent;
  }
`;


const StyledMarnetLogo = styled.img`
width:100px;
margin-right:15px;
`


class DomainPricesSection extends React.Component {



  handleLinkClick = (languageCode) => {
    const baseUrl = DOMAIN_PRICES_ENDPOINT;
    const languageParam = languageCode === "mk" ? "mk" : "en";
    const url = `${baseUrl}/${languageParam}`;
    window.open(url);
  };


  render() {


    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <DataContext.Consumer>
                    {({ domainGroups, domains, currency }) => (
                      <StyledDomainPricesSection className="domain-prices-section">
                        <Tabs
                          defaultTab={
                            this.props.selectedTab
                              ? this.props.selectedTab
                              : "macedonian"
                          }
                          renderTabs={(active, onSelect) => {
                            let tabs = [];

                            for (const key in domainGroups) {
                              if (domainGroups.hasOwnProperty(key) && domainGroups[key] !== "greek") {
                                tabs.push(
                                  <Tab
                                    key={`tab-${domainGroups[key]}`}
                                    label={domainGroups[key]}
                                    active={active}
                                    onSelect={onSelect}
                                  >
                                    {translate(domainGroups[key])}
                                  </Tab>
                                );
                              }
                            }
                            
                            tabs.push(
                              <li key="marnet-link" className="nav-item">
                                <ExternalLink
                                  underline
                                  style={{ cursor: 'pointer' }}
                                  className="nav-link-marnet text-uppercase ml-auto"
                                  onClick={() =>
                                    this.handleLinkClick(
                                      activeLanguage && activeLanguage.code === "mk"
                                        ? "mk"
                                        : "en"
                                    )
                                  }  

                                >
                                  {translate("prices-download-pdf")}
                                </ExternalLink>
                              </li>
                            );
                            return tabs;
                          }}
                          renderTabContent={active => {
                            for (const key in domainGroups) {
                              if (
                                domainGroups.hasOwnProperty(key) &&
                                active === domainGroups[key]
                              ) {
                                return (
                                  <TabContent
                                    key={`tab-content-${domainGroups[key]}`}
                                    label={domainGroups[key]}
                                    active={active}
                                  >
                                    <DomainPricesTable
                                      items={domains[domainGroups[key]]}
                                      currency={
                                        activeLanguage && currency
                                          ? currency
                                          : activeLanguage.code === "mk"
                                            ? "мкд"
                                            : "eur"
                                      }
                                    />
                                  </TabContent>
                                );
                              }
                            }
                          }}
                        />
                      </StyledDomainPricesSection>
                    )}
                  </DataContext.Consumer>
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default DomainPricesSection;
