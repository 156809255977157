import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { isBrowser, isTablet, isMobileOnly } from "react-device-detect";

import Tabs from "../../../../components/Tabs";
import Tab from "../../../../components/Tabs/Tab";
import TabContent from "../../../../components/Tabs/TabContent";

import AndroidPopAddNewAccountImage from "../Images/AndroidPopAddNewAccountImage";
import AndroidPopServerSettingsImage from "../Images/AndroidPopServerSettingsImage";

const StyledImage = styled.img`
  width: ${props => {
    if (props.isMobile) return "380px";
    if (props.isTablet) return "380px";
    return "430px";
  }};
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-android.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

class AndroidPopStepsPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Tabs
                defaultTab={
                  this.props.currentStep > 0
                    ? `step${this.props.currentStep}`
                    : "step1"
                }
                renderTabs={(active, onSelect) => [
                  <Tab
                    key="step-1"
                    label="step1"
                    active={active}
                    onSelect={e => {
                      onSelect(e);
                      this.props.onSetStep(1);
                    }}
                  >
                    {translate("email-tutorials-step-1")}
                  </Tab>,
                  <Tab
                    key="step-2"
                    label="step2"
                    active={active}
                    onSelect={e => {
                      onSelect(e);
                      this.props.onSetStep(2);
                    }}
                  >
                    {translate("email-tutorials-step-2")}
                  </Tab>,
                  <Tab
                    key="step-3"
                    label="step3"
                    active={active}
                    onSelect={e => {
                      onSelect(e);
                      this.props.onSetStep(3);
                    }}
                  >
                    {translate("email-tutorials-step-3")}
                  </Tab>,
                  <Tab
                    key="step-4"
                    label="step4"
                    active={active}
                    onSelect={e => {
                      onSelect(e);
                      this.props.onSetStep(4);
                    }}
                  >
                    {translate("email-tutorials-step-4")}
                  </Tab>,
                  <Tab
                    key="step-5"
                    label="step5"
                    active={active}
                    onSelect={e => {
                      onSelect(e);
                      this.props.onSetStep(5);
                    }}
                  >
                    {translate("email-tutorials-step-5")}
                  </Tab>
                ]}
                renderTabContent={active => (
                  <React.Fragment>
                    <TabContent key="step-1" label="step1" active={active}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-center">
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.android-pop-imap-steps.open-up-your-default-email-app"
                            )}
                          </p>
                          {activeLanguage && (
                            <StyledImage
                              isBrowser={isBrowser}
                              isTablet={isTablet}
                              isMobile={isMobileOnly}
                              src={
                                this.props.images[
                                  `1-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </div>
                    </TabContent>

                    <TabContent key="step-2" label="step2" active={active}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-center">
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.android-pop-imap-steps.please-enter-your-email-address"
                            )}
                          </p>

                          {this.props.email && (
                            <AndroidPopAddNewAccountImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `2-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </div>
                      </div>
                    </TabContent>

                    <TabContent key="step-3" label="step3" active={active}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-center">
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.android-pop-imap-steps.in-server-settings-part"
                            )}
                          </p>
                          {this.props.email && (
                            <AndroidPopServerSettingsImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `3-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </div>
                      </div>
                    </TabContent>
                    <TabContent key="step-4" label="step4" active={active}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-center">
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.android-pop-imap-steps.please-fill-out-incoming-server"
                            )}
                          </p>
                          {activeLanguage && (
                            <StyledImage
                              isBrowser={isBrowser}
                              isTablet={isTablet}
                              isMobile={isMobileOnly}
                              src={
                                this.props.images[
                                  `4-POP-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </div>
                    </TabContent>
                    <TabContent key="step-5" label="step5" active={active}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-center">
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.android-pop-imap-steps.please-fill-out-outgoing-server"
                            )}
                          </p>
                          {activeLanguage && (
                            <StyledImage
                              isBrowser={isBrowser}
                              isTablet={isTablet}
                              isMobile={isMobileOnly}
                              src={
                                this.props.images[
                                  `5-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </div>
                    </TabContent>
                  </React.Fragment>
                )}
              />
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default AndroidPopStepsPage;
