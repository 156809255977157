import React from "react";
import {Translate} from "react-localize-redux";
import styled from "styled-components";
import {isMobileOnly} from "react-device-detect";
import {Form, Field} from "react-final-form";
import {withRouter} from "react-router-dom";
import {ROUTE_OFFER_SENT} from "../../../routes";

import * as quoteService from "../../../services/quoteService";

import {
    validateFullName,
    validateEmail,
    validatePhoneNumberWithoutFormat,
    validateMessage, validateCompanyName, validateCheckbox
} from "../../../common/validationRules";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Container from "../../Containers/Container";
import InputField from "../../InputField";
import TextArea from "../../TextArea";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Checkbox from "../../Checkbox";

const StyledProjectQuoteFormSection = styled.div``;
const StyledContainer = styled(Container)`
  padding: ${props => (props.isMobile ? "20px" : "40px")};
`;


const StyledCheckbox = styled.div`
  & {
    width: 100%;
    cursor: pointer;
   
    padding-left: 0;
    margin-bottom:10px;
  }

`;

class ProjectQuoteFormSection extends React.Component {
    onSubmit = values => {
        return quoteService
            .createProjectQuote(values)
            .then(() => this.props.history.push(ROUTE_OFFER_SENT));
    };
    validate = values => {
        console.log(values)
        if (this.props.companyName) {
            return {
                customer_name: validateFullName(values.customer_name, true),
                company_name: validateCompanyName(values.company_name, true),
                customer_email: validateEmail(values.customer_email, true),
                customer_phone: validatePhoneNumberWithoutFormat(
                    values.customer_phone,
                    true
                ),
                message: validateMessage(values.message, true),
                pp: validateCheckbox(values.pp, "validation.accept-privacy-policy-global"),
                pp2:validateCheckbox(values.pp2, "validation.accept-privacy-policy-global")
            };
        } else {
            return {
                customer_name: validateFullName(values.customer_name, true),
                customer_email: validateEmail(values.customer_email, true),
                customer_phone: validatePhoneNumberWithoutFormat(
                    values.customer_phone,
                    true
                ),
                message: validateMessage(values.message, true),
                pp: validateCheckbox(values.pp, "validation.accept-privacy-policy-global"),
                pp2:validateCheckbox(values.pp2, "validation.accept-privacy-policy-global")
            };
        }
    };

    render() {
        console.log(this.props.companyName)
        return (
            <Translate>
                {({translate}) => (
                    <StyledProjectQuoteFormSection className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <StyledContainer isMobile={isMobileOnly}>
                                <Form
                                    validate={this.validate}
                                    onSubmit={this.onSubmit}
                                    render={({handleSubmit, invalid, submitting}) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-6">
                                                    <Field
                                                        component={InputField}
                                                        label={translate("name")}
                                                        placeholder={translate("enter-name")}
                                                        name="customer_name"
                                                    />
                                                    <Field
                                                        component={InputField}
                                                        label={translate("email")}
                                                        placeholder={translate("enter-email")}
                                                        name="customer_email"
                                                    />
                                                    <Field
                                                        component={InputField}
                                                        label={translate("telephone-number")}
                                                        placeholder={translate("enter-telephone-number")}
                                                        name="customer_phone"
                                                    />

                                                </div>
                                                <div className="col-12 col-sm-12 col-md-6">

                                                    <Field
                                                        component={TextArea}
                                                        rows="9"
                                                        name="message"
                                                        label={translate("describe-the-requirements")}
                                                        placeholder={translate("your-message")}
                                                    />



                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12">
                                                    <br/>
                                                    <br/>
                                                    <Field
                                                        component={Checkbox}
                                                        value={true}
                                                        label={translate("sections.free-ebook.agree-privacy-policy")}
                                                        name="pp"
                                                    />
                                                    <br/>

                                                    <Field
                                                        component={Checkbox}
                                                        value={true}
                                                        label={translate("agreement-about-privacy-policy-and-used")}
                                                        name="pp2"
                                                    />
                                                </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-6 offset-md-6 text-right">
                                                    <PrimaryButton
                                                        disabled={invalid || submitting}
                                                        submitting={submitting}
                                                    >
                                                        {translate("request-offer")}
                                                    </PrimaryButton>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                />
                            </StyledContainer>
                        </div>
                    </StyledProjectQuoteFormSection>
                )}
            </Translate>
        );
    }
}

export default withRouter(ProjectQuoteFormSection);
