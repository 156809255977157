import React from "react";
import styled from "styled-components";

const StyledLanguageMenuItem = styled.div`
  display: block;
  padding: 0 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
  & strong {
    color: ${props => props.theme.neutralBase};
  }

  & a {
    color: ${props => props.theme.neutralBase};
  }
`;

const LanguageMenuItem = ({ children, ...rest }) => (
  <StyledLanguageMenuItem {...rest}>
    <StyledTitle>{children}</StyledTitle>
  </StyledLanguageMenuItem>
);

export default LanguageMenuItem;
