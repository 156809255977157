import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";
import { withRouter } from "react-router-dom";

import KnowledgeBaseCategories from "../../KnowledgeBaseCategories";
import MostPopularArticles from "../../MostPopularArticles";

import Badge from "../../Badges/Badge";
import BadgeSuccess from "../../Badges/BadgeSuccess";
import Container from "../../Containers/Container";

import withLoading from "../../Loading/WithLoading";
import ContentLoader from "../../Loading/ContentLoader";

import graphicLike from "../../MostPopularArticles/graphic-like-active.svg";

const StyledViewArticle = styled.div`
  padding: 100px 0;
`;

const StyledContainer = styled(Container)`
  padding: 20px;

  & img {
    max-width: 100%;
    height: auto;
  }
`;

const StyledLikeImage = styled.img`
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  margin-top: -3px;
`;

const StyledArticleTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 30px;
`;

const StyledBadgeSuccess = styled(BadgeSuccess)`
  cursor: pointer;
`;

const KnowledgeBaseCategoriesWithLoading = withLoading(
  KnowledgeBaseCategories,
  ContentLoader
);
const ArticleWithLoading = withLoading(StyledContainer, ContentLoader);

class ViewArticle extends React.Component {
  constructor(props) {
    super(props);
    this.articleRef = null;
  }

  createArticleRef = ref => (this.articleRef = ref);

  componentDidMount() {
    if (this.articleRef) {
      window.scrollIntoView({ behavior: "smooth" });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      if (this.articleRef) {
        this.articleRef.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  render() {
    const {
      categories,
      article,
      isLoading,
      isLoadingCategories,
      voteArticle,
      isAuthenticated,
      passRef
    } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <StyledViewArticle className="row" isMobile={isMobileOnly}>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-4">
                      <KnowledgeBaseCategoriesWithLoading
                        isLoading={isLoadingCategories}
                        title={translate("categories")}
                        categories={categories}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-8">
                      <ArticleWithLoading isLoading={isLoading}>
                        {article && (
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              {!isAuthenticated ? (
                                <BadgeSuccess>
                                  <StyledLikeImage src={graphicLike} alt="" />{" "}
                                  {article.votes}
                                </BadgeSuccess>
                              ) : (
                                <StyledBadgeSuccess
                                  onClick={() =>
                                    voteArticle(article.id, article.votes)
                                  }
                                >
                                  <StyledLikeImage src={graphicLike} alt="" />{" "}
                                  {article.votes}
                                </StyledBadgeSuccess>
                              )}
                              <Badge>{article.category_name}</Badge>
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12"
                              ref={this.createArticleRef}
                            >
                              <StyledArticleTitle>
                                {article.title}
                              </StyledArticleTitle>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12">
                              {parse(article.article)}
                            </div>
                          </div>
                        )}
                      </ArticleWithLoading>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledViewArticle>
        )}
      </Translate>
    );
  }
}

export default withRouter(ViewArticle);
