import React from "react";
import AccordionItem from "../../components/Accordion/AccordionItem";
import styled from "styled-components";

const StyledArticle = styled.span`
  display: inline-block;
  padding: 8px 19px;
  border: 1px solid #d8dde6;
  margin-top: 10px;
  border-radius: 3px;
  width: 100%;
  & a {
    font-weight: 600;
    font-size: 17px;
  }
`;

export default class OtherLegalPageDetailsEn extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-1`}
                            title={'Definitions '}
                        >
                            <StyledArticle>
                                <p>Our privacy policy is based on the terms used by the Law on personal data protection
                                    (hereinafter: LPDP). It should be understandable to our visitors and users. To make
                                    sure that that’s the case, first we will explain the terms we use.
                                    In this Privacy policy, among other things, we use the following terms:
                                </p>
                                <ul>


                                    <li>
                                        <p><strong>Personal data
                                        </strong></p>
                                        <p> The term personal data refers to any information regarding an identified
                                            natural person or a natural person that can be identified (personal data
                                            subject), and a natural person that can be identified is a person whose
                                            identity can be established directly or indirectly, especially according to
                                            an identifier such as first and last name, citizen’s personal number,
                                            location data, online identifier, or according to one or more
                                            characteristics specific to his/her physical, physiological, genetic,
                                            mental, economic, cultural or social identity.
                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Personal data subject
                                        </strong></p>
                                        <p>A personal data subject is an identified or natural person that can be
                                            identified, whose personal data is processed by the controller responsible
                                            for personal data processing.
                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Personal data processing
                                        </strong></p>
                                        <p>Personal data processing is any operation or set of operations performed on
                                            personal data, or a group of personal data, automatically or otherwise, such
                                            as: collecting, recording, organizing, structuring, storing, adjusting or
                                            changing, withdrawing, consulting, inspecting, using, disclosing through
                                            transmission, disclosing or otherwise making available, adjusting or
                                            combining, limiting, deleting or destroying.

                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Restriction of personal data processing
                                        </strong></p>
                                        <p> Restriction of personal data processing is labeling of stored personal data,
                                            in order to limit their processing in the future.

                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Profiling
                                        </strong>
                                        </p>
                                        <p>Profiling is any form of automatic processing of personal data, which
                                            consists of using personal data to assess certain personal aspects related
                                            to the natural person, and especially to analyze or predict aspects related
                                            to the performance of professional duties of that natural person, his/hers
                                            economic status, health, personal preferences, interests, confidentiality,
                                            behavior, location or movement.
                                        </p>

                                    </li>


                                    <li>
                                        <p><strong>Pseudonymization
                                        </strong></p>

                                        <p>Pseudonymization is the processing of personal data in a way that personal
                                            data can no longer be linked to a particular subject without the use of
                                            additional information, provided that such additional information are stored
                                            separately and are subject to technical and organizational measures to
                                            ensure that the personal data are not related to an identified natural
                                            person or a natural person that can be identified.
                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Controller
                                        </strong></p>
                                        <p>A controller is a natural or legal person, a body of state authority, a body
                                            of state or a legal person established by the state to exercise public
                                            authority, an agency or another body, which independently or together with
                                            others determines the aims and manner of processing personal data, and when
                                            the aims and the manner of processing the personal data are determined by
                                            law, the same law determines the controller or the special criteria for
                                            his/hers selection.


                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Processor
                                        </strong></p>
                                        <p>Processor is a natural or legal person, a body of state authority, a body of
                                            state or legal person established by the state to exercise public authority,
                                            agency or other body that processes personal data on behalf of the
                                            controller.


                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Beneficiary
                                        </strong></p>
                                        <p>Beneficiary is a natural or legal person, a body of state authority, a body
                                            of state or legal person established by the state to exercise public
                                            authority, agency or other body to which personal data are disclosed,
                                            whether it’s a third party or not. However, state authorities and state
                                            bodies to which personal data are disclosed in a special investigation in
                                            accordance with the law are not considered users, and the processing of this
                                            data by these authorities must be in accordance with the applicable rules
                                            for the protection of personal data according to the goals of that
                                            processing.

                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Third party
                                        </strong></p>
                                        <p>Third party is any natural or legal person, a body of state authority, a body
                                            of state or legal person established by the state to exercise public
                                            authority, agency or other body, which is not a subject, controller,
                                            processor or a person under the direct authorization of the controller or
                                            the processor authorized to process the data.
                                        </p>

                                    </li>

                                    <li>
                                        <p><strong>Consent
                                        </strong></p>
                                        <p>Consent of the subject is any freely given, concrete, informed and
                                            unambiguous statement of will, through a statement or clearly confirmed
                                            action, which expresses consent for the processing of his/hers personal
                                            data.
                                        </p>

                                    </li>


                                </ul>
                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-2`}
                            title={'Controller’s name and address'}
                        >
                            <StyledArticle>

                                <p>CPTS MKHOST DOOEL import-export Skopje (MKHOST)
                                </p>
                                <p>Phone:<a href="tel:+ 389-2-2779-444"> + 389 (2) 2779 444</a></p>
                                <p>E-mail address:<a href="mailto: info@mkhost.com.mk"> info@mkhost.com.mk</a></p>
                                <p>Website: < a href="https://mkhost.com/mk"> https://mkhost.com/mk </a></p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-3`}
                            title={'Personal data protection officer'}
                        >
                            <StyledArticle>

                                <p>Contact phone:<a href="tel:+ 389-2-2779-444"> +392 2 2779 444</a>
                                </p>
                                <p> Email address:<a href="mailto: info@mkhost.com.mk"> info@mkhost.com.mk</a>
                                </p>
                                <p>Any visitor or user of this website can directly contact our personal data protection
                                    officer for any questions and suggestions regarding personal data protection.

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-4`}
                            title={'How do we apply the principles of personal data protection?'}
                        >
                            <StyledArticle>


                                <ul>
                                    <li><p><strong> Legal, fair and transparent manner of personal data
                                        processing</strong></p>
                                        <p>We collect and process your personal data on the website upon your consent.

                                        </p>
                                    </li>
                                    <li><p><strong>Limiting the purpose of personal data processing
                                    </strong></p>
                                        <p>We collect and process your personal data on the website to order the
                                            packages we offer (domain purchase, hosting or server), to consult us, to
                                            open a ticket, to register and log in to the mkhost portal, to order free
                                            e-book from MKHOST, to submit an application for registration and change of
                                            domain or change of ownership, MKHOST chat, to apply through e-mail for an
                                            open job vacancy and to share your ideas for a website. Your personal data
                                            are not a subject of further processing for purposes other than those
                                            originally defined.

                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Limiting the scope of personal data
                                        </strong></p>
                                        <p>
                                            The processing of your personal data is limited only to data necessary to
                                            meet the purposes of the processing. The scope of personal data is adequate
                                            to what’s necessary to meet the objectives set out in point 5 of this
                                            Privacy policy. If there is a need for additional processing of your
                                            personal data, we will inform you and ask for your consent for the
                                            additional processing.


                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Personal data accuracy
                                        </strong></p>
                                        <p>
                                            We implement reasonable measures to verify the accuracy, to correct and / or
                                            delete the inaccurate personal data we process. Alongside these measures, we
                                            enable you to exercise your right to request correction, supplementation or
                                            deletion of personal data in an easy and simple way.


                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Limiting deadlines for personal data processing
                                        </strong>
                                        </p>
                                        <p>
                                            We make sure your personal data are stored within the deadlines provided in
                                            point 16 of this Privacy policy. After the expiration of the deadlines or
                                            after the fulfillment of the processing goal, your personal data is deleted.


                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Integrity and confidentiality of personal data
                                        </strong></p>
                                        <p>
                                            We take appropriate technical and organizational measures to protect the
                                            personal data collected through this website from unauthorized access,
                                            illegal disclosure and data destruction. The access to personal data is
                                            restricted only to specifically authorized persons.


                                        </p>
                                    </li>

                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-5`}
                            title={'Legal basis and purposes for your personal data processing '}
                        >
                            <StyledArticle>

                                <p>Our website collects personal data solely from personal data subjects (users).


                                </p>

                                <ul>
                                    <li>
                                        <p><strong>Legal basis
                                        </strong></p>
                                        <p>The legal basis for the processing of your personal data on our website
                                            through the forms: "Order", "Shopping Cart", "Consultation", "Ticket
                                            opening", "Registration and login", "Submitting a request for registration
                                            and change of domain or change of ownership "," Applying for an open job
                                            vacancy " , " Sharing ideas " , " Free e-book "and" MKhost Chat "is your
                                            consent.
                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Goals
                                        </strong></p>
                                        <p>Through the forms "Order", "Cart" and "Registration and log in" we process
                                            your personal data to start activities for the realization of the requested
                                            order and payment, which establishes an agreement between you and MKHOST.
                                            Through the forms "Consultation", "Ticket opening" and "Sharing ideas" we
                                            process your personal data so if you have problems with the products or the
                                            account, you can share your ideas, as well as to communicate with you and
                                            respond to your message through which you can request information about our
                                            services, products, etc.
                                            Through the "Free e-book" form we process your personal data so you can
                                            download a free e-book.
                                            Through the "MKhost Chat" form we process your personal data to respond to
                                            your messages.
                                            Through the option "Applying for an open job vacancy" you can submit a CV
                                            (biography) for the open job vacancies at <a
                                                href="mailto:jobs@mkhost.com.mk"> jobs@mkhost.com.mk.</a>
                                             For "Submitting a request for registration and change of domain or change of
                                            ownership” by natural persons it’s necessary to fill out the request and
                                            submit it in person at the premises of MKHOST.


                                        </p>
                                    </li>


                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-6`}
                            title={'What categories of personal data do we process? '}
                        >
                            <StyledArticle>


                                <ul>
                                    <li>
                                        <p><strong>Forms „Order“, „Cart“ and „Registration and login“</strong></p>
                                        <p>If you want to order one of the packages we offer, as established in point 5
                                            of this Policy, you need to enter your email address </p>
                                    </li>

                                    <li>
                                        <p><strong>Forms „Consultation“, „Ticket opening“ and „Sharing ideas“</strong>
                                        </p>
                                        <p>If you want to contact us for our services, to request an offer, if you have
                                            a problem using the services or a technical issue, if you want to register a
                                            domain or have a problem with payment or invoice you need to enter your
                                            personal data:
                                        </p>
                                        <p> Name and surname,</p>
                                        <p> Email address,
                                        </p>
                                        <p> Message,
                                        </p>
                                        <p>Attach file.
                                        </p>
                                        <p>Our website enables you a quick contact and direct communication with us
                                            through this email address: <a
                                                href="mail to: info@mkhost.com.mk"> info@mkhost.com.mk.</a></p>

                                    </li>
                                    <li>
                                        <p><strong>Form „Free e-book“
                                        </strong></p>
                                        <p>If you want to download a free e-book, you need to enter your personal
                                            information:
                                        </p>
                                        <p> Name and surname and Email address


                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Form „МKhost chat“
                                        </strong></p>
                                        <p>If you want to contact us online and leave a message, you need to enter your
                                            personal information:


                                        </p>
                                        <p> Name and surname (optional you can connect through Facebook or Google
                                            profile)

                                        </p>
                                        <p>Email address


                                        </p>
                                        <p>
                                            Telephone number and Message


                                        </p>

                                    </li>


                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-7`}
                            title={'Cookies '}
                        >
                            <StyledArticle>
                                <p>Our website uses cookies. Cookies are text files stored in your information system
                                    through the web browser.
                                </p>
                                <p> Many cookies contain a so-called cookie ID - a unique cookie identifier, which
                                    consists of a series of characters through which visited websites distinguish their
                                    users' web browsers from other web browsers, using a unique cookie identifier. </p>
                                <p>By using cookies on our website, we offer better services to you as customers.
                                    Cookies allow us to identify our website users. The purpose is to make it easier for
                                    you to use our website. If you use cookies on our website, you don’t have to enter
                                    access data every time you access the website, because you download them from the
                                    website and the cookie is stored on your information system.
                                </p>
                                <p>As a user, you can prevent cookies from being set through our website at any time by
                                    using the appropriate browser setting or you can permanently refuse cookies setup,
                                    and you may delete cookies that have already been set at any time through your
                                    browser or other software programs. If you turn off cookies through the web browser,
                                    you will not be able to take full advantage of all the features on our website.
                                </p>
                                <p>More information is available in the
                                    <a href="https://mkhost.com/mk/other/legal"> https://mkhost.com/mk/other/legal </a>
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-8`}
                            title={'Collecting general data and information'}
                        >
                            <StyledArticle>
                                <p>Our website collects a range of general data and information when you access it.
                                    These general data and information are stored in the log files of the server. The
                                    collected data and information may relate to (1) the type of browser and versions
                                    used, (2) the operating system used by the access system, (3) the website from which
                                    the access system accesses our website, (4) the date and time of access to the
                                    website; (5) the Internet Protocol (IP) address, (6) the Internet service provider
                                    of the access system and (7) other similar information that may be used in the event
                                    of an attack on our information system.

                                </p>
                                <p> When using these general data and information, we do not draw conclusions about the
                                    user. Instead, we need this information to (1) deliver the content of our website
                                    properly, (2) optimize the content of our website, (3) ensure the long-term
                                    sustainability of our information system and website technology, and (4) provide law
                                    enforcement with the information needed to prosecute in the event of a
                                    cyber-attack.</p>
                                <p> Therefore, we anonymously analyze and collect statistics in order to ensure an
                                    optimal level of protection of the personal data we process. The anonymous data from
                                    server log files are stored separately from your personal information.

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-9`}
                            title={'Personal data protection when using Facebook   '}
                        >
                            <StyledArticle>

                                <p>The Facebook component is integrated on our website. Facebook is a social network - a
                                    place for social meetings on the Internet that allows users to communicate with each
                                    other in cyberspace. The social network can serve as a platform for exchanging
                                    opinions and experiences or for obtaining personal or business information. Facebook
                                    allows users to create private profiles, post photos, and connect through friend
                                    requests.


                                </p>
                                <p> Facebook, Inc., 1 Hacker Way, Menlo Park, California 94025, United States of America
                                    (USA) is a data controller of US and Canadian entities. If the entity resides
                                    outside the United States or Canada, the controller is Facebook Ireland Ltd., 4
                                    Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland.

                                </p>
                                <p>Whenever you visit our website, which has the Facebook component (Facebook plug-in)
                                    integrated, the web browser of your information system is automatically prompted to
                                    submit a view of the relevant Facebook component. An overview of all Facebook
                                    plug-ins can be found at <a
                                        href="https://developers.facebook.com/docs/plugins"> https://developers.facebook.com/docs/plugins</a>.
                                    During this technical process, Facebook is informed which website have you visited.


                                </p>
                                <p>
                                    Facebook receives information about visiting our website when you log in to Facebook
                                    while visiting our website. If you don’t want this information to be shared on
                                    Facebook, sign out of your Facebook account before visiting our website.
                                    The data protection guide posted by Facebook is available at
                                    <a href=" https://facebook.com/about/privacy"> https://facebook.com/about/privacy</a> .
                                    It contains information about the collection,
                                    processing and use of personal data by Facebook. In addition, it explains what
                                    settings Facebook offers to protect your privacy. Additionally, various settings
                                    options are available to disable Facebook data transfer.

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-10`}
                            title={' Personal data protection when using Instagram '}
                        >
                            <StyledArticle>

                                <p>The Instagram component is integrated on our website. Instagram is a service that can
                                    be described as an audiovisual platform, which allows users to share photos and
                                    videos, as well as share that data on other social networks.


                                </p>
                                <p> Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbor, Dublin 2 Ireland is
                                    a controller of personal data processed through Instagram.


                                </p>
                                <p>Whenever you visit our website, which integrates the Instagram component (Insta
                                    button), your information system web browser is automatically prompted to submit a
                                    preview of the relevant Instagram component. During this technical process,
                                    Instagram is informed which website have you visited.


                                </p>
                                <p>If you are logged in to Instagram at the same time, Instagram detects all your visits
                                    to our website and the total time spent on our website. This data is collected
                                    through the Instagram component and linked to your Instagram account. If you click
                                    the Instagram button which is integrated on our website, Instagram links this data
                                    to your personal Instagram account and saves the personal information.


                                </p>
                                <p>Instagram receives information about our website when you log in to Instagram while
                                    visiting our website. This happens regardless of whether you clicked the Instagram
                                    button or not. If you don’t want this information to be shared on Instagram, sign
                                    out of your Instagram account before visiting our website.

                                </p>
                                <p>Additional information and applicable provisions for the protection of personal data
                                    on Instagram can be found at the following link:

                                    <a href="https://help.instagram.com/155833707900388">
                                        <p> https://help.instagram.com/155833707900388</p></a>
                                    <a href="https://www.instagram.com/about/legal/privacy">
                                        <p> https://www.instagram.com/about/legal/privacy.</p></a>

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-11`}
                            title={'Personal data protection when using Linkedin '}
                        >
                            <StyledArticle>

                                <p>The LinkedIn component is integrated on our website. LinkedIn is an online social
                                    network that allows users to connect with existing business contacts and create new
                                    business contacts. More than 400 million registered people in more than 200
                                    countries use LinkedIn. LinkedIn is currently the largest business contact platform
                                    and one of the most visited websites in the world.


                                </p>
                                <p>LinkedIn Corporation, 2029 Stierlin Court Mountain View, CA 94043, USA is a
                                    controller of personal data processed through LinkedIn. LinkedIn Ireland, Wilton
                                    Plaza, Wilton Place, Dublin 2, Ireland is responsible for privacy policy matters
                                    outside the United States.


                                </p>
                                <p>Whenever you visit our website, which integrates the LinkedIn component (LinkedIn
                                    button), the user information system web browser automatically requests to download
                                    a preview of the relevant LinkedIn component.


                                </p>
                                <p>More information can be found at <a
                                    href="https://developer.linkedin.com/plugins"> https://developer.linkedin.com/plugins</a>.
                                    During this technical process, LinkedIn knows which website you have visited.


                                </p>
                                <p>If you are logged in to LinkedIn at the same time, LinkedIn detects all your visits
                                    to our website and the total time spent on our website. The data is collected
                                    through the LinkedIn component and linked to your LinkedIn account. If you click the
                                    LinkedIn button integrated on our website, LinkedIn links this data to your personal
                                    LinkedIn user account and saves personal information.

                                </p>
                                <p>LinkedIn receives information through the LinkedIn component that you have visited
                                    our website provided you are logged in to LinkedIn at the same time while you visit
                                    our website. This happens regardless of whether you clicked the LinkedIn button or
                                    not. If you don’t want this information to be shared on LinkedIn, sign out of your
                                    LinkedIn account before visiting our website.
                                </p>

                                <p>LinkedIn offers the option to cancel the receiving of emails, text messages and
                                    targeted ads, as well as the option to manage ad settings at the following link
                                    <a href="https://www.linkedin.com/psettings/guest-controls"> https://www.linkedin.com/psettings/guest-controls</a> .
                                    LinkedIn also uses affiliates
                                    such as Eire, Google Analytics, BlueKai, DoubleClick, Nielsen, Comscore, Eloqua and
                                    Lotame. The setting of the cookies can be refused at the following link
                                    <a href="https://www.linkedin.com/legal/cookie-policy"> https://www.linkedin.com/legal/cookie-policy</a> .
                                    The LinkedIn Privacy Policy is
                                    available at <a
                                        href="https://www.linkedin.com/legal/privacy-policy"> https://www.linkedin.com/legal/privacy-policy</a>.
                                    The LinkedIn cookie
                                    policy is available at <a
                                        href="https://www.linkedin.com/legal/cookie-policy"> https://www.linkedin.com/legal/cookie-policy</a> .
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-12`}
                            title={'  Personal data protection when using YouTube'}
                        >
                            <StyledArticle>

                                <p>The YouTube component is integrated on our website. YouTube is an internet video
                                    portal that allows video publishers and other users to upload videos for free, as
                                    well as view, rate and comment on them for free. YouTube allows you to post all
                                    kinds of videos, access entire movies and TV shows, as well as music videos and
                                    user-generated videos through the Internet portal.


                                </p>
                                <p> The YouTube service is provided by Google Ireland Limited, Gordon House, Barrow
                                    Street, Dublin, D04 E5W5, Ireland.


                                </p>
                                <p>Whenever you visit our website that integrates the YouTube component (YouTube video),
                                    your information system web browser is automatically prompted to submit a preview of
                                    the relevant YouTube component. More YouTube information is available at
                                    <a href="https://www.youtube.com/howyoutubeworks"> https://www.youtube.com/howyoutubeworks </a> .During
                                    this technical process, YouTube is
                                    informed which website have you visited.


                                </p>
                                <p>If you are logged in to YouTube, YouTube recognizes each visit to our website. These
                                    information are collected by YouTube and Google and assigned to your YouTube
                                    account.


                                </p>
                                <p>YouTube and Google receive information through the YouTube component that you have
                                    visited our website if you are logged in to YouTube at the time of your visit. This
                                    happens whether you clicked on the YouTube video or not. If you don’t agree to such
                                    data transfer to YouTube and Google, you may sign out of your YouTube account before
                                    logging in to our website.


                                </p>
                                <p>The YouTube Privacy Policy, available at <a
                                    href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en </a>,
                                    provides information about the collection, processing and use of personal data by
                                    YouTube and Google.
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-13`}
                            title={'  Personal data protection when using Twitter '}
                        >
                            <StyledArticle>

                                <p>On this website, has integrated the Twitter component. Twitter is a
                                    more linguistic, publicly available “microblogging” service where users can post and
                                    share so-called tweets, e.g. short messages, which are limited to 280 characters.
                                    These short messages are available to everyone, including those who haven’t logged
                                    in to Twitter. Tweets are also displayed to the followers of the respective users.
                                    Followers are other Twitter users who follow users' tweets. Additionally, Twitter
                                    allows you to reach a wide audience through hash tags, links or re-tweets.


                                </p>

                                <p>Twitter International Company, One Cumberland Place, Fenian Street Dublin 2, D02
                                    AX07, Ireland is the company offering the Twitter service.


                                </p>
                                <p>Each time you refer to one of the individual pages on this website, which is managed
                                    by Makedonija Lek and on which the Twitter component (Twitter button) is integrated,
                                    the web browser of your information system is automatically prompted to send a
                                    preview of the relevant Twitter component. An overview of all Twitter buttons can be
                                    found at <a
                                        href="https://about.twitter.com/de/resources/buttons"> https://about.twitter.com/de/resources/buttons </a> .
                                    During this technical
                                    process, Twitter is informed which website have you visited.


                                </p>
                                <p>The purpose of integrating the Twitter component is to rebroadcast the content of
                                    this website in order to enable our users to present this website in the digital
                                    world and to increase the number of our visitors


                                </p>
                                <p>If you are logged in to Twitter, Twitter discloses each of your calls on our website
                                    and the duration of your stay on our website and which subpage of our website have
                                    you visited. This data is collected through the Twitter component and linked to your
                                    Twitter account. If you click one of the Twitter buttons integrated on our website,
                                    Twitter links that data to your personal Twitter account and stores your personal
                                    information.


                                </p>
                                <p>Twitter receives information through the Twitter component that you have visited our
                                    website, provided that you are logged in to Twitter at the time of entering our
                                    website. This happens whether you clicked on the Twitter component or not. If you
                                    don’t agree with such data transfer on Twitter, you can prevent it by logging out of
                                    your Twitter account before logging in to our website.Provisions for the protection
                                    of personal data on Twitter are available at the following link
                                   <a href="https://twitter.com/privacy?lang=en"> https://twitter.com/privacy?lang=en </a> .


                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-14`}
                            title={' Personal data protection when using Google Analytics  '}
                        >
                            <StyledArticle>

                                <p>The Google Analytics component is integrated on our website. Google Analytics is a
                                    web analytics service that collects and analyzes web site user behavior data. Among
                                    other things, information is collected about the website from which the user
                                    originates (the so-called Referrer), as well as how often and for how long the visit
                                    to the website lasts. Web analytics is mainly used to optimize the website.


                                </p>
                                <p> The company that provides the Google Analytics component is Google Ireland Limited,
                                    Gordon House, Barrow Street, Dublin, D04 E5W5, Ireland.


                                </p>
                                <p>Google Analytics sets a cookie on the user's information system. The definition of
                                    cookies is explained above as well as in the Cookie Policy available at (link). By
                                    setting a cookie, Google can analyze the usage of our website. The cookie is used to
                                    collect and store personal data, such as the time of access, the place of access and
                                    the frequency of visits to our website by the user. Each time you visit our Website,
                                    these personal information, including the IP address used by the User to access the
                                    Internet, will be transmitted to Google in the United States and stored by Google in
                                    the United States. Google may transfer this personal information to third parties.


                                </p>
                                <p>By properly adjusting the browser you can prevent the setting of cookies through our
                                    website, but also permanently prohibit the setting of cookies. This customization of
                                    the browser would also prevent Google Analytics from setting cookies on your
                                    information system. Additionally, cookies already used by Google Analytics can be
                                    deleted at any time through a web browser or other software program.


                                </p>
                                <p>You can also download and install the browser add-on at
                                    https://tools.google.com/dlpage/gaoptout. This browser add-on tells Google Analytics
                                    not to transmit any data and information about your website visits through
                                    JavaScript. Installing browser add-ons are considered as complaints towards Google.
                                    If your information system is later deleted, formatted, or reinstalled, you must
                                    reinstall the browser add-ons to disable Google Analytics. If the browser add-on is
                                    uninstalled or disabled, it may be reinstalled or activated.
                                    Additional information and applicable data protection provisions from Google can be
                                    downloaded at:
                                    <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a> and
                                    <a href="https://marketingplatform.google.com/about/analytics/terms/us"> https://marketingplatform.google.com/about/analytics/terms/us</a>.
                                    Google Analytics is further explained at the following link:
                                    <a href="https://marketingplatform.google.com/about/analytics"> https://marketingplatform.google.com/about/analytics </a> .


                                </p>
                                <p>In the "Cart" form we use the ReCAPTCHA tool of Google Ireland Limited, Gordon House
                                    for
                                    which we need your consent. More privacy information can be found at
                                    <a href="https://policies.google.com/privacy?hl=en "> https://policies.google.com/privacy?hl=en </a> as
                                    well as in the terms of use available at
                                    <a href="https://policies.google.com/terms?hl=en"> https://policies.google.com/terms?hl=en </a> .
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-15`}
                            title={' Do we transfer personal data? '}
                        >
                            <StyledArticle>

                                <p>The servers on the MKHOST website are located on servers in the EU, more precisely in
                                    Germany, Frankfurt.


                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-16`}
                            title={'How long do we store personal data '}
                        >
                            <StyledArticle>

                                <p>We process and store your personal data submitted to us for consultation, to order a
                                    free e-book from MKHOST, and to share your ideas for a website, etc. only for the
                                    time necessary to achieve the purpose for which they were collected, and for a
                                    maximum of 1 year from the day of message delivery.


                                </p>
                                <p>Your personal data submitted for ticket opening if you have problems with the
                                    products or the account we process and store only in the time necessary to achieve
                                    the purpose for which they are collected, and no later than 5 year from
                                    the date of problem resolution.


                                </p>
                                <p>Your personal information which are submitted through the order form
                                    (domain purchase, hosting or server) will be deleted within 2 year, from the filling
                                    of the order form.


                                </p>
                                <p>Your personal data submitted through the form for registration and log in to the
                                    MKHOST portal will be deleted after withdrawal of consent or within 2 year of
                                    profile inactivity.


                                </p>
                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-17`}
                            title={' Do we make decisions based on automated data processing? '}
                        >
                            <StyledArticle>

                                <p>MKHOST doesn’t make individual decisions based on automatic data processing including
                                    profiling.


                                </p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-18`}
                            title={' Do we and to whom we disclose your personal data? '}
                        >
                            <StyledArticle>

                                <p>We will disclose your personal data to the competent state bodies for the purpose of
                                    conducting procedures in accordance with the law. For any disclosure of your
                                    personal data, we will keep written records and we will notify you in an appropriate
                                    manner.
                                    If in the future there is a need of disclosing third party’s data which are not
                                    foreseen in this Policy, we will ask for your consent where necessary and amend the
                                    Privacy Policy before such processing begins.


                                </p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-19`}
                            title={' What are your rights as personal data subjects? '}
                        >
                            <StyledArticle>
                                <p><strong> Right to confirmation
                                </strong></p>

                                <p>
                                    As a personal data subject (user, buyer, website visitor) you have the right to
                                    receive confirmation from MKHOST whether we process your personal data.

                                </p>
                                <p><strong>Right to access
                                </strong></p>

                                <p>

                                    At any time, you have the right to receive free information from MKHOST about your
                                    personal data, as well as a copy of this data. LPDP guarantees you access to the
                                    following information:
                                    purpose of processing;
                                    categories of personal data;
                                    users or categories of users to whom personal data have been or will be disclosed;
                                    if possible, storage time of personal data or if that is not possible, the criteria
                                    used to determine the storage time;
                                    existence of the right to request correction or deletion of personal data or
                                    restriction of personal data processing;
                                    existence of the right to submit a request to the Agency for personal data
                                    protection;
                                    when personal data are not collected from you, all available information about the
                                    source of personal data;
                                    existence of automatic decision-making, including profiling, and in those cases,
                                    relevant information about the logic involved, as well as the significance and
                                    anticipated consequences of such processing for the subject.
                                    Besides that, you have the right to receive information about whether personal data
                                    has been transferred to a third country, as well as the appropriate protective
                                    measures regarding the transfer.


                                </p>
                                <p><strong>Right to correction
                                </strong></p>

                                <p>

                                    You have the right to receive correction on your incorrect personal data without
                                    undue delay. Taking into account the purposes of the processing, you have the right
                                    to supplement the incomplete personal data.


                                </p>
                                <p><strong> Right to deletion („A right to be forgotten“)
                                </strong></p>

                                <p>
                                    You have the right to request the deletion of your personal data without undue
                                    delay. We are obliged to act upon such request if one of the following conditions is
                                    met:
                                    Personal data are no longer needed for the purposes of which they were collected or
                                    processed;
                                    You have withdrawn the consent on which the processing is based, and there’s no
                                    other legal basis for processing;
                                    You have objected the processing;
                                    Processing of personal data is illegal;


                                </p>
                                <p><strong> Right to processing restriction
                                </strong></p>

                                <p>
                                    You have the right to restrict the processing of your personal data, if one of the
                                    following conditions is met:
                                    you dispute the accuracy of the personal data, for a period that will allow us to
                                    check their accuracy;
                                    the processing is illegal, but you oppose the deletion of personal data, and instead
                                    require a restriction on their processing;
                                    MKHOST no longer needs to process your personal data, but you need it to establish,
                                    realize or defend your legal requirements;
                                    You object to the processing, pending verification on whether our legitimate
                                    interests take precedence over your interests.


                                </p>
                                <p><strong> Right to data transfer
                                </strong></p>

                                <p>
                                    You have the right to receive the personal data processed automatically with your
                                    consent, in a structured, commonly used, machine-readable format or to request that
                                    we transfer this data to another controller, provided that this is technically
                                    feasible.


                                </p>
                                <p><strong> Right to file a complaint

                                </strong></p>

                                <p>
                                    You have the right to file a complaint to MKHOST, against the processing of your
                                    personal data for direct marketing purposes.
                                    In the event of a complaint, we will no longer process your personal data for this
                                    purpose.
                                </p>
                                <p><strong> Right to withdraw your consent for the processing of personal data  </strong></p>


                                <p>You have the right to withdraw your consent for the processing of your personal data at any time.
                                    If you want to exercise any of your rights, please access the following link: (a
                                    link to the forms)
                                    The personal data protection officer will act upon your request without delay.
                                    For any questions regarding the protection of personal data, contact the personal
                                    data protection officer at:

                                    Contact phone:<a href="tel:+389-2-2779-444>"> +389 (2) 2779 444</a>
                                    Email address: <a href="mailto:info@mkhost.com.mk"> info@mkhost.com.mk</a></p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-20`}
                            title={' Right to submit a request to the Agency for personal data protection '}
                        >
                            <StyledArticle>

                                <p>
                                    If you think that we don’t process personal data in accordance with the LPDP, you
                                    can contact:
                                    Personal data protection agency
                                    blvd. "Goce Delchev" no. 18 Skopje
                                </p>
                                <p><a href="https://www.dzlp.mk"> https://www.dzlp.mk </a></p>
                                <p><a href = "mailto:info@privacy.mk">info@privacy.mk</a></p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>


                </div>
            </div>

        )
    }
}