import React from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";
import { withLocalize } from "react-localize-redux";

import MobileMenuAccordion from "./MobileMenuAccordion";
import MobileMenuItem from "./MobileMenuItem";
import LanguageMenuItem from "./LanguageMenuItem";

const StyledMobileMenu = styled.div`
  position: fixed;
  top: 56px;
  left: 15px;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background-color: ${props => props.theme.white};
  overflow-y: auto;
  padding-bottom: 60px;
`;

const StyledMobileMenuLinkItem = styled.div`
  display: block;
  padding: 0 10px;
  margin-bottom: 15px;
  cursor: pointer;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
`;

class MobileMenu extends React.Component {
  onGoTo = route => {
    this.props.history.push(route);
    this.props.onHideMenu();
  };

  render() {
    return (
      <StyledMobileMenu className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <MobileMenuAccordion
              className="col-12 col-sm-12 col-md-12"
              title="Language"
              languageMenu={true}
              language={
                this.props.activeLanguage && this.props.activeLanguage.code
              }
            >
              {this.props.languages &&
                this.props.languages.map(language => (
                  <LanguageMenuItem
                    key={`lang-${language}-${Math.random()}`}
                    onClick={() => this.props.setActiveLanguage(language.code)}
                  >
                    {this.props.activeLanguage.code === language.code ? (
                      <strong>
                        <a
                          href={
                            this.props.activeLanguage.code === "mk"
                              ? "https://mkhost.com" +
                                this.props.location.pathname.replace(
                                  "/mk",
                                  "/en"
                                )
                              : "https://mkhost.com" +
                                this.props.location.pathname.replace(
                                  "/en",
                                  "/mk"
                                )
                          }
                        >
                          {language.name}
                        </a>
                      </strong>
                    ) : (
                      <a
                        href={
                          this.props.activeLanguage.code === "mk"
                            ? "https://mkhost.com" +
                              this.props.location.pathname.replace("/mk", "/en")
                            : "https://mkhost.com" +
                              this.props.location.pathname.replace("/en", "/mk")
                        }
                      >
                        {language.name}
                      </a>
                    )}
                  </LanguageMenuItem>
                ))}
            </MobileMenuAccordion>
            {this.props.routes.map(
              (route, index) =>
                route.groups &&
                route.groups.length > 0 && (
                  <MobileMenuAccordion
                    className="col-12 col-sm-12 col-md-12"
                    open={this.props.location.pathname.startsWith(route.path)}
                    key={`nav-item-${index}`}
                    to={route.path}
                    title={this.props.translate(route.title)}
                    id={`menu-${index}`}
                  >
                    {route.groups.map(group =>
                      group.routes.map(
                        (route, index) => {
                          if (this.props.activeLanguage && route.hideOn !== this.props.activeLanguage.code) {
                            return (
                                route.path ? (
                                    <MobileMenuItem
                                        onGoTo={this.onGoTo}
                                        link={
                                          route.path.indexOf(":") > -1
                                              ? route.path.split(":")[0]
                                              : route.path
                                        }
                                        key={route.path + Math.random()}
                                        title={this.props.translate(route.title)}
                                        description={this.props.translate(
                                            route.description
                                        )}
                                    />
                                ) : (
                                    <a
                                        key={route.path + Math.random()}
                                        href={route.link}
                                        target="_blank"
                                        rel="nofollow noopenner"
                                    >
                                      <StyledMobileMenuLinkItem>
                                        <StyledTitle>
                                          {this.props.translate(route.title)}
                                        </StyledTitle>
                                        <StyledDescription>
                                          {this.props.translate(route.description)}
                                        </StyledDescription>
                                      </StyledMobileMenuLinkItem>
                                    </a>
                                )
                            )
                          }
                        }
                      )
                    )}
                  </MobileMenuAccordion>
                )
            )}
          </div>
        </div>
      </StyledMobileMenu>
    );
  }
}

export default withRouter(withLocalize(MobileMenu));
