import React from "react";
import styled from "styled-components";

const StyledSplitSection = styled.div``;

const SplitSection = ({
  left,
  right,
  leftClassName,
  rightClassName,
  backgroundImage
}) => (
  <StyledSplitSection className="row" backgroundImage={backgroundImage}>
    <div className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="container">
          <div className="row">
            <div
              className={
                leftClassName ? leftClassName : "col-12 col-sm-12 col-md-5"
              }
            >
              {left && typeof left === "function" && left()}
            </div>
            <div
              className={
                rightClassName
                  ? rightClassName
                  : "col-12 col-sm-12 col-md-6 offset-md-1"
              }
            >
              {right && typeof right === "function" && right()}
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledSplitSection>
);

export default SplitSection;
