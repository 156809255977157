import styled from "styled-components";
import { theme } from "../../../design/Themes";

const StatusText = styled.span`

  color: ${theme.neutral6}
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 3px;
  margin-bottom: 5px;
`;

StatusText.defaultProps = {
  className: "status-text"
};

export default StatusText;
