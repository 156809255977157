import styled from "styled-components";
import { theme } from "../../../design/Themes";

import StatusText from "../StatusText";

const StatusTextDanger = styled(StatusText)`
  color: ${theme.red3};
`;

export default StatusTextDanger;
