import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Tabs from "../../../../components/Tabs";
import Tab from "../../../../components/Tabs/Tab";
import TabContent from "../../../../components/Tabs/TabContent";

import ThunderbirdExistingAccountImage from "../Images/ThunderbirdExistingAccountImage";
import ThunderbirdPopServerSettingsImage from "../Images/ThunderbirdPopServerSettingsImage";
import ThunderbirdImapServerSettingsImage from "../Images/ThunderbirdImapServerSettingsImage";

const StyledImage = styled.img`
  width: 550px;
  margin: 0 auto;
`;

const StyledHeader = styled.div`
  background-image: url("/assets/images/email-setup/email-delivery-background-thunderbird.jpg");
  background-size: cover;
  background-position: center;
  height: 180px;
`;

class ThunderbirdPopStepsPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <div className="row mb-4">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="container text-center">
                  <Tabs
                    defaultTab={
                      this.props.currentStep > 0
                        ? `step${this.props.currentStep}`
                        : "step1"
                    }
                    renderTabs={(active, onSelect) => [
                      <Tab
                        key="step-1"
                        label="step1"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(1);
                        }}
                      >
                        {translate("email-tutorials-step-1")}
                      </Tab>,
                      <Tab
                        key="step-2"
                        label="step2"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(2);
                        }}
                      >
                        {translate("email-tutorials-step-2")}
                      </Tab>,
                      <Tab
                        key="step-3"
                        label="step3"
                        active={active}
                        onSelect={e => {
                          onSelect(e);
                          this.props.onSetStep(3);
                        }}
                      >
                        {translate("email-tutorials-step-3")}
                      </Tab>
                    ]}
                    renderTabContent={active => (
                      <React.Fragment>
                        <TabContent key="step-1" label="step1" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.thunderbird-pop-imap-steps.open-thnderbird"
                            )}
                          </p>

                          {activeLanguage && (
                            <StyledImage
                              src={
                                this.props.images[
                                  `1-${activeLanguage.code.toLowerCase()}.jpg`
                                ]
                              }
                              className="img-fluid"
                            />
                          )}
                        </TabContent>

                        <TabContent key="step-2" label="step2" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.thunderbird-pop-imap-steps.enter-info"
                            )}
                          </p>
                          {this.props.email && (
                            <ThunderbirdExistingAccountImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `2-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </TabContent>

                        <TabContent key="step-3" label="step3" active={active}>
                          <p className="mb-4">
                            {translate(
                              "pages.email-tutorial.thunderbird-pop-imap-steps.fill-out-everything"
                            )}
                          </p>
                          {this.props.email && (
                            <ThunderbirdPopServerSettingsImage
                              email={this.props.email}
                              image={
                                this.props.images[
                                  `3-POP-${activeLanguage.code.toLowerCase()}.png`
                                ]
                              }
                            />
                          )}
                        </TabContent>
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default ThunderbirdPopStepsPage;
