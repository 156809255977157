import React from "react";
import styled from "styled-components";

import Icon from "../Icon";

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${props => props.theme.neutral5};
  margin-bottom: 3px;
`;

const Label = ({ children, ...rest }) => (
  <StyledLabel className={rest.className}>
    {children} {rest.icon && <Icon icon={rest.icon} />}
  </StyledLabel>
);

Label.defaultProps = {
  label: "",
  icon: null
};
export default Label;
