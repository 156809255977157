import React from "react";
import AccordionItem from "../../components/Accordion/AccordionItem";
import styled from "styled-components";

const StyledArticle = styled.span`
  display: inline-block;
  padding: 8px 19px;
  border: 1px solid #d8dde6;
  margin-top: 10px;
  border-radius: 3px;
  width: 100%;
  & a {
    font-weight: 600;
    font-size: 17px;
  }
`;

export default class OtherLegalPageDetailsMk extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-1`}
                            title={'Дефиниции'}
                        >
                            <StyledArticle>
                                <p>Нашата политика за приватност се заснова на термините што ги користи Законот за
                                    заштита на личните податоци (во натамошниот текст: ЗЗЛП). Таа треба да биде
                                    разбирлива за нашите посетители и корисници. За да бидеме сигурни дека навистина е
                                    така, прво ќе ги објасниме поимите што ги користиме.
                                    Во оваа Политика за приватност, меѓу другото, ги користиме следниве поими:</p>
                                <ul>


                                    <li>
                                        <p><strong>Личен податок</strong></p>
                                        <p> Под личен податок се подразбира секоја информација која се однесува на
                                            идентификувано физичко лице или физичко лице кое може да се идентификува
                                            (субјект на лични
                                            податоци), а физичко лице кое може да се идентификува е лице чиј идентитет
                                            може да се
                                            утврди директно или индиректно, посебно врз основа на идентификатор како што
                                            се име и презиме, матичен број на
                                            граѓанинот, податоци за локација, идентификатор преку интернет, или врз
                                            основа на едно или повеќе обележја
                                            специфични за неговиот физички, физиолошки, генетски, ментален, економски,
                                            културен или социјален идентитет.</p>
                                    </li>

                                    <li>
                                        <p><strong>Субјект на личните податоци</strong></p>
                                        <p> Субјект на личните податоци е идентификувано или физичко лице кое може да се
                                            идентификува, чии лични податоци ги обработува контролорот одговорен за
                                            обработка на личните
                                            податоци.</p>
                                    </li>
                                    <li>
                                        <p><strong>Обработка на личните податоци</strong></p>
                                        <p>Обработка на личните податоци е секоја операција или збир на операции кои се
                                            извршуваат врз личните податоци, или група на лични податоци, автоматски или
                                            на
                                            друг начин, како што се: собирање, евидентирање, организирање,
                                            структурирање,
                                            чување, приспособување или промена, повлекување, консултирање, увид,
                                            употреба,
                                            откривање преку пренесување, објавување или на друг начин правење достапни,
                                            усогласување или комбинирање, ограничување, бришење или уништување.
                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Ограничување на обработката на податоците</strong></p>
                                        <p> Ограничување за обработката на личните податоци е означување на личните
                                            податоци
                                            кои се чуваат, a со цел ограничување на нивната обработка во иднина.
                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Профилирање</strong>
                                        </p>
                                        <p>Профилирање е секоја форма на автоматска обработка на лични податоци,
                                            која се состои од користење на лични податоци за оценување на одредени лични
                                            аспекти поврзани со физичкото лице, а особено за анализа или предвидување на
                                            аспекти кои се однесуваат на извршување
                                            на професионалните обврски на тоа физичко лице, неговата економска состојба,
                                            здравје, лични преференции, интереси, доверливост,
                                            однесување, локација или движење.</p>

                                    </li>


                                    <li>
                                        <p><strong>Псевдонимизација</strong></p>

                                        <p>Псевдонимизација е обработка на личните податоци на таков начин што личните
                                            податоци не можат повеќе да се поврзат со одреден субјект без да се користат
                                            дополнителни информации, под услов таквите дополнителни информации да се
                                            чуваат
                                            одделно и да подлежат на технички и организациски мерки со кои ќе се
                                            обезбеди
                                            дека личните податоци не се поврзани со идентификувано физичко лице или
                                            физичко
                                            лице кое може да се идентификува.
                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Контролор</strong></p>
                                        <p>Контролор е физичко или правно лице, орган на државната власт, државен орган
                                            или
                                            правно лице основано од државата за вршење на јавни овластувања, агенција
                                            или
                                            друго тело, кое самостојно или заедно со други ги утврдува целите и начинот
                                            на
                                            обработка на личните податоци, а кога целите и начинот на обработка на
                                            личните
                                            податоци се утврдени со закон, со истиот закон се определуваат контролорот
                                            или
                                            посебните критериуми за негово определување.

                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Обработувач</strong></p>
                                        <p>Обработувач е физичко или правно лице, орган на државната власт, државен
                                            орган
                                            или правно лице основано од државата за вршење на јавни овластувања,
                                            агенција
                                            или друго тело кое ги обработува личните податоци во име на контролорот.

                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Корисник</strong></p>
                                        <p>Корисник е физичко или правно лице, орган на државната власт, државен орган
                                            или
                                            правно лице основано од државата за вршење на јавни овластувања, агенција
                                            или
                                            друго тело на кое му се откриваат личните податоци без разлика дали е тоа
                                            трето
                                            лице или не. Меѓутоа, органите на државната власт и државните органи на кои
                                            им
                                            се откриваат личните податоци во рамките на посебна истрага во согласност со
                                            закон, не се сметаат за корисници, при што обработката на овие податоци од
                                            овие
                                            органи мора да биде во согласност со важечките правила за заштита на личните
                                            податоци според целите на таа обработка.
                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Трето лице</strong></p>
                                        <p>Трето лице е секое физичко или правно лице, орган на државната власт, државен
                                            орган или правно лице основано од државата за вршење на јавни овластувања,
                                            агенција или друго тело, кое не е субјект, контролор, обработувач или лице,
                                            кое
                                            под директно овластување на контролорот или обработувачот е овластено да ги
                                            обработува податоците.</p>

                                    </li>

                                    <li>
                                        <p><strong>Согласност</strong></p>
                                        <p>Согласност на субјектот е секоја слободно дадена, конкретна, информирана и
                                            недвосмислена изјава на волја, преку изјава или јасно потврдено дејствие, а
                                            со
                                            кои се изразува согласност за обработка на неговите лични податоци.
                                        </p>

                                    </li>


                                </ul>
                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-2`}
                            title={'Назив и адреса на контролорот'}
                        >
                            <StyledArticle>

                                <p>ДПТУ МКХОСТ ДООЕЛ увоз-извоз Скопје(МКХОСТ ) </p>
                                <p>Tелефон:<a href="tel:+ 389-2-2779-444">+ 389 (2) 2779 444</a></p>
                                <p>Електронска адреса:<a href="mailto: info@mkhost.com.mk"> info@mkhost.com.mk</a></p>
                                <p> Веб страница: < a href="https://mkhost.com/mk">https://mkhost.com/mk </a></p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-3`}
                            title={'Офицер за заштита на личните податоци'}
                        >
                            <StyledArticle>

                                <p>Контакт телефон:<a href="tel:+ 389-2-2779-444">+392 2 2779 444</a>
                                </p>
                                <p> Електронска адреса:<a href="mailto: info@mkhost.com.mk"> info@mkhost.com.mk</a>
                                </p>
                                <p>Секој посетител или корисник на оваа веб страница, може директно да контактира со
                                    нашиот
                                    офицер за заштита на лични податоци за какви било прашања и предлози во врска со
                                    заштитата на личните податоци.
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-4`}
                            title={'Како ги применуваме начелата за заштита на личните податоци?'}
                        >
                            <StyledArticle>

                                <p>Вашите лични податоци на веб страницата ги собираме и обработуваме врз основа на Ваша
                                    согласност.
                                </p>

                                <ul>
                                    <li><p><strong>Законит, фер и транспарентен начин на обработка на личните
                                        податоци</strong></p>
                                        <p>Вашите лични податоци на веб страницата ги собираме и обработуваме врз основа
                                            на
                                            Ваша согласност.
                                        </p>
                                    </li>
                                    <li><p><strong>Ограничување на целта на обработката на личните податоци</strong></p>
                                        <p>Вашите лични податоци на веб страницата ги собираме и обработуваме за цели на
                                            нарачка на пакетите кои ги нудиме (купување на домен, хостинг или сервер),
                                            за да
                                            не консултирате, за да отворите тикет, за регистрација и најава на <a
                                                href="https://portal.mkhost.com/login">порталот
                                                mkhost</a>, за нарачка на бесплатна е-книга од МКХОСТ, за поднесување на
                                            барање
                                            за
                                            регистрација и промена на домен или промена на сопственост, МКХОСТ чет,
                                            аплицирање преку е-маил на отворен конкурс за вработување и да ги споделите
                                            вашите идеи за веб сајт. Вашите лични податоци не се предмет на натамошна
                                            обработка за цели различни од првично дефинираните.

                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Ограничување на обемот на личните податоци</strong></p>
                                        <p>
                                            Обработката на вашите лични податоци е ограничена само на оние податоци кои
                                            се
                                            неопходни за да се исполнат целите на обработката. Обемот на личните
                                            податоци е
                                            соодветен на она што е потребно за исполнување на целите, определени во
                                            точка 5
                                            од оваа Политика за приватност. Доколку се појави потреба за дополнителна
                                            обработка на Вашите лични податоци, ќе Ве информираме и ќе побараме Ваша
                                            согласност за таа дополнителна обработка.

                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Точност на личните податоци</strong></p>
                                        <p>
                                            Спроведуваме разумни мерки за потврдување на точноста, за исправка и/или
                                            бришење
                                            на неточните лични податоци кои ги обработуваме. Покрај овие мерки, Ви
                                            овозможуваме да го остварите Вашето право да побарате исправка, дополнување
                                            или
                                            бришење на личните податоци на лесен и едноставен начин.


                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Ограничување на роковите на обработка на личните податоци</strong>
                                        </p>
                                        <p>
                                            Водиме грижа, Вашите лични податоци да се чуваат во роковите предвидени во
                                            точка
                                            16 од оваа Политика за приватност. По изминувањето на роковите или по
                                            исполнување на целта за обработката, Вашите лични податоци се бришат.


                                        </p>
                                    </li>

                                    <li>
                                        <p><strong>Интегритет и доверливост на личните податоци</strong></p>
                                        <p>
                                            Преземаме соодветни технички и организациски мерки за заштита на личните
                                            податоци што се собираат преку оваа веб страница од неовластен пристап,
                                            незаконско откривање и уништување на податоците. Пристапот до личните
                                            податоци е
                                            ограничен само на лицата кои се посебно овластени за тоа.


                                        </p>
                                    </li>

                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-5`}
                            title={'Правна основа и цели за обработка Вашите лични податоци '}
                        >
                            <StyledArticle>

                                <p>Нашата веб страница собира лични податоци исклучиво од субјектите на лични податоци
                                    (корисниците).

                                </p>

                                <ul>
                                    <li>
                                        <p><strong>Правна основа</strong></p>
                                        <p>Правна основа за обработка на Вашите лични податоци на нашата веб страница
                                            преку
                                            формите: „Нарачка“, „Кошничка“, „Консултација“, „Отворање на тикет“,
                                            „Регистрација и најава“, „Поднесување на барање за регистрација и промена на
                                            домен или промена на сопственост“, „Аплицирање на отворен оглас за работа“,
                                            „Споделување на идеи“, „Бесплатна е-книга“ и „МКхост Чет“ е Вашата
                                            согласност.
                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Цели</strong></p>
                                        <p>Преку формите „Нарачка“, „Кошничка“ и „Регистрација и најава“ ги обработуваме
                                            Вашите лични податоци за да отпочнеме активности за реализација на бараната
                                            нарачка и наплата, со што се воспоставува договорен однос меѓу Вас и МКХОСТ.
                                            Преку формите „Консултација“, „Отворање на тикет“ и „Споделување на идеи“ ги
                                            обработуваме Вашите лични податоци доколку имате проблеми со продуктите или
                                            сметката, да споделите ваши идеи, како и да оствариме комуникација со Вас и
                                            да
                                            одговориме на Вашата порака преку која може да барате информации за нашите
                                            услуги, производи и сл.
                                            Преку формата „Бесплатна е-книга“ ги обработуваме Вашите лични податоци за
                                            да
                                            преземете бесплатно е-книга.
                                            Преку формата „МКхост Чет“ ги обработуваме Вашите лични податоци за да
                                            одговориме на вашите пораки.
                                            Преку опцијата „Аплицирање на отворен оглас за работа“ можете да доставите
                                            CV
                                            (биографија) за отворените огласи за работа на <a
                                                href="mail to:jobs@mkhost.com.mk"> jobs@mkhost.com.mk</a>.
                                            За „Поднесување на барање за регистрација и промена на домен или промена на
                                            сопственост“ од страна на физички лица потребно е да се пополни барањето и
                                            да се
                                            достави лично во просториите на МКХОСТ.


                                        </p>
                                    </li>


                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-6`}
                            title={'Кои категории на лични податоци ги обработуваме? '}
                        >
                            <StyledArticle>
                                <ul>
                                    <li>
                                        <p><strong>Форма „Нарачка“, „Кошничка“ и „Регистрација и најава“
                                        </strong></p>

                                        <p>Доколку сакате да нарачате еден од пакетите што ги нудиме, како што е
                                            утврдено во
                                            точка 5 од оваа Политика, потребно е да ја внесете Вашата електронска адреса


                                        </p>

                                    </li>
                                    <li>
                                        <p><strong>Форма „Консултација“, „Отворање на тикет“ и „Споделување на
                                            идеи“</strong></p>

                                        <p>Доколку сакате да не контактирате за нашите услуги, да побарате понуда,
                                            доколку имате проблем со користење на услугите или техничко прашање, доколку
                                            сакате да регистрирате домен или имате проблем со наплата или фактура
                                            потребно е да ги внесете Вашите лични податоци:
                                        </p>
                                        <p> Име и презиме,</p>
                                        <p> Електронска адреса,</p>
                                        <p> Порака,</p>
                                        <p>Прикачи фајл.</p>
                                        <p>Нашата веб страница Ви овозможува брз контакт и директна комуникација со нас
                                            и
                                            преку електронската адреса: info@mkhost.com.mk.</p>

                                    </li>
                                    <li>
                                        <p><strong>Форма „Бесплатна е-книга“</strong></p>
                                        <p>Доколку сакате да преземете бесплатно е-книга, потребно е да ги внесете
                                            Вашите
                                            лични податоци:</p>
                                        <p> Име и презиме и Електронска адреса

                                        </p>
                                    </li>
                                    <li>
                                        <p><strong>Форма „МКхост чет“</strong></p>
                                        <p>Доколку сакате да не контактирате онлајн и да оставите порака, потребно е да
                                            ги
                                            внесете Вашите лични податоци:


                                        </p>
                                        <p> Име и презиме (опционално може да се поврзете преку Фејсбук или Гугл
                                            профилот)

                                        </p>
                                        <p>Електронска адреса

                                        </p>
                                        <p>
                                            Телефонски број и Порака

                                        </p>

                                    </li>


                                </ul>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-7`}
                            title={'Колачиња (Cookies) '}
                        >
                            <StyledArticle>
                                <p>Нашата веб страница користи колачиња. Колачињата се текстуални датотеки зачувани во
                                    Вашиот информациски систем преку веб пребарувачот.</p>
                                <p> Многу колачиња содржат таканаречен ИД на колачето - единствен идентификатор на
                                    колачето,
                                    кој се состои од низа карактери преку кои посетените веб страници ги разликуваат веб
                                    пребарувачите на нивните корисници од другите веб пребарувачи, а со помош на
                                    единствениот идентификатор на колачето.</p>
                                <p>Со користење на колачиња на нашата веб страница, Ви нудиме подобри услуги Вам на
                                    корисниците. Колачињата ни овозможуваат, да ги препознаваме корисниците на нашата
                                    веб
                                    страница. Целта на ова е да Ви го олесниме користењето на нашата веб страница.
                                    Доколку
                                    користите колачиња на нашата веб страница, не мора секогаш кога пристапувате до веб
                                    страницата да внесувате податоци за пристап, затоа што ги преземате од веб
                                    страницата и
                                    колачето е зачувано на Вашиот информациски систем.</p>
                                <p>Како корисник, можете во секое време да спречите поставување на колачиња преку нашата
                                    веб страница со користење на соодветна поставка на веб пребарувачот или трајно да го
                                    одбиете поставувањето колачиња, а може и веќе поставените колачиња да ги избришете
                                    во
                                    кое било време преку веб пребарувачот или други софтверски програми. Ако го
                                    исклучите
                                    поставувањето колачиња преку веб пребарувачот, не ќе може во целост да ги користите
                                    сите
                                    функции на нашата веб страница.</p>
                                <p>Повеќе информации се достапни во Политиката за колачиња на МКХОСТ.
                                    (<a href="https://mkhost.com/mk/other/legal">https://mkhost.com/mk/other/legal</a>)
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-8`}
                            title={'Собирање на општи податоци и информации '}
                        >
                            <StyledArticle>
                                <p>Нашата веб страница собира низа општи податоци и информации кога пристапувате до неа.
                                    Овие општи податоци и информации се зачувани во лог фајловите (датотеките) на
                                    серверот.
                                    Собраните податоци и информации може да се однесуваат на (1) видот на веб
                                    пребарувачот и
                                    користените верзии, (2) оперативниот систем што го користи системот за пристап, (3)
                                    веб
                                    страницата од која системот за пристап пристапува до нашата веб страница, (4)
                                    датумот и
                                    времето на пристап до веб страницата, (5) адресата на Интернет протоколот (IP
                                    адресата),
                                    (6) давателот на Интернет услуги на системот за пристап и (7) други слични
                                    информации
                                    што можат да се користат во случај на напад врз нашиот информациски систем.
                                </p>
                                <p> При користење на овие општи податоци и информации, не донесуваме заклучоци за
                                    корисникот. Наместо тоа, овие информации ни се потребни за (1) правилно да ја
                                    испорачаме
                                    содржината на нашата веб страница, (2) да ја оптимизираме содржината на нашата веб
                                    страница, (3) да обезбедиме долгорочна одржливост на нашиот информациски систем и
                                    технологијата на веб страницата, и (4) да им ги дадеме на органите за спроведување
                                    на
                                    законот информациите потребни за гонење во случај на сајбер-напад.</p>
                                <p>Затоа, анонимно анализираме и собираме статистички податоци со цел да обезбедиме
                                    оптимално ниво на заштита на личните податоци што ги обработуваме. Анонимните
                                    податоци
                                    од лог фајловите (датотеките) на серверите се зачувуваат одделно од Вашите лични
                                    податоци.
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-9`}
                            title={'Заштита на личните податоци при користење на Facebook  '}
                        >
                            <StyledArticle>

                                <p>На нашата веб страница е интегрирана компонентата на Facebook. Facebook е социјална
                                    мрежа
                                    - место за социјални состаноци на Интернет кое на корисниците им овозможува да
                                    комуницираат едни со други во виртуелен простор. Социјалната мрежа може да послужи
                                    како
                                    платформа за размена на мислења и искуства или за добивање лични или деловни
                                    информации.
                                    Facebook им овозможува на корисниците да креираат приватни профили, да поставуваат
                                    фотографии и да се поврзат преку барања за пријателство.

                                </p>
                                <p> Facebook, Inc., 1 Hacker Way, Menlo Park, Калифорнија 94025, Соединетите Американски
                                    Држави (САД) е контролор на податоците на субјектите од САД и Канада. Ако субјектот
                                    живее надвор од САД или Канада, контролор е Facebook Ireland Ltd., 4 Grand Canal
                                    Square,
                                    Grand Canal Harbour, Даблин 2, Ирска.
                                </p>
                                <p>Секогаш кога ќе ја посетите нашата веб страница, на која е интегрирана компонентата
                                    на
                                    Facebook (Facebook plug-in), веб пребарувачот на Вашиот информациски систем,
                                    автоматски
                                    се поттикнува да испрати приказ на соодветната компонента на Facebook. Преглед на
                                    сите
                                    Facebook plug-in може да се обезбеди на <a
                                        href="https://developers.facebook.com/docs/plugins"> https://developers.facebook.com/docs/plugins</a>.
                                    Во текот на овој технички процес, Facebook е информиран за тоа која веб страница сте
                                    ја
                                    посетиле.
                                    Facebook прима информации за посетата на нашата веб страница, кога сте најавени на
                                    Facebook за време на посета на нашата веб страница. Доколку не сакате овие
                                    информации да
                                    се пренесуваат на Facebook, одјавете го Вашиот кориснички профил на Facebook пред да
                                    ја
                                    посетите нашата веб страница.

                                </p>
                                <p>Упатството за заштита на податоците објавено од Facebook е достапно на
                                    <a href=" https://facebook.com/about/privacy"> https://facebook.com/about/privacy</a> .
                                    Тоа содржи информации за собирањето, обработката и
                                    употребата на личните податоци од страна на Facebook. Покрај тоа, објаснето е какви
                                    поставки нуди Facebook за заштита на Вашата приватност. Дополнително, достапни се
                                    различни опции на поставки, за да се оневозможи преносот на податоци на Facebook.
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-10`}
                            title={' Заштита на личните податоци при користење на Instagram '}
                        >
                            <StyledArticle>

                                <p>На нашата веб страница е интегрирана компонентата на Instagram. Instagram е услуга
                                    која
                                    може да се опише како аудиовизуална платформа, која на корисниците им овозможува
                                    споделување на фотографии и видеозаписи, како и ширење на таквите податоци на
                                    другите
                                    друштвени мрежи.


                                </p>
                                <p> Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Даблин 2 Ирска е
                                    контролор на личните податоци кои се обработуваат преку Инстаграм.

                                </p>
                                <p>Секогаш кога ќе ја посетите нашата веб страница, на која е интегрирана компонентата
                                    на
                                    Instagram (копчето Insta), веб пребарувачот на Вашиот информациски систем,
                                    автоматски се
                                    поттикнува да испрати приказ на соодветната компонента на Instagram. Во текот на
                                    овој
                                    технички процес, Instagram е информиран која веб страница сте ја посетиле.

                                </p>
                                <p>Доколку истовремено сте пријавени на Instagram, Instagram ги детектира сите посети на
                                    нашата веб страница од Ваша страна и севкупното време поминато на нашата веб
                                    страница.
                                    Тие податоци се собираат преку компонентата на Instagram и се поврзуваат со Вашиот
                                    Instagram профил. Ако кликнете на Instagram копчето кое е интегрирано на нашата веб
                                    страница, Instagram ги поврзува овие податоци со Вашиот личен Instagram кориснички
                                    профил и ги зачувува личните податоци.

                                </p>
                                <p>Instagram прима информации за посетата на нашата веб страница, кога сте најавени на
                                    Instagram за време на посетата на нашата веб страница. Ова се случува независно од
                                    тоа
                                    дали сте кликнале на Instagram копчето или не. Доколку не сакате овие информации да
                                    се
                                    пренесуваат на Instagram, одјавете се од Instagram профилот пред да ја посетите
                                    нашата
                                    веб страница.
                                </p>
                                <p>Дополнителни информации и важечки одредби за заштита на личните податоци на Instagram
                                    можат да се најдат на следниот линк:
                                    <a href="https://help.instagram.com/155833707900388">
                                        <p>https://help.instagram.com/155833707900388</p></a>
                                    <a href="https://www.instagram.com/about/legal/privacy">
                                        <p>https://www.instagram.com/about/legal/privacy.</p></a>

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-11`}
                            title={' Заштита на личните податоци при користење на Linkedin '}
                        >
                            <StyledArticle>

                                <p>На нашата веб страница е интегрирана компонентата на LinkedIn. LinkedIn е онлајн
                                    друштвена мрежа која им овозможува на корисниците со постоечки деловни контакти да
                                    се поврзат и да создаваат нови деловни контакти. Повеќе од 400 милиони регистрирани
                                    луѓе во повеќе од 200 земји користат LinkedIn. LinkedIn моментално е најголемата
                                    платформа
                                    за деловни контакти и една од најпосетуваните веб-страници во светот.


                                </p>
                                <p> LinkedIn Corporation, 2029 Stierlin Court Mountain View, CA 94043, Соединетите
                                    Американски Држави е контролор на личните податоци кои се обработуваат преку
                                    LinkedIn. LinkedIn Ирска, Wilton Plaza, Wilton Place, Даблин 2, Ирска е одговорен за
                                    прашања
                                    поврзани со политиката за приватност надвор од Соединетите Американски Држави.


                                </p>
                                <p>Секогаш кога ќе ја посетите нашата веб страница, на која е интегрирана компонентата
                                    LinkedIn (копчето LinkedIn), веб пребарувачот на информацискиот систем на
                                    корисникот, автоматски бара да преземе приказ од соодветната LinkedIn компонента.


                                </p>
                                <p>Повеќе информации може да најдете на <a
                                    href="https://developer.linkedin.com/plugins"> https://developer.linkedin.com/plugins</a>.
                                    Во текот на оваа техничка постапка, LinkedIn знае која веб страница сте ја посетиле.


                                </p>
                                <p>Доколку истовремено сте пријавени на LinkedIn, LinkedIn ги детектира сите посети на
                                    нашата веб страница од Ваша страна и севкупното време поминато на нашата веб
                                    страница. Тие податоци се собираат преку LinkedIn компонентата и се поврзуваат со
                                    Вашиот
                                    LinkedIn профил. Ако кликнете на LinkedIn копчето кое е интегрирано на нашата веб
                                    страница,
                                    LinkedIn ги поврзува овие податоци со Вашиот личен LinkedIn кориснички профил и ги
                                    зачувува личните податоци.
                                </p>
                                <p>LinkedIn добива информации преку компонентата LinkedIn дека сте ја посетиле нашата
                                    веб страница под услов истовремено да сте пријавен на LinkedIn за време на посетата
                                    на
                                    нашата веб страница. Тоа се случува независно од тоа дали сте кликнале на LinkedIn
                                    копчето или не. Доколку не сакате овие информации да се пренесуваат на LinkedIn,
                                    одјавете се од LinkedIn профилот пред да ја посетите нашата веб страница.</p>

                                <p>LinkedIn нуди можност за откажување од добивање е-маил пораки, СМС пораки и насочени
                                    реклами, како и можност за управување со поставките за реклами на следниот линк
                                    https://www.linkedin.com/psettings/guest-controls. LinkedIn користи и подружници
                                    како што се Eire, Google Analytics, BlueKai, DoubleClick, Nielsen, Comscore, Eloqua
                                    и
                                    Lotame. Поставувањето на таквите колачиња може да се одбие на следниот линк
                                    <a href="https://www.linkedin.com/legal/cookie-policy"> https://www.linkedin.com/legal/cookie-policy</a>.
                                    Политиката за приватност на LinkedIn е
                                    достапна на <a
                                        href="https://www.linkedin.com/legal/privacy-policy"> https://www.linkedin.com/legal/privacy-policy</a> .
                                    Политиката за колачиња на
                                    LinkedIn е достапна на <a
                                        href="https://www.linkedin.com/legal/cookie-policy"> https://www.linkedin.com/legal/cookie-policy</a> .
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-12`}
                            title={' Заштита на личните податоци при користење на YouTube '}
                        >
                            <StyledArticle>

                                <p>На нашата веб страница, интегрирана е компонентата на YouTube. YouTube е интернет
                                    видео
                                    портал кој им овозможува на видео издавачите и на другите корисници бесплатно да
                                    поставуваат видеозаписи, како и бесплатно нивно прегледување, оценување и
                                    коментирање.
                                    YouTube ви овозможува да објавувате секаков вид на видеа, да пристапите до цели
                                    филмови
                                    и ТВ -емисии, како и музички видеа и видеа направени од корисници преку Интернет
                                    порталот.


                                </p>
                                <p> YouTube услугата ја овозможува трговското друштво Google Ireland Limited, Gordon
                                    House,
                                    Barrow Street, Даблин, D04 E5W5, Ирска.


                                </p>
                                <p>Секогаш кога ќе ја посетите нашата веб страница на која е интегрирана компонентата
                                    YouTube (видео на YouTube), веб пребарувачот на Вашиот информациски систем
                                    автоматски се
                                    поттикнува да испрати приказ на соодветната компонента на YouTube. Дополнителни
                                    информации за YouTube се достапни на <a
                                        href="https://www.youtube.com/howyoutubeworks"> https://www.youtube.com/howyoutubeworks</a>.
                                    Во текот
                                    на овој технички процес, YouTube е информиран која веб страница сте ја посетиле.


                                </p>
                                <p>Ако сте најавени на YouTube, YouTube ја препознава секоја посета на нашата веб
                                    страница.
                                    Овие информации се собираат од YouTube и Google и се доделуваат на вашата сметка на
                                    YouTube.


                                </p>
                                <p>YouTube и Google добиваат информации преку компонентата YouTube дека сте ја посетилe
                                    нашата веб страница, ако сте најавени на YouTube во моментот на посетата на нашата
                                    веб
                                    страница. Ова се случува без разлика дали сте кликнале на YouTube видеозаписот или
                                    не.
                                    Доколку не се согласувате со таквиот пренос на податоци на YouTube и Google можете
                                    да се
                                    одјавите од Вашиот профил на YouTube пред да се најавите на нашата веб страница.

                                </p>
                                <p>Одредбите за заштита на личните податоци на YouTube кои се достапни на следниот линк
                                    <a href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en</a>,
                                    обезбедуваат информации за собирање,
                                    обработка и употреба на личните податоци од страна на YouTube и Google.
                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-13`}
                            title={' Одредби за заштита на податоците за употреба на Twitter '}
                        >
                            <StyledArticle>

                                <p>На оваа веб страници e интегрирана компонентата на Twitter. Twitter е
                                    повеќе јазична, јавно достапна услуга за "microblogging" на која корисниците можат
                                    да објавуваат и шират таканаречени твитове, на пр. кратки пораки, кои се ограничени
                                    на
                                    280 знаци. Овие кратки пораки се достапни на секого, вклучително и оние кои не се
                                    пријавиле
                                    на Twitter.


                                </p>
                                <p> Твитовите, исто така се прикажуваат и на следбениците на соодветните корисници.
                                    Следбениците се други корисници на Twitter кои ги следат твитовите на корисниците.
                                    Дополнително, Twitter овозможува да се обратите на широката публика преку хаштаг,
                                    линкови или ретвитови.


                                </p>
                                <p>Twitter International Company, One Cumberland Place, Fenian Street Даблин 2, D02
                                    AX07, Ирска е компанијата која ја нуди Twitter услугата.


                                </p>
                                <p>Со секое повикување на една од поединечните страници на оваа интернет страница, со
                                    која
                                    управува МКХост и на која е интегрирана Twitter компонентата (копчето
                                    Twitter),
                                    веб прелистувачот на Вашиот информациски систем автоматски се поттикнува да испрати
                                    приказ на соодветната компонента на Twitter. Преглед на сите Twitter копчиња може да
                                    најдете на <a
                                        href="https://about.twitter.com/de/resources/buttons">https://about.twitter.com/de/resources/buttons</a>.
                                    За време на овој технички
                                    процес, Twitter е информиран која веб страница сте ја посетиле.


                                </p>
                                <p>Целта на интегрирање на компонентата на Twitter е реемитување на содржината на оваа
                                    веб
                                    страница се со цел да им овозможиме на нашите корисници да ја претстават оваа веб
                                    страница во дигиталниот свет и да го зголемат бројот на нашите посетители.


                                </p>
                                <p>Ако сте најавени на Twitter, Twitter го открива секој повик на нашата веб страница од
                                    Ваша страна и времетраењето на Вашиот престој на нашата веб страница и која
                                    потстраница
                                    на нашата веб страница сте ја посетиле. Овие податоци се собираат преку Twitter
                                    компонентата и се поврзуваат со Вашата Twitter сметката. Ако кликнете на едно од
                                    копчињата на Твитер, интегрирано на нашата веб страница, Twitter ги поврзува тие
                                    податоци со Вашата лична сметка на Twitter и ги чува личните податоци.

                                </p>
                                <p>Twitter добива информации преку компонентата Twitter дека сте ја посетилe нашата веб
                                    -страница, под услов да сте најавени на Twitter во моментот на повикување на нашата
                                    веб
                                    страница. Ова се случува без разлика дали сте кликнале на компонентата Твитер или
                                    не.
                                    Доколку не се согласувате со таквиот пренос на податоци на Twitter можете да го
                                    спречито
                                    тоа со одјавување од Вашиот профил на Твитер пред да се најавите на нашата веб
                                    страница.
                                    Одредбите за заштити на личните податоци на Twitter се достапни на следниот линк
                                    <a href="https://twitter.com/privacy?lang=en.">https://twitter.com/privacy?lang=en.</a>

                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-14`}
                            title={' Заштита на личните податоци при користење na Google Analytics '}
                        >
                            <StyledArticle>

                                <p>На нашата веб страница, интегрирана е компонентата Google Analytics. Google Analytics
                                    е
                                    услуга за веб анализа - собирање и анализа на податоците за однесувањето на
                                    корисниците
                                    на веб страниците. Меѓу другото, се собираат информации за веб страницата од која
                                    потекнува корисникот (т.н. Referrer), како и колку често и колку долго трае посетата
                                    на
                                    веб страницата. Веб анализата главно се користи за оптимизирање на веб страницата.


                                </p>
                                <p> Компанијата што ја обезбедува компонентата Google Analytics е Google Ireland
                                    Limited,
                                    Gordon House, Barrow Street, Даблин, D04 E5W5, Ирска.


                                </p>
                                <p>Google Analytics поставува колаче на информацискиот систем на корисникот.
                                    Дефиницијата за колачиња е објаснета погоре како и во Политиката за колачиња. Со
                                    поставување колаче, Google може да ја анализира употребата на нашата веб страница.
                                    Колачето се користи за собирање и чување на лични податоци, како што се времето на
                                    пристап, местото од каде се пристапува и зачестеноста на посетите на нашата веб
                                    страница од страна на корисникот. Со секоја посета на нашата веб страница, ваквите
                                    лични
                                    податоци, вклучително и IP-адресата што ја користи корисникот за пристап до
                                    Интернет, ќе
                                    бидат пренесени на Google во САД и зачувани од Google во САД. Google може да ги
                                    пренесе
                                    овие лични податоци на трети лица.


                                </p>
                                <p>Со соодветно прилагодување на веб пребарувачот можете да спречите поставување на
                                    колачиња
                                    преку нашата веб страница, но и трајно да забраните поставување на колачиња. Ваквото
                                    прилагодување на веб пребарувачот исто така би спречило Google Analytics да
                                    поставува
                                    колачиња на Вашиот информациски систем. Покрај тоа, колачињата што веќе ги користи
                                    Google Analytics може да се избришат во секое време преку веб пребарувач или други
                                    софтверски програми.


                                </p>
                                <p>Воедно, можете да преземете и инсталирате додаток на веб пребарувачот на
                                    <a href="https://tools.google.com/dlpage/gaoptout"> https://tools.google.com/dlpage/gaoptout</a>.
                                    Овој додаток на веб пребарувачот му кажува на
                                    Google Analytics преку JavaScript да не се пренесуваат какви било податоци и
                                    информации
                                    за Вашите посети на веб страници. Инсталирањето додатоци на пребарувачот се смета за
                                    приговор кон Google. Ако Вашиот информациски систем подоцна е избришан, форматиран
                                    или
                                    повторно инсталиран, мора повторно да ги инсталирате додатоците на веб пребарувачот
                                    за
                                    да го оневозможи Google Analytics. Ако додатокот на веб пребарувачот е деинсталиран
                                    или
                                    оневозможен, можно е повторно да се инсталира или активира.


                                </p>
                                <p>Дополнителни информации и важечки одредби за заштита на податоците од Google може да
                                    се
                                    преземат на:
                                    <a href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en</a> и
                                    <a href="https://marketingplatform.google.com/about/analytics/terms/us"> https://marketingplatform.google.com/about/analytics/terms/us/</a> .
                                    Google Analytics е дополнително објаснет на следниот линк:
                                    <a href="https://marketingplatform.google.com/about/analytics"> https://marketingplatform.google.com/about/analytics</a>.
                                    Во формата „Кошничка“ ја користиме алатката ReCAPTCHA на Google Ireland Limited,
                                    Gordon
                                    House за што ни е потребна ваша согласност за користење на истата. Повеќе информации
                                    за
                                    приватноста може да најдете на <a
                                        href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/privacy?hl=en</a> како
                                    и на
                                    условите за користење достапни на следниот линк <a
                                        href="https://policies.google.com/privacy?hl=en"> https://policies.google.com/terms?hl=en</a>.


                                </p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-15`}
                            title={' Дали вршиме пренос на лични податоци? '}
                        >
                            <StyledArticle>

                                <p>Серверите на веб страницата МКХОСТ се лоцирани на сервери во ЕУ, поточно во
                                    Германија, Франкфурт.


                                </p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-16`}
                            title={' Во кој рок ги чуваме личните податоци? '}
                        >
                            <StyledArticle>

                                <p>Вашите лични податоци доставени за да не консултирате, за нарачка на бесплатна
                                    е-книга од МКХОСТ,и да ги споделите вашите идеи за веб сајт и сл. ги обработуваме и
                                    чуваме само во времето потребно за постигнување на целта заради која се собрани, а
                                    најдолго 1 година од денот на доставување на пораката.

                                </p>
                                <p>Вашите лични податоци доставени за да отворите тикет доколку имате проблеми со
                                    продуктите или сметката ги обработуваме и чуваме само во времето потребно за
                                    постигнување на целта заради која се собрани, а најдолго 5 години од денот на
                                    разрешување на проблемот.


                                </p>
                                <p>Вашите лични податоци кои се доставени преку формата нарачка (купување на домен,
                                    хостинг или сервер) ќе бидат избришани во рок од 2 години од некативност на
                                    профилот.


                                </p>
                                <p>Вашите лични податоци кои се доставени преку формата за регистрација и најава на
                                    порталот МКХОСТ ќе бидат избришани во рок од 2 години од неактивност на профилот.


                                </p>
                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-17`}
                            title={' Дали донесуваме одлуки врз основа на автоматска обработка на податоци? '}
                        >
                            <StyledArticle>

                                <p>МКХОСТ не донесува поединечни одлуки врз основа на автоматска обработка на податоци
                                    вклучувајќи и профилирање.


                                </p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-18`}
                            title={' Дали и на кого ги откриеме Вашите лични податоци согласно закон '}
                        >
                            <StyledArticle>

                                <p>Вашите лични податоци ќе ги откриеме на надлежни државни органи за цели на водење на
                                    постапки согласно закон. За секое откривање на Вашите лични податоци, ќе водиме
                                    писмена евиденција и ќе Ве известиме на соодветен начин.
                                    Доколку во иднина се појави потреба за откривање на податоците на трето лице кои не
                                    се предвидени во оваа Политика, ќе побараме Ваша согласност онаму каде што е тоа
                                    неопходно и ќе извршиме дополнување на Политиката за приватност пред таа обработка
                                    воопшто да
                                    започне.


                                </p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-19`}
                            title={' Кои се Вашите права како субјекти на лични податоци? '}
                        >
                            <StyledArticle>
                                <p><strong>Право на потврда</strong></p>

                                <p>
                                    Како субјект на лични податоци (корисник, купувач, посетител на веб страницата)
                                    имате право да добиете потврда од МКХОСТ дали ги обработуваме Вашите лични податоци.
                                </p>
                                <p><strong>Право на пристап</strong></p>

                                <p>

                                    Во секое време, бесплатно имате право да добиете информации од МКХОСТ за Вашите
                                    лични податоци, како и копија од овие податоци. ЗЗЛП Ви гарантира пристап до
                                    следниве
                                    информации: цел на обработката;

                                    категории на личните податоци;
                                    корисници или категории на корисници на кои им биле откриени или ќе бидат откриени
                                    личните податоци;
                                    ако е можно, време на чување на личните податоци или ако тоа не е можно,
                                    критериумите
                                    користени за одредување на времето на чување;
                                    постоење на право да се бара исправка или бришење на лични податоци или ограничување
                                    на обработката на лични податоци;
                                    постоење на право на поднесување барање до Агенцијата за заштита на личните
                                    податоци;
                                    кога личните податоци не се собрани од Вас, сите достапни информации за изворот на
                                    лични податоци;
                                    постоење на автоматско донесување одлуки, вклучително и профилирање, и во тие
                                    случаи,
                                    релевантни информации за вклучената логика, како и значењето и предвидените
                                    последици на таквата обработка за субјектот.
                                    Покрај тоа, имате право да добиете информации за тоа дали личните податоци се
                                    пренесени во трета земја, како и за соодветните заштитни мерки во врска со преносот.


                                </p>
                                <p><strong>Право на исправка</strong></p>

                                <p>

                                    Имате право да добиете исправка на Вашите неточни лични податоци без непотребно
                                    одложување. Земајќи ги предвид целите на обработката, имате право да ги дополните
                                    нецелосните лични податоци.</p>


                                <p><strong> Право на бришење („Право да се биде заборавен“)</strong></p>
                                <p> Имате право да барате бришење на Вашите лични податоци без непотребно одложување.
                                    Ние сме должни да постапиме по ваквото барање ако е исполнет еден од следните
                                    услови:
                                    Личните податоци повеќе не се потребни за целите за кои се собрани или за кои се
                                    обработуваат;
                                    Сте ја повлекле согласноста на која се заснова обработката, а не постои друга правна
                                    основа за обработка;
                                    Сте приговарале на обработката;
                                    Обработката на личните податоци е незаконита;
                                    Постои законска обврска за бришење на личните податоци;

                                </p>

                                <p><strong>Право на ограничувања за обработка</strong></p>

                                <p>
                                    Имате право да ја ограничите обработката на Вашите лични податоци, доколку е
                                    исполнет еден од следниве услови:
                                    ја оспорувате точноста на личните податоци, за период кој ќе ни овозможи да ја
                                    провериме нивната точност;
                                    обработката е незаконита, но се спротивставувате на бришењето на личните податоци, а
                                    наместо тоа бара ограничување на нивната обработка;
                                    МКХОСТ нема повеќе потреба да ги обработува Вашите лични податоци, но Вам Ви се
                                    потребно за воспоставување, остварување или одбрана на Вашите правни барања;
                                    Приговарате на обработката, во очекување на верификација дали нашите легитимни
                                    интереси преовладуваат над Вашите интереси.


                                </p>
                                <p><strong>Право на преносливост на податоци</strong></p>

                                <p>
                                    Личните податоци чија обработка ја вршиме со автоматски средства, врз основа на Ваша
                                    согласност, имате право да ги добиете во структуриран, вообичаено користен, машински
                                    читлив формат или да барате да ги пренесеме овие податоци на друг контролор, под
                                    услов ако е технички изводливо.


                                </p>
                                <p><strong>Право на приговор</strong></p>

                                <p>
                                    Имате право да поднесете приговор до МКХОСТ, против обработката на Вашите лични
                                    податоци за цели на директен маркетинг.
                                    Во случај на приговор, повеќе нема да ги обработуваме Вашите лични податоци за оваа
                                    цел.


                                </p>
                                <p><strong>Право на повлекување согласност за заштита на податоците
                                </strong></p>

                                <p>
                                    Согласноста за обработка на Вашите лични податоци, имате право да ја повлечете во
                                    секое време.
                                    Доколку сакате да остварите некое од Вашите права, Ве молиме пристапите до следниот
                                    линк: (да се стави линк до обрасците)
                                    Офицерот за заштита на личните податоци ќе постапи по Вашето барање без
                                    одлагање.</p>
                                <p> За сите прашања во врска со заштитата на личните податоци, контактирајте го офицерот
                                    за заштита на лични податоци на:
                                    Контакт телефон:<a href="tel:+389-2-2779-444>"> +389 (2) 2779 444</a>
                                    Електронска адреса:<a href="mailto:info@mkhost.com.mk"> info@mkhost.com.mk</a></p>


                            </StyledArticle>
                        </AccordionItem>


                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <AccordionItem
                            key={`article-20`}
                            title={' Право на поднесување барање до Агенција за заштита на личните податоци '}
                        >
                            <StyledArticle>

                                <p>
                                    Доколку сметате дека личните податоци не ги обработуваме согласно ЗЗЛП можете да се
                                    обратите до:
                                    Агенција за заштита на личните податоци
                                    бул. „Гоце Делчев“ бр. 18 Скопје</p>
                                <p><a href="https://www.dzlp.mk"> https://www.dzlp.mk </a></p>
                                <p><a href="mailto: info@privacy.mk">info@privacy.mk</a></p>

                            </StyledArticle>
                        </AccordionItem>


                    </div>


                </div>
            </div>
        )
    }
}