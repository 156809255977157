import styled from "styled-components";
import { theme } from "../../../design/Themes";

const Container = styled.div`
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-radius: 5px;
  color: ${theme.neutral5};
  font-family: "Open Sans", sans-serf;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  //padding: 30px;
`;

export default Container;
