import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Container from "../Containers/Container";
import PrimaryButton from "../Buttons/PrimaryButton";

import { ROUTE_OTHER_CAREERS } from "../../routes";

const StyledJobPosition = styled.div`
  display: block;
  float: left;
  vertical-align: top;
  width: 100%;
  margin-bottom: 30px;
`;

const StyledContainer = styled(Container)`
  display: block;
  width: 100%;
  position: relative;
  padding: 30px;
  float: left;
  border: 1px solid
    ${props => {
      if (props.isNew) return props.theme.greenBase;
      if (props.isOnSale) return props.theme.redBase;
      return props.theme.neutral3;
    }};
`;

const StyledContainerBadge = styled.span`
  display: inline-block;
  position: absolute;
  top: -15px;
  left: 15px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  padding: 6px 11px;
  border-radius: 14px;
  text-transform: uppercase;
  background-color: ${props => {
    if (props.isOpen) return props.theme.greenBase;
    if (!props.isOpen) return props.theme.redBase;
  }};
  color: ${props => props.theme.white};
`;

const StyledPosition = styled.h3`
  display: block;
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 12px;
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.42;
  color: ${props => props.theme.neutral6};
  margin-bottom: 50px;
`;

const StyledButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  margin-right: 0;
`;

const JobPosition = ({ position, isOpen, description, link }) => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledJobPosition>
        <StyledContainer isOpen={isOpen}>
          {isOpen && (
            <StyledContainerBadge isOpen={isOpen}>
              {translate("open")}
            </StyledContainerBadge>
          )}
          {!isOpen && (
            <StyledContainerBadge isOpen={isOpen}>
              {translate("closed")}
            </StyledContainerBadge>
          )}
          <StyledPosition>
            {activeLanguage && position[activeLanguage.code]}
          </StyledPosition>
          {activeLanguage &&
            description && (
              <StyledDescription>
                {description[activeLanguage.code]}
              </StyledDescription>
            )}

          {isOpen && (
            <Link to={`${ROUTE_OTHER_CAREERS}${link}`}>
              <PrimaryButton>{translate("learn-more")}</PrimaryButton>
            </Link>
          )}
          {!isOpen && (
            <PrimaryButton disabled={true}>
              {translate("learn-more")}
            </PrimaryButton>
          )}
        </StyledContainer>
      </StyledJobPosition>
    )}
  </Translate>
);
export default JobPosition;
