import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SecondaryButton from "../../Buttons/SecondaryButton";

import BulletPoint from "../../BulletPoint";

import { ROUTE_OTHER_KNOWLEDGEBASE } from "../../../routes";

const StyledVisitHelpDeskSection = styled.div`
  padding: 75px 0;
`;

const StyledTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 38px;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 28px;
  color: ${props => props.theme.neutral6};
`;

const StyledVisitKnowledgebase = styled(SecondaryButton)`
  margin-top: 40px;
`;

const VisitHelpDeskSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledVisitHelpDeskSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledTitle>
                    {translate("need-help?-just-visit-our-help-desk")}
                  </StyledTitle>
                  <StyledDescription>
                    {translate(
                      "easily-manage-customer-support-tickets-and-access-our-knowledgebase"
                    )}
                  </StyledDescription>
                  <BulletPoint>
                    {parse(translate("quickly-open,-reply-to,-and-close"))}{" "}
                    {translate("support-tickets")}.
                  </BulletPoint>
                  <BulletPoint>
                    {parse(translate("easily-search-and-re-open"))}{" "}
                    {translate("old-support-tickets")}.
                  </BulletPoint>
                  <BulletPoint>
                    {parse(translate("access-our-knowledgebase"))}{" "}
                    {translate(
                      "with-over-30-articles-on-the-basics-of-domains,-hosting,-setting-up-emails-and-much-more"
                    )}
                  </BulletPoint>

                  <Link to={ROUTE_OTHER_KNOWLEDGEBASE}>
                    <StyledVisitKnowledgebase>
                      {translate("visit-knowledgebase")}
                    </StyledVisitKnowledgebase>
                  </Link>
                </div>

                <div className="col-12 col-sm-12 col-md-8">
                  <img
                    className="img-fluid"
                    src="/assets/images/image_cp-help-desk.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledVisitHelpDeskSection>
    )}
  </Translate>
);
export default VisitHelpDeskSection;
