import React from "react";

import BulletPoint from "../../components/BulletPoint";

export default [
  {
    index: 0,
    title: "Domus",
    codename: "domus",
    description: {
      mk:
        "Бизнисот со недвижнини е еден од најфлуидните во економската сфера, како што е и овој динамичен и едноставен дизајн.",
      en:
        "Real estate business is one of the most dynamic in the economic sector, as well as this dynamic and easy to use design."
    },

    type: { mk: "За недвижности", en: "For real estate" },
    image: "/assets/images/themes/theme_domus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 1,
    title: "Fructus",
    codename: "fructus",
    description: {
      mk:
        "Овошјето и зеленчукот се неизоставен дел од сечија исхрана, а со тоа и пазарот кој може да се опфати е прилично голем. Искористи и покажи ја својата понуда.",
      en:
        "Offering services about education and care about children? Explain all the parents why your offer is the best for their loved ones."
    },
    type: { mk: "За здрав живот", en: "For real estate" },
    image: "/assets/images/themes/theme_fructus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 2,
    title: "Gusto",
    codename: "gusto",
    description: {
      mk:
        "Сите сакаат убава и вкусна храна и пијалоци. Но, како да привлечеш повеќе муштерии за твојот ресторан или ланч бар преку веб сајт? Дизајнот е еден од клучните моменти.",
      en:
        "Everyone wants good and delicious food. But, how can you attract more customers at your restaurant or launch bar through your website? Design is one of the key parts."
    },
    type: { mk: "За угостителски услуги", en: "For restaurants & cafes" },
    image: "/assets/images/themes/theme_gusto.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 3,
    title: "Liber",
    codename: "liber",
    description: {
      mk:
        "Нудиш услуги со едукација и грижа на најмладите? Таргетирај ги родителите и покажи им зошто токму твојот избор е најдобар за нивните најмили.",
      en:
        "Offering services about education and care about children? Explain all the parents why your offer is the best for their loved ones."
    },
    type: { mk: "За образовни услуги", en: "For education purposes" },
    image: "/assets/images/themes/theme_liber.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 4,
    title: "Navigo",
    codename: "navigo",
    description: {
      mk:
        "Патувањето е интегриран дел од животот. Оваа модерна ера носи и полесни перспективи околу патувањата видливи онлајн. Дизајн идеален за оваа намена.",
      en:
        "Travelling is an integrated part of our lives. This modern era brings easier perspective for travelling as seen online. Ideal design for this purpose."
    },
    type: { mk: "За патувања", en: "For travelling arrangements" },
    image: "/assets/images/themes/theme_navigo.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 5,
    title: "Opportunitas",
    codename: "opportunitas",
    description: {
      mk:
        "Една од човековите потреби е постојано да се едуцира и да има свои хобија. Што имаш да му понудиш на светот што ќе ги привлече останатите?",
      en:
        "One of the people’s needs is the constant education upgrading, as well as enjoying hobbies. What do you have to show the world which will attract the others?"
    },
    type: { mk: "За едукација", en: "For education upgrading" },
    image: "/assets/images/themes/theme_opportunitas.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 6,
    title: "Tabula",
    codename: "tabula",
    description: {
      mk:
        "Потребен ти е простор за објавување на твоите завршни сметки? Едноставно и идеално место кое одговара на овој проблем.",
      en:
        "Do you need a place where you can share your final accounts? Ideal design which responds to your problem. Here is your solution."
    },
    type: { mk: "За сметководствени потреби", en: "For accounting companies" },
    image: "/assets/images/themes/theme_tabula.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },

  {
    index: 7,
    title: "Tabula Plus",
    codename: "tabulaplus",
    description: {
      mk:
        "Потребен ти е простор за објавување на твоите завршни сметки? Едноставно и идеално место кое одговара на овој проблем.",
      en:
        "Do you need a place where you can share your final accounts? Ideal place which respond to your problem. Here is your solution."
    },
    type: { mk: "За сметководствени потреби", en: "For accounting companies" },
    image: "/assets/images/themes/theme_tabula-plus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 8,
    title: "Texo",
    codename: "texo",
    description: {
      mk:
        "Информатичкиот сектор и софтверските апликации се во постојан раст. Како подобро да се прикаже ваков бизнис, отколку со модерен и прогресивен дизајн?",
      en:
        "The IT sector and the software applications are growing at a constant rate. How can be a business like this be better presented, if not by a modern and progressive design?"
    },
    type: { mk: "Зa IT потреби", en: "For IT companies" },
    image: "/assets/images/themes/theme_texo.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 9,
    title: "Visum",
    codename: "visum",
    description: {
      mk:
        "Фотографијата и естетиката се твоето хоби? Овој дизајн ги отсликува формите во кои можеш да ја искажеш својата креативност.",
      en:
        "Photography and esthetics are your hobbies? This design reflects the shapes in which you can express your creativity."
    },
    type: { mk: "За визуелни потреби", en: "For visual purposes" },
    image: "/assets/images/themes/theme_visum.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 10,
    title: "Cogno",
    codename: "cogno",
    description: {
      mk:
        "Одлично решение за твојот мал или семеен бизнис. Дизајн кој идеално се вклопува во визијата за идеално претставување на компанија која штотуку го започнува својот раст.",
      en:
        "Excellent solution for your small or family business. A web template which fits the vision of ideal public showing for a starting company."
    },
    type: { mk: "За почетни бизниси", en: "For small businesses" },
    image: "/assets/images/themes/theme_cogno.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 11,
    title: "Impetus",
    codename: "impetus",
    description: {
      mk:
        "Бизнисот поврзан со рент а кар и транспорт е секогаш еден од најпрофитабилните, но еден од оние на кои навистина им е потребно онлајн присуство.",
      en:
        "The rent a car and transport business are always one of the most profitable, but at the same time, are one of those that really need an online presence."
    },
    type: { mk: "За рент а кар услуги", en: "For rent a car services" },
    image: "/assets/images/themes/theme_impetus.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 12,
    title: "Negotium",
    codename: "negotium",
    description: {
      mk:
        "Големите корпорации секогаш имаат потреба од прецизно и добро претставување во јавноста. Овој дизајн е одговор токму на потребите на големите бизниси.",
      en:
        "Big corporations always have the need of precise public exposure. This design is the answer for those businesses."
    },
    type: { mk: "За корпорациски потреби", en: "For corporate usage" },
    image: "/assets/images/themes/theme_negotium.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 13,
    title: "Primo",
    codename: "primo",
    description: {
      mk:
        "Претприемачот секогаш треба да преземе ризик. Дизајнот идеален за старт-ап компанија е дефинитивно пресметан ризик во кој вреди да се инвестира.",
      en:
        "An entrepreneur must always take risks. Ideal for a start-up company, this design is a calculated risk worth investing at."
    },
    type: { mk: "За стартапи", en: "For startups" },
    image: "/assets/images/themes/theme_primo.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  },
  {
    index: 14,
    title: "Sanitas",
    codename: "sanitas",
    description: {
      mk:
        "Грижата за здравјето е еден од основните човекови потреби за квалитетен живот. Нудиш здравствени услуги? Оваа тема е идеална за твојот бизнис.",
      en:
        "Health care is one of the basic human needs for quality life. Offering health services? This web template is ideal for your business."
    },
    type: { mk: "За услуги за здравје", en: "For health services" },
    image: "/assets/images/themes/theme_sanitas.png",
    livePreview: "https://mkhost.mk",
    items: {
      en: [
        "Use custom colors, logos and images",
        "Choose from 500+ Google fonts",
        "Available in 1 language",
        "Optimized for mobile"
      ],
      mk: [
        "Користи свои бои, логоа и слики",
        "500+ Google фонтови",
        "Достапно на еден јазик",
        "Оптимизиран за мобилен"
      ]
    }
  }
];
