import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly, isTablet } from "react-device-detect";
import parse from "html-react-parser";

import SectionTitle from "../../SectionTitle";
import Perk from "../../Perk";

const StyledWhatAreTheBenefitsFromFirstWebsiteWebinar = styled.div`
  padding: ${props => !props.isMobile && "0px"};
  background-repeat: no-repeat;
  background-position: top;
  background-image: url("/assets/backgrounds/bg-3-1.svg");
  background-repeat: no-repeat;
  background-position: top;
`;

const StyledTop = styled.div`
  margin-bottom: ${props => (props.isMobile ? "0px" : "40px")};
  padding-top: ${props => (props.isTablet ? "48px" : "130px")};
`;

const StyledBottom = styled.div`
  background-color: ${props => props.theme.blue1};
  padding-bottom: 130px;
`;

const WhatAreTheAdvantagesFromCloud = props => (
  <Translate>
    {({ translate }) => (
      <StyledWhatAreTheBenefitsFromFirstWebsiteWebinar
        className="row"
        isMobile={isMobileOnly}
        isTablet={isTablet}
      >
        <StyledTop className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("sections.what-are-the-benefits-from-first-website-webinar.title")}
                  </SectionTitle>
                </div>
              </div>
            </div>
          </div>
        </StyledTop>
        <StyledBottom className="col-12 col-sm-12 col-md-12">
          <div>
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate(
                        "sections.what-are-the-benefits-from-first-website-webinar.perk-1.title"
                      )}
                      description={translate(
                        "sections.what-are-the-benefits-from-first-website-webinar.perk-1.description"
                      )}
                      icon="hosting"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-1.items.item-1"
                        ),
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-1.items.item-2"
                        ),
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-1.items.item-3"
                        )
                      ]}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate(
                        "sections.what-are-the-benefits-from-first-website-webinar.perk-2.title"
                      )}
                      description={parse(
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-2.description"
                        )
                      )}
                      icon="domains"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-2.items.item-1"
                        ),
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-2.items.item-2"
                        ),
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-2.items.item-3"
                        )
                      ]}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-4">
                    <Perk
                      isMobile={isMobileOnly}
                      title={translate(
                        "sections.what-are-the-benefits-from-first-website-webinar.perk-3.title"
                      )}
                      description={translate(
                        "sections.what-are-the-benefits-from-first-website-webinar.perk-3.description"
                      )}
                      icon="websites"
                      color="blueBase"
                      blueIcon={true}
                      items={[
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-3.items.item-1"
                        ),
                        translate(
                          "sections.what-are-the-benefits-from-first-website-webinar.perk-3.items.item-2"
                        )
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </StyledBottom>
      </StyledWhatAreTheBenefitsFromFirstWebsiteWebinar>
    )}
  </Translate>
);

export default WhatAreTheAdvantagesFromCloud;
