import React from "react";

import Loader from "../Loader";

const FaqContentLoader = () => (
  <Loader width="800" height="200">
    <rect x="10" y="10" width="750" height="20" rx="3" />

    <rect x="10" y="45" width="750" height="20" rx="3" />

    <rect x="10" y="80" width="750" height="20" rx="3" />

    <rect x="10" y="115" width="750" height="20" rx="3" />

    <rect x="10" y="150" width="750" height="20" rx="3" />
  </Loader>
);

export default FaqContentLoader;
