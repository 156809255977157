import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

const SectionTitle = styled.h2`
  display: block;
  margin: 0;
  font-size: ${isMobileOnly ? "28px" : "36px"};
  line-height: ${isMobileOnly ? "1.36" : "1.33"};
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-bottom: 20px;
`;

export default SectionTitle;
