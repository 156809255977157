import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import SectionDescription from "../../SectionDescription/SectionDescription";
import JobPosition from "../../JobPosition";

const StyledJobPostsSection = styled.div`
  padding: 75px 0;
`;


const JobPostsSection = ({ jobs }) => (
  <Translate>
    {({ translate }) => (
      <StyledJobPostsSection className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                {jobs.length > 0 ? (
                  jobs.map((job, index) => (
                    <div
                      key={job.position + index}
                      className="col-12 col-sm-12 col-md-4"
                    >
                      <JobPosition {...job} />
                    </div>
                  ))
                ) : (
                  <SectionDescription>
                    {translate("no-jobs-available")}
                  </SectionDescription>
                )}
              </div>
            </div>
          </div>
        </div>
      </StyledJobPostsSection>
    )}
  </Translate>
);

export default JobPostsSection;

