import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

import Container from "../Containers/Container";
import Badge from "../Badges/Badge";

import { compileRoute, ROUTE_OTHER_KNOWLEDGEBASE_CATEGORY } from "../../routes";

const StyledKnowledgeBaseCategory = styled(Container)`
  margin-bottom: 30px;
`;

const StyledHeader = styled.div`
  padding: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${props => props.theme.neutral5};
  text-transform: uppercase;
  border-bottom: 1px solid ${props => props.theme.neutral3};
`;

const StyledCategory = styled.div`
  padding: 15px 30px;
  border-bottom: 1px solid ${props => props.theme.neutral3}
  &:last-child{
    border-bottom :0;
  }
`;
const StyledTitle = styled.span`
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledDescription = styled.span`
  font-size: 14px;
  color: ${props => props.theme.neutral6};
`;

const KnowledgeBaseCategories = ({ title, categories }) => (
  <Translate>
    {({ translate }) => (
      <StyledKnowledgeBaseCategory>
        <StyledHeader>{translate("categories")}</StyledHeader>
        {categories &&
          categories.length > 0 &&
          categories.map((category, index) => (
            <Link
              key={category.name + category.id}
              to={compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_CATEGORY, {
                id: category.id
              })}
            >
              <StyledCategory>
                <StyledTitle>
                  {category.name}{" "}
                  <Badge className="float-right">
                    {category.number_of_articles} {translate("articles")}
                  </Badge>
                </StyledTitle>
                <StyledDescription>{category.description}</StyledDescription>
              </StyledCategory>
            </Link>
          ))}
      </StyledKnowledgeBaseCategory>
    )}
  </Translate>
);
export default KnowledgeBaseCategories;
