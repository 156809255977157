import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notice from "../Notice";

const StyledNoticeSuccess = styled.div`
  background-color: ${theme.green1};
  border: 1px solid ${theme.greenBase};
  color: ${theme.greenBase};
  border-radius: 3px;
  display: inline-block;
  & a {
    color: ${theme.greenBase};
  }
`;

const NoticeSuccess = ({ children, ...rest }) => (
  <StyledNoticeSuccess className="notice-success">
    <Notice icon="success" {...rest}>
      {children}
    </Notice>
  </StyledNoticeSuccess>
);

NoticeSuccess.defaultProps = {
  dismissable: false,
  onDismiss: () => {}
};

export default NoticeSuccess;
