import React from "react";
import styled from "styled-components";

import ContainerFlag from "../../Containers/ContainerFlag";
import Icon from "../../Icon";

const StyledFlag = styled.div`
  display: block;
  padding: 15px 35px;
  padding-left: 48px;
  position: relative;
  & .icon {
    top: 0;
  }
`;

const StyledFlagTitle = styled.span`
  display: block;
  color: ${props => props.theme.neutralBase};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  margin-bottom: ${props => (props.title ? "10px" : "0")};
`;
const StyledFlagContent = styled.div`
  color: ${props => props.theme.neutral6};
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  max-width: 320px;
  word-break: normal;
  & a {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    color: ${props => props.theme.blueBase};
  }
`;

const StyledFlagStatusIcon = styled.span`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  left: 15px;
  display: inline-block;
`;

const StyledFlagCloseIcon = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  & .icon-close {
    color: ${props => props.theme.neutral4};
  }
`;

const FlagCloseIcon = props => (
  <StyledFlagCloseIcon onClick={props.onDismiss}>
    <Icon icon="close" />
  </StyledFlagCloseIcon>
);

FlagCloseIcon.defaultProps = {
  onDismiss: () => {}
};

const Flag = ({ children, ...rest }) => (
  <ContainerFlag className="flag-container">
    <StyledFlag>
      <StyledFlagStatusIcon>
        <Icon icon={rest.icon || "info"} />
      </StyledFlagStatusIcon>
      {rest.dismissable && <FlagCloseIcon onDismiss={rest.onDismiss} />}

      {rest.title && (
        <StyledFlagTitle className="flag-title">{rest.title}</StyledFlagTitle>
      )}

      <StyledFlagContent className="flag-content">{children}</StyledFlagContent>
    </StyledFlag>
  </ContainerFlag>
);

Flag.defaultProps = {
  dismissable: true
};

export default Flag;
