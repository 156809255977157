import React from "react";
import { Translate } from "react-localize-redux";
import parse from "html-react-parser";
import styled from "styled-components";

import {
  compileRoute,
  ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE,
  ROUTE_EMAIL_TUTORIALS
} from "../../../routes";

import * as helpdeskService from "../../../services/helpdeskService";

import LearnMoreSection from "../LearnMoreSection";
import AccordionItem from "../../../components/Accordion/AccordionItem";
import ContentWidget from "../../../components/ContentWidget";
import FaqContentLoader from "../../Loading/FaqContentLoader";
import { FAQ_CATEGORY_GENERAL } from "../../../config/faq";

const StyledArticle = styled.span`
  display: inline-block;
  padding: 8px 19px;
  border: 1px solid #d8dde6;
  margin-top: 10px;
  border-radius: 3px;
  & a {
    font-weight: 600;
    font-size: 14px;
  }
`;

class MostPopularArticlesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: null,
      isFetching: true
    };
  }

  componentDidMount() {
    let faqCategory = this.props.faqCategory
      ? this.props.faqCategory
      : FAQ_CATEGORY_GENERAL;
    this.setState({
      isFetching: true
    });
    helpdeskService.fetchMostPopularArticles(faqCategory).then(result => {
      const { articles } = result.data.data;

      this.setState({
        articles: articles && articles.length > 0 ? articles : null,
        isFetching: false
      });
    });
  }

  renderStaticFaq(translate) {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-8">
          <AccordionItem title={translate("most-popular-articles.a1.title")}>
            {parse(translate("most-popular-articles.a1.content"))}
            <StyledArticle>
              {parse(
                translate("most-popular-articles.a1.link").replace(
                  "${LINK}",
                  compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                    id: 18
                  })
                )
              )}
            </StyledArticle>
          </AccordionItem>

          <AccordionItem
            title={translate("most-popular-articles.a2.title")}
            link={parse(translate("most-popular-articles.a2.link"))}
          >
            {parse(translate("most-popular-articles.a2.content"))}
            <StyledArticle>
              {parse(
                translate("most-popular-articles.a2.link").replace(
                  "${LINK}",
                  compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                    id: 27
                  })
                )
              )}
            </StyledArticle>
          </AccordionItem>

          <AccordionItem
            title={translate("most-popular-articles.a3.title")}
            link={parse(translate("most-popular-articles.a3.link"))}
          >
            {parse(translate("most-popular-articles.a3.content"))}
            <StyledArticle>
              {parse(
                translate("most-popular-articles.a3.link").replace(
                  "${LINK}",
                  compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                    id: 19
                  })
                )
              )}
            </StyledArticle>
          </AccordionItem>

          <AccordionItem
            title={translate("most-popular-articles.a4.title")}
            link={parse(translate("most-popular-articles.a4.link"))}
          >
            {parse(translate("most-popular-articles.a4.content"))}
            <StyledArticle>
              {parse(
                translate("most-popular-articles.a4.link").replace(
                  "${LINK}",
                  compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                    id: 38
                  })
                )
              )}
            </StyledArticle>
          </AccordionItem>

          <AccordionItem
            title={translate("most-popular-articles.a5.title")}
            link={parse(translate("most-popular-articles.a5.link"))}
          >
            {parse(translate("most-popular-articles.a5.content"))}
            <StyledArticle>
              {parse(
                translate("most-popular-articles.a5.link").replace(
                  "${LINK}",
                  compileRoute(ROUTE_OTHER_KNOWLEDGEBASE_ARTICLE, {
                    id: 7
                  })
                )
              )}
            </StyledArticle>
          </AccordionItem>
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <ContentWidget
            title={translate("sections.homepage_learn_more.email-setup-title")}
            link={ROUTE_EMAIL_TUTORIALS}
            linkTitle={translate(
              "sections.homepage_learn_more.email-setup-button"
            )}
          >
            {translate("sections.homepage_learn_more.email-setup-description")}
          </ContentWidget>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <LearnMoreSection
            title={translate("sections.homepage_learn_more.title")}
          >
            {this.state.isFetching && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <FaqContentLoader />
                </div>
              </div>
            )}

            {!this.state.isFetching &&
              this.state.articles === null &&
              this.renderStaticFaq(translate)}
            {!this.state.isFetching && this.state.articles && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8">
                  {this.state.articles.map(article => (
                    <AccordionItem
                      key={`article-${article.id}`}
                      title={parse(article.title)}
                    >
                      <StyledArticle>{parse(article.article)}</StyledArticle>
                    </AccordionItem>
                  ))}
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <ContentWidget
                    title={translate(
                      "sections.homepage_learn_more.email-setup-title"
                    )}
                    link={ROUTE_EMAIL_TUTORIALS}
                    linkTitle={translate(
                      "sections.homepage_learn_more.email-setup-button"
                    )}
                  >
                    {translate(
                      "sections.homepage_learn_more.email-setup-description"
                    )}
                  </ContentWidget>
                </div>
              </div>
            )}
          </LearnMoreSection>
        )}
      </Translate>
    );
  }
}

export default MostPopularArticlesSection;
