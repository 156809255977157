import React from "react";
import styled from "styled-components";

const BackgroundSpacer = styled.div`
  background: ${props => props.theme.blue1};
  width: 100%;
  height: ${props => props.height};
  display: block;
  left: 0;
`;

export default BackgroundSpacer;
