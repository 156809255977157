import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import Container from "../../components/Containers/Container";
import SuccessButton from "../../components/Buttons/SuccessButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";

import { ROUTE_OTHER_CAREERS, ROUTE_WEBSITE_DOMAIN } from "../../routes";

import seoData from "../../seo/seoData";

const StyledHeader = styled.div`
  padding: ${props => (props.isMobile ? " 0 0 10px 0" : "100px 0")};
  text-align: center;
`;

const StyledHeaderTitle = styled.h2`
  font-size: ${props => (props.isMobile ? "34px" : "54px")};
  font-weight: 600;
  line-height: ${props => (props.isMobile ? "1.41" : "1.19")};
  color: ${props => props.theme.neutralBase};
  margin-bottom: ${props => (props.isMobile ? "20px" : "30px")};
`;

const StyledHeaderDescription = styled.h3`
  font-size: ${props => (props.isMobile ? "16px" : "18px")};
  line-height: ${props => (props.isMobile ? "1.63" : "1.56")};
  color: ${props => props.theme.neutral6};
`;

const StyledPerson = styled.div`
  margin: 0 auto;
  margin-bottom: 35px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: ${props => (props.isMobile ? "150px" : "200px")};
  height: ${props => (props.isMobile ? "150px" : "200px")};
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  box-shadow: 0 3px 7px 0 rgba(123, 139, 167, 0.25);
`;

const StyledName = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${props => props.theme.neutralBase};
`;

const StyledPosition = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

const StyledAboutCompany = styled.div`
  position: relative;
  padding: ${props => (props.isMobile ? "50px 0" : "150px 0")};
  background-image: url("/assets/backgrounds/bg-1-1.svg");
  background-repeat: no-repeat;
  background-size: ${props => (props.isMobile ? "300%" : "contain")};
  background-position: ${props =>
    props.isMobile ? "center 76%" : "center center"};
`;

const StyledStats = styled.div`
  vertical-align: middle;
  padding-top: ${props => (props.isMobile ? "30px" : "200px")};
  text-align: center;
`;

const StyledStat = styled.span`
  display: block;
  font-size: 34px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledInfo = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: ${props => props.isMobile && "30px"};
  position: relative;
  min-height: 400px;
  & .logo {
    display: block;
    width: 40px;
    margin-bottom: 20px;
  }

  & .title {
    display: block;
    font-size: 34px;
    font-weight: 600;
    line-height: 1.41;
    color: ${props => props.theme.white};
    margin-bottom: 20px;
  }

  & .description {
    display: block;
    font-size: 16px;
    line-height: 1.63;
    color: ${props => props.theme.white};
    margin-bottom: 40px;
  }

  & a {
    position: ${isMobileOnly ? "static" : "absolute"};
    left: 30px;
    bottom: 30px;
  }
`;

const StyledBlueContainer = styled(StyledContainer)`
  background-color: ${props => props.theme.blue5};
  border-color: ${props => props.theme.blue5};
`;

const StyledGreenContainer = styled(StyledContainer)`
  background-color: ${props => props.theme.greenBase};
  border-color: ${props => props.theme.greenBase};
`;

const StyledJoin = styled.div`
  padding: ${props => !props.isMobile && "150px"};
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  margin-top: -188px;
`;

class OtherAboutUsPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_about_us.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_about_us.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_about_us.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_about_us.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_about_us.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_about_us.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_about_us.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}


            <StyledAboutCompany className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-5">
                        <SectionTitle>
                          {translate("about-the-company")}
                        </SectionTitle>
                        <SectionDescription>
                          {translate(
                            "mkhost-is-the-biggest-hosting-provider-and-domain-registry-company-in-the-republic-of-macedonia,-which-launched-on-1st-september-2006"
                          )}
                          .
                        </SectionDescription>

                        <SectionDescription>
                          {translate(
                            "during-the-past-13-years,-mkhost-has-grown-into-a-brand-which-helped-many-companies,-organizations,-as-well-as-individuals,-to-make-an-online-presence-through-their-own-websites,-and-therefore-expand-their-business-nationally-and-regionally"
                          )}
                        </SectionDescription>

                        <SectionDescription>
                          {translate(
                            "with-online-solutions-created-by-modern-standards,-the-focus-of-mkhost-are-always-the-clients,-together-with-constant-and-fast-professional-technical-support"
                          )}
                        </SectionDescription>

                        <SectionDescription>
                          {translate(
                            "mkhost-is-following-and-implementing-the-new-updates-and-trends-in-the-technology,-with-the-goal-of-constant-improving-of-the-services-and-automatization-of-the-processes"
                          )}
                        </SectionDescription>
                      </div>
                      <div className="col-12 col-sm-12 col-md-2 offset-md-1 ">
                        <StyledStats isMobile={isMobileOnly}>
                          <StyledStat>30%</StyledStat>
                          {translate("domain-market-share-in-macedonia")}
                        </StyledStats>
                      </div>
                      <div className="col-12 col-sm-12 col-md-2">
                        <StyledStats isMobile={isMobileOnly}>
                          <StyledStat>20,255</StyledStat>
                          <StyledInfo>
                            {translate("total-number-of-domains-registered")}
                          </StyledInfo>
                        </StyledStats>
                      </div>
                      <div className="col-12 col-sm-12 col-md-2 ">
                        <StyledStats isMobile={isMobileOnly}>
                          <StyledStat>5,000+</StyledStat>
                          {translate("active-users")}
                        </StyledStats>
                      </div>
                    </div>
                  </div>
                </div>
                <StyledBackgroundSpacer height="220px" />
              </div>
            </StyledAboutCompany>

            <StyledJoin className="row" isMobile={isMobileOnly}>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-7">
                        <StyledBlueContainer isMobile={isMobileOnly}>
                          <img
                            className="logo"
                            src="/brand/icon-white.png"
                            alt="MKhost"
                          />
                          <span className="title">
                            {translate(
                              "interested-in-becoming-a-part-of-the-mkhost-team"
                            )}
                          </span>
                          <span className="description">
                            {translate(
                              "send-your-cv-to-jobs@mkhost.com.mk-and-convince-us-how-your-work-will-improve-the-experience-of-mkhost-clients"
                            )}
                            !
                          </span>
                          <Link to={ROUTE_OTHER_CAREERS}>
                            <SuccessButton size="l">
                              {translate("see-our-open-positions")}
                            </SuccessButton>
                          </Link>
                        </StyledBlueContainer>
                      </div>
                      <div className="col-12 col-sm-12 col-md-5">
                        <StyledGreenContainer isMobile={isMobileOnly}>
                          <span className="title">
                            {translate("what-is-it-like-working-at-mkhost")}
                          </span>
                          <span className="description">
                            {translate(
                              "our-workspace-is-filled-with-friendly,-dynamic,-and-teamwork-oriented-environment.-find-our-more-about-a-typical-work-day-at-mkhost-on-our-blog-post"
                            )}
                          </span>
                          <a
                            href="https://blog.mkhost.com.mk/kako-izgleda-eden-raboten-den-vo-timot-na-mkhost/"
                            target="blank"
                            rel="noopener nofollow"
                          >
                            <SecondaryButton size="l">
                              {translate("read-the-blog-post")}
                            </SecondaryButton>
                          </a>
                        </StyledGreenContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledJoin>
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherAboutUsPage;
