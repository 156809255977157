import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import IconTextInfo from "../../IconTextInfo";
import check_success from "../../../assets/images/check_success.svg";
import FeaturesTable from "../../FeaturesTable";
import SupportPlan from "../../SupportPlan";
import Container from "../../Containers/Container";
import parse from "html-react-parser";

const StyledWWhyChooseDedicatedServerSection = styled.div`
  padding-top: ${props => (props.isMobile ? "35px 0" : "75px 0")};
`;
const StyledTitle = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  line-height: 1.56;
  margin-bottom: 20px;
`;


const StypedTop = styled.div`
  margin-bottom: 50px;
  padding-left:20px;
`;
const StyledAvaiable = styled.img``;

const FeaturesTableSection = styled.div`
  margin-top: -159px;
  padding-bottom: 75px;
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin-bottom: 15px;
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const StyledFreeBlock = styled(Container)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${props => props.theme.neutral4};
  text-align: center;
  padding: 14px;
`;

const StyledCheck = styled.img`
  position: absolute;
  left: 0;
  top: 1px;
  color: ${props => props.theme.greenBase};
`;

const StyledBulletPoint = styled.p`
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
`;

const features = [
  {
    name: {
      en: "CPU",
      mk: "CPU"
    },
    translate:true,
    mk41:
        {mk:  "AMD Ryzen™ 5 3600 <br/> 1 физички процесори со 6 јадра/12 нитки (AMD Zen2)",
      en : "AMD Ryzen™ 5 3600 <br/> Hexa-Core <br>Simultaneous Multithreading Virtualization (AMD Zen2)"},
    mk51: {mk: "AMD Ryzen™ 7 3700X <br/>1 физички процесори со 8 јадра/16 нитки (AMD Zen2)}" ,
      en : "AMD Ryzen™ 7 3700X <br/>1 Octa-Core <br>Simultaneous Multithreading Virtualization (AMD Zen2)"},
    mk61: {mk: "AMD Ryzen™ 9 3900 <br/> 1 физички процесор со 12 јадра/24 нитки (AMD Zen2)",
      en:"AMD Ryzen™ 9 3900 <br/>12 Core <br></be> Simultaneous Multithreading Virtualization (AMD Zen2)"}
  },
  {
    name: {
      en: "RAM",
      mk: "RAM"
    },
    mk41: "32 GB DDR4 ECC",
    mk51: "64 GB DDR4 ECC RAM",
    mk61: "128 GB DDR4 ECC RAM"
  },
  {
    name: {
      en: "Hosting space",
      mk: "Хостинг простор"
    },
    mk41: "512GB",
    mk51: "1TB",
    mk61: "2TB"
  },
  {
    name: {
      en: "Bandwidth",
      mk: "Сообраќај"
    },
    mk41: "unlimited",
    mk51: "unlimited",
    mk61: "unlimited"
  },
  {
    name: {
      en: "Full root access",
      mk: "Full root access"
    },
    mk41: true,
    mk51: true,
    mk61: true
  },
  {
    name: {
      en: "Hosting resources",
      mk: "Дедицирани ресурси"
    },
    mk41: true,
    mk51: true,
    mk61: true
  },
  // {
  //   name: {
  //     en: "Self-managed",
  //     mk: "Self-managed"
  //   },
  //   mk41: true,
  //   mk51: true,
  //   mk61: true
  // },
  {
    name: {
      en: "MySQL",
      mk: "MySQL"
    },
    mk41: true,
    mk51: true,
    mk61: true
  },
  {
    name: {
      en: "cPanel",
      mk: "cPanel"
    },
    mk41: true,
    mk51: true,
    mk61: true
  }

];

const WhyChooseDedicatedServerSection = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledWWhyChooseDedicatedServerSection
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-7">

                  <SectionDescription>
                    {translate(
                        "the-price-does-not-include-a-one-time-set-up"
                    )}

                  </SectionDescription>

                </div>
              </StypedTop>
              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-7">
                  <SectionTitle>
                    {translate("menage-server")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                        "menage-subtitle-1"
                    )}

                  </SectionDescription>
                  <SectionDescription>
                    {translate(
                        "menage-subtitle-2"
                    )}

                  </SectionDescription>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("conf-backup-server-daily-month")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("remote-config-backup")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("monitoring-nagios")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("update-on-server")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("security-cpguard")}
                  </StyledBulletPoint>
                  <StyledBulletPoint>
                    <StyledCheck src={check_success} />{" "}
                    {translate("setting-server")}
                  </StyledBulletPoint>

                </div>
              </StypedTop>

              <SectionDescription>
                    {translate(
                        "the-price-does-not-include-a-one-time-set-up2"
                    )}

                  </SectionDescription>

              <StypedTop className="row">
                <div className="col-12 col-sm-12 col-md-7">
                  <SectionTitle>
                    {translate("why-choose-a-dedicated-server")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "with-dedicated-web-hosting,-you're-renting-a-physical-server-dedicated-to-your-website,-and-your-website-only"
                    )}
                  </SectionDescription>
                  <SectionDescription>
                    {translate(
                      "a-dedicated-server-is-much-faster-than-shared-hosting-because-you're-not-sharing-resources-like-bandwidth-or-storage-with-other-websites-hosted-on-the-same-server"
                    )}
                  </SectionDescription>
                </div>
              </StypedTop>


              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <IconTextInfo
                    icon={'input-checkbox'}
                    iconColor={'blueBase'}
                    iconBgColor={'blue1'}
                    title={"guaranteed-quality"}
                    description={"dedicated-servers-guaranteed-quality-description"}
                    />
                    <IconTextInfo
                        icon={'contacts'}
                        iconColor={'blueBase'}
                        iconBgColor={'blue1'}
                        title={"user-managed"}
                        description={"unlimited-user-access-and-control-over-the-entire-server"}
                    />
                    <IconTextInfo
                        icon={'help_desk'}
                        iconColor={'blueBase'}
                        iconBgColor={'blue1'}
                        title={"infrastructure-support"}
                        description={"dedicated-servers-infrastructure-description"}
                    />

                    <IconTextInfo
                        icon={'cpanel'}
                        iconColor={'blueBase'}
                        iconBgColor={'blue1'}
                        title={"advanced-cPanel"}
                        description={"cpanel-license-a-reputable-administration-panel-used-by-70%-of-hosting-providers"}
                    />

                    <IconTextInfo
                        icon={'setup'}
                        iconColor={'blueBase'}
                        iconBgColor={'blue1'}
                        title={"configuration"}
                        description={"fully-configure-Layer-7-protection-infrastructure-to-minimize-potential-attacks"}
                    />
                    <IconTextInfo
                        icon={'files'}
                        iconColor={'blueBase'}
                        iconBgColor={'blue1'}
                        title={"data-storage"}
                        description={"incremental-backup-on-a-daily-weekly-basis"
                        }
                    />
                  </div>

                </div>
                {!isMobileOnly && (
                    <div style={{"margin":"100px 0"}}>&nbsp;</div>
                )}
                <StypedTop className="row">
                  <div className="col-12 col-sm-12 col-md-8">
                    <SectionTitle>
                      {translate("detailed-technical-characteristics-of-hosting-plans")}
                    </SectionTitle>
                  </div>
                </StypedTop>
                {!isMobileOnly && (
                <div style={{"margin":"150px 0"}}>&nbsp;</div>
                )}

                {isMobileOnly && (
                    <div style={{"margin":"50px 0"}}>&nbsp;</div>
                )}
                <FeaturesTableSection
                    className="row no-gutters"
                    isMobile={isMobileOnly}
                >
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row no-gutters">
                      <div className="container">
                        <div className="row no-gutters">
                          <div className="col-12 col-sm-12 col-md-3" />
                          <div className="col-12 col-sm-12 col-md-9">
                            <div className="row no-gutters">
                              <div
                                  className="col-12 col-sm-12 col-md-4"
                                  key={`ssl-${Math.random()}`}
                              >
                                <SupportPlan
                                    firstChild={true}
                                    className={'text-center'}
                                    title={"MK41"}
                                />
                                {isMobileOnly && (
                                    <FeaturesTable className="features-table row no-gutters">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        {features.map(
                                            (feature) => (
                                                <div
                                                    key={`feature-ssl-${Math.random()}`}
                                                    className="row no-gutters feature-row"
                                                >
                                                  <div className="col-12 col-sm-12 col-md-9">
                                                    <div className="row no-gutters">
                                                      <div className="col-12 col-sm-12 col-md-4">

                                                        {activeLanguage &&
                                                        (
                                                            <div className="feature-value text-center">
                                                              {feature.name[activeLanguage.code]}
                                                              {feature.translate ?
                                                                  (
                                                                      <div>
                                                                        {feature["mk41"][activeLanguage.code]}
                                                                      </div>
                                                                  )
                                                                  :  (<div>
                                                                    {feature["mk41"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk41"])}
                                                                  </div>)

                                                              }
                                                            </div>
                                                        )
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            )
                                        )}
                                      </div>
                                    </FeaturesTable>
                                )}
                              </div>
                              <div
                                  className="col-12 col-sm-12 col-md-4"
                                  key={`ssl-${Math.random()}`}
                              >
                                <SupportPlan
                                    className={'text-center'}
                                    firstChild={false}
                                    title={"MK51"}
                                />
                                {isMobileOnly && (
                                    <FeaturesTable className="features-table row no-gutters">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        {features.map(
                                            (feature) => (
                                                <div
                                                    key={`feature-ssl-${Math.random()}`}
                                                    className="row no-gutters feature-row"
                                                >
                                                  <div className="col-12 col-sm-12 col-md-9">
                                                    <div className="row no-gutters">
                                                      <div className="col-12 col-sm-12 col-md-4">

                                                        {activeLanguage &&
                                                        (
                                                            <div className="feature-value text-center">
                                                              {feature.name[activeLanguage.code]}
                                                              {feature.translate ?
                                                                  (
                                                                      <div>
                                                                        {feature["mk51"][activeLanguage.code]}
                                                                      </div>
                                                                  )
                                                                  :  (<div>
                                                                    {feature["mk51"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk51"])}
                                                                  </div>)

                                                              }
                                                            </div>
                                                        )
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            )
                                        )}
                                      </div>
                                    </FeaturesTable>
                                )}
                              </div>
                              <div
                                  className="col-12 col-sm-12 col-md-4"
                                  key={`ssl-${Math.random()}`}
                              >
                                <SupportPlan
                                    firstChild={false}
                                    className={'text-center'}
                                    title={"MK61"}
                                />
                                {isMobileOnly && (
                                    <FeaturesTable className="features-table row no-gutters">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        {features.map(
                                            (feature) => (
                                                <div
                                                    key={`feature-ssl-${Math.random()}`}
                                                    className="row no-gutters feature-row"
                                                >
                                                  <div className="col-12 col-sm-12 col-md-9">
                                                    <div className="row no-gutters">
                                                      <div className="col-12 col-sm-12 col-md-4">

                                                          {activeLanguage &&
                                                          (
                                                                <div className="feature-value text-center">
                                                                {feature.name[activeLanguage.code]}
                                                                  {feature.translate ?
                                                                      (
                                                                            <div>
                                                                              {feature["mk61"][activeLanguage.code]}
                                                                            </div>
                                                                        )
                                                                      :  (<div>
                                                                          {feature["mk61"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk61"])}
                                                                        </div>)
                                                                  }
                                                                </div>
                                                            )
                                                          }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            )
                                        )}
                                      </div>
                                    </FeaturesTable>
                                )}
                              </div>

                            </div>
                          </div>
                        </div>

                        {!isMobileOnly && (
                            <FeaturesTable className="row no-gutters">
                              <div className="col-12 col-sm-12 col-md-12">
                                {features.map(
                                    (feature) => (
                                        <div
                                            key={`feature-ssl-${Math.random()}`}
                                            className="row no-gutters feature-row"
                                        >
                                          <div className="col-12 col-sm-12 col-md-3 feature-wrapper">
                                            <div className="feature">
                                              {activeLanguage &&
                                              (feature.name[activeLanguage.code])
                                              }
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-9">
                                            <div className="row no-gutters">
                                              {feature.translate ?
                                                  (
                                                      <>
                                                        <div
                                                            className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                            key={`ssl-feature-${Math.random()}`}
                                                        >
                                                          <div className="feature-value text-center">
                                                            {activeLanguage &&
                                                            parse(feature["mk41"][activeLanguage.code])
                                                            }
                                                          </div>
                                                        </div>
                                                        <div
                                                            className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                            key={`ssl-feature-${Math.random()}`}
                                                        >
                                                          <div className="feature-value text-center">
                                                            {activeLanguage &&
                                                            parse(feature["mk51"][activeLanguage.code])
                                                            }
                                                          </div>
                                                        </div>
                                                        <div
                                                            className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                            key={`ssl-feature-${Math.random()}`}
                                                        >
                                                          <div className="feature-value text-center">
                                                            {activeLanguage &&
                                                            parse(feature["mk61"][activeLanguage.code])
                                                            }
                                                          </div>
                                                        </div>
                                                      </>
                                                  ) : (
                                                      <>
                                                      <div
                                                          className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                          key={`ssl-feature-${Math.random()}`}
                                                      >
                                                        <div className="feature-value text-center">
                                                          {feature["mk41"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk41"])}
                                                        </div>
                                                      </div>
                                                <div
                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                key={`ssl-feature-${Math.random()}`}
                                                >
                                                <div className="feature-value text-center">
                                              {feature["mk51"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk51"])}
                                                </div>
                                                </div>
                                                <div
                                                className="col-12 col-sm-12 col-md-4 feature-value-wrapper"
                                                key={`ssl-feature-${Math.random()}`}
                                                >
                                                <div className="feature-value text-center">
                                              {feature["mk61"] === true ? <StyledAvaiable src={check_success} /> : parse(feature["mk61"])}
                                                </div>
                                                </div>
                                                      </>
                                                  )}


                                            </div>
                                          </div>
                                        </div>
                                    )
                                )}
                              </div>
                            </FeaturesTable>
                        )}
                      </div>
                    </div>
                  </div>
                </FeaturesTableSection>


                <StypedTop className="row">
                  <div className="col-12 col-sm-12 col-md-7">
                    <SectionTitle>
                      {translate("what-does-a-dedicated-server-service-include")}
                    </SectionTitle>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate("the-server-is-up-and-running -99%-of-the-time")}
                    </StyledBulletPoint>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate("troubleshooting-a-potential-hardware-problem-by-MKhost")}
                    </StyledBulletPoint>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate("constant-server-access")}
                    </StyledBulletPoint>
                    <StyledBulletPoint>
                      <StyledCheck src={check_success} />{" "}
                      {translate("upon-agreement-with-the-client-on-the-first-setup-of-the-server-by-MKhost-an-initial-setting")}
                    </StyledBulletPoint>


                  </div>
                </StypedTop>


              </div>
              </div>
            </div>
        </div>

      </StyledWWhyChooseDedicatedServerSection>

    )}
  </Translate>
);
export default WhyChooseDedicatedServerSection;
