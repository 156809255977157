import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Perk from "../../Perk";

const StyledWindowsHostingAdvancedFeaturesSection = styled.div`
  padding: ${props => (props.isMobile ? "30px 0" : "75px 0")};
`;

const WindowsHostingAdvancedFeaturesSection = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledWindowsHostingAdvancedFeaturesSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("advanced-features-included-in-all-plans")}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "our-windows-web-hosting-offers-the-power-and-flexibility-of-parallels-plesk-panel,-iis,-microsoft-sql-server,-and-a-wide-variety-of-other-hosting-features,-including-site-building-tools-and-templates"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("programming")}
                    icon="websites"
                    color="greenBase"
                    backgroundColor="green1"
                    items={
                      activeLanguage && activeLanguage.code === "mk"
                        ? [
                            "Класично ASP",
                            "ASP.NET 3.5, 4.7",
                            "ASP.NET MVC 3/4/5",
                            "Node.js",
                            "Silverlight 4/5",
                            "PHP 5.6, 7.1, 7.4, 8.1",
                            "Zend Optimizer / Zend Guard Loader",
                            "Microsoft .NET Core 2.1 / 2.2 / 3.0 / 3.1 / 5.0 / 6.0 / 7.0"
                          ]
                        : [
                            "Classic ASP",
                            "ASP.NET 3.5, 4.7",
                            "ASP.NET MVC 3/4/5",
                            "Node.js",
                            "Silverlight 4/5",
                            "PHP 5.6, 7.1, 7.4, 8.1",
                            "Zend Optimizer / Zend Guard Loader",
                            "Microsoft .NET Core 2.1 / 2.2 / 3.0 / 3.1 / 5.0 / 6.0 / 7.0"
                          ]
                    }
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("database")}
                    icon="hosting"
                    color="greenBase"
                    backgroundColor="green1"
                    items={
                      activeLanguage && activeLanguage.code === "mk"
                        ? [
                            "SQL Server 2012/2014/2016/2019",
                            "Оддалечен (Remote) пристап до MSSQL бази",
                            "SQL Backup и SQL Restore",
                            "Овозможен студио пристап за SQL манаџмент",
                            "SQL Server Local DB",
                            "MySQL5 & phpMyAdmin",
                            "Пристап до база"
                          ]
                        : [
                            "SQL Server 2012/2014/2016/2019",
                            "Remote Access to MSSQL DBs",
                            "SQL Backup and SQL Restore",
                            "SQL Management Studio Access Allowed",
                            "SQL Server Local DB",
                            "MySQL5 & phpMyAdmin",
                            "Database access"
                          ]
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWindowsHostingAdvancedFeaturesSection>
    )}
  </Translate>
);

export default WindowsHostingAdvancedFeaturesSection;
