import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import Perk from "../../Perk";
import { isMobileOnly } from "react-device-detect";

const StyledWhyPurchaseSSLSection = styled.div`
  padding: ${props => (props.isMobile ? "75px 0 30px 0" : "75px 0")};
`;

const Feature = styled.div`
  margin-bottom: 30px;
  & .circled-icon {
    margin-bottom: 20px;
  }
`;

const WhyPurchaseSSLSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWhyPurchaseSSLSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <SectionTitle>
                    {translate("why-purchase-ssl-for-your-website")}?
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "ssl-certificates-secure-your-website-and-your-customer-s-information-so-that-any-information-enetered-on-any-page-is-always-protected"
                    )}
                  </SectionDescription>
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("better-google-ranking")}
                    description={() => (
                      <span>
                        {translate(
                          "the-worlds-largest-search-engine-is-crucial-for-your-organic-development-and-the-expansion-of-the-customer-base-and-customer-base-by-adding-the-https-protocol,-you-will-also-improve-your-position-and-rankings-on-google"
                        )}
                      </span>
                    )}
                    icon="ranking"
                    color="greenBase"
                    backgroundColor="green1"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("greater-customer-trust")}
                    description={() => (
                      <span>
                        {translate(
                          "by-adding-an-ssl-certificate,-your-website-gets-a-secure-button-that-is-a-guarantee-for-visitors-that-the-place-safe-to-use-or-leave-data-online-purchase"
                        )}
                      </span>
                    )}
                    icon="contacts"
                    color="yellowBase"
                    backgroundColor="yellow1"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-4 offset-md-4">
                  <Perk
                    title={translate("secure-acceptance-of-credit-cards")}
                    description={() => (
                      <span>
                        {translate(
                          "the-ssl-certificate-enables-secure-online-credit-card-transactions-using-your-web-site,-allowing-users-to-feel-safe-when-using-your-services"
                        )}
                      </span>
                    )}
                    icon="lock"
                    color="redBase"
                    backgroundColor="red1"
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-4">
                  <Perk
                    title={translate("data-protection")}
                    description={() => (
                      <span>
                        {translate(
                          "the-ssl-certificate-protects-the-sensitive-information-of-your-visitors-and-customers-by-encrypting-the-data-they-send-with-you.-after-you-receive-them,-they-are-decrypted"
                        )}
                      </span>
                    )}
                    icon="privacy"
                    color="blueBase"
                    backgroundColor="blue1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledWhyPurchaseSSLSection>
    )}
  </Translate>
);
export default WhyPurchaseSSLSection;
