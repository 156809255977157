import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import parse from "html-react-parser";
import { isMobileOnly } from "react-device-detect";

import CircledIcon from "../../CircledIcon";
import SecondaryButton from "../../Buttons/SecondaryButton";

import { CLIENTS_APP_DOMAIN } from "../../../services/endpoints";

const StyledWorksOnAllDevicesWrapper = styled.div`
  padding: ${props => (props.isMobile ? "270px 0" : "180px 0")};
  margin-left: -30px;
  margin-right: -30px;
  background-color: ${props => props.theme.greenBase};
  position: relative;
  margin-bottom: ${props => (props.isMobile ? "40px" : "0px")};

  & > div {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const StyledTablet = styled.img`
  position: absolute;
  top: 0;
  left: ${props => (props.isMobile ? "-7%" : "7%")};
  width: ${props => props.isMobile && "360px"};

  @media (min-width: 1025px) and (max-width: 1600px) {
    left: -11%;
  }
`;
const StyledPhone = styled.img`
  position: absolute;
  bottom: 0;
  right: 15%;
  width: ${props => props.isMobile && "200px"};

  @media (min-width: 1025px) and (max-width: 1600px) {
    right: 5%;
  }
`;

const StyledTitle = styled.h2`
  margin: 0;
  font-size: ${props => (props.isMobile ? "34px" : "54px")};
  font-weight: 600;
  line-height: ${props => (props.isMobile ? "1.41" : "1.19")};
  color: ${props => props.theme.white};
  text-align: center;
  margin-bottom: 20px;
`;

const StyledDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.64;
  text-align: center;
  color: ${props => props.theme.white};
`;

const StyledCircledIconWrapper = styled.div`
  & .circled-icon {
    margin: 0 auto;
  }
  display: block;
  margin-bottom: 20px;
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 30px;
`;

const WorksOnAllDevicesSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledWorksOnAllDevicesWrapper isMobile={isMobileOnly} className="row">
        <StyledTablet
          src="/assets/images/image_tablet.png"
          alt=""
          isMobile={isMobileOnly}
        />
        <div className="col-12 col-sm-12 col-md-12">
          <div className="container text-center">
            <StyledCircledIconWrapper>
              <CircledIcon
                icon="tablet"
                backgroundColor="white"
                color="greenBase"
              />
            </StyledCircledIconWrapper>
            <StyledTitle isMobile={isMobileOnly}>
              {parse(translate("works-on-all-devices"))}
            </StyledTitle>
            <StyledDescription>
              {parse(translate("works-on-all-devices-description"))}
            </StyledDescription>

            <a href={CLIENTS_APP_DOMAIN}>
              <StyledSecondaryButton size="l">
                {translate("try-it")}
              </StyledSecondaryButton>
            </a>
          </div>
        </div>
        <StyledPhone
          src="/assets/images/image_smartphone.png"
          alt=""
          isMobile={isMobileOnly}
        />
      </StyledWorksOnAllDevicesWrapper>
    )}
  </Translate>
);

export default WorksOnAllDevicesSection;
