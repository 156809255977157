import React from "react";
import { toastSuccess, toastDanger } from "../components/ToastMessage";

export default function displayToastMessageForResponse(
  context,
  response,
  link = null,
  linkTitle = null
) {
  if (!response) {
    toastDanger(context, "General Error");
    return;
  }

  if (response.error) {
    toastDanger(
      context,
      messageWithAction(response.messages[0], link, linkTitle) || ""
    );
    return;
  }

  toastSuccess(
    context,
    messageWithAction(response.messages[0], link, linkTitle) || ""
  );
}

function messageWithAction(message, action, actionTitle) {
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">{message}</div>
      {action &&
        actionTitle && (
          <div className="col-12 col-sm-12 col-md-12">
            <a href={action}>{actionTitle}</a>
          </div>
        )}
    </div>
  );
}
