import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";

import Container from "../../Containers/Container";

const StyledWhoIsSearchResultsInfo = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledTable = styled(Container)`
  margin-bottom: 20px;
`;

const StyledHeader = styled.div`
  font-size: 16px;
  line-height: 1.38;
  color: ${props => props.theme.neutral4};
  padding: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.neutral3};
  text-transform: uppercase;
`;

const StyledRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutral3};

  &:last-child {
    border-bottom: 0;
  }
`;

const StyledKey = styled.div`
  padding: 10px 30px;
  padding-left: 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${props => props.theme.neutral5};
`;

const StyledValue = styled.div`
  padding: 10px 30px;
  padding-left: ${props => props.isMobile && "45px"};
  font-size: 16px;
  line-height: 1.38;
  color: ${props => props.theme.neutralBase};
`;

const WhoIsSearchResultsInfo = props => (
  <Translate>
    {({ translate }) => (
      <StyledWhoIsSearchResultsInfo className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {props.results.info && (
            <StyledTable>
              <StyledHeader>
                {translate("pages.domains.whois.domain-information-for")}{" "}
                {props.results.info.domain}
              </StyledHeader>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.domain-name")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.info.domain}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.registrar")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.info.registrar}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.registered-on")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.info.registered}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.valid-until")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.info.expire}
                  </StyledValue>
                </div>
              </StyledRow>
            </StyledTable>
          )}

          {props.results.registrant && (
            <StyledTable>
              <StyledHeader>
                {translate("pages.domains.whois.registrant")}
              </StyledHeader>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.name")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.registrant.name}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.email")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.registrant["e-mail"]}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.phone")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.registrant.phone}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.address")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.registrant.address
                      .filter(
                        addressItem =>
                          addressItem.length > 0 && addressItem !== "-"
                      )
                      .join(", ")}
                  </StyledValue>
                </div>
              </StyledRow>
            </StyledTable>
          )}

          {props.results.administrative && (
            <StyledTable>
              <StyledHeader>
                {translate("pages.domains.whois.administrative-contact")}
              </StyledHeader>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.name")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.administrative.name}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.email")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.administrative["e-mail"]}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.phone")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.administrative.phone}
                  </StyledValue>
                </div>
              </StyledRow>
            </StyledTable>
          )}

          {props.results.technical && (
            <StyledTable>
              <StyledHeader>
                {translate("pages.domains.whois.technical-contact")}
              </StyledHeader>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.name")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.technical.name}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.email")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.technical["e-mail"]}
                  </StyledValue>
                </div>
              </StyledRow>
              <StyledRow>
                <div className="row">
                  <StyledKey className="col-12 col-sm-12 col-md-3">
                    {translate("pages.domains.whois.phone")}
                  </StyledKey>
                  <StyledValue
                    className="col-12 col-sm-12 col-md-9"
                    isMobile={isMobileOnly}
                  >
                    {props.results.technical.phone}
                  </StyledValue>
                </div>
              </StyledRow>
            </StyledTable>
          )}

          {props.results.name_servers && (
            <StyledTable>
              <StyledHeader>
                {translate("pages.domains.whois.nameservers")}
              </StyledHeader>
              {props.results.name_servers.nserver.map((nameServer, index) => (
                <StyledRow key={`ns-${index}`}>
                  <div className="row">
                    <StyledKey className="col-12 col-sm-12 col-md-3">
                      {translate("pages.domains.whois.nameserver")}
                    </StyledKey>
                    <StyledValue
                      className="col-12 col-sm-12 col-md-9"
                      isMobile={isMobileOnly}
                    >
                      {nameServer}
                    </StyledValue>
                  </div>
                </StyledRow>
              ))}
            </StyledTable>
          )}
        </div>
      </StyledWhoIsSearchResultsInfo>
    )}
  </Translate>
);
export default WhoIsSearchResultsInfo;
