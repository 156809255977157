import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";

import PrimaryButton from "../Buttons/PrimaryButton";

import CustomTooltip from "../CustomTooltip";

import { ROUTE_DOMAINS_REGISTER_WITHOUT_PARAMS } from "../../routes";

const StyledDomainOfTheMonth = styled.div`
  border: 1px solid ${props => props.theme.blueBase};
  border-radius: 3px;
`;

const StyledTop = styled.div`
  padding: 40px 30px;
  background-color: ${props => props.theme.blueBase};
  color: ${props => props.theme.white};
`;

const StyledBottom = styled.div`
  padding: 36px 30px;
  padding-bottom: 30px;
  background-color: ${props => props.theme.white};
`;

const StyledExtension = styled.h3`
  display: block;
  margin: 0;
  font-size: 40px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
`;

const StyledInfo = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  margin-bottom: 25px;
`;

const StyledDiscountedPrice = styled.span`
  display: inline-block;
  font-size: 18px;
  color: ${props => props.theme.neutral5};
  text-decoration: line-through;
  margin-right: 10px;
`;
const StyledPrice = styled.span`
  display: inline-block;
  font-size: 30px;
  font-weight: 600;
  color: ${props => props.theme.blueBase};
  margin-bottom: 30px;
`;

const StyledPeriod = styled.span`
  font-size: 22px;
  color: ${props => props.theme.blueBase};
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: ${props => props.theme.white};
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.42;
  color: ${props => props.theme.white};
`;

const DomainOfTheMonth = ({ domain }) => (
  <Translate>
    {({ translate }) => (
      <StyledDomainOfTheMonth>
        <StyledTop>
          <StyledTitle>{translate("domain-of-the-month")}</StyledTitle>
          <StyledDescription>
            {translate(
              "be-on-the-lookout-for-domains-of-the-month-for-special-offers-and-discounts-on-ntlds"
            )}
          </StyledDescription>
        </StyledTop>
        <StyledBottom>
          <StyledExtension>{domain.extension}</StyledExtension>
          <StyledInfo>{domain.description}</StyledInfo>
          <StyledPrice>
            {domain.discountedPrice && (
              <StyledDiscountedPrice>
                {domain.currency}
                {domain.discountedPrice}
              </StyledDiscountedPrice>
            )}
            {`${domain.currency} ${domain.price}`}
            <StyledPeriod>
              {" "}
              /{domain.period}
              {domain.currency.toLowerCase() === "мкд" && (
                <CustomTooltip
                  inline={true}
                  content={translate("price-without-vat")}
                >
                  <span>*</span>
                </CustomTooltip>
              )}
            </StyledPeriod>
          </StyledPrice>
          <Link rel="noopener noreferrer" target="_blank" to={ROUTE_DOMAINS_REGISTER_WITHOUT_PARAMS + `?ext=${domain.extension}`}>
            <PrimaryButton size="l">{translate("register-now")}</PrimaryButton>
          </Link>
        </StyledBottom>
      </StyledDomainOfTheMonth>
    )}
  </Translate>
);

export default DomainOfTheMonth;
