import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import JobPostsSection from "../../components/Sections/JobPostsSection";

// import AccordionItem from "../../components/Accordion/AccordionItem";
// import ContentWidget from "../../components/ContentWidget";
import SectionDescription from "../../components/SectionDescription";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import BulletPoint from "../../components/BulletPoint";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "-344" : "-364px")};
`;

const jobs = [


  {
    position: {
      mk: "Оглас за работа во МКхост (Агент за корисничка поддршка)",
      en: "Оглас за работа во МКхост (Агент за корисничка поддршка)"
    },
    isOpen: true,
    description: {
      mk: "Аплицирај до: 30.10.2024",
      en: "Аплицирај до : 30.10.2024"
    },
    link: `rabota-vo-mkhost-agent-customer-support`
  }
]



  ;
// {
//   position: {
//     mk: "Оглас за пракса во МКхост (Систем администратор)",
//     en: "MKhost Internship (System administrator)"
//   },
//   isOpen: false,
//   description: {
//     mk: "Аплицирај до: 01.06.2019",
//     en: "Apply by: 1 June 2019"
//   },
//   link: `praksa-vo-mkhost-system-administrator`
// },
/*
{
position: {
  mk: "Оглас за работа во МКхост (Систем администратор)",
  en: "Open job position at MKhost (System administrator)"
},
isOpen: true,
description: {
  mk: "Аплицирај до: 01.04.2022",
  en: "Аплицирај до: 01.04.2022"
},
link: `rabota-vo-mkhost-system-administrator`
},
*/

// {
//  position: {
//    mk: "Оглас за работа во МКхост (FrontEnd Developer)",
//     en: "Оглас за работа во МКхост (FrontEnd Developer)"
//    },
//  isOpen: true,
// description: {
//     mk: "Аплицирај до: 15.12.2022",
//     en: "Аплицирај до: 15.12.2022"
// },
//   link: `rabota-vo-mkhost-web-developer`
// },
/*{
  position: {
    mk: "Оглас за работа во МКхост (Агент за продажба и поддршка)",
    en: "Оглас за работа во МКхост (Агент за продажба и поддршка)"
  },
  isOpen: true,
  description: {
    mk: "Аплицирај до: 01.04.2022",
    en: "Аплицирај до: 01.04.2022"
  },
  link: `rabota-vo-mkhost-agent-po-prodazba`
},


{
  position: {
    mk: "Оглас за работа во МКхост (Специјалист за техничка поддршка)",
    en: "Оглас за работа во МКхост (Специјалист за техничка поддршка)"
  },
  isOpen: true,
  description: {
    mk: "Аплицирај до: 01.04.2022",
    en: "Аплицирај до: 01.04.2022"
  },
  link: `rabota-vo-mkhost-specijalist-tehnicka-poddrska`
}
   */



class OtherCareersPage extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <React.Fragment>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].other_careers.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].other_careers.meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].other_careers.meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={seoData[activeLanguage.code].other_careers.title}
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].other_careers.meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
                <meta
                  name="twitter:title"
                  content={seoData[activeLanguage.code].other_careers.title}
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].other_careers.meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_control-panel.png`}
                />
              </Helmet>
            )}
            <Hero
              title={translate("build-your-career-at-mkhost")}
              backgroundImage="/assets/backgrounds/bg-3-1.svg"
              backgroundPosition={isMobileOnly ? "bottom" : "top"}
              backgroundPositionY={isMobileOnly ? "0" : "35px"}
              backgroundSize={isMobileOnly ? "cover" : "auto"}
              paddingBottom={isMobileOnly && "0"}
              leftClassName="col-12 col-sm-12 col-md-6"
              rightClassName="col-12 col-sm-12 col-md-6"
              subtitle={() => (
                <React.Fragment>
                  <SectionDescription>
                    {translate(
                      "our-team-is-the-most-important-factor-in-our-company.-we-continuously-look-for-new-potential-members-who-can-help-us-improve-our-products-and-services"
                    )}
                  </SectionDescription>
                  <SectionDescription>
                    <BulletPoint>
                      {translate("teamwork")} {translate("oriented")}
                    </BulletPoint>
                    <BulletPoint>
                      {translate("friendly")} {translate("working-environment")}
                    </BulletPoint>
                    <BulletPoint>
                      {translate("dynamic")} {translate("workflow")}
                    </BulletPoint>
                  </SectionDescription>
                </React.Fragment>
              )}
            >
              {!isMobileOnly && <StyledBackgroundSpacer height="250px" />}
            </Hero>
            <JobPostsSection jobs={jobs} />
          </React.Fragment>
        )}
      </Translate>
    );
  }
}
export default OtherCareersPage;
