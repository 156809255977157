import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import { isMobileOnly } from "react-device-detect";

import SmallTitle from "../../SmallTitle";
import SmallDescription from "../../SmallDescription";
import Container from "../../Containers/Container";
import CustomTooltip from "../../CustomTooltip";
import PrimaryButton from "../../Buttons/PrimaryButton";

import { ROUTE_OTHER_CONTACT } from "../../../routes";

const StyledOneTimePremiumSupportSection = styled.div`
  padding: ${props => (props.isMobile ? "40px 0 70px 0" : "75px 0")};
`;

const StyledContainer = styled(Container)`
  padding: 30px;
  margin-bottom: 30px;
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 26px;
  font-weight: 700;
  color: ${props => props.theme.blueBase};
`;

const StyledPeriod = styled.span`
  font-size: 16px;
  color: ${props => props.theme.blueBase};
  font-weight: 400;
`;

const StyledSmallDescription = styled(SmallDescription)`
  margin-bottom: 50px;
`;

const OneTimePremiumSupportSection = () => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledOneTimePremiumSupportSection
        className="row"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <SectionTitle>
                    {translate(
                      "need-more-help?-try-our-one-time-premium-support-options"
                    )}
                  </SectionTitle>
                  <SectionDescription>
                    {translate(
                      "our-one-time-premium-support-options-can-be-used-at-any-time-for-any-of-your-products-or-services-purchased-from-us"
                    )}
                    .
                  </SectionDescription>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-5">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("addon-domain-registration")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate(
                        "we-will-migrate-1-addon-domain-to-your-mkhost-account"
                      )}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 text-left">
                        <StyledPrice>
                          {activeLanguage && activeLanguage.code === "mk" ? (
                            <span>
                              990 МКД
                              <CustomTooltip
                                inline={true}
                                content={translate("price-without-vat")}
                              >
                                <span />
                              </CustomTooltip>
                            </span>
                          ) : (
                            <span>€16.95</span>
                          )}
                          <StyledPeriod>
                            {" "}
                            /{translate("domain")}
                            {activeLanguage && activeLanguage.code === "mk" && (
                              <CustomTooltip
                                inline={true}
                                content={translate("price-without-vat")}
                              >
                                <span>*</span>
                              </CustomTooltip>
                            )}
                          </StyledPeriod>
                        </StyledPrice>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 text-right">
                        <Link to={ROUTE_OTHER_CONTACT}>
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("contact-us")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-5">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("google-cloud-offisite-backup")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate("google-cloud-offisite-backup-description")}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 text-left">
                        <StyledPrice>
                          {activeLanguage && activeLanguage.code === "mk" ? (
                            <span>1990 МКД</span>
                          ) : (
                            <span>€34.07</span>
                          )}
                          <StyledPeriod>
                            {" "}
                            /{translate("backup")}
                            {activeLanguage && activeLanguage.code === "mk" && (
                              <CustomTooltip
                                inline={true}
                                content={translate("price-without-vat")}
                              >
                                <span>*</span>
                              </CustomTooltip>
                            )}
                          </StyledPeriod>
                        </StyledPrice>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 text-right">
                        <Link to={ROUTE_OTHER_CONTACT}>
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("contact-us")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-5">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("backup-from-secondary")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate("backup-from-secondary-description")}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 text-left">
                        <StyledPrice>
                          {activeLanguage && activeLanguage.code === "mk" ? (
                            <span>
                              990 МКД
                              <CustomTooltip
                                inline={true}
                                content={translate("price-without-vat")}
                              >
                                <span>*</span>
                              </CustomTooltip>
                            </span>
                          ) : (
                            <span>€16.95</span>
                          )}
                        </StyledPrice>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 text-right">
                        <Link to={ROUTE_OTHER_CONTACT}>
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("contact-us")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
                <div className="col-12 col-sm-12 col-md-5">
                  <StyledContainer>
                    <SmallTitle>
                      {translate("full-restore-from-secondary-backup")}
                    </SmallTitle>
                    <StyledSmallDescription>
                      {translate(
                        "full-restore-from-secondary-backup-description"
                      )}
                    </StyledSmallDescription>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 text-left">
                        <StyledPrice>
                          {activeLanguage && activeLanguage.code === "mk" ? (
                            <span>
                              990 МКД
                              <CustomTooltip
                                inline={true}
                                content={translate("price-without-vat")}
                              >
                                <span>*</span>
                              </CustomTooltip>
                            </span>
                          ) : (
                            <span>€16.95</span>
                          )}
                        </StyledPrice>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 text-right">
                        <Link to={ROUTE_OTHER_CONTACT}>
                          <PrimaryButton
                            className={isMobileOnly && "btn-block mt-2"}
                          >
                            {translate("contact-us")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </div>
                  </StyledContainer>
                </div>
                <div className="col-md-10">
                  {activeLanguage && activeLanguage.code === "mk" && (
                    <p className="float-right">
                      *Во цената не е вклучено 18% ДДВ
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledOneTimePremiumSupportSection>
    )}
  </Translate>
);
export default OneTimePremiumSupportSection;
