import styled from "styled-components";

const InputHelpBlock = styled.p`
  min-width: 250px;
  font-size: 12px;
  color: ${props => props => {
    const validColor = props.theme.greenBase;
    const invalidColor = props.theme.redBase;
    const normalColor = props.theme.neutral4;
    if (!props.valid && !props.invalid) return normalColor;
    if (props.valid && !props.invalid) return validColor;
    if (!props.valid && props.invalid) return invalidColor;
  }};
  line-height: 1.5;
  margin-bottom: 0;
  @media (max-width: 425px) {
    position: relative !important;
  }
`;

InputHelpBlock.defaultProps = {
  className: "input-help-block"
};

export default InputHelpBlock;
