import React from "react";
import styled from "styled-components";

import Icon from "../Icon";

const StyledAccordionItem = styled.div`
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.neutral3};
  border-radius: 3px;
  margin-bottom: 10px;
`;

const StyledTitle = styled.h3`
  position: relative;
  margin: 0;
  padding: 15px;
  padding-left: 50px;
  font-size: 16px;
  line-height: 1.38;
  color: ${props => props.theme.blueBase};
  cursor: pointer;
`;

const StyledContent = styled.div`
  padding: 15px;
  padding-left: 50px;
  padding-right: 40px;
  padding-bottom: 30px;
  & img {
    max-width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 15px;
  top: 27px;
  color: ${props => props.theme.neutral4};
`;

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  onToggle = () => {
    this.setState({ opened: !this.state.opened });
  };

  render() {
    return (
      <StyledAccordionItem>
        <StyledTitle onClick={() => this.onToggle()}>
          <StyledIcon
            icon={this.state.opened ? "chevron_up" : "chevron_down"}
          />
          {this.props.title}
        </StyledTitle>
        {this.state.opened && (
          <StyledContent>{this.props.children}</StyledContent>
        )}
      </StyledAccordionItem>
    );
  }
}

export default AccordionItem;
