import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import Slider from "react-slick";
import { Helmet } from "react-helmet";

import Hero from "../../components/Hero";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";
import BlogPostCard from "../../components/Cards/BlogPostCard";
import { AccordionItem } from "../../components/Accordion";
import ContentWidget from "../../components/ContentWidget";
import LearnMoreSection from "../../components/Sections/LearnMoreSection";
import TestimonialsSection from "../../components/Sections/TestimonialsSection";
import DomainsSearchSection from "../../components/Sections/DomainsSearchSection";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import HostingPlan from "../../components/HostingPlan";

import HostingPageHostingPlans from "../../components/Sections/HostingPageHostingPlans";
import PremiumPackageSection from "../../components/Sections/PremiumPackageSection";
import PersonalizedServicesSection from "../../components/Sections/PersonalizedServicesSection";
import ThirdPartyControlPanelSection from "../../components/Sections/ThirdPartyControlPanelSection";
import TechnologyWeUseSection from "../../components/Sections/TechnologyWeUseSection";
import OurPortfolioSection from "../../components/Sections/OurPortfolioSection";
import ProjectQuoteFormSection from "../../components/Sections/ProjectQuoteFormSection";
import MostPopularArticlesSection from "../../components/Sections/MostPopularArticlesSection";

import seoData from "../../seo/seoData";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import { FAQ_CATEGORY_WEB_DESIGN } from "../../config/faq";

const StyledWebDesignPersonalizedPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: -101px;
  }
`;

const StyledPlan = styled.div`
  margin-bottom: 30px;
`;

const StyledQuote = styled.div`
  background-image: url("/assets/backgrounds/bg-1-2.svg");
  background-position: bottom;
  background-position-y: 90px;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 75px 0;
`;

const StyledQuoteSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: 301px;
`;

class WebDesignPersonalizedPage extends React.Component {
  constructor(props) {
    super(props);
    this.quoteRef = null;
  }

  onGoToQuoteForm = () => {
    if (this.quoteRef) {
      this.quoteRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledWebDesignPersonalizedPage>
            {activeLanguage && activeLanguage.code && (
              <Helmet>
                <title>
                  {seoData[activeLanguage.code].webdesign_personalized.title}
                </title>
                <meta
                  name="keywords"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized
                      .meta_keywords
                  }
                />
                <meta
                  name="description"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized
                      .meta_description
                  }
                />
                <meta
                  property="og:title"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized.title
                  }
                />
                <meta
                  property="og:description"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized
                      .meta_description
                  }
                />
                <meta
                  property="og:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_personlized-solutions.png`}
                />

                <meta
                  name="twitter:title"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized.title
                  }
                />
                <meta
                  name="twitter:description"
                  content={
                    seoData[activeLanguage.code].webdesign_personalized
                      .meta_description
                  }
                />
                <meta
                  name="twitter:image"
                  content={`${ROUTE_WEBSITE_DOMAIN}/assets/share/illustration_personlized-solutions.png`}
                />
              </Helmet>
            )}
            <Hero
              title={() => (
                <span>
                  {translate("personalized")}
                  <br />
                  {translate("web-solutions")}
                </span>
              )}
              image="/assets/illustrations/illustration_personlized-solutions.png"
              backgroundImage="/assets/backgrounds/bg-1-1.svg"
              backgroundSize={isMobileOnly ? "cover" : "contain"}
              paddingBottom={isMobileOnly ? "30px" : "0"}
              subtitle={translate(
                "let-our-team-of-designers-and-developers-create-a-custom-solution-for-your-website,-web-app-or-software"
              )}
              action={() => (
                <PrimaryButton onClick={() => this.onGoToQuoteForm()} size="l">
                  {translate("request-an-offer")}
                </PrimaryButton>
              )}
            />
            {!isMobileOnly && (
              <div className="row">
                <BackgroundSpacer height="150px" />
              </div>
            )}
            <PersonalizedServicesSection />
            <OurPortfolioSection />
            <TechnologyWeUseSection />

            <StyledQuote ref={ref => (this.quoteRef = ref)} className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-4">
                        <SectionTitle>
                          {translate("request-an-offer")}
                        </SectionTitle>
                        <SectionDescription>
                          {translate(
                            "tell-us-what-kind-of-project-you-have-in-mind-and-we'll-send-you-an-offer-as-soon-as-possible"
                          )}
                        </SectionDescription>
                      </div>
                      <div className="col-12 col-sm-12 col-md-8">
                        <ProjectQuoteFormSection />
                      </div>
                    </div>
                  </div>
                </div>
                <StyledQuoteSpacer height="215px" />
              </div>
            </StyledQuote>
            <MostPopularArticlesSection faqCategory={FAQ_CATEGORY_WEB_DESIGN} />
          </StyledWebDesignPersonalizedPage>
        )}
      </Translate>
    );
  }
}
export default withRouter(WebDesignPersonalizedPage);
