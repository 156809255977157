import React from "react";
import styled from "styled-components";
import { Translate, withLocalize } from "react-localize-redux";
import parse from "html-react-parser";
import { isMobileOnly } from "react-device-detect";

import DiscountedAmountBadge from "../../../DiscountedAmountBadge";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import SecondaryButton from "../../../Buttons/SecondaryButton";
import Icon from "../../../Icon";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE,
  DOMAIN_STATUS_UNSUPPORTED,
  DOMAIN_STATUS_INVALID
} from "../../../../pages/Domains/DomainStatuses";

import getNoticeForStatus from "../../../../utils/getNoticeForStatus";

import { calculateDomainReducedPrice } from "../../../../services/pricingService";

import { compileRoute, ROUTE_DOMAINS_WHOIS } from "../../../../routes";

const StyledDomainSearchResult = styled.div`
  //padding: 25px;

  & .notice-success,
  & .notice-danger,
  & .notice-info {
    margin-right: 30px;
    vertical-align: top;
  }

  & .notice-danger {
    margin-right: ${props => props.isMobile && "0"};
    display: ${props => props.isMobile && "block"};
    text-align: ${props => props.isMobile && "center"};
  }
`;

const StyledDomain = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-top: ${props => !props.description && "10px"};
`;

const StyledPrice = styled.div`
  display: inline-block;
  float: right;
  color: ${props => props.theme.neutral6};
  font-size: 14px;
  font-weight: 600;

  & .discounted-amount-badge {
    display: inline-block;
    vertical-align: ${props => (props.hasDiscountPrice ? "sub" : "initial")};
  }
`;

const StyledAddToCartButton = styled(PrimaryButton)`
  //display: inline-block;
  //float: right;
  //top: 5px;
`;

const StyledAssignAndTransferButton = styled(PrimaryButton)`
  display: inline-block;
`;

const StyledAssignButton = styled(SecondaryButton)`
  display: inline-block;
  margin-right: 30px;
`;

const StyledRegisterAddToCartButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  top: 5px;
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
`;

const StyledMarginButton = styled.div`
  margin-bottom: 30px;
`;

const StyledNote = styled.div`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
  margin-top: 10px;
`;

const StyledWhoIsLink = styled.a`
  display: inline-block;
  position: relative;
  float: right;
  margin-right: 15px;
  top: 15px;
  font-size: 16px;
  color: ${props => props.theme.blueBase};
`;

const DomainSearchResult = ({
  status,
  domain,
  price = null,
  currency = null,
  onAddToCart = null,
  onAssignDomain = null,
  addedToCart = null,
  submitting = null,
  statusText = null,
  description = null,
  type,
  priceReduce = null,
  activeLanguage,
  translate,
  note = null,
  showWhoIs
}) => {
  const NoticeComponent = getNoticeForStatus(status);

  let domainPrice = null;
  if (type === "register" || type === "transfer") {
    domainPrice =
      domain &&
      domain.pricing &&
      domain.pricing[type] &&
      domain.pricing[type].billing_cycle.derivations[0];
  }

  let transferDescription = null;
  if (description) {
    if (!domainPrice) {
      transferDescription = description;
    } else {
      let calculatedPrice = domainPrice.price.value;
      let calculatedCurrency = domainPrice.price.currency.code;
      if (priceReduce) {
        calculatedPrice = calculateDomainReducedPrice(
          calculatedPrice,
          priceReduce,
          activeLanguage.code
        );
      }
      transferDescription = description
        .replace(
          "${FOR}",
          activeLanguage && activeLanguage.code === "en"
            ? " " + translate("general.for")
            : calculatedPrice > 0
            ? " " + translate("general.for")
            : ""
        )
        .replace(
          "${PRICE}",
          calculatedPrice === 0
            ? translate("general.free").toUpperCase()
            : `${calculatedCurrency} ${calculatedPrice}`.toUpperCase()
        );
    }

    transferDescription = parse(transferDescription);
  }

  return (
    <React.Fragment>
      {type === "register" && !onAssignDomain && (
        <StyledDomainSearchResult isMobile={isMobileOnly}>
          {status && (
            <NoticeComponent>
              {statusText
                ? statusText
                : translate(`general.${status}`) +
                  " " +
                  translate("general.domain")}
            </NoticeComponent>
          )}
          <StyledDomain description={description} isMobile={isMobileOnly}>
            {domain.name}{" "}
            {description && (
              <StyledDescription>{description}</StyledDescription>
            )}
            {note && <StyledNote>{note}</StyledNote>}
          </StyledDomain>

          {showWhoIs && activeLanguage && (
            <StyledWhoIsLink
              href={compileRoute(ROUTE_DOMAINS_WHOIS, {
                language: activeLanguage.code,
                name: domain.name
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              {translate("general.whois")}
            </StyledWhoIsLink>
          )}

          {onAddToCart && typeof onAddToCart === "function" && (
            <StyledRegisterAddToCartButton
              disabled={addedToCart || submitting}
              submitting={submitting}
              onClick={() => onAddToCart(domain, type)}
            >
              <Icon icon="cart" />{" "}
              {addedToCart
                ? translate("added-to-cart")
                : translate("add-to-cart")}
            </StyledRegisterAddToCartButton>
          )}

          {onAssignDomain && typeof onAssignDomain === "function" && (
            <StyledRegisterAddToCartButton
              disabled={addedToCart || submitting}
              submitting={submitting}
              onClick={() => onAssignDomain(domain)}
            >
              <Icon icon="cart" />{" "}
              {addedToCart
                ? translate("added-to-cart")
                : translate("add-to-cart")}
            </StyledRegisterAddToCartButton>
          )}
          {domain &&
            domain.pricing &&
            domain.pricing[type] &&
            status === DOMAIN_STATUS_AVAILABLE && (
              <StyledPrice
                hasDiscountPrice={priceReduce/*&& priceReduce.discountType === type*/}
              >
                <DiscountedAmountBadge
                  amount={{
                    amount:
                      priceReduce /*&& priceReduce.discountType === type*/
                        ? calculateDomainReducedPrice(
                            domain.pricing[type].billing_cycle.derivations[0]
                              .price.value,
                            priceReduce,
                            activeLanguage.code
                          )
                        : domain.pricing[type].billing_cycle.derivations[0]
                            .price.value,
                    discountedAmount:
                      priceReduce /*&& priceReduce.discountType === type*/
                        ? domain.pricing[type].billing_cycle.derivations[0]
                            .price.value
                        : null,
                    currency:
                      domain.pricing[type].billing_cycle.derivations[0].price
                        .currency.prefix,
                    period:
                      domain.pricing[type].billing_cycle.derivations[0].name
                  }}
                />
              </StyledPrice>
            )}
        </StyledDomainSearchResult>
      )}

      {type === "register" && onAssignDomain && (
        <StyledDomainSearchResult>
          {status && (
            <StyledMarginButton className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <NoticeComponent>
                  {statusText ? (
                    <React.Fragment>
                      <StyledDescription>{statusText}</StyledDescription>
                      <StyledDescription>
                        {transferDescription}
                      </StyledDescription>
                    </React.Fragment>
                  ) : (
                    translate(`general.${status}`) +
                    " " +
                    translate("general.domain")
                  )}
                </NoticeComponent>
              </div>
            </StyledMarginButton>
          )}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {onAssignDomain && typeof onAssignDomain === "function" && (
                <StyledAssignButton
                  disabled={addedToCart || submitting}
                  submitting={submitting}
                  onClick={() => onAssignDomain(domain)}
                >
                  <Icon icon="cart" />{" "}
                  {addedToCart
                    ? translate("added-to-cart")
                    : translate(
                        "sections.domain_search.assign_without_register"
                      )}
                </StyledAssignButton>
              )}
              {onAddToCart && typeof onAddToCart === "function" && (
                <StyledAssignAndTransferButton
                  disabled={addedToCart || submitting}
                  submitting={submitting}
                  onClick={() => onAddToCart(domain, type)}
                >
                  <Icon icon="cart" />{" "}
                  {addedToCart
                    ? translate("added-to-cart")
                    : translate("sections.domain_search.assign_and_register")}
                </StyledAssignAndTransferButton>
              )}
            </div>
          </div>
        </StyledDomainSearchResult>
      )}

      {type === "transfer" && (
        <StyledDomainSearchResult>
          {status && (
            <StyledMarginButton className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <NoticeComponent>
                  {statusText ? (
                    <React.Fragment>
                      <StyledDescription>{statusText}</StyledDescription>
                      <StyledDescription>
                        {transferDescription}
                      </StyledDescription>
                    </React.Fragment>
                  ) : (
                    translate(`general.${status}`) +
                    " " +
                    translate("general.domain")
                  )}
                </NoticeComponent>

                {note && <StyledNote>{note}</StyledNote>}
              </div>
            </StyledMarginButton>
          )}

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {onAssignDomain &&
                typeof onAssignDomain === "function" &&
                !onAddToCart &&
                typeof onAddToCart !== "function" && (
                  <StyledAssignAndTransferButton
                    disabled={addedToCart || submitting}
                    submitting={submitting}
                    onClick={() => onAssignDomain(domain)}
                  >
                    <Icon icon="cart" />{" "}
                    {addedToCart
                      ? translate("added-to-cart")
                      : translate(
                          "sections.domain_search.assign_without_transfer"
                        )}
                  </StyledAssignAndTransferButton>
                )}

              {onAssignDomain &&
                typeof onAssignDomain === "function" &&
                onAddToCart &&
                typeof onAddToCart === "function" &&
                !note && (
                  <StyledAssignButton
                    disabled={addedToCart || submitting}
                    submitting={submitting}
                    onClick={() => onAssignDomain(domain)}
                  >
                    <Icon icon="cart" />{" "}
                    {addedToCart
                      ? translate("added-to-cart")
                      : translate(
                          "sections.domain_search.assign_without_transfer"
                        )}
                  </StyledAssignButton>
                )}
              {onAssignDomain &&
                typeof onAssignDomain === "function" &&
                onAddToCart &&
                typeof onAddToCart === "function" &&
                note && (
                  <StyledAssignAndTransferButton
                    disabled={addedToCart || submitting}
                    submitting={submitting}
                    onClick={() => onAssignDomain(domain)}
                  >
                    <Icon icon="cart" />{" "}
                    {addedToCart
                      ? translate("added-to-cart")
                      : translate(
                          "sections.domain_search.assign_without_transfer"
                        )}
                  </StyledAssignAndTransferButton>
                )}
              {onAddToCart && typeof onAddToCart === "function" && (
                <StyledAssignAndTransferButton
                  disabled={addedToCart || submitting}
                  submitting={submitting}
                  onClick={() => onAddToCart(domain, type)}
                >
                  <Icon icon="cart" />{" "}
                  {addedToCart
                    ? translate("added-to-cart")
                    : translate("sections.domain_search.assign_and_transfer")}
                </StyledAssignAndTransferButton>
              )}
            </div>
          </div>
        </StyledDomainSearchResult>
      )}
    </React.Fragment>
  );
};
export default withLocalize(DomainSearchResult);
