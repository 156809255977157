import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { Helmet } from "react-helmet";
import { ROUTE_WEBSITE_DOMAIN } from "../../routes";
import Hero from "../../components/Hero";
import BackgroundSpacer from "../../components/BackgroundSpacer";
import seoData from "../../seo/seoData";
import ReadMoreAboutWebSolution from "../../components/Sections/ReadMoreAboutWebSolution";
import SectionTitle from "../../components/SectionTitle";
import SectionDescription from "../../components/SectionDescription";

import Clock from "../../components/Sections/Clock";
import parse from "html-react-parser";
import YoungEnterpreneursFormSection
  from "../../components/Sections/YoungEnterpreneursFormSection/YoungEnterpreneursFormSection";
import OtherWebSolutionsForm from "../../components/Sections/OtherWebSolutionsForm";

const StyledHostingWebPage = styled.div`
  & .hosting-hosting-plans {
    margin-top: ${props => (props.isMobile ? "60px" : "30px")};
  }
`;

const StyledBackgroundSpacer = styled(BackgroundSpacer)`
  position: absolute;
  z-index: -1;
  top: ${props => (props.isMobile ? "0" : "-87px")};
  left: 0;
  width: 100%;
`;

const StyledFirstWebsiteWrapper = styled.div`
  background-repeat: no-repeat;
  background-position: top;
`;

const StyledQuoteSection = styled.div`
  margin-top: 70px;
  padding: 75px 0;
  padding-bottom: 150px;
`;


class HostingWebPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        deadline: "September, 03, 2021"
      };
  }


  render() {
    return (
        <Translate>
          {({ translate, activeLanguage }) => (
              <StyledHostingWebPage className="row" isMobile={isMobileOnly}>
                {activeLanguage && activeLanguage.code && (
                    <Helmet>
                      <title>
                        {seoData[activeLanguage.code].other_web_solution.title}
                      </title>
                      <meta
                          name="keywords"
                          content={
                            seoData[activeLanguage.code].other_web_solution
                                .meta_keywords
                          }
                      />
                      <meta
                          name="description"
                          content={
                            seoData[activeLanguage.code].other_web_solution
                                .meta_description
                          }
                      />
                      <meta
                          property="og:title"
                          content={
                            seoData[activeLanguage.code].other_web_solution.title
                          }
                      />
                      <meta
                          property="og:description"
                          content={
                            seoData[activeLanguage.code].other_web_solution
                                .meta_description
                          }
                      />
                      <meta
                          property="og:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/letna_og.png`}
                      />

                      <meta
                          name="twitter:title"
                          content={
                            seoData[activeLanguage.code]. other_web_solution.title
                          }
                      />
                      <meta
                          name="twitter:description"
                          content={
                            seoData[activeLanguage.code] .other_web_solution
                                .meta_description
                          }
                      />
                      <meta
                          name="twitter:image"
                          content={`${ROUTE_WEBSITE_DOMAIN}/assets/illustrations/letna_og.png`}
                      />
                    </Helmet>
                )}
                <div className="col-12 col-sm-12 col-md-12">
                  <Hero
                      title={() => (
                          <span>
                    {translate("lets-help-you-in-your-online-business")}
                  </span>
                      )}
                      subtitle={() => (
                        <div>

                        {translate(
                            "mkhost-wants-to-support-young-creatives-who-sell-their-creations")
                      }
                      <br/>

                      <b>{translate(
                          "the-application-lasts-from")
                      }</b>
                        </div>)}
                      image="/assets/illustrations/Letna.png"
                      backgroundImage="/assets/backgrounds/bg-1-1.svg"
                      leftClassName="col-12 col-sm-12 col-md-7"
                      rightClassName="col-12 col-sm-12 col-md-5 "
                      paddingBottom={isMobileOnly ? "0" : "0"}
                      backgroundPosition={isMobileOnly && "top"}
                      backgroundPositionY={isMobileOnly ? "10px" : "115px"}
                      backgroundSize={isMobileOnly ? "cover" : "auto"}
                  >
                    <StyledBackgroundSpacer
                        isMobile={isMobileOnly}
                        height={isMobileOnly ? "187px" : "270px"}
                    />
                  </Hero>


                  <ReadMoreAboutWebSolution/>



                {/*<div className="row">*/}
                {/*  <div className="container">*/}

                {/*<OtherWebSolutionsForm />*/}
                    <StyledFirstWebsiteWrapper className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <StyledQuoteSection className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="row">
                              <div className="container">
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <SectionTitle>
                                      {translate("left-days-offer")}
                                      <Clock deadline={this.state.deadline} />
                                      {parse(translate("days-left"))}

                                    </SectionTitle>
                                    <SectionDescription>
                                      {parse(
                                          translate(
                                              "submite-online-form-application-for-support-small-bussiness"
                                          )
                                      )}
                                      .
                                    </SectionDescription>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <OtherWebSolutionsForm/>
                                    {/*<YoungEnterpreneursFormSection />*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </StyledQuoteSection>
                      </div>
                    </StyledFirstWebsiteWrapper>

                {/*  </div>*/}
                {/*</div>*/}
                </div>

              </StyledHostingWebPage>

          )}
        </Translate>
    );

  }

}
export default withRouter(HostingWebPage);
