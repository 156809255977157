import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import DataContext from "../../../DataContext";

import SplitSection from "../SplitSection";
import SectionTitle from "../../SectionTitle";
import SectionDescription from "../../SectionDescription";
import SecondaryButton from "../../Buttons/SecondaryButton";

import RegisterDomainWidget from "../../RegisterDomainWidget";

import bg2 from "../../../assets/images/bg-2.svg";

import { ROUTE_DOMAINS_NEW_TLDS } from "../../../routes";

const StyledNewDomainExtensionsSection = styled.div`
  padding-top: ${props => (props.isMobile ? "30px" : "60px")};
  padding-bottom: ${props => (props.isMobile ? "30px" : "60px")};
  background-image: url(${bg2});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: ${props => props.isMobile && "cover"};
`;

const FindMoreWrapper = styled.div`
  display: inline-block;
  margin-top: 24px;
  margin-bottom: ${props => props.isMobile && "65px;"};
`;

const StyledDomainWidgetsWrapper = styled.div`
  overflow-y: ${props => props.isMobile && "hidden"}
  overflow-x: ${props => props.isMobile && "auto"}
  width: ${props => props.isMobile && "100%"}
  display: ${props => props.isMobile && "flex"};
  padding: ${props => props.isMobile && "35px 0"};
`;

const NewDomainExtensionsSection = ({ onSelectExtension }) => (
  <Translate>
    {({ translate }) => (
      <StyledNewDomainExtensionsSection isMobile={isMobileOnly} className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <SplitSection
            leftClassName="col-12 col-sm-12 col-md-4"
            rightClassName="col-12 col-sm-12 col-md-8"
            left={() => (
              <React.Fragment>
                <SectionTitle>
                  {translate("sections.new_domain_extensions.title")}
                </SectionTitle>
                <SectionDescription>
                  {translate("sections.new_domain_extensions.description")}
                </SectionDescription>
                <FindMoreWrapper isMobile={isMobileOnly}>
                  <a
                    href={ROUTE_DOMAINS_NEW_TLDS}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SecondaryButton size="l">
                      {translate(
                        "sections.new_domain_extensions.find-out-more"
                      )}
                    </SecondaryButton>
                  </a>
                </FindMoreWrapper>
              </React.Fragment>
            )}
            right={() => (
              <StyledDomainWidgetsWrapper
                className="domain-widgets-wrapper"
                isMobile={isMobileOnly}
              >
                <DataContext.Consumer>
                  {({ domains: { fresh }, currency }) => {
                    const rest =
                      fresh &&
                      currency &&
                      Object.keys(fresh)
                        .slice(0, 4)
                        .map(domain => {
                          return {
                            extension: "." + domain,
                            price: parseFloat(
                              fresh[domain][currency.toLowerCase()].register[
                                "1"
                              ]
                            ).toFixed(currency === "eur" ? 2 : 0),
                            period: translate("year"),
                            currency: currency,
                            description: translate(
                              `new-domains-descriptions.${domain}`
                            )
                          };
                        });

                    return (
                      fresh &&
                      rest &&
                      rest.map(domain => (
                        <RegisterDomainWidget
                          key={`register-domain-widget-${domain.extension}`}
                          {...domain}
                          onSelectExtension={onSelectExtension}
                        />
                      ))
                    );
                  }}
                </DataContext.Consumer>
              </StyledDomainWidgetsWrapper>
            )}
          />
        </div>
      </StyledNewDomainExtensionsSection>
    )}
  </Translate>
);
export default NewDomainExtensionsSection;
