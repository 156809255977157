import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import check_success from "../../../assets/images/check_success.svg";

const StyledBulkDomainsSection = styled.div`
  margin-top: 42px;
  padding-bottom: ${props => !props.isMobile && "150px"};
`;

const StyledTable = styled.div`
  border: 1px solid ${props => props.theme.neutral3};
  margin-bottom: 20px;

  &:first-child {
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.neutral3};
  background-color: ${props => props.theme.blue2};
  padding: ${props => (props.isMobile ? "16px" : "30px")};
`;
const StyledRow = styled.div`
  display: block;
  width: 100%;
  background-color: ${props => props.theme.white};
  &:nth-child(even) {
    background-color: ${props => props.theme.blue1};
  }
  font-size: 20px;
  color: ${props => props.theme.neutral6};
  padding: 20px 0;
  text-align: center;
`;

const StyledTitle = styled.h3`
  margin: 0;
  display: block;
  font-weight: 600;
  font-size: ${props => (props.isMobile ? "15px" : "24px")};
  color: ${props => props.theme.neutralBase};
  line-height: 1.42;
`;

const StyledDescription = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 1.63;
  color: ${props => props.theme.neutral6};
`;

const StyledCheck = styled.img`
  color: ${props => props.theme.greenBase};
`;

const StyledDisclaimer = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 1.43;
  color: ${props => props.theme.neutral6};
`;

const BulkDomainsSection = () => (
  <Translate>
    {({ translate }) => (
      <StyledBulkDomainsSection className="row" isMobile={isMobileOnly}>
        <div className="col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="container">
              <div className="row no-gutters">
                <StyledTable className="col-4 col-sm-4 col-md-4">
                  <StyledHeader isMobile={isMobileOnly}>
                    <StyledTitle isMobile={isMobileOnly}>
                      {translate("number-of-domains")}
                    </StyledTitle>
                    {!isMobileOnly && (
                      <StyledDescription>
                        {translate(
                          "the-more-domains-you-register-with-us,-the-more-discounts-you-get"
                        )}
                      </StyledDescription>
                    )}
                  </StyledHeader>
                  <div className="row no-gutters">
                    <StyledRow>10-15</StyledRow>
                    <StyledRow>16-25</StyledRow>
                    <StyledRow>26-30</StyledRow>
                    <StyledRow>31-40</StyledRow>
                    <StyledRow>41-99</StyledRow>
                    <StyledRow>100+</StyledRow>
                  </div>
                </StyledTable>
                <StyledTable className="col-4 col-sm-4 col-md-4">
                  <StyledHeader isMobile={isMobileOnly}>
                    <StyledTitle isMobile={isMobileOnly}>
                      {translate("discount")} {isMobileOnly && <br />}
                      {isMobileOnly && <br />}
                    </StyledTitle>
                    {!isMobileOnly && (
                      <StyledDescription>
                        {translate(
                          "discounts-apply-to-both-domain-registration-and-domain-renewal-prices"
                        )}{" "}
                        *
                      </StyledDescription>
                    )}
                  </StyledHeader>
                  <div className="row no-gutters">
                    <StyledRow>10%</StyledRow>
                    <StyledRow>15%</StyledRow>
                    <StyledRow>20%</StyledRow>
                    <StyledRow>25%</StyledRow>
                    <StyledRow>30%</StyledRow>
                    <StyledRow>35%+</StyledRow>
                  </div>
                </StyledTable>
                <StyledTable className="col-4 col-sm-4 col-md-4">
                  <StyledHeader isMobile={isMobileOnly}>
                    <StyledTitle isMobile={isMobileOnly}>
                      {translate("extra-support")}
                    </StyledTitle>
                    {!isMobileOnly && (
                      <StyledDescription>
                        {translate(
                          "get-additional-support-for-managing-your-domains"
                        )}
                      </StyledDescription>
                    )}
                  </StyledHeader>
                  <div className="row no-gutters">
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                    <StyledRow>
                      <StyledCheck src={check_success} />
                    </StyledRow>
                  </div>
                </StyledTable>
              </div>
              <div className="row no-gutters">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledDisclaimer>
                    *{" "}
                    {translate(
                      "discounts-are-fixed-for-.mk-domains.-please-contact-us-for-discount-information-regarding-specific-tlds"
                    )}
                    .
                  </StyledDisclaimer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledBulkDomainsSection>
    )}
  </Translate>
);

export default BulkDomainsSection;
